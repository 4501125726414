import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import { getLeagueCupStatus } from "../../core/store/leagues/reducers";
import { fetchLeagueCupStatus } from "../../core/store/leagues/thunks";
import BoldLink from "../BoldLink";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";

type OwnProps = RouteComponentProps<{ leagueId: string }>;

const CupNotStarted: React.FC<OwnProps> = ({ leagueId }) => {
  const leagueNumber = Number(leagueId!) || 0;

  const cupStatus = useSelector((state: RootState) =>
    leagueNumber ? getLeagueCupStatus(state, leagueNumber) : null
  );

  const reduxDispatch = useDispatch<ThunkDispatch>();

  useEffect(() => {
    leagueNumber &&
      !cupStatus &&
      reduxDispatch(fetchLeagueCupStatus(leagueNumber));
  }, [leagueNumber, cupStatus, reduxDispatch]);

  if (!cupStatus) {
    return null;
  }

  return (
    <Wrapper>
      <Main>
        <Copy>
          <Title>{cupStatus.name}</Title>

          <Box mb={4}>
            <BoldLink to={`/leagues/${leagueNumber}/standings/c`}>
              View league standings
            </BoldLink>
          </Box>

          {cupStatus.qualification_event ? (
            <>
              <SubHeading>{`The Cup is scheduled to start in GW${
                cupStatus.qualification_event + 1
              }`}</SubHeading>
              {cupStatus.has_byes ? (
                <>
                  <p>
                    Fixtures will be determined at the end of Gameweek{" "}
                    {cupStatus.qualification_event}.{" "}
                    {cupStatus.qualification_numbers > 2 && (
                      <>
                        If there are {cupStatus.qualification_numbers} teams in
                        the associated league, then each team will have an
                        opponent in Gameweek {cupStatus.qualification_event + 1}
                        . If there are{" "}
                        {cupStatus.qualification_numbers === 4
                          ? "3 teams in the league, 1 team "
                          : `between
                    ${cupStatus.qualification_numbers / 2 + 1} and ${
                              cupStatus.qualification_numbers - 1
                            } teams in the league, some teams `}
                        will receive a bye in Gameweek{" "}
                        {cupStatus.qualification_event + 1} based on their score
                        in Gameweek {cupStatus.qualification_event}.
                      </>
                    )}
                  </p>

                  <p>
                    The starting round of the cup is determined by the number of
                    teams in the associated league and the final will be
                    contested in Gameweek 38.
                  </p>

                  <p>
                    You will not be entered into the cup if you have joined the
                    league after the Gameweek prior to the cup starting.
                  </p>

                  <SubHeading>How the cup works</SubHeading>
                  <p>
                    Each team will be randomly drawn against another unless they
                    have received a bye. The winner (the team with the highest
                    Gameweek score minus any transfer points), will progress to
                    the next round and another random draw, the losers are out!
                    This process continues until the final round when the two
                    remaining teams contest the cup final. If a cup match is
                    drawn, then the following tie-breaks will be applied until a
                    winner is found:
                  </p>
                  <ol>
                    <li>Most goals scored in the Gameweek</li>
                    <li>Fewest goals conceded in the Gameweek</li>
                    <li>Virtual coin toss</li>
                  </ol>
                </>
              ) : (
                <>
                  <p>
                    The top {cupStatus.qualification_numbers} ranked managers in
                    Gameweek {cupStatus.qualification_event} will be entered
                    into the first round of the cup in Gameweek{" "}
                    {cupStatus.qualification_event + 1}. The final will be
                    contested in Gameweek 38.
                  </p>

                  <SubHeading>How the cup works</SubHeading>
                  <p>
                    Each qualifying team will be randomly drawn against another
                    in the first round. The winner (the team with the highest
                    Gameweek score minus any transfer points), will progress to
                    the second round and another random draw, the losers are
                    out! This process continues until the final round when the
                    two remaining teams contest the cup final. If a cup match is
                    drawn, then the following tie-breaks will be applied until a
                    winner is found:
                  </p>
                  <ol>
                    <li>Most goals scored in the Gameweek</li>
                    <li>Fewest goals conceded in the Gameweek</li>
                    <li>Virtual coin toss</li>
                  </ol>
                </>
              )}
            </>
          ) : (
            <p>There is no information available for this cup yet.</p>
          )}
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default CupNotStarted;

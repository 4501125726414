import React from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components/macro";

type Props = {
  name: string;
  color: string;
  label: string;
  onChange: (name: string, color: any) => void;
};

const ColorPickerWrapper = styled.div`
  position: relative;
  display: inline;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: ;
`;

const ColorPickerBox = styled.div`
  display: inline-block;
  padding: 4px;
  margin: 0;
  cursor: pointer;
  background: #eee;
  vertical-align: middle;
`;

const Preview = styled.div`
  position: relative;
  width: 25px;
  height: 20px;
  border: solid 1px #222;
  margin-right: 5px;
  float: left;
  background-color: ${({ color }) => color};
`;

const DropDownArrow = styled.div`
  padding: 2px 0;
  height: 16px;
  line-height: 16px;
  float: left;
  font-size: 10px;
`;

const SyledSketch = styled(SketchPicker)`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.dialog};
  left: 0;
`;

const ColorLabel = styled.div`
  padding-left: 0.5rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.4rem;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.overlay};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const ColorPicker: React.FunctionComponent<Props> = ({
  name,
  label,
  color,
  onChange,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Wrapper>
      <ColorPickerWrapper>
        <ColorPickerBox onClick={(event) => setOpen((open) => !open)}>
          <Preview color={color} />
          <DropDownArrow>▼</DropDownArrow>
        </ColorPickerBox>
        {open && (
          <>
            <Overlay
              onClick={(event) => {
                setOpen(false);
              }}
            />
            {/* Can supply custom swatch */}
            <SyledSketch
              color={color}
              onChange={(color) => onChange(name, color.hex)}
              disableAlpha
            />
          </>
        )}
      </ColorPickerWrapper>
      <ColorLabel>{label}</ColorLabel>
    </Wrapper>
  );
};

export default ColorPicker;

import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "../../../core/store";
import { deleteLeague } from "../../../core/store/leagues/thunks";
import { IPrivateLeagueAdmin } from "../../../core/store/leagues/types";
import Button from "../../Button";
import SubHeading from "../../SubHeading";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

interface IPropsFromDispatch {
  remove: (leagueId: number) => void;
}

type Props = IOwnProps & IPropsFromDispatch;

class Delete extends React.Component<Props> {
  public render() {
    const { league, remove } = this.props;
    return (
      <>
        <SubHeading>Delete League</SubHeading>
        <p>
          To delete the league, click on the button below. The players entered
          in the league will still exist and can enter other leagues if desired.
        </p>
        <Button onClick={() => remove(league.id)}>Delete league</Button>
      </>
    );
  }
}

export { Delete as DeleteTest };

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  remove: (leagueId: number) => {
    if (window.confirm("Are you sure you want to delete this league?")) {
      dispatch(deleteLeague(leagueId));
    }
  },
});

export default connect(null, mapDispatchToProps)(Delete);

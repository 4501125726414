import { rgba } from "polished";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import { IElement } from "../core/store/elements/types";
import { ITeam } from "../core/store/teams/types";
import { integerToMoney, isMoneyStat } from "../core/utils/money";
import ElementDialogButton from "./ElementDialogButton";
import ElementInTable from "./ElementInTable";
import { BorderedCell, ElementRow } from "./ElementTable";

const StyledElementListRow = styled(ElementRow)<IStyledProps>`
  ${(props) =>
    props.isProposed &&
    css`
      td,
      button {
        color: ${rgba("black", 0.6)};
      }

      img {
        opacity: 0.6;
      }
    `}
`;

interface IStyledProps {
  isProposed: boolean;
}

interface IProps extends IStyledProps {
  currencyDivisor: number;
  element: IElement;
  renderElementDialog: () => void;
  renderElementMenu: () => void;
  sort: React.ReactText;
  team: ITeam;
}

const ElementListRow: React.FC<IProps> = ({
  currencyDivisor,
  element,
  isProposed,
  renderElementMenu,
  sort,
  team,
}) => (
  <StyledElementListRow isProposed={isProposed}>
    <BorderedCell>
      <ElementDialogButton elementId={element.id} variant="list" />
    </BorderedCell>
    <BorderedCell>
      <ElementInTable
        renderElementMenu={renderElementMenu}
        element={element}
        team={team}
      />
    </BorderedCell>
    <td>{integerToMoney(element.now_cost, currencyDivisor)}</td>
    <td>
      {isMoneyStat(sort)
        ? integerToMoney(element[sort] as number, currencyDivisor)
        : element[sort]}
    </td>
  </StyledElementListRow>
);

export default ElementListRow;

import styled from "styled-components/macro";

const SectionHeading = styled.h3`
  padding: 1.9rem 0.8rem;
  margin: 0;
  border-bottom: ${({ theme }) => theme.borders[0]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.7rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export default SectionHeading;

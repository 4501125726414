import { Link } from "@reach/router";
import { rgba } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";

const NavList = styled.ul`
  display: flex;
  margin: 0 20% 0 0;
  padding: 0;
  border-radius: ${({ theme }) => theme.radii[0]};
  list-style-type: none;
  background: #edf1f9;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-right: 33%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 66%;
  }
`;

const NavItem = styled.li`
  flex: 1;
`;

// TODO - This stops isCurrent being passed to Link but doesn't have
// any type checking
const NavLink = styled(({ isCurrent, ...rest }) => <Link {...rest} />)`
  display: block;
  margin: 0.2rem;
  padding: 0.7rem ${({ theme }) => theme.space[2]};
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;
  text-decoration: none;

  ${(props) =>
    props.isCurrent &&
    css`
      background: white;
      box-shadow: 0 1px 4px 0 ${rgba("black", 0.16)};
      border-radius: 2px;
    `}
`;

interface ILinks {
  href: string;
  text: string;
}

interface ISubNav {
  links: ILinks[];
}

const SubNav: React.FC<ISubNav> = ({ links }) => (
  <nav>
    <NavList>
      {links.map((l) => (
        <NavItem key={l.href}>
          <NavLink to={l.href} isCurrent={window.location.pathname === l.href}>
            {l.text}
          </NavLink>
        </NavItem>
      ))}
    </NavList>
  </nav>
);

export default SubNav;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ThunkDispatch } from "../../../core/store";
import { getElementStatsByIdentifier } from "../../../core/store/element-stats/reducers";
import { IElementStat } from "../../../core/store/element-stats/types";
import { getElement } from "../../../core/store/elements/reducers";
import { showElementSummary } from "../../../core/store/elements/thunks";
import { getTeamsById } from "../../../core/store/teams/reducers";
import { getElementStatNames } from "../../../utils/element-stats";
import ElementDialogButton from "../../ElementDialogButton";
import ElementInTable from "../../ElementInTable";
import { IFixtureStatsRow } from "./types";

const FixtureStatsRow: React.FC<IFixtureStatsRow> = ({ stat }) => {
  const element = useSelector((state: RootState) =>
    getElement(state, stat.element)
  );
  const teamsById = useSelector(getTeamsById);
  const statsByName: Record<string, IElementStat> = useSelector(
    getElementStatsByIdentifier
  );

  const reduxDispatch = useDispatch<ThunkDispatch>();

  const statNames = getElementStatNames();

  if (!element) {
    return null;
  }

  return (
    <tr>
      <td>
        <ElementDialogButton elementId={element.id} variant="list" />
      </td>
      <td>
        <ElementInTable
          renderElementMenu={() =>
            reduxDispatch(showElementSummary(element.id))
          }
          element={element}
          team={teamsById[element.team]}
        />
      </td>
      <td>{stat.total_points}</td>
      {statNames.map((n) => (
        <td key={n}>{stat[statsByName[n].name]}</td>
      ))}
    </tr>
  );
};

export default FixtureStatsRow;

import { rgba } from "polished";
import * as React from "react";
import styled from "styled-components/macro";
import Title from "./Title";

const StyledPageHeader = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]};

  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.space[4]};

    ::before {
      content: "";
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-image: linear-gradient(
        to right,
        ${rgba("white", 0)} 0%,
        #efefef 50%,
        ${rgba("white", 0)} 100%
      );
    }

    ${Title} {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
`;
interface IProps {
  children: React.ReactNode;
  title: React.ReactNode;
}

const PageHeader: React.FC<IProps> = ({ children, title }) => (
  <StyledPageHeader>
    <Title>{title}</Title>
    {children}
  </StyledPageHeader>
);

export default PageHeader;

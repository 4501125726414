import { Link } from "@reach/router";
import { ellipsis, hiDPI, rgba } from "polished";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerComp, IPlayerCompProps } from "../../utils/player-comps";
import BoldLink from "../BoldLink";
import ButtonLink from "../ButtonLink";
import pattern2Desktop from "../../img/pattern/pattern-2-d.png";
import pattern2Desktop2x from "../../img/pattern/pattern-2-d@2x.png";
import { ControlArrowRight } from "../icons/Arrows";

const StyledEventInfo = styled.div`
  overflow: auto;
  margin: ${({ theme }) => `0 ${theme.space[2]} ${theme.space[4]}`};
  border-radius: ${({ theme }) => theme.radii[0]};
  box-shadow: 0 8px 12px 0 ${rgba("black", 0.08)};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.lightBlue},
    ${({ theme }) => theme.colors.darkBlue}
  );

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    background-image: url(${pattern2Desktop}), url(${pattern2Desktop}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-position: right -60px top -650px, right 225px top -500px, 0 0;
    background-repeat: no-repeat;

    ${hiDPI(2)} {
      background-image: url(${pattern2Desktop}), url(${pattern2Desktop2x}),
        linear-gradient(
          to right,
          ${({ theme }) => theme.colors.lightBlue},
          ${({ theme }) => theme.colors.darkBlue}
        );
      background-size: 618px 849px, 618px 849px, auto;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const Header = styled.div<IPlayerCompProps>`
  position: relative;
  margin: ${({ theme }) => `${theme.space[2]} ${theme.space[2]} 0`};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: 0;
    padding: ${({ theme }) => `${theme.space[4]} 65% 0 ${theme.space[4]}`};
    background-image: url(${(props) => props.images.x1});
    background-repeat: no-repeat;
    background-position: right 35px top;

    ${hiDPI(2)} {
      background-image: url(${(props) => props.images.x2});
      background-size: 288px 192px;
    }
  }
`;

const Heading = styled.h3`
  margin-bottom: ${({ theme }) => theme.space[3]};
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 2.5rem;
  }
`;

const PrizesLink = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    position: static;
    padding: ${({ theme }) => theme.space[3]} 0;
  }
`;

const InfoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-top: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => `0 0 ${theme.radii[0]} ${theme.radii[0]}`};
  background-color: ${rgba("white", 0.8)};
  box-shadow: 0 4px 12px 0 ${rgba("black", 0.08)};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-top: 0;
  }
`;

const InfoItem = styled.li`
  flex: 1 0 50%;
  padding: ${({ theme }) => `${theme.space[3]} 0.2rem`};
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #14c6ec;
  ${ellipsis()};

  &:nth-child(even) {
    border-right-width: 0;
  }

  &:nth-child(-n + 2) {
    border-top-width: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-basis: 33.33333%;

    &:nth-child(even) {
      border-right-width: 1px;
    }

    &:nth-child(-n + 2) {
      border-top-width: 1px;
    }

    &:nth-child(-n + 3) {
      border-top-width: 0;
    }

    &:nth-child(3n) {
      border-right-width: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 16.66666%;
    margin-top: ${({ theme }) => theme.space[3]};
    margin-bottom: ${({ theme }) => theme.space[3]};
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-top-width: 0;

    &:not(:last-child) {
      border-right-width: 1px;
    }

    &:nth-child(-n + 3) {
      border-top-width: 0;
    }
  }
`;

const ItemHeading = styled.h4`
  padding-bottom: 0.3rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
`;

const ItemValue = styled.div`
  ${ellipsis()};
  font-size: ${({ theme }) => theme.fontSizes[3]};
`;

const ItemLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
`;

const ItemLinkHeading = styled(ItemHeading)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

interface IPropsFromState {
  elementsById: IElementsById;
  now: IEvent | null;
}

const EventInfo: React.FC<IPropsFromState> = ({ elementsById, now }) => {
  if (!now) {
    return null;
  }
  const matches = now.chip_plays.filter((cp) => cp.chip_name === "wildcard");
  const wildcards = matches.length ? matches[0].num_played : "-";
  return (
    <StyledEventInfo>
      <Header images={getPlayerComp()}>
        <Heading>{now.name}</Heading>
        <ButtonLink to="my-team" fullwidth="true" variant="light">
          My Team
        </ButtonLink>
        <PrizesLink>
          <BoldLink to="prizes">Prizes</BoldLink>
        </PrizesLink>
      </Header>
      <InfoList>
        <InfoItem>
          {now.highest_scoring_entry ? (
            <ItemLink
              to={`/entry/${now.highest_scoring_entry}/event/${now.id}/`}
            >
              <ItemLinkHeading>
                <span>Highest Points</span>
                <ControlArrowRight />
              </ItemLinkHeading>
              <ItemValue>{now.highest_score}</ItemValue>
            </ItemLink>
          ) : (
            <>
              <ItemHeading>Highest Points</ItemHeading>
              <ItemValue>-</ItemValue>
            </>
          )}
        </InfoItem>
        <InfoItem>
          <ItemHeading>Average Points</ItemHeading>
          <ItemValue>{now.average_entry_score || "-"}</ItemValue>
        </InfoItem>
        {now.id > 1 && (
          <InfoItem>
            <ItemHeading>Transfers Made</ItemHeading>
            <ItemValue>
              {now.transfers_made ? now.transfers_made.toLocaleString() : "-"}
            </ItemValue>
          </InfoItem>
        )}
        {now.id > 1 && (
          <InfoItem>
            <ItemHeading>Most Transferred In</ItemHeading>
            <ItemValue>
              {now.most_transferred_in
                ? elementsById[now.most_transferred_in].web_name
                : "-"}
            </ItemValue>
          </InfoItem>
        )}
        {now.id === 1 && (
          <InfoItem>
            <ItemHeading>Most Selected Player</ItemHeading>
            <ItemValue>
              {now.most_selected
                ? elementsById[now.most_selected].web_name
                : "-"}
            </ItemValue>
          </InfoItem>
        )}
        <InfoItem>
          <ItemHeading>Wildcards Played</ItemHeading>
          <ItemValue>
            {wildcards === null ? "-" : wildcards.toLocaleString()}
          </ItemValue>
        </InfoItem>
        <InfoItem>
          <ItemHeading>Most Captained</ItemHeading>
          <ItemValue>
            {now.most_captained
              ? elementsById[now.most_captained].web_name
              : "-"}
          </ItemValue>
        </InfoItem>
        {now.id === 1 && (
          <InfoItem>
            <ItemHeading>Most Vice Captained</ItemHeading>
            <ItemValue>
              {now.most_vice_captained
                ? elementsById[now.most_vice_captained].web_name
                : "-"}
            </ItemValue>
          </InfoItem>
        )}
      </InfoList>
    </StyledEventInfo>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
  now: getCurrentEvent(state),
});

export default connect(mapStateToProps)(EventInfo);

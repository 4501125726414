import styled from "styled-components/macro";

export const ElementSingleFixture = styled.span`
  font-size: 0.9rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: 1rem;
  }
`;

export const ElementMultiFixtures = styled.span`
  display: block;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  font-size: 0.7rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: 0;
    font-size: 1rem;
  }
`;

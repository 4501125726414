import styled from "styled-components/macro";
import { textGradient } from "./Utils";

export const PillRadioLabel = styled.label`
  display: inline-block;
  cursor: pointer;
`;

// https://moderncss.dev/pure-css-custom-styled-radio-buttons/#step-1-hide-the-native-radio-input
export const PillRadioInput = styled.input`
  appearance: none;
  background-color: #fff;
  margin: 0;

  + span {
    display: inline-block;
    padding: ${(props) => `${props.theme.space[1]} ${props.theme.space[3]}`};
    border: 1px solid #e8e7e8;
    border-radius: 16px;
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.fonts.bold};
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSizes[0]};
  }

  :checked + span {
    background-color: ${(props) => props.theme.colors.primary};
    span {
      ${textGradient}
    }
  }
`;

import styled from "styled-components/macro";

export const TeamName = styled.abbr`
  vertical-align: middle;
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    &:after {
      content: attr(title);
    }
    span {
      display: none;
    }
  }
`;

import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  dreamTeamAsPickLight,
  getEventDreamTeam,
  getEventDreamTeamFormation,
  getOverallDreamTeam,
  getOverallDreamTeamFormation,
} from "../../core/store/dream-teams/reducers";
import {
  fetchEventDreamTeam,
  fetchOverallDreamTeam,
} from "../../core/store/dream-teams/thunks";
import { IDreamTeamData } from "../../core/store/dream-teams/types";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import {
  fetchEventLive,
  showElementSummary,
} from "../../core/store/elements/thunks";
import {
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getFixturesForEventByTeam } from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { isBlankWeek } from "../../utils/events";
import BoldLink from "../BoldLink";
import BoldLinkButton from "../BoldLinkButton";
import { StyledElementFixtureBar } from "../ElementFixtureBar";
import ElementShirt from "../ElementShirt";
import Fixtures from "../Fixtures";
import { Glass, PatternWrapMain } from "../GraphicPatterns";
import { Main, Secondary, Wrapper } from "../Layout";
import Media from "../Media";
import {
  EventPager,
  NextLink,
  PagerHeading,
  PagerItem,
  PagerItemNext,
  PreviousLink,
} from "../Pager";
import Pitch from "../Pitch";
import { NotFound } from "../Routes";
import SubHeading from "../SubHeading";
import Title from "../Title";
import { ControlArrowRight } from "../icons/Arrows";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import DreamTeamTable from "./DreamTeamTable";
import ElementExplainDialog from "./ElementExplainDialog";
import PitchFormation from "./PitchFormation";
import valueForPlayedElement from "./valueForPlayedElement";

const DTScoreboard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space[4]};
`;

const DTScoreboardPanel = styled.div`
  position: relative;
  flex: 1 0 calc(50% - ${({ theme }) => theme.space[2]});
  margin: ${({ theme }) => theme.space[2]} 0;
  text-align: center;

  :not(:last-child)::after {
    content: "";
    position: absolute;
    top: ${({ theme }) => theme.space[1]};
    right: 0;
    bottom: ${({ theme }) => theme.space[1]};
    width: 1px;
    background-color: #eaeaea;
  }
`;

const DTScoreboardHeading = styled.h3`
  margin-bottom: ${({ theme }) => theme.space[1]};
  padding-top: ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[3]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }
`;

const DTScoreboardValue = styled.div`
  position: relative;
  font-size: 4rem;
  font-family: ${({ theme }) => theme.fonts.bold};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-bottom: 0.3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 0.6rem;
    padding-bottom: 1rem;
  }
`;

const TopPlayer = styled.div`
  display: inline-block;
`;

const TopPlayerData = styled.div`
  text-align: left;
`;

type OwnProps = RouteComponentProps<{ eventId?: string }>;

interface IPropsFromState {
  data: IDreamTeamData | null;
  elementsById: IElementsById;
  elementsDataById: IElementsEventDataById;
  fixturesForEventByTeam: IFixturesForEventByTeam;
  event: IEvent | undefined;
  formation: string;
  now: IEvent | null;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  fetchEventLive: (entryId: number) => void;
  fetchEventDreamTeam: (eventId: number) => void;
  fetchOverallDreamTeam: () => void;
  showElementDialog: (elementId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  elementForMenu: number;
}

class DreamTeam extends React.Component<Props, IState> {
  public state: IState = {
    elementForMenu: 0,
  };

  public handleShowMenuForElement = (element: number) => {
    this.setState({ elementForMenu: element });
  };

  public handleHideMenuForElement = () => {
    this.setState({ elementForMenu: 0 });
  };

  public fetchData() {
    if (this.props.event) {
      this.props.fetchEventDreamTeam(this.props.event.id);
      this.props.fetchEventLive(this.props.event.id);
    } else {
      this.props.fetchOverallDreamTeam();
    }
  }

  public componentDidMount() {
    this.fetchData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.event !== this.props.event) {
      this.fetchData();
    }
  }

  public render() {
    const {
      data,
      elementsById,
      elementsDataById,
      event,
      fixturesForEventByTeam,
      formation,
      now,
      showElementDialog,
      teamsById,
    } = this.props;
    if (!now) {
      return <NotFound />;
    }
    if (!data) {
      return null;
    }
    const picks = dreamTeamAsPickLight(data.team);
    const topPlayer = elementsById[data.top_player.id];

    // For the overall team we just show the standard element dialog as there
    // is no explain
    if (this.state.elementForMenu && !event) {
      showElementDialog(this.state.elementForMenu);
    }

    // Create a new function on each render as data could have changed and
    // need to ensure a render of connected subcomponents
    const renderPickValue = event
      ? valueForPlayedElement({
          elementsById,
          eventId: event.id,
          fixturesForEventByTeam,
        })
      : (pick: IPickLight) => {
          const elementData = data.team.filter(
            (e) => e.element === pick.element
          );
          return elementData.length ? (
            <StyledElementFixtureBar>
              {elementData[0].points}
            </StyledElementFixtureBar>
          ) : null;
        };
    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>{`Team of the ${event ? "Week" : "Season"}`}</Title>
          </Box>
          <PatternWrapMain>
            {event ? (
              <Box mb={4}>
                <PagerHeading role="status" aria-live="polite">
                  {event.name}
                </PagerHeading>
                {(event.id > 1 || event.id < now.id) && (
                  <EventPager>
                    {event.id > 1 && (
                      <PagerItem>
                        <PreviousLink
                          to={`/team-of-the-week/${event.id - 1}`}
                        />
                      </PagerItem>
                    )}
                    {event.id < now.id && (
                      <PagerItemNext>
                        <NextLink to={`/team-of-the-week/${event.id + 1}`} />
                      </PagerItemNext>
                    )}
                  </EventPager>
                )}
              </Box>
            ) : (
              <Box m={2}>
                <SubHeading>Overall</SubHeading>
              </Box>
            )}
            {(!event || !isBlankWeek(event.id)) && (
              <>
                <Glass>
                  <DTScoreboard>
                    <DTScoreboardPanel>
                      <DTScoreboardHeading>{`Player of the ${
                        event ? "Week" : "Season"
                      }`}</DTScoreboardHeading>
                      <Box mb={1}>
                        <BoldLinkButton
                          onClick={() => showElementDialog(topPlayer.id)}
                        >
                          {topPlayer.web_name}
                          <ControlArrowRight />
                        </BoldLinkButton>
                      </Box>
                      <Box mb={1}>
                        <TopPlayer>
                          <Media
                            img={
                              <ElementShirt
                                elementId={topPlayer.id}
                                sizes="(min-width: 1024px) 55px, (min-width: 610px) 44px, 33px"
                                hasShadow={true}
                              />
                            }
                            centred={true}
                          >
                            <TopPlayerData>
                              <div>{teamsById[topPlayer.team].short_name}</div>
                              <div>{data.top_player.points} Points</div>
                            </TopPlayerData>
                          </Media>
                        </TopPlayer>
                      </Box>
                    </DTScoreboardPanel>
                    <DTScoreboardPanel>
                      <DTScoreboardHeading>Total Points</DTScoreboardHeading>
                      <DTScoreboardValue>
                        {data.team.reduce((total, e) => total + e.points, 0)}
                      </DTScoreboardValue>
                      {event ? (
                        <BoldLink to="/team-of-the-week/">Overall</BoldLink>
                      ) : (
                        <BoldLink to={`/team-of-the-week/${now.id}`}>
                          Gameweek
                        </BoldLink>
                      )}
                    </DTScoreboardPanel>
                  </DTScoreboard>
                </Glass>
                <Tabs>
                  <TabPanel label="Pitch View" link="pitch">
                    <Box pt={4}>
                      <Pitch sponsor="default">
                        <PitchFormation
                          chipName={""}
                          eventId={event?.id}
                          formation={formation}
                          picks={picks}
                          renderDreamTeam={() => null}
                          renderElementMenu={this.handleShowMenuForElement}
                          renderPickValue={renderPickValue}
                        />
                      </Pitch>
                    </Box>
                  </TabPanel>
                  <TabPanel label="List View" link="list">
                    <Box my={4} bg="white">
                      <DreamTeamTable
                        elements={data.team.map((e) => e.element)}
                        renderElementMenu={this.handleShowMenuForElement}
                        dataById={elementsDataById}
                      />
                    </Box>
                  </TabPanel>
                </Tabs>
              </>
            )}
            {event && this.state.elementForMenu ? (
              <ElementExplainDialog
                elementId={this.state.elementForMenu}
                eventId={event.id}
                closeDialog={this.handleHideMenuForElement}
              />
            ) : null}
          </PatternWrapMain>
          {event && <Fixtures eventId={event.id} />}
        </Main>
        <Secondary>
          <span>&nbsp;</span>
        </Secondary>
      </Wrapper>
    );
  }
}
export { DreamTeam as DreamTeamTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const eventId = Number(ownProps.eventId) || 0;
  return {
    data: eventId
      ? getEventDreamTeam(state, eventId)
      : getOverallDreamTeam(state),
    elementsById: getElementsById(state),
    elementsDataById: getElementsEventDataById(state, eventId),
    event: getEventsById(state)[eventId],
    fixturesForEventByTeam: getFixturesForEventByTeam(state, eventId),
    formation: eventId
      ? getEventDreamTeamFormation(state, eventId)
      : getOverallDreamTeamFormation(state),
    now: getCurrentEvent(state),
    teamsById: getTeamsById(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEventLive: (eventId) => dispatch(fetchEventLive(eventId)),
  fetchEventDreamTeam: (eventId) => dispatch(fetchEventDreamTeam(eventId)),
  fetchOverallDreamTeam: () => dispatch(fetchOverallDreamTeam()),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DreamTeam);

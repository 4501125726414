import styled, { css } from "styled-components/macro";
import Link from "../../Link";
import { IChipListStyles } from "./types";

const ChipLabelStyles = css`
  background: ${({ theme }) => theme.colors.purple70};
  border-radius: ${({ theme }) => theme.radii[2]};
  padding: ${({ theme }) => `13px ${theme.space[5]}`};
  font-family: ${({ theme }) => theme.fonts.regular};
`;

export const StyledChipList = styled.ul<IChipListStyles>`
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  padding: ${(props) =>
    props.$chipType === "transfer" ? "0" : `${props.theme.space[2]} 0`};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    gap: ${({ theme }) => theme.space[2]};
    padding: ${(props) =>
      props.$chipType === "transfer" ? "0" : `${props.theme.space[2]} 40%`};
  }
`;

export const ChipLabel = styled.div`
  ${ChipLabelStyles}
`;

export const ChipLink = styled(Link)`
  ${ChipLabelStyles}

  :hover::after {
    display: none;
  }
`;

export const ChipButtonWrap = styled.div`
  display: flex;
  gap: 12px;
`;

export const ChipCardLink = styled(Link)`
  display: inherit;
  flex: 0 1 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex: 1 0 100px;
  }

  :hover::after {
    display: none;
  }
`;

import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import useDimensions from "../../core/hooks/useDimensions";
import { ThunkDispatch } from "../../core/store";
import { getEvents } from "../../core/store/events/reducers";
import { getFixturesByEvent } from "../../core/store/fixtures/reducers";
import { fetchFixturesFuture } from "../../core/store/fixtures/thunks";
import { getTeams, getTeamsById } from "../../core/store/teams/reducers";
import Copy from "../Copy";
import Explain from "../Explain";
import FixturesNav from "../FixturesNav";
import HelmetHead from "../HelmetHead";
import { Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import FDRHeader from "./FDRHeader";
import FDRTable from "./FDRTable";
import { formatFixturesByEventAndTeam, sortTeamsByEventDiff } from "./helpers";
import useTableColumns from "./useTableColumns";

type Props = RouteComponentProps;

const FDRContainer: React.FC<Props> = () => {
  // Redux/Selectors
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const teams = useSelector(getTeams);
  const teamsById = useSelector(getTeamsById);
  const events = useSelector(getEvents);
  const fixturesByEvent = useSelector(getFixturesByEvent);
  // Custom hooks
  const [tableRef, tableDimensions] = useDimensions();
  const [visibleColumns] = useTableColumns(tableDimensions.width);
  // Variables
  const nextEventIndex = events.findIndex((event) => event.is_next) + 1 || 0;
  const [currentEventIndex, setEventIndex] = useState(nextEventIndex);
  const [teamFixtures, setTeamFixtures] = useState({});
  const [sortedTeams, setSortedTeams] = useState(teams);
  const [activeHeadingIndex, setActiveEventId] = useState(0);

  useEffect(() => {
    reduxDispatch(fetchFixturesFuture());
  }, [reduxDispatch]);

  useEffect(() => {
    if (Object.keys(fixturesByEvent).length) {
      setTeamFixtures(formatFixturesByEventAndTeam(fixturesByEvent));
    }
  }, [fixturesByEvent]);

  const handlePrevClick = () => setEventIndex(currentEventIndex - 1);

  const handleNextClick = () => setEventIndex(currentEventIndex + 1);

  function handleHeadingSort(eventId: number, sortType: string) {
    setActiveEventId(eventId);
    setSortedTeams(
      sortTeamsByEventDiff(teamFixtures, eventId, sortType, sortedTeams)
    );
  }

  const showPrevBtn = currentEventIndex > nextEventIndex;
  const showNextBtn = currentEventIndex + visibleColumns - 1 <= events.length;

  return (
    <>
      <Wrapper className="webview-hidden">
        <Box flex={1} pt={4} mx={2}>
          <Title>Fixtures &amp; Results</Title>
          <FixturesNav />
        </Box>
      </Wrapper>
      <div ref={tableRef}>
        <HelmetHead
          title="Fixture Difficulty Rating | Fantasy Premier League"
          description="For help planning your FPL transfer and team selection strategy, view our fixture difficulty rating (FDR). For more information, visit the official website of the Premier League."
        />
        {nextEventIndex ? (
          <>
            <FDRHeader
              title="Fixture Difficulty Rating (FDR)"
              showPrevBtn={showPrevBtn}
              showNextBtn={showNextBtn}
              onHandlePrevClick={handlePrevClick}
              onHandleNextClick={handleNextClick}
            />
            <FDRTable
              teams={sortedTeams}
              teamsById={teamsById}
              teamFixtures={teamFixtures}
              currentEventIndex={currentEventIndex}
              events={events}
              activeHeadingIndex={activeHeadingIndex}
              onHeadingSort={handleHeadingSort}
            />
          </>
        ) : (
          <Wrapper>
            <Box mt={4}>
              <Copy>
                <Title>Fixture Difficulty Rating (FDR)</Title>
                <p>
                  The FDR table will display here when next season's Fantasy
                  Premier League game is live.
                </p>
              </Copy>
            </Box>
          </Wrapper>
        )}
      </div>
      <Wrapper>
        <Box mt={4}>
          <Explain>
            <Copy>
              <SubHeading>FDR Explained</SubHeading>
              <p>
                The FDR is based on a complex algorithm developed by FPL
                experts.
              </p>
              <p>
                A set of formulas process key Opta data variables, along with
                each team's home and away form for the past six matches, to
                generate a rank for the perceived difficulty of each Gameweek
                opponent.
              </p>
              <p>
                The FDR is designed to help FPL managers plan their transfer and
                team selection strategy. It is reviewed on a weekly basis and
                updated as the season progresses.
              </p>
            </Copy>
          </Explain>
        </Box>
      </Wrapper>
    </>
  );
};

export default FDRContainer;

import React from "react";
import { Box } from "rebass/styled-components";
import { ITeam } from "../../core/store/teams/types";
import { IPulseNewsArticle, IPulseRelatedArticle } from "../../utils/pulse";
import NewsThumb from "../news/NewsThumb";
import {
  Figure,
  IconWrap,
  NewsIcon,
  NewsLink,
  NewsSubtitle,
  PlayIcon,
  RelatedHeadline,
  RelatedLink,
  StyledRelatedArticles,
  Thumb,
} from "../news/styles";
import ExternalHeadline from "./ExternalHeadline";
import { ScoutBody, ScoutTitle } from "./styles";

interface IScoutCard {
  externalNews: Record<string, IPulseNewsArticle> | null;
  newsItem: any;
  teams: ITeam[] | null;
}

const ScoutCard: React.FC<IScoutCard> = ({ externalNews, newsItem, teams }) => {
  const related = newsItem.related;

  return (
    <div>
      <NewsLink href={`https://www.premierleague.com/news/${newsItem.id}`}>
        <Figure>
          <Thumb>
            <NewsThumb
              news={newsItem}
              imgSizes={{
                x1: { width: 300, height: 200 },
                x2: { width: 600, height: 400 },
              }}
            />
          </Thumb>
          <ScoutBody>
            <Box mb={1}>
              <NewsSubtitle>{newsItem.subtitle}</NewsSubtitle>
            </Box>
            <div>
              <ScoutTitle>{newsItem.title}</ScoutTitle>
            </div>
          </ScoutBody>
        </Figure>
      </NewsLink>
      {related && (
        <StyledRelatedArticles>
          {related.slice(0, 2).map((r: IPulseRelatedArticle) => (
            <React.Fragment key={r.id}>
              {externalNews && externalNews[r.id] && teams ? (
                <ExternalHeadline article={externalNews[r.id]} teams={teams} />
              ) : (
                <>
                  {r.type === "TEXT" && (
                    <RelatedLink
                      href={`https://www.premierleague.com/news/${r.id}`}
                    >
                      <IconWrap>
                        <PlayIcon />
                      </IconWrap>
                      <RelatedHeadline>{r.label}</RelatedHeadline>
                    </RelatedLink>
                  )}
                  {r.type === "VIDEO" && (
                    <RelatedLink
                      href={`https://www.premierleague.com/video/single/${r.id}`}
                    >
                      <IconWrap>
                        <NewsIcon />
                      </IconWrap>
                      <RelatedHeadline>{r.label}</RelatedHeadline>
                    </RelatedLink>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </StyledRelatedArticles>
      )}
    </div>
  );
};

export default ScoutCard;

import { useSelector } from "react-redux";
import { getElementTypesById } from "../../../core/store/element-types/reducers";
import { StatHeading, StatRank, StatValue, StyledStatItem } from "./styles";
import { IStatItem } from "./types";

const StatItem: React.FC<IStatItem> = ({ element, rankType, value, title }) => {
  const elementTypesById = useSelector(getElementTypesById);
  return (
    <StyledStatItem>
      <StatHeading>{title}</StatHeading>
      <StatValue>{value}</StatValue>
      {rankType && (
        <StatRank aria-describedby="rank-title">
          <strong>{rankType}</strong> of{" "}
          {elementTypesById[element.element_type].element_count}
        </StatRank>
      )}
    </StyledStatItem>
  );
};

export default StatItem;

import { navigate, RouteComponentProps } from "@reach/router";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getEntry, getLeagueForEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { getCurrentEvent } from "../../core/store/events/reducers";
import {
  getClassicLeague,
  getH2HMatches,
  getLeagueCupStatus,
  getLeagueEntries,
} from "../../core/store/leagues/reducers";
import {
  fetchClassicLeagueStandings,
  fetchH2HLeagueMatches,
  fetchLeagueCupStatus,
  fetchLeagueEntries,
} from "../../core/store/leagues/thunks";
import { getPlayerData } from "../../core/store/player/reducers";
import { fetchNewsArticle } from "../../utils/pulse";
import Alert from "../Alert";
import BoldLink from "../BoldLink";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import { NextIcon, PrevIcon } from "../icons/Chevrons";
import { Main, Secondary, Wrapper } from "../Layout";
import { Pager, PagerItem, PagerItemNext } from "../Pager";
import ReportNameButton from "../ReportNameButton";
import SubHeading from "../SubHeading";
import CupArticleErrorBoundary from "./CupArticleErrorBoundary";
import H2HEntryFilter from "./H2HEntryFilter";
import H2HEventFilter from "./H2HEventFilter";
import LineHeading from "./LineHeading";
import MatchesTable from "./MatchesTable";
import StandingsHeading from "./StandingsHeading";
import { SelectWrap } from "./Styles";
import { getLeagueUrl } from "./utils";

const StyledCupArticle = styled.div`
  div {
    padding: 0;
  }

  h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.7rem;
  }
`;

type OwnProps = RouteComponentProps<{ leagueId: string }>;

const MatchesCup: React.FC<OwnProps> = ({ location, leagueId }) => {
  const leagueNumber = Number(leagueId!) || 0;
  const query = qs.parse(location!.search, {
    ignoreQueryPrefix: true,
  });
  const entryNumber = Number(query.entry) || 0;
  const eventNumber = Number(query.event) || 0;
  const pageMatches = Number(query.page_matches) || 1;

  const buildMatchesUrl = (
    leagueNumber: number,
    eventNumber: number,
    entryNumber: number,
    pageMatches: number
  ) => {
    let url = `/leagues/${leagueNumber}/cup?page_matches=${pageMatches}`;
    if (entryNumber) {
      url += `&entry=${entryNumber}`;
    }
    if (eventNumber) {
      url += `&event=${eventNumber}`;
    }
    return url;
  };

  const player = useSelector(getPlayerData);

  const cupStatus = useSelector((state: RootState) =>
    leagueNumber ? getLeagueCupStatus(state, leagueNumber) : null
  );

  const entry = useSelector((state: RootState) =>
    entryNumber ? getEntry(state, entryNumber) : null
  );

  const leagueEntry = useSelector((state: RootState) =>
    entry ? getLeagueForEntry(state, entry.id, leagueNumber) : null
  );

  const myLeagueEntry = useSelector((state: RootState) =>
    player?.entry ? getLeagueForEntry(state, player.entry, leagueNumber) : null
  );

  const now = useSelector(getCurrentEvent);

  const league = useSelector((state: RootState) =>
    leagueNumber ? getClassicLeague(state, leagueNumber) : null
  );

  const matches = useSelector((state: RootState) =>
    cupStatus?.league
      ? getH2HMatches(
          state,
          cupStatus.league,
          entryNumber,
          eventNumber,
          pageMatches
        )
      : null
  );

  const leagueEntries = useSelector((state: RootState) =>
    cupStatus?.league ? getLeagueEntries(state, cupStatus.league) : null
  );

  const didNotQualify = leagueEntry && leagueEntry.cup_qualified === false;
  const notStarted = leagueEntry && leagueEntry.cup_qualified === null;
  // Event where we can show entries for large cups
  const largeCupEvent = 32;

  const reduxDispatch = useDispatch<ThunkDispatch>();
  const [cupArticle, setCupArticle] = useState<any>(null);

  // Can we fetch matches?
  const canFetchMatches = () => {
    if (!cupStatus) {
      return false;
    }
    // Asking for a specific entry is fine as long as qualified
    if (entryNumber) {
      return leagueEntry?.cup_qualified;
    }
    // Can always request a cup that isn't large
    if (!cupStatus.is_large) {
      return true;
    }
    // Asking for a late event in a large league is fine
    if (eventNumber >= largeCupEvent) {
      return true;
    }
    // We are able to show the current round or first round draw
    if (now && now.id >= largeCupEvent && cupStatus.qualification_event) {
      navigate(
        buildMatchesUrl(
          leagueNumber,
          Math.max(now.id, cupStatus.qualification_event + 1),
          0,
          1
        ),
        {
          replace: true,
        }
      );
    }
    // We are able to show the logged in entry. We are safe to use non null
    // assertion on player.entry if myLeagueEntry
    else if (myLeagueEntry && myLeagueEntry?.cup_qualified) {
      navigate(buildMatchesUrl(leagueNumber, 0, player!.entry!, 1), {
        replace: true,
      });
    }
    return false;
  };
  const fetchMatches = canFetchMatches();

  // API calls ...

  // This is currently the 'best' way to get a league which is needed by
  // child components for things like name
  useEffect(() => {
    leagueNumber &&
      !league &&
      reduxDispatch(fetchClassicLeagueStandings(leagueNumber, 1, 1, 1));
  }, [league, leagueNumber, reduxDispatch]);

  // Get any requested entry if different to the logged in entry as there
  // is a chance we won't have it
  useEffect(() => {
    entryNumber &&
      player?.entry !== entryNumber &&
      reduxDispatch(fetchEntrySummary(entryNumber));
  }, [entryNumber, player, reduxDispatch]);

  // Get the cup status for the parent league
  useEffect(() => {
    leagueNumber && reduxDispatch(fetchLeagueCupStatus(leagueNumber));
  }, [leagueNumber, reduxDispatch]);

  // Get a list of league entries for filtering. We need to know the cup
  // status before we can do this
  useEffect(() => {
    cupStatus &&
      cupStatus.league &&
      !cupStatus.is_large &&
      reduxDispatch(fetchLeagueEntries(cupStatus.league));
  }, [cupStatus, reduxDispatch]);

  // Get the list of matches.
  useEffect(() => {
    cupStatus &&
      cupStatus.league &&
      fetchMatches &&
      reduxDispatch(
        fetchH2HLeagueMatches(
          cupStatus.league,
          entryNumber,
          eventNumber,
          pageMatches
        )
      );
  }, [
    cupStatus,
    fetchMatches,
    entryNumber,
    eventNumber,
    pageMatches,
    reduxDispatch,
  ]);

  // If ever we want to test this, here is a test article id that you can
  // insert into your dev DB game_cup table, pulse_article_id column - 10509429
  useEffect(() => {
    cupStatus?.pulse_article_id &&
      fetchNewsArticle(cupStatus.pulse_article_id).then((article) =>
        setCupArticle(article)
      );
  }, [cupStatus]);

  // We are still waiting for required data so delay rendering ...
  if (!cupStatus || !now || !league || (entryNumber && !entry)) {
    return null;
  }

  const cupStartText =
    now.id === cupStatus.qualification_event ? "will start" : "started";

  // This function wil be called if there are no matches, it attempts to
  // work out why!
  const getNoMatchesMessage = () => {
    if (eventNumber && eventNumber > now.id) {
      return `Cup matches for Gameweek ${eventNumber} will be available at the
      end of Gameweek ${eventNumber - 1}`;
    }
    if (cupStatus.is_large && now.id < largeCupEvent) {
      return `Cup matches will be available after Gameweek ${largeCupEvent}`;
    }
    if (notStarted || cupStatus.qualification_event === now.id) {
      return "The draw for the first round is yet to be made";
    }
    if (entryNumber && eventNumber) {
      return "This team has been eliminated";
    }
    return "";
  };

  const handleEntryFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    navigate(
      buildMatchesUrl(
        leagueNumber,
        eventNumber,
        Number(e.currentTarget.value),
        1
      )
    );

  const handleEventFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value) {
      return navigate(
        buildMatchesUrl(
          leagueNumber,
          Number(e.currentTarget.value),
          entryNumber,
          1
        )
      );
    }
    return navigate(buildMatchesUrl(leagueNumber, 0, entryNumber, 1));
  };

  return (
    <Wrapper>
      <Main>
        <StandingsHeading
          type="classic"
          league={league}
          leagueNumber={leagueNumber}
        />
        <Box mx={2} mb={2}>
          <BoldLink to={getLeagueUrl(league.id, league.scoring)}>
            View league standings
          </BoldLink>
        </Box>
        {didNotQualify ? (
          <Box mx={2} my={4}>
            <Alert>
              This team did not qualify for the cup.{" "}
              <BoldLink to={buildMatchesUrl(league.id, 0, 0, 1)}>
                View cup matches
              </BoldLink>
            </Alert>
          </Box>
        ) : (
          <>
            <SelectWrap>
              {!cupStatus.is_large && leagueEntries && (
                <H2HEntryFilter
                  entryId={entryNumber}
                  leagueEntries={leagueEntries}
                  handleFilterChange={handleEntryFilterChange}
                />
              )}
              {cupStatus.qualification_event &&
                !notStarted &&
                (!cupStatus.is_large || now.id >= largeCupEvent) && (
                  <H2HEventFilter
                    eventId={eventNumber}
                    startEvent={
                      cupStatus.is_large &&
                      cupStatus.qualification_event + 1 < largeCupEvent
                        ? largeCupEvent
                        : cupStatus.qualification_event + 1
                    }
                    handleFilterChange={handleEventFilterChange}
                  />
                )}
            </SelectWrap>
            {matches && matches.results.length ? (
              <MatchesTable
                selectedEntry={entryNumber}
                // Only way we can show cup winner for cup final but not h2h GW38
                isCup={true}
                isAllMine={entryNumber === player?.entry && eventNumber === 0}
                matches={matches.results}
              />
            ) : (
              <Box mx={2}>
                <Alert>{getNoMatchesMessage()}</Alert>
              </Box>
            )}
          </>
        )}
        {cupStatus.qualification_event && (
          <Box my={4}>
            <LineHeading>
              The cup {cupStartText} in GW{cupStatus.qualification_event + 1}
            </LineHeading>
          </Box>
        )}
        <Copy>
          <Box mb={4}>
            <SubHeading>How the cup works</SubHeading>
            <p>
              Each qualifying team will be randomly drawn against another in the
              first round. The winner (the team with the highest Gameweek score
              minus any transfer points), will progress to the second round and
              another random draw, the losers are out! This process continues
              until the final round when the two remaining teams contest the cup
              final.
            </p>
            <p>
              If a cup match is drawn, then the following tie-breaks will be
              applied until a winner is found:
            </p>
            <ol>
              <li>Most goals scored in the Gameweek</li>
              <li>Fewest goals conceded in the Gameweek</li>
              <li>Virtual coin toss</li>
            </ol>
          </Box>
          {cupArticle && (
            <CupArticleErrorBoundary>
              <StyledCupArticle
                dangerouslySetInnerHTML={{ __html: cupArticle.body }}
              ></StyledCupArticle>
            </CupArticleErrorBoundary>
          )}
        </Copy>
        {matches && (
          <Box mt={4}>
            <Pager>
              <PagerItem>
                {pageMatches > 1 && (
                  <ButtonLink
                    to={buildMatchesUrl(
                      leagueNumber,
                      eventNumber,
                      entryNumber,
                      pageMatches - 1
                    )}
                    variant="tertiary"
                    fullwidth="true"
                  >
                    <PrevIcon />
                    <span>Previous</span>
                  </ButtonLink>
                )}
              </PagerItem>
              <PagerItemNext>
                {matches.has_next && (
                  <ButtonLink
                    to={buildMatchesUrl(
                      leagueNumber,
                      eventNumber,
                      entryNumber,
                      pageMatches + 1
                    )}
                    variant="tertiary"
                    fullwidth="true"
                  >
                    <span>Next</span>
                    <NextIcon />
                  </ButtonLink>
                )}
              </PagerItemNext>
            </Pager>
          </Box>
        )}
      </Main>
      <Secondary>
        {league && (
          <Box mt="0.7rem" mx={2}>
            <ReportNameButton league={league} />
          </Box>
        )}
      </Secondary>
    </Wrapper>
  );
};

export default MatchesCup;

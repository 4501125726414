import { ITeamsById } from "../../core/store/teams/types";
import { INewsByFixtureId } from "./types";
import { IPulseNewsArticle, IPulseNewsArticleRefs } from "../../utils/pulse";

export const findTeamByKey = (
  teamsById: ITeamsById,
  key: "id" | "code" | "short_name" | "name" | "pulse_id",
  value: number | string
) => {
  const filteredTeams = Object.keys(teamsById)
    .map((idx) => teamsById[idx])
    .filter((t: any) => t[key as string] === value);

  if (filteredTeams.length === 0) {
    return null;
  }

  return filteredTeams[0];
};

export const transformNewsArticles = (
  newsArticles: IPulseNewsArticle[],
  teamsById: ITeamsById
) => {
  return newsArticles.reduce(
    (acc: INewsByFixtureId, article: IPulseNewsArticle) => {
      if (article.references) {
        const pulseFixtureFromArticle = article.references.filter(
          (ref: IPulseNewsArticleRefs) => ref.type === "FOOTBALL_FIXTURE"
        )[0];

        const pulseClubFromArticle = article.references.filter(
          (ref: IPulseNewsArticleRefs) => ref.type === "FOOTBALL_CLUB"
        )[0];

        if (pulseClubFromArticle && pulseFixtureFromArticle) {
          const fixtureId = pulseFixtureFromArticle.id;
          if (!acc[fixtureId]) {
            acc[fixtureId] = {};
          }

          const ismTeam = findTeamByKey(
            teamsById,
            "pulse_id",
            pulseClubFromArticle.id
          );

          if (ismTeam) {
            const articleWithTeam = {
              ...article,
              ismTeam: {
                id: ismTeam.id,
                code: ismTeam.code,
                pulse_id: ismTeam.pulse_id,
                name: ismTeam.name,
              },
            };

            if (!acc[fixtureId][ismTeam.id]) {
              acc[fixtureId][ismTeam.id] = articleWithTeam;
            } else {
              const lastItem = acc[fixtureId][ismTeam.id];
              if (new Date(articleWithTeam.date) > new Date(lastItem.date)) {
                acc[fixtureId][ismTeam.id] = articleWithTeam;
              }
            }
          }
        }
      }

      return acc;
    },
    {}
  );
};

import { navigate, RouteComponentProps } from "@reach/router";
import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { getCurrentEvent, getEvents } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import {
  getActiveEntryInLeague,
  getH2HLeague,
  getH2HMatches,
  getH2HNewEntries,
  getH2HStandings,
  getLeagueEntries,
  getLeagueEntriesError,
} from "../../core/store/leagues/reducers";
import {
  fetchH2HLeagueMatches,
  fetchH2HLeagueStandings,
  fetchLeagueEntries,
} from "../../core/store/leagues/thunks";
import {
  IEntryInLeague,
  IH2HMatchesData,
  IH2HStandingsData,
  ILeague,
  INewEntriesData,
} from "../../core/store/leagues/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import Alert from "../Alert";
import ButtonLink from "../ButtonLink";
import { NextIcon, PrevIcon } from "../icons/Chevrons";
import { Main, Secondary, Wrapper } from "../Layout";
import { Pager, PagerItem, PagerItemNext } from "../Pager";
import ReportNameButton from "../ReportNameButton";
import H2HEntryFilter from "./H2HEntryFilter";
import H2HEventFilter from "./H2HEventFilter";
import H2HNav from "./H2HNav";
import MatchesTable from "./MatchesTable";
import StandingsHeading from "./StandingsHeading";
import { SelectWrap } from "./Styles";

type OwnProps = RouteComponentProps<{
  entryId: string;
  eventId: string;
  leagueId: string;
}>;

interface IPropsFromState {
  defaultEntryNumber: number;
  entryNumber: number;
  eventNumber: number;
  events: IEvent[];
  isPlayerInLeague: boolean;
  league: ILeague | null;
  leagueEntries: IEntryInLeague[] | null;
  leagueEntriesError: IError | null;
  leagueNumber: number;
  matches: IH2HMatchesData | null;
  newEntries: INewEntriesData | null;
  now: IEvent | null;
  pageMatches: number;
  player: IPlayer | null;
  standings: IH2HStandingsData | null;
}

interface IPropsFromDispatch {
  fetchEntries: (leagueId: number) => void;
  fetchMatches: (
    leagueId: number,
    entryId: number,
    eventId: number,
    page: number
  ) => void;
  fetchStandings: (leagueId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

class MatchesH2H extends React.Component<Props> {
  defaultEntry?: number;
  public componentDidMount() {
    this.defaultEntry = this.props.defaultEntryNumber;
    if (this.props.leagueNumber) {
      const {
        entryNumber,
        eventNumber,
        fetchEntries,
        fetchMatches,
        fetchStandings,
        leagueNumber,
        pageMatches,
      } = this.props;
      // Have to fetch standings to get league
      fetchStandings(leagueNumber);
      // We could look at delaying these calls as they are pointless for
      // unstarted h2h leagues at the cost of some complexity as we may have
      // to wait for league to be available. As a 'good enough' we don't do
      // them before the season starts ...
      if (this.props.now) {
        fetchEntries(leagueNumber);
        fetchMatches(leagueNumber, entryNumber, eventNumber, pageMatches);
      }
    }
  }
  public componentDidUpdate(prevProps: Props) {
    const {
      entryNumber,
      eventNumber,
      fetchMatches,
      isPlayerInLeague,
      leagueEntriesError,
      leagueNumber,
      pageMatches,
      player,
    } = this.props;
    if (
      prevProps.eventNumber !== eventNumber ||
      prevProps.pageMatches !== pageMatches ||
      prevProps.entryNumber !== entryNumber
    ) {
      fetchMatches(leagueNumber, entryNumber, eventNumber, pageMatches);
    }
    // We've got a large league, we can only show default or logged in entry
    let showEntry = 0;
    if (
      leagueEntriesError !== prevProps.leagueEntriesError &&
      this.isLargeLeague()
    ) {
      showEntry = this.defaultEntry
        ? this.defaultEntry
        : isPlayerInLeague
        ? // As isPlayerInLeague we must have a player and an entry
          player!.entry!
        : 0;
      navigate(this.buildMatchesUrl(leagueNumber, 0, showEntry, pageMatches), {
        replace: true,
      });
    }
  }

  public buildMatchesUrl(
    leagueNumber: number,
    eventNumber: number,
    entryNumber: number,
    pageMatches: number
  ) {
    let url = `/leagues/${leagueNumber}/matches/h?page_matches=${pageMatches}`;
    if (entryNumber) {
      url += `&entry=${entryNumber}`;
    }
    if (this.props.now?.id && eventNumber !== this.props.now.id) {
      url += `&event=${eventNumber}`;
    }
    return url;
  }

  public isLargeLeague() {
    return this.props.leagueEntriesError?.badRequest?.non_field_errors[0]
      ?.code === "large_leagues_forbidden"
      ? true
      : false;
  }

  public handleEntryFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    navigate(
      this.buildMatchesUrl(
        this.props.leagueNumber,
        this.props.eventNumber,
        parseInt(e.currentTarget.value, 10),
        1
      )
    );

  public handleEventFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    navigate(
      this.buildMatchesUrl(
        this.props.leagueNumber,
        parseInt(e.currentTarget.value, 10),
        this.props.entryNumber,
        1
      )
    );

  public render() {
    const {
      entryNumber,
      eventNumber,
      league,
      leagueEntries,
      leagueNumber,
      matches,
      newEntries,
      now,
      pageMatches,
      player,
      standings,
    } = this.props;
    if (!league || !newEntries) {
      return null;
    }
    // If we have new entries then switch to dedicated page
    if (!now || newEntries.results.length) {
      navigate(`/leagues/${league.id}/new-entries/h`, { replace: true });
    }
    if (!matches) {
      return null;
    }
    // Handle the edge case between rollover and matches being created ...
    if (
      now &&
      now.id === league.start_event &&
      standings &&
      !standings.results.length
    ) {
      return (
        <Wrapper>
          <Main>
            <StandingsHeading
              type="h2h"
              league={league}
              leagueNumber={leagueNumber}
            />
            <Box mx={2}>
              <p>
                Matches are currently being generated, please check back later.
              </p>
            </Box>
          </Main>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Main>
          <StandingsHeading
            type="h2h"
            league={league}
            leagueNumber={leagueNumber}
          />
          <Box mx={2}>
            <>
              {now && (
                <H2HNav
                  eventId={now.id}
                  leagueId={leagueNumber}
                  defaultEntry={this.defaultEntry && this.defaultEntry}
                />
              )}
              {leagueEntries && (
                <SelectWrap>
                  <H2HEntryFilter
                    entryId={entryNumber}
                    leagueEntries={leagueEntries}
                    handleFilterChange={this.handleEntryFilterChange}
                  />
                  <H2HEventFilter
                    eventId={eventNumber}
                    startEvent={league.start_event}
                    handleFilterChange={this.handleEventFilterChange}
                  />
                </SelectWrap>
              )}
              {this.isLargeLeague() && (
                <Box m={2}>
                  <Alert>
                    In head-to-head leagues with more than 512 teams only
                    matches for a single team can be viewed.
                  </Alert>
                </Box>
              )}
              {now && (
                <MatchesTable
                  selectedEntry={entryNumber}
                  isAllMine={entryNumber === player?.entry && eventNumber === 0}
                  matches={matches.results}
                />
              )}
              <Box mt={4}>
                <Pager>
                  <PagerItem>
                    {pageMatches > 1 && (
                      <ButtonLink
                        to={this.buildMatchesUrl(
                          leagueNumber,
                          eventNumber,
                          entryNumber,
                          pageMatches - 1
                        )}
                        variant="tertiary"
                        fullwidth="true"
                      >
                        <PrevIcon />
                        <span>Previous</span>
                      </ButtonLink>
                    )}
                  </PagerItem>
                  <PagerItemNext>
                    {matches.has_next && (
                      <ButtonLink
                        to={this.buildMatchesUrl(
                          leagueNumber,
                          eventNumber,
                          entryNumber,
                          pageMatches + 1
                        )}
                        variant="tertiary"
                        fullwidth="true"
                      >
                        <span>Next</span>
                        <NextIcon />
                      </ButtonLink>
                    )}
                  </PagerItemNext>
                </Pager>
              </Box>
            </>
          </Box>
        </Main>
        <Secondary>
          {league && (
            <Box mt="0.7rem" mx={2}>
              <ReportNameButton league={league} />
            </Box>
          )}
        </Secondary>
      </Wrapper>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  // Get league id from path
  const leagueNumber = parseInt(ownProps.leagueId!, 10) || 0;
  // Get entry id from query string
  const query = qs.parse(ownProps.location!.search, {
    ignoreQueryPrefix: true,
  });
  const entryNumber = parseInt(query.entry as string, 10) || 0;
  const defaultEntryNumber = parseInt(query.default_entry as string, 10) || 0;
  const now = getCurrentEvent(state);
  const eventNumber = query.event
    ? parseInt(query.event as string, 10)
    : now?.id || 0;
  const pageMatches = parseInt(query.page_matches as string, 10) || 1;
  return {
    defaultEntryNumber,
    entryNumber,
    eventNumber,
    events: getEvents(state),
    isPlayerInLeague: Boolean(
      getActiveEntryInLeague(state, leagueNumber, "h2h")
    ),
    league: getH2HLeague(state, leagueNumber),
    leagueEntries: getLeagueEntries(state, leagueNumber),
    leagueEntriesError: getLeagueEntriesError(state, leagueNumber),
    leagueNumber,
    matches: leagueNumber
      ? getH2HMatches(
          state,
          leagueNumber,
          entryNumber,
          eventNumber,
          pageMatches
        )
      : null,
    newEntries: getH2HNewEntries(state, leagueNumber, 1),
    now,
    pageMatches: pageMatches,
    player: getPlayerData(state),
    standings: getH2HStandings(state, leagueNumber, 1),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntries: async (leagueId: number) =>
    dispatch(fetchLeagueEntries(leagueId)),
  fetchMatches: (
    leagueId: number,
    entryId: number,
    eventId: number,
    page: number
  ) => dispatch(fetchH2HLeagueMatches(leagueId, entryId, eventId, page)),
  fetchStandings: (leagueId: number) =>
    dispatch(fetchH2HLeagueStandings(leagueId, 1, 1)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchesH2H);

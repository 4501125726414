import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { ThunkDispatch } from "../../core/store";
import { getElements } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { getTeams } from "../../core/store/teams/reducers";
import { ITeam } from "../../core/store/teams/types";
import Collapsible from "../Collapsible";
import Copy from "../Copy";
import Explain from "../Explain";
import HelmetHead from "../HelmetHead";
import { Main, Wrapper } from "../Layout";
import ScoutNav from "../scout/ScoutNav";
import SubHeading from "../SubHeading";
import Title from "../Title";
import { NewTabCopy } from "../Utils";
import { reduceElementsBySetPieceTakers, reduceNotesByTeamId } from "./helpers";
import {
  Element,
  ElementName,
  ExternalIcon,
  Group,
  Note,
  NoteLink,
  NotesWrapper,
  SetPieceTakersContent,
  SetPieceTitle,
  StyledInfo,
  StyledSetPieceTakers,
  TeamContentInner,
} from "./styles";

export interface INote {
  external_link: boolean;
  info_message: string;
  source_link: string;
}

export interface INotesItem {
  id: number;
  notes: INote[];
}

export interface IStateNotes {
  last_updated: string;
  byId: INotesById;
}

export interface INotesById {
  [key: string]: INotesItem;
}

export interface ISPTElement {
  id: number;
  name: string;
  order: number;
}

export interface ISPTTeam {
  team: ITeam;
  penalties: ISPTElement[];
  direct_freekicks: ISPTElement[];
  corners_and_indirect_freekicks: ISPTElement[];
}

export interface ISPTById {
  [key: string]: ISPTTeam;
}

type Props = RouteComponentProps;

const SetPieceTakers: React.FC<Props> = () => {
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const teams = useSelector(getTeams);
  const elements = useSelector(getElements);
  const [notes, setNotes] = useState({} as IStateNotes);

  const setPieceTakersByTeamId = useMemo(
    () => reduceElementsBySetPieceTakers(elements, teams),
    [elements, teams]
  );
  const teamsArr: ISPTTeam[] = Object.keys(setPieceTakersByTeamId)
    .map((key: string) => setPieceTakersByTeamId[key])
    .sort((a, b) => a.team.name.localeCompare(b.team.name));

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch("/api/team/set-piece-notes/");
        const { last_updated, teams } = await res.json();
        setNotes({ last_updated, byId: reduceNotesByTeamId(teams) });
      } catch (error) {}
    }
    fetchData();
  }, []);

  const renderElements = (setPieceTypes: ISPTElement[]) => {
    if (setPieceTypes.length === 0) {
      return <Element>No Set-Piece Takers</Element>;
    }

    return setPieceTypes
      .sort((a: ISPTElement, b: ISPTElement) => a.order - b.order)
      .map((element: ISPTElement) => (
        <Element data-testid="element" key={element.id}>
          <StyledInfo
            onClick={() => reduxDispatch(showElementSummary(element.id))}
          />
          <ElementName>{element.name}</ElementName>
        </Element>
      ));
  };

  return (
    <>
      <Wrapper className="webview-hidden">
        <Box flex={1} pt={4} mx={2}>
          <Title>The Scout</Title>
          <ScoutNav />
        </Box>
      </Wrapper>
      <Wrapper>
        <HelmetHead
          title="Set-Piece Takers, Player Statistics | Fantasy Premier League"
          description="View each Premier League team's set-piece takers for penalties, free kicks and corners. For more information, visit the official website of the Premier League."
        />
        <Main isWide={true}>
          <StyledSetPieceTakers>
            <Box mb={4}>
              <SetPieceTakersContent>
                {teamsArr.map((t: ISPTTeam, index: number) => {
                  return (
                    <div key={t.team.id} data-testid="team">
                      <Collapsible team={t.team} headingText={t.team.name}>
                        <TeamContentInner>
                          <Group>
                            <SetPieceTitle>Penalties</SetPieceTitle>
                            {renderElements(t.penalties)}
                          </Group>
                          <Group>
                            <SetPieceTitle>Direct free-kicks</SetPieceTitle>
                            {renderElements(t.direct_freekicks)}
                          </Group>
                          <Group>
                            <SetPieceTitle>
                              Corners &amp; indirect free-kicks
                            </SetPieceTitle>
                            {renderElements(t.corners_and_indirect_freekicks)}
                          </Group>
                        </TeamContentInner>
                        {notes.byId && notes.byId[t.team.id] && (
                          <NotesWrapper data-testid={`team-${t.team.id}-notes`}>
                            {notes.byId[t.team.id].notes.length > 0 &&
                              notes.byId[t.team.id].notes.map(
                                (note: INote, index: number) => (
                                  <Note
                                    key={`note-team-${t.team.id}-${index}`}
                                    data-testid={`team-${t.team.id}-note`}
                                  >
                                    {note.info_message ? (
                                      note.source_link ? (
                                        <NoteLink
                                          href={note.source_link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <span>
                                            {note.info_message}
                                            <NewTabCopy />
                                            {note.external_link && (
                                              <ExternalIcon />
                                            )}
                                          </span>
                                        </NoteLink>
                                      ) : (
                                        <span>{note.info_message}</span>
                                      )
                                    ) : null}
                                  </Note>
                                )
                              )}
                          </NotesWrapper>
                        )}
                      </Collapsible>
                    </div>
                  );
                })}
              </SetPieceTakersContent>
            </Box>
            <Box my={4}>
              <Explain>
                <Copy>
                  <SubHeading>Set-Piece Takers Explained</SubHeading>
                  <p>
                    This table displays a list of expected set-piece takers for
                    each Premier League club. It’s based on a mix of information
                    gathered from last season’s matches, pre-season friendlies
                    and, where appropriate, a player’s role at a previous club
                    and predicted role at their new club. As the season
                    progresses, the table is constantly reviewed and updated as
                    new information becomes available on assigned set-piece
                    takers.
                  </p>
                </Copy>
              </Explain>
            </Box>
          </StyledSetPieceTakers>
        </Main>
      </Wrapper>
    </>
  );
};

export default SetPieceTakers;

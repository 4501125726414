import { darken, size } from "polished";
import Slider from "react-slick";
import styled from "styled-components/macro";
import { ReactComponent as BaseExternalIcon } from "../../img/icons/external.svg";
import leftArrow from "../../img/icons/chevron-left.svg";
import rightArrow from "../../img/icons/chevron-right.svg";
import { Box } from "rebass";

const SLIDER_X_PADDING = "4rem";

export const StyledNewsTickerHeader = styled.header`
  max-width: 1220px;
  margin: 0 auto;
`;

export const StyledNewsTickerSlider = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding-left: ${(props) => props.theme.space[2]};
  padding-right: ${(props) => props.theme.space[2]};
  /* Same height as positioned dots */
  padding-bottom: 25px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SliderArticles = styled.div`
  padding-left: 4px;
  padding-right: 4px;
`;

export const SliderArticle = styled.div`
  display: flex;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledArticleTitle = styled.div`
  display: block;
  margin-left: 5px;
  color: #000;
  text-decoration: none;
  font-size: 11px;
  line-height: 1.4;
`;

export const StyledArticleTitleAnchor = styled.a`
  display: block;
  margin-left: 5px;
  color: #000;
  font-size: 11px;
  cursor: pointer;
  line-height: 1.4;

  &:hover {
    color: ${({ theme }) => theme.colors.darkPink};
    text-decoration: underline;
  }
`;

export const StyledSlider = styled(Slider)`
  min-height: 0px;
  min-width: 0px;
  margin-top: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 0 ${SLIDER_X_PADDING};
    border: 1px solid #d8d8d8;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      float: none;
      width: 80vw;
      height: auto;
      padding: 1rem 0 1rem;
      padding-right: 10px;

      @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 0;
        border-right: 1px solid #efefef;
      }

      > div {
        height: 100%;
        border: 1px solid #d8d8d8;

        @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
          border: 0;
        }
      }
    }
  }

  .slick-dots {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;

    li {
      margin-left: 0;
      margin-right: 0;
    }

    li button:before {
      opacity: 1;
      font-size: 10px;
      width: 10px;
      height: 10px;
      border: 2px solid #efefef;
      border-radius: 50%;
      color: transparent;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    li.slick-active button:before {
      font-size: 10px;
      color: transparent;
      background: #efefef;
      border: none;
    }
  }

  .slick-prev {
    left: 0;

    &::before {
      background: url(${leftArrow});
    }
  }

  .slick-next {
    right: 0;

    &::before {
      background: url(${rightArrow});
    }
  }

  .slick-prev,
  .slick-next {
    top: 0;
    bottom: 0;
    width: ${SLIDER_X_PADDING};
    height: auto;
    transform: none;
    transition: all 0.2s;
    background-color: ${({ theme }) => theme.colors.grey};

    &:hover,
    &:focus {
      border-color: ${darken(0.05, "#ebebeb")};
      background-color: ${darken(0.05, "#f6f6f5")};
    }

    &::before {
      ${size(15, 9)};
      content: "";
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 14px 24px;
    }

    .slick-disabled {
      cursor: default;

      &:hover,
      &:focus {
        border-color: #ebebeb;
        background-color: #f6f6f5;
      }
    }

    .slick-list,
    .slick-track {
      min-height: 0px;
      min-width: 0px;
    }
  }
`;

export const StyledSlide = styled(Box)`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-left: 8px;
    padding-right: 8px;
    outline: none;
  }
`;

export const StyledSlideInner = styled.div`
  padding: 12px 8px;
  outline: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 0;
    border: none;
  }
`;

export const SliderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[2]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const Hashtag = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.bold};
  margin: 0 ${({ theme }) => theme.space[2]};
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 1.4em;
  }
`;

export const LogoWrapper = styled.div``;

export const ExternalIcon = styled(BaseExternalIcon)`
  ${size(8)}
  margin-left: 0.4rem;
  fill: ${({ theme }) => theme.colors.darkGrey};
`;

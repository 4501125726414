import React, { useEffect, useState } from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import {
  fetchNewsArticle,
  fetchPlaylist,
  getPulseTeamIdFromRefs,
  IPulseNewsArticle,
  IPulseRelatedArticle,
  IPulseTag,
} from "../../utils/pulse";
import ScoutCard from "../scout/ScoutCard";
import { ScoutItem, ScoutList } from "../scout/styles";
import SubHeading from "../SubHeading";
import UpdatingErrorBoundary from "./UpdatingErrorBoundary";

const ArticleHeading = styled.h3`
  color: ${({ theme }) => theme.colors.darkPink};
  font-family: ${({ theme }) => theme.fonts.boldItalic};
`;

const UpdatingArticle = styled.div`
  ul {
    margin-left: ${({ theme }) => theme.space[1]};

    li {
      line-height: 2;
      list-style-type: disc;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.darkPink};
    text-decoration: none;
  }
`;

interface IPulsePlaylistItem {
  id: number;
  type: string;
  response: any;
}

interface IPulsePlaylist {
  items: IPulsePlaylistItem[];
  title: string;
}

const UpdatingContent: React.FC = () => {
  const [updatingArticle, setUpdatingArticle] = useState<any>(null);
  const [updatingPlaylist, setUpdatingPlaylist] =
    useState<IPulsePlaylist | null>(null);
  const [externalNews, setExternalNews] =
    useState<Record<string, IPulseNewsArticle> | null>(null);
  useEffect(() => {
    Promise.all([fetchNewsArticle(2204115), fetchPlaylist(2204122, 10)]).then(
      (data) => {
        const [updatingArticleData, updatingPlaylistData] = data;
        setUpdatingArticle(updatingArticleData);
        setUpdatingPlaylist(updatingPlaylistData);

        const relatedTextContentIds = updatingPlaylistData.items
          .reduce(
            (acc: any, cur: any) => [
              ...acc,
              ...cur.response.related.slice(0, 2),
            ],
            []
          )
          .filter((r: IPulseRelatedArticle) => r.type === "TEXT")
          .map((r: IPulseRelatedArticle) => r.id);

        const newsRequests: [Promise<IPulseNewsArticle>] =
          relatedTextContentIds.map((id: number) => fetchNewsArticle(id));

        Promise.all(newsRequests).then((articles) => {
          const externalNewsData = articles.filter((a) =>
            a
              ? a.tags.some((tag: IPulseTag) => tag.label === "External")
              : false
          );
          if (externalNewsData.length > 0) {
            const externalNewsById: Record<string, IPulseNewsArticle> =
              externalNewsData.reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr.id]: curr,
                }),
                {}
              );

            setExternalNews(externalNewsById);
          }
        });
      }
    );
  }, []);

  if (!updatingArticle || !updatingPlaylist) {
    return null;
  }

  const getOptaCodeFromTags = (article: IPulseNewsArticle) => {
    const OptaCodeTagsArray = article.tags?.filter(
      (x) => x.label.indexOf("OptaID_") !== -1
    );

    const OptaCodeTagLabel =
      OptaCodeTagsArray && OptaCodeTagsArray.length > 0
        ? OptaCodeTagsArray[0].label
        : "";

    const OptaCode = OptaCodeTagLabel
      ? Number(OptaCodeTagLabel.split("_t")[1])
      : 0;

    return OptaCode;
  };

  const buildTeamsArray = (
    relatedArticles: any[],
    externalNews: Record<string, IPulseNewsArticle>
  ) =>
    relatedArticles.map((r) => {
      const externalArticle = externalNews[r.id] || null;
      const refs = externalArticle ? externalArticle.references : null;
      return {
        code: externalArticle ? getOptaCodeFromTags(externalArticle) : 0,
        id: 0,
        name: "",
        short_name: "",
        unavailable: false,
        pulse_id: refs && refs.length > 0 ? getPulseTeamIdFromRefs(refs) : 0,
      };
    });

  return (
    <Box px={2}>
      <Box mt={2} mb={4}>
        <h2>{updatingArticle.description}</h2>
      </Box>
      <Box mb={2}>
        <ArticleHeading>{updatingArticle.subtitle}</ArticleHeading>
      </Box>
      <Box mb={4}>
        <UpdatingArticle
          dangerouslySetInnerHTML={{ __html: updatingArticle.body }}
        />
      </Box>
      <SubHeading>{updatingPlaylist.title}</SubHeading>
      <ScoutList>
        {updatingPlaylist.items.slice(0, 3).map((i) => {
          return (
            <ScoutItem key={i.id}>
              <ScoutCard
                newsItem={i.response}
                externalNews={externalNews}
                teams={
                  externalNews
                    ? buildTeamsArray(i.response.related, externalNews)
                    : null
                }
              />
            </ScoutItem>
          );
        })}
      </ScoutList>
    </Box>
  );
};

const UpdatingWrapper: React.FC = () => (
  <UpdatingErrorBoundary>
    <UpdatingContent />
  </UpdatingErrorBoundary>
);

export default UpdatingWrapper;

import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import { IWinner } from "./types";
import * as actions from "./actions";

export const fetchEventWinners =
  (event: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet<IWinner[]>(
        `winners/event/${event}/`,
        dispatch,
        actions.fetchEventWinners,
        { event }
      );
    } catch (e) {
      return;
    }
  };

export const fetchPhaseWinners =
  (phase: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet<IWinner[]>(
        `winners/phase/${phase}/`,
        dispatch,
        actions.fetchPhaseWinners,
        { phase }
      );
    } catch (e) {
      return;
    }
  };

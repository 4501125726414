import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFixturesByEvent } from "../core/store/fixtures/reducers";
import { IFixture } from "../core/store/fixtures/types";
import { fetchBroadcastersForFixtures } from "../utils/pulse";

export interface IBroadcasterInfo {
  abbreviation: string;
  name: string;
  url: string;
}

type BroadcasterInfo = Record<string, Record<string, IBroadcasterInfo[]>>;

export const FixtureBroadcastersContext = React.createContext(
  {} as BroadcasterInfo
);

const FixtureBroadcasters: React.FC = ({ children }) => {
  const [broadcasterInfo, setBroadcasterInfo] = useState<BroadcasterInfo>({});
  const fixturesByEvent = useSelector(getFixturesByEvent);

  const optaMapFromFixtures = (fixtures: IFixture[]): Record<string, number> =>
    fixtures.reduce(
      (memo, f) => ({
        ...memo,
        [`g${f.code}`]: f.id,
      }),
      {}
    );

  useEffect(() => {
    // Limit fetches caused by loading all fixtures for FDR
    let fetchesInProgress = 0;
    Object.keys(fixturesByEvent).forEach(async (event) => {
      // We already have a fetch in progress
      if (fetchesInProgress) return;

      const fixtures = fixturesByEvent[event];
      // There are no fixtures in the event so no broadasters ...
      if (!fixtures.length) return;

      // We have fixtures but no broadcaster info so fetch it
      if (!broadcasterInfo[event]) {
        fetchesInProgress++;
        const optaMap = optaMapFromFixtures(fixtures);
        const broadcasterData = await fetchBroadcastersForFixtures(
          moment(fixtures[0].kickoff_time!).format("YYYY-MM-DD"),
          moment(fixtures[fixtures.length - 1].kickoff_time!).format(
            "YYYY-MM-DD"
          )
        ).catch((error) => {});
        if (broadcasterData && broadcasterData.content) {
          const eventBroadcasterInfo: Record<string, IBroadcasterInfo[]> = {};
          broadcasterData.content.forEach((data) => {
            if (data.fixture && data.fixture.altIds && data.broadcasters) {
              const fixtureId = optaMap[data.fixture.altIds.opta];
              const bData = data.broadcasters
                .filter(
                  (broadcasterData: IBroadcasterInfo) =>
                    !/^UK Radio -/.test(broadcasterData.name)
                )
                .map((broadcasterData: IBroadcasterInfo) => ({
                  abbreviation: broadcasterData.abbreviation,
                  name: broadcasterData.name,
                  url: broadcasterData.url,
                }));
              if (bData && bData.length) {
                eventBroadcasterInfo[fixtureId] = bData;
              }
            }
          });
          setBroadcasterInfo((state) => ({
            ...state,
            [event]: eventBroadcasterInfo,
          }));
        }
      }
    });
    // We only want to run this effect when fixturesByEvent changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixturesByEvent]);

  return (
    <FixtureBroadcastersContext.Provider value={broadcasterInfo}>
      {children}
    </FixtureBroadcastersContext.Provider>
  );
};

export default FixtureBroadcasters;

import { rgba } from "polished";
import * as React from "react";
import styled from "styled-components/macro";
import { IElementType } from "../../core/store/element-types/types";
import { ReactComponent as AddPersonBase } from "../../img/icons/add-person.svg";
import ElementTypeLabel from "../ElementTypeLabel";
import { PitchCard, PitchCardButton, PitchElementWrap } from "../PitchCard";

export const AddPerson = styled(AddPersonBase)`
  display: inline-block;
  vertical-align: middle;
  fill: ${(props) => props.theme.colors.white};
`;

const StyledPitchPlaceholder = styled(PitchCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  margin: auto;
  padding: ${(props) => props.theme.space[1]};
  border-color: ${(props) => rgba(props.theme.colors.white, 0.3)};
  color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[3]};
  }
`;

interface IProps {
  elementType: IElementType;
  onClick: () => void;
}

const PitchPlaceholder: React.FC<IProps> = ({ elementType, onClick }) => (
  <PitchElementWrap>
    <PitchCardButton onClick={onClick}>
      <StyledPitchPlaceholder>
        <AddPerson title="Add Player" />
        <ElementTypeLabel elementType={elementType} />
      </StyledPitchPlaceholder>
    </PitchCardButton>
  </PitchElementWrap>
);

export default PitchPlaceholder;

import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "../../core/store";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { fetchMyTeam } from "../../core/store/my-team/thunks";
import { ITransferState } from "../../core/store/my-team/types";
import { getTransferState } from "../../core/store/squad/reducers";
import HelmetHead from "../HelmetHead";
import LeaderboardAd from "../LeaderboardAd";
import ConfirmTransfersDialog from "./ConfirmTransfersDialog";
import SquadBase from "./SquadBase";
import TransfersListTable from "./TransfersListTable";

interface IPropsFromState {
  nextEvent: IEvent | null;
  transfersState: ITransferState | null;
}

interface IPropsFromDispatch {
  fetchMyTeam: () => void;
}

type Props = RouteComponentProps & IPropsFromState & IPropsFromDispatch;

class Transfers extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchMyTeam();
  }

  public render() {
    const { nextEvent, transfersState } = this.props;
    // Don't render until we know what transfers staate we need to render ...
    if (!transfersState) {
      return null;
    }
    const scoreboard = !nextEvent
      ? "basic"
      : transfersState.status === "cost"
      ? "cost"
      : "unlimitedTransfers";
    return (
      <>
        <LeaderboardAd slot="Leaderboard_Transfers" id="ism-transfers-ad" />
        <SquadBase
          listTable={(listTableProps) => (
            <TransfersListTable {...listTableProps} />
          )}
          scoreboard={scoreboard}
          submitDialog={(handleHide) => (
            <ConfirmTransfersDialog handleHide={handleHide} />
          )}
          title="Transfers"
          headTags={
            <HelmetHead
              title="Fantasy Football Transfers | Fantasy Premier League"
              description="To make transfers in your Fantasy Premier League team and consider playing your wildcard, visit the official website of the Premier League."
            />
          }
          nextEvent={nextEvent}
        />
      </>
    );
  }
}

export { Transfers as TransfersTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  nextEvent: getNextEvent(state),
  transfersState: getTransferState(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchMyTeam: () => dispatch(fetchMyTeam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);

import { hiDPI, size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as FPLChallengeLogo } from "../../img/fpl-challenge-logo.svg";
import pattern2Desktop from "../../img/pattern/pattern-2-d.png";
import pattern2Desktop2x from "../../img/pattern/pattern-2-d@2x.png";
import pattern2Mobile from "../../img/pattern/pattern-2-m.png";
import pattern2Mobile2x from "../../img/pattern/pattern-2-m@2x.png";
import ButtonLink from "../ButtonLink";
import Dialog from "../Dialog";
import { ArrowRight } from "../icons/Arrows";

const ChallengeLink = styled.a`
  display: inline-block;
  margin-top: ${({ theme }) => theme.space[4]};
  padding: 1.3rem 2.4rem;
  border-radius: ${({ theme }) => theme.radii[0]};
  background: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  text-decoration: none;
`;

const ArrowIcon = styled(ArrowRight)`
  ${size(12)}
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]};
  padding: ${({ theme }) => theme.space[2]};
`;

const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
`;

const Background = styled.div`
  position: relative;
  border-radius: 16px;
  padding: ${({ theme }) => theme.space[3]};
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.fantasy} 0%,
    ${({ theme }) => theme.colors.blue} 100%
  );
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: 2.4rem;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    background-image: url(${pattern2Mobile});
    background-size: 240px;
    background-repeat: no-repeat;
    background-position: -40px 20px;
    z-index: -1;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);

    ${hiDPI(2)} {
      background-image: url(${pattern2Mobile2x});
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      background-image: url(${pattern2Desktop});
      background-position: -50px -50px;

      ${hiDPI(2)} {
        background-image: url(${pattern2Desktop2x});
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
  width: 90%;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 80%;
  }
`;

const ModalCopy = styled.p`
  padding-top: ${({ theme }) => theme.space[4]};
`;

const ChallengeLogo = styled(FPLChallengeLogo)`
  ${size("auto", 220)}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size("auto", 280)}
  }
`;

const PlayChallengePanel = () => {
  return (
    <Background>
      <Content>
        <ChallengeLogo />
        <p>
          Create a season-long mini-league and face a new challenge every week!
          Starting with the NEW "5 a Side" event!
        </p>
        <div>
          <ChallengeLink href="https://fplchallenge.premierleague.com/">
            <LinkWrap>
              Play Fantasy Challenge
              <ArrowIcon />
            </LinkWrap>
          </ChallengeLink>
        </div>
      </Content>
    </Background>
  );
};

interface IProps {
  closeDialog: () => void;
}

const TeamSavedModal: React.FC<IProps> = ({ closeDialog }) => {
  return (
    <Dialog closeDialog={closeDialog}>
      <Dialog.Header closeDialog={closeDialog}>Team Saved</Dialog.Header>
      <Dialog.Body>
        <ModalContent>
          <ModalCopy>
            Dive into your leagues to track your performance, compare and see
            how you measure up against other managers.
          </ModalCopy>
          <div>
            <ButtonLink variant="secondary" to={"../leagues"}>
              <LinkWrap>
                View my Leagues
                <ArrowIcon />
              </LinkWrap>
            </ButtonLink>
          </div>
          <PlayChallengePanel />
        </ModalContent>
      </Dialog.Body>
    </Dialog>
  );
};

export default TeamSavedModal;

import * as styled from "styled-components";
import theme from "./theme";

export default styled.createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: ${theme.fonts.regular};
    font-size: 62.5%;
  }

  body {
    margin: 0;
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes[2]};
  }

  p {
    color: inherit;
    line-height: 1.6;
  }

  .ism {
    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    strong {
      font-family: ${theme.fonts.bold};
    }

    input[type="search"] {
      box-sizing: inherit;
    }
  }
`;

import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../core/store";
import {
  getEntry,
  getEntryChipHistory,
  getEntryEventHistory,
  getEntrySeasonHistory,
} from "../core/store/entries/reducers";
import {
  fetchEntryHistory,
  fetchEntrySummary,
} from "../core/store/entries/thunks";
import {
  IEntry,
  IEntryChipHistory,
  IEntryEventHistory,
  IEntrySeasonHistory,
} from "../core/store/entries/types";
import { getPlayerData } from "../core/store/player/reducers";
import { formatRawAsLocal } from "../core/utils/datetime";
import { integerToMoney } from "../core/utils/money";
import { getChipName } from "../utils/chips";
import Entry from "./Entry";
import HelmetHead from "./HelmetHead";
import { Main, Secondary, Wrapper } from "./Layout";
import Movement from "./leagues/Movement";
import Link from "./Link";
import SubHeading from "./SubHeading";
import Table, { ScrollTable } from "./Table";
import Title from "./Title";
import { getShortNameFromId } from "../utils/events";
import Tooltip, { TooltipLabel } from "./Tooltip";

type OwnProps = RouteComponentProps<{ entryId: string }>;

interface IPropsFromState {
  chipHistory: IEntryChipHistory[];
  entry: IEntry | null;
  eventHistory: IEntryEventHistory[];
  mine: boolean;
  seasonHistory: IEntrySeasonHistory[];
}

interface IPropsFromDispatch {
  fetchEntryHistory: (entryId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

class EntryHistory extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchEntryHistory(Number(this.props.entryId));
    this.props.fetchEntrySummary(Number(this.props.entryId));
  }

  public movementFromIndex(index: number): React.ReactNode {
    const thisRank = this.props.eventHistory[index].overall_rank;
    const lastRank =
      index === this.props.eventHistory.length - 1
        ? null
        : this.props.eventHistory[index + 1].overall_rank;
    return <Movement lastRank={lastRank} rank={thisRank} />;
  }

  public render() {
    const { entry } = this.props;
    if (!entry) {
      return null;
    }
    return (
      <Wrapper>
        <HelmetHead
          title="Fantasy Football Gameweek History | Fantasy Premier League"
          description={`To view the Gameweek history of ${entry.player_first_name} ${entry.player_last_name}, along with the status of your Chips, visit the official website of the Premier League.`}
        />
        <Main>
          <Box mx={2}>
            <Title>Entry History</Title>
          </Box>

          <Box mb={4}>
            <Box mx={2}>
              <SubHeading>This Season</SubHeading>
            </Box>
            <ScrollTable>
              <Table>
                <thead>
                  <tr>
                    <th scope="col">
                      <Tooltip content="Gameweek">
                        <TooltipLabel>GW</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Overall Rank">
                        <TooltipLabel>OR</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Movement">
                        <TooltipLabel>#</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Overall Points">
                        <TooltipLabel>OP</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Gameweek Rank">
                        <TooltipLabel>GWR</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Gameweek Points">
                        <TooltipLabel>GWP</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Points on Bench">
                        <TooltipLabel>PB</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Transfers Made">
                        <TooltipLabel>TM</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Transfers Cost">
                        <TooltipLabel>TC</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Squad Value">
                        <TooltipLabel>£</TooltipLabel>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...this.props.eventHistory].map((eh, i) => (
                    <tr key={eh.event}>
                      <td>
                        <Link to={`/entry/${entry.id}/event/${eh.event}`}>
                          {getShortNameFromId(eh.event)}
                        </Link>
                      </td>
                      <td>
                        {eh.overall_rank
                          ? eh.overall_rank.toLocaleString()
                          : "-"}
                      </td>
                      <td>{this.movementFromIndex(i)}</td>
                      <td>{eh.total_points}</td>
                      <td>{eh.rank ? eh.rank.toLocaleString() : "-"}</td>
                      <td>{eh.points.toLocaleString()}</td>
                      <td>{eh.points_on_bench}</td>
                      <td>{eh.event_transfers}</td>
                      <td>{eh.event_transfers_cost}</td>
                      <td>{integerToMoney(eh.value, 10)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ScrollTable>
          </Box>

          <Box mb={4}>
            <Box mx={2}>
              <SubHeading>Chips</SubHeading>
            </Box>
            <Table>
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Name</th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
              <tbody>
                {this.props.chipHistory.map((ch, i) => (
                  <tr key={ch.event}>
                    <td>{formatRawAsLocal(ch.time)}</td>
                    <td>{getChipName(ch.name)}</td>
                    <td>
                      <Link to={`/entry/${entry.id}/event/${ch.event}`}>
                        {getShortNameFromId(ch.event)}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>

          <Box mb={4}>
            <Box mx={2}>
              <SubHeading>Previous Seasons</SubHeading>
            </Box>
            <Table>
              <thead>
                <tr>
                  <th scope="col">Season</th>
                  <th scope="col">Points</th>
                  <th scope="col">Rank</th>
                </tr>
              </thead>
              <tbody>
                {this.props.seasonHistory.reverse().map((sh) => (
                  <tr key={sh.season_name}>
                    <td>{sh.season_name}</td>
                    <td>{sh.total_points}</td>
                    <td>{sh.rank}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Main>
        <Secondary>
          <Entry entryId={entry.id} />
        </Secondary>
      </Wrapper>
    );
  }
}

export { EntryHistory as EntryHistoryTest };

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const entryId = Number(ownProps.entryId);
  const player = getPlayerData(state);
  return {
    chipHistory: getEntryChipHistory(state, entryId),
    entry: getEntry(state, entryId),
    eventHistory: [...getEntryEventHistory(state, entryId)].reverse(),
    mine: Boolean(player && player.entry && player.entry === entryId),
    seasonHistory: getEntrySeasonHistory(state, entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntryHistory: (entryId: number) => dispatch(fetchEntryHistory(entryId)),
  fetchEntrySummary: (entryId: number) => dispatch(fetchEntrySummary(entryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntryHistory);

import { useSelector } from "react-redux";
import { RootState } from "../../core/store";
import { getElement } from "../../core/store/elements/reducers";
import {
  getElementEventOpponents,
  getFixturesForEventByTeam,
} from "../../core/store/fixtures/reducers";
import { ElementMultiFixtures, ElementSingleFixture } from "./styles";
import { IMultiProps } from "./types";

const FixturesForElement = ({ elementId, eventId }: IMultiProps) => {
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );
  const fixturesForEventByTeam = useSelector((state: RootState) =>
    eventId ? getFixturesForEventByTeam(state, eventId) : null
  );

  const fixtures =
    element && fixturesForEventByTeam
      ? fixturesForEventByTeam[element.team]
      : [];

  const elementEventOpponents = useSelector((state: RootState) =>
    fixtures?.length && element
      ? getElementEventOpponents(state, element, fixtures)
      : []
  );

  if (!element || !fixturesForEventByTeam) {
    return null;
  }

  if (elementEventOpponents.length === 1) {
    return (
      <ElementSingleFixture>{elementEventOpponents[0]}</ElementSingleFixture>
    );
  }

  if (elementEventOpponents.length > 1) {
    return (
      <ElementMultiFixtures>
        {elementEventOpponents.join(", ")}
      </ElementMultiFixtures>
    );
  }

  return <div>-</div>;
};
export default FixturesForElement;

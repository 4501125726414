import { useSelector } from "react-redux";
import { RootState } from "../../core/store";
import {
  getElement,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import { getFixturesForEventByTeam } from "../../core/store/fixtures/reducers";
import FixtureForElement from "../FixturesForElement/FixtureForElement";
import { ElementMultiPoints } from "./styles";
import { IElementPoints } from "./types";

export const ElementPoints = ({ eventId, pick }: IElementPoints) => {
  const fixturesForEventByTeam = useSelector((state: RootState) =>
    getFixturesForEventByTeam(state, eventId)
  );
  const element = useSelector((state: RootState) =>
    getElement(state, pick.element)
  );
  const elementsEventDataById = useSelector((state: RootState) =>
    element ? getElementsEventDataById(state, eventId) : null
  );

  if (!element || !elementsEventDataById) {
    return null;
  }

  const elementEventData = elementsEventDataById[element.id];

  const explainList = elementEventData.explain;
  const totalPoints =
    elementEventData.stats.total_points * Math.max(pick.multiplier, 1);

  if (explainList.length === 1) {
    return <>{totalPoints}</>;
  }

  const ElementFixtures = fixturesForEventByTeam[element.team];
  const allFixturesStarted = ElementFixtures.every((f) => f.started);
  let fragments: React.ReactNode[] = [];
  let started = 0;

  elementEventData.explain.forEach((e) => {
    const fixture = ElementFixtures.find((f) => f.id === e.fixture);
    if (fixture) {
      if (!fixture.started) {
        fragments.push(
          <FixtureForElement element={element} fixture={fixture} />
        );
      } else {
        started++;
      }
    }
  });
  if (started) {
    fragments.unshift(totalPoints);
  }
  const MultiPoints = fragments.reduce((result, item) => (
    <ElementMultiPoints $allStarted={allFixturesStarted}>
      {result}
      {", "}
      {item}
    </ElementMultiPoints>
  ));

  return <>{MultiPoints}</>;
};

export default ElementPoints;

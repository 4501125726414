import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import { IEventStatusData } from "./types";
import * as actions from "./actions";

export const fetchEventStatus =
  (): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
      await apiGet<IEventStatusData>(
        "event-status/",
        dispatch,
        actions.fetchEventStatus
      );
    } catch (e) {
      return;
    }
  };

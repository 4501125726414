import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { getElement } from "../../core/store/elements/reducers";
import { IPickLight } from "../../core/store/entries/types";
import { actionSubstitution } from "../../core/store/my-team/thunks";
import Tooltip, { TooltipLabel } from "../Tooltip";
import TeamPitchElement from "./TeamPitchElement";

const StyledBenchUnit = styled.div`
  flex: 1;
`;

const BenchUnitHeading = styled.h3`
  margin: ${({ theme }) => theme.space[1]} 0;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;
  letter-spacing: -0.1px;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: ${({ theme }) => theme.space[2]};
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
`;
interface IProps {
  chipName: string | null;
  eventId?: number;
  index: number;
  pick: IPickLight;
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: (pick: IPickLight) => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

const BenchUnit = ({
  chipName,
  index,
  pick,
  renderDreamTeam,
  renderElementMenu,
  renderPickValue,
}: IProps) => {
  const element = useSelector((state: RootState) =>
    getElement(state, pick.element)
  );
  const elementTypesById = useSelector(getElementTypesById);
  const reduxDispatch = useDispatch<ThunkDispatch>();

  if (!element) {
    return null;
  }
  const elementType = elementTypesById[element.element_type];
  return (
    <StyledBenchUnit>
      <BenchUnitHeading>
        {`${index ? `${index}. ` : ""}`}
        <Tooltip content={elementType.singular_name}>
          <TooltipLabel>{elementType.singular_name_short}</TooltipLabel>
        </Tooltip>
      </BenchUnitHeading>
      <TeamPitchElement
        actionMe={() => reduxDispatch(actionSubstitution(pick.element))}
        chipName={chipName}
        pick={pick}
        renderDreamTeam={renderDreamTeam}
        renderElementMenu={() => renderElementMenu(pick)}
        renderPickValue={renderPickValue}
      />
    </StyledBenchUnit>
  );
};

export default BenchUnit;

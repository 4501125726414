import styled from "styled-components/macro";

const LineHeading = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: inherit;
  text-align: center;

  ::before,
  ::after {
    content: "";
    display: inline-block;
    width: 24%;
    height: 0.3rem;
    vertical-align: middle;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 33%;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
      width: 36%;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
      width: 38%;
    }
  }
  ::before {
    margin-right: 1%;
    background: linear-gradient(
      to left,
      ${({ theme }) => theme.colors.primary},
      transparent
    );
  }
  ::after {
    margin-left: 1%;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.primary},
      transparent
    );
  }
`;

export default LineHeading;

import { RouteComponentProps } from "@reach/router";
import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getCurrentEvent,
  getNextEvent,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import {
  getActiveEntryInLeague,
  getH2HLeague,
  getH2HStandings,
} from "../../core/store/leagues/reducers";
import { fetchH2HLeagueStandings } from "../../core/store/leagues/thunks";
import { IH2HStandingsData, ILeague } from "../../core/store/leagues/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import ButtonLink from "../ButtonLink";
import { ControlArrowLeft, ControlArrowRight } from "../icons/Arrows";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import { Pager, PagerItem, PagerItemNext } from "../Pager";
import Table, { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import H2HNav from "./H2HNav";
import HelmetHeadLeagues from "./HelmetHeadLeagues";
import Movement from "./Movement";
import StandingsHeading from "./StandingsHeading";
import StandingsRow from "./StandingsRow";

type OwnProps = RouteComponentProps<{ leagueId: string }>;

interface IPropsFromState {
  defaultEntryNumber: number;
  isPlayerInLeague: boolean;
  league: ILeague | null;
  leagueNumber: number;
  next: IEvent | null;
  now: IEvent | null;
  pageNewEntries: number;
  pageStandings: number;
  player: IPlayer | null;
  standings: IH2HStandingsData | null;
}

interface IPropsFromDispatch {
  fetchStandings: (
    leagueId: number,
    pageNewEntries: number,
    pageStandings: number
  ) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

class StandingsH2H extends React.Component<Props> {
  public fetchLeagueStandings() {
    if (this.props.leagueNumber) {
      this.props.fetchStandings(
        this.props.leagueNumber,
        this.props.pageNewEntries,
        this.props.pageStandings
      );
    }
  }

  public componentDidMount() {
    this.fetchLeagueStandings();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.pageNewEntries !== this.props.pageNewEntries ||
      prevProps.pageStandings !== this.props.pageStandings
    ) {
      this.fetchLeagueStandings();
    }
  }

  public buildLink(pageStandings: number) {
    return `/leagues/${
      this.props.league!.id
    }/standings/h?page_standings=${pageStandings}`;
  }

  public render() {
    const {
      defaultEntryNumber,
      league,
      now,
      pageStandings,
      player,
      standings,
      leagueNumber,
    } = this.props;
    if (!league) {
      return null;
    }
    return (
      <Wrapper>
        <HelmetHeadLeagues />
        <Main>
          <StandingsHeading
            type="h2h"
            league={league}
            leagueNumber={leagueNumber}
          />
          {standings && standings.results[0] && now && (
            <H2HNav
              eventId={now.id}
              leagueId={league.id}
              defaultEntry={defaultEntryNumber && defaultEntryNumber}
            />
          )}
          <Box mb={2}>
            <ScrollTable>
              <Table>
                <thead>
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Team & Manager</th>
                    <th scope="col">
                      <Tooltip content="Won">
                        <TooltipLabel>W</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Drew">
                        <TooltipLabel>D</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Lost">
                        <TooltipLabel>L</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">Score</th>
                    <th scope="col">
                      <Tooltip content="Points">
                        <TooltipLabel>Pts</TooltipLabel>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {standings &&
                    standings.results.map((ls) => (
                      <StandingsRow
                        mine={
                          player !== null &&
                          player.entry !== null &&
                          player.entry === ls.entry
                        }
                        key={ls.entry}
                      >
                        <td>
                          <Flex alignItems="center">
                            <Box mr={1}>{ls.rank}</Box>
                            <Movement lastRank={ls.last_rank} rank={ls.rank} />
                          </Flex>
                        </td>
                        <td>
                          {ls.entry ? (
                            <Link to={`/entry/${ls.entry}/event/${now!.id}`}>
                              <strong>{ls.entry_name}</strong>
                            </Link>
                          ) : (
                            <strong>{ls.entry_name}</strong>
                          )}
                          <br />
                          {ls.player_name}
                        </td>
                        <td>{ls.matches_won}</td>
                        <td>{ls.matches_drawn}</td>
                        <td>{ls.matches_lost}</td>
                        <td>{ls.points_for}</td>
                        <td>{ls.total}</td>
                      </StandingsRow>
                    ))}
                </tbody>
              </Table>
            </ScrollTable>
          </Box>
          {standings && standings.results.length > 0 && (
            <Box mb={4}>
              <Pager>
                <PagerItem>
                  {pageStandings > 1 && (
                    <ButtonLink
                      to={this.buildLink(pageStandings - 1)}
                      variant="secondary"
                      fullwidth="true"
                    >
                      <ControlArrowLeft />
                      <span>Previous</span>
                    </ButtonLink>
                  )}
                </PagerItem>
                <PagerItemNext>
                  {standings.has_next && (
                    <ButtonLink
                      to={this.buildLink(pageStandings + 1)}
                      variant="secondary"
                      fullwidth="true"
                    >
                      <span>Next</span>
                      <ControlArrowRight />
                    </ButtonLink>
                  )}
                </PagerItemNext>
              </Pager>
            </Box>
          )}
        </Main>
        <Secondary>
          {league && (
            <Box mt="0.7rem" mx={2}>
              <ButtonLink
                to={`/help/report-name?leagueId=${league.id}&leagueName=${league.name}`}
                variant="secondary"
                fullwidth="fullwidth"
              >
                Report Offensive Name
                <ControlArrowRight />
              </ButtonLink>
            </Box>
          )}
        </Secondary>
      </Wrapper>
    );
  }
}

export { StandingsH2H as StandingH2HTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  // Get league id from path
  const leagueNumber = parseInt(ownProps.leagueId!, 10) || 0;

  // Get page numbers from query string
  const query = qs.parse(ownProps.location!.search, {
    ignoreQueryPrefix: true,
  });
  const defaultEntryNumber = parseInt(query.default_entry as string, 10) || 0;
  const pageNewEntries = parseInt(query.page_new_entries as string, 10) || 1;
  const pageStandings = parseInt(query.page_standings as string, 10) || 1;
  const next = getNextEvent(state);
  const now = getCurrentEvent(state);
  return {
    defaultEntryNumber,
    isPlayerInLeague: Boolean(
      getActiveEntryInLeague(state, leagueNumber, "h2h")
    ),
    league: getH2HLeague(state, leagueNumber),
    leagueNumber,
    next: next,
    now: now,
    pageNewEntries,
    pageStandings,
    player: getPlayerData(state),
    standings: getH2HStandings(state, leagueNumber, pageStandings),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchStandings: (
    leagueId: number,
    pageNewEntries: number,
    pageStandings: number
  ) =>
    dispatch(fetchH2HLeagueStandings(leagueId, pageNewEntries, pageStandings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandingsH2H);

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

interface IStyledPitch {
  pitch: string;
}

export interface IElementName {
  copnr: number | null;
}

const StyledPitch = styled.div<IStyledPitch>`
  min-height: 330px;
  border-radius: 6px;
  background: url(${(props) => props.pitch}) no-repeat center top;
  background-size: 625px 460px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 524px;
    padding-top: 5px;
    background-size: 938px 690px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 690px;
    background-size: 1210px 890px;
  }
`;

export const ElementRow = styled.div`
  display: flex;
  max-width: 410px;
  margin: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: 620px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    max-width: none;
  }
`;

export const PitchRow = styled(ElementRow)`
  margin-bottom: 0.3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-bottom: 2.8rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-bottom: 4.5rem;

    :nth-child(4n) {
      margin-bottom: 1.5rem;
    }
  }
`;

export const PitchUnit = styled.div`
  flex: 1;
  min-height: 7.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 9.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 11.6rem;
  }
`;

interface IProps {
  sponsor: string;
}

const Pitch: React.FC<IProps> = ({ children, sponsor }) => {
  let [pitchSrc, setPitchSrc] = useState("");

  useEffect(() => {
    import(`../img/pitches/pitch-${sponsor}.svg`).then((src) => {
      setPitchSrc(src.default);
    });
  }, [sponsor]);

  return (
    <StyledPitch pitch={pitchSrc} data-testid="pitch">
      {children}
    </StyledPitch>
  );
};

export default Pitch;

import { size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as BaseNewsIcon } from "../../img/icons/news.svg";
import { ReactComponent as BasePlayIcon } from "../../img/icons/play.svg";
import { ReactComponent as BaseExternalIcon } from "../../img/icons/external.svg";

export const NewsLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  :hover img {
    transform: scale(1.1);
  }
`;

export const Figure = styled.figure`
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: block;
  }
`;

export const Thumb = styled.div`
  position: relative;
  width: 40%;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: auto;
  }
`;

export const NewsImg = styled.img`
  display: block;
  width: 100%;
  transition: opacity 0.4s ease-in-out, transform 0.4s;
`;

export const NewsSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.boldItalic};
  font-size: 1.1rem;
`;

export const NewsTitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.33;

  ${NewsLink}:hover & {
    text-decoration: underline;
  }
`;

export const StyledRelatedArticles = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: block;
    margin: ${({ theme }) => `0 ${theme.space[2]} ${theme.space[3]}`};
    padding-top: 0.8rem;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
    color: #6c6c6c;
  }
`;

export const RelatedLink = styled.a`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.space[1]};
  font-size: 1.1rem;
  text-decoration: none;
  transition: all 0.2s;
`;

export const IconWrap = styled.div`
  flex: 0 0 18%;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 15%;
  }
`;

export const PlayIcon = styled(BasePlayIcon)`
  ${size(14, 12)};
  fill: ${({ theme }) => theme.colors.darkPink};
`;

export const RelatedHeadline = styled.p`
  color: #6c6c6c;
  line-height: 1.36;

  :hover {
    color: ${({ theme }) => theme.colors.darkPink};
    text-decoration: underline;
  }
`;

export const NewsIcon = styled(BaseNewsIcon)`
  ${size(14, 20)};
  fill: ${({ theme }) => theme.colors.darkPink};
`;

export const ExternalIcon = styled(BaseExternalIcon)`
  ${size(10)};
  margin-left: ${(props) => props.theme.space[1]};
  fill: #6c6c6c;
`;

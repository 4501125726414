import styled from "styled-components/macro";

interface IProps {
  height: string;
}

export const Divider = styled.hr`
  height: 2px;
  margin: 0;
  border: none;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.lightGrey};
`;

const Keyline = styled.div<IProps>`
  height: ${(props) => props.height};
  margin: 0 ${({ theme }) => theme.space[2]};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.yellow},
    ${({ theme }) => theme.colors.fantasy}
  );
`;

export default Keyline;

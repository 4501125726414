import { rgba, size } from "polished";
import * as React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElement } from "../../core/store/elements/reducers";
import { IPickLight } from "../../core/store/entries/types";
import { ReactComponent as Captain } from "../../img/icons/captain.svg";
import { ReactComponent as Sub } from "../../img/icons/sub.svg";
import { ReactComponent as ViceCaptain } from "../../img/icons/vice-captain.svg";
import ElementDialogButton from "../ElementDialogButton";
import ElementShirt from "../ElementShirt";
import {
  ElementName,
  InfoControl,
  PitchCardButton,
  PitchElementData,
  PitchElementIconsWrap,
  PitchElementWrap,
  ShirtWrap,
  StyledPitchElement,
} from "../PitchCard";
import { UnstyledButton, VisuallyHidden } from "../Utils";

const StyledSub = styled(Sub)`
  display: block;
  ${size(9)}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(14)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(16)}
  }
`;

const sharedCaptainStyles = css<IStyledCaptainProps>`
  display: block;
  ${size(9)}
  color: white;

  ${(props) =>
    props.variant === "3xc" &&
    css`
      color: black;
      fill: white;
      border-radius: 50%;
      box-shadow: 1px 1px 10px -4px ${rgba("black", 0.5)};
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(14)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(16)}
  }
`;

const StyledCaptain = styled(Captain)`
  ${sharedCaptainStyles}
`;

const StyledViceCaptain = styled(ViceCaptain)`
  ${sharedCaptainStyles}
`;

interface IProps {
  actionMe: () => void;
  chipName: string | null;
  pick: IPickLight;
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: () => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

interface IStyledCaptainProps {
  variant?: string;
}

const TeamPitchElement: React.FC<IProps> = ({
  actionMe,
  chipName,
  pick,
  renderDreamTeam,
  renderElementMenu,
  renderPickValue,
}) => {
  const element = useSelector((state: RootState) =>
    getElement(state, pick.element)
  );

  if (!element) {
    return null;
  }
  const copnr = element.chance_of_playing_next_round;

  return (
    <PitchElementWrap variant={pick.subStatus || ""}>
      <PitchCardButton type="button" onClick={renderElementMenu}>
        <StyledPitchElement>
          <ShirtWrap>
            <ElementShirt
              elementId={element.id}
              sizes="(min-width: 1024px) 84px, (min-width: 610px) 64px, 46px"
            />
          </ShirtWrap>
          <PitchElementData>
            <ElementName copnr={element.news ? copnr : null}>
              {element.web_name}
            </ElementName>
            <>{renderPickValue(pick)}</>
          </PitchElementData>
        </StyledPitchElement>
      </PitchCardButton>
      <div>
        <PitchElementIconsWrap>
          {actionMe && typeof pick.subStatus !== "undefined" && (
            <UnstyledButton type="button" onClick={actionMe}>
              <StyledSub />
              <VisuallyHidden>Switch player</VisuallyHidden>
            </UnstyledButton>
          )}
          {pick.is_captain &&
            (chipName === "3xc" ? (
              <StyledCaptain variant="3xc" />
            ) : (
              <StyledCaptain />
            ))}
          {pick.is_vice_captain &&
            (chipName === "3xc" ? (
              <StyledViceCaptain variant="3xc" />
            ) : (
              <StyledViceCaptain />
            ))}
          <div>{renderDreamTeam(pick)}</div>
        </PitchElementIconsWrap>
        <InfoControl>
          <ElementDialogButton elementId={element.id} />
        </InfoControl>
      </div>
    </PitchElementWrap>
  );
};

export default TeamPitchElement;

import { ThunkResult } from "..";
import { apiGet, apiPost } from "../../utils/api";
import { getElementsById } from "../elements/reducers";
import { getNextEvent } from "../events/reducers";
import { getPlayerData } from "../player/reducers";
import { getPotentialChips } from "./reducers";
import * as actions from "./actions";
import { toUpdateChipAPI } from "../my-team/reducers";
import { IMyTeamData } from "../my-team/types";

export const chipActivationHandle =
  (chipName: string | null): ThunkResult<Promise<void>> =>
  // chip name should be provided that you wish to play
  // if null is passed, that would result in a chip being
  // cancelled
  async (dispatch, getState) => {
    const potentialChips = getPotentialChips(getState());
    const player = getPlayerData(getState());

    if (!player || !player.entry) {
      return;
    }

    const chip = potentialChips.find((chip) => chip.name === chipName);
    const pendingChip = potentialChips.find((chip) => chip.is_pending);

    if (
      (pendingChip && pendingChip.chip_type === "transfer" && !chip) ||
      (chip && chip.chip_type === "transfer")
    ) {
      const nextEvent = getNextEvent(getState());
      if (!nextEvent) {
        return;
      }
      try {
        await apiPost(
          "transfers/",
          {
            chip: chipName,
            entry: player.entry,
            event: nextEvent.id,
            transfers: [],
          },
          dispatch,
          actions.saveChipTransfer
        );
        await apiGet<IMyTeamData>(
          `my-team/${player.entry}/`,
          dispatch,
          actions.fetchChipState,
          { elementsById: getElementsById(getState()) }
        );
      } catch (e) {
        // Handle error if needed
        return;
      }
    } else if (!chip || chip.chip_type === "team") {
      try {
        await apiPost(
          `my-team/${player.entry}/`,
          {
            chip: chipName,
            picks: toUpdateChipAPI(getState()),
          },
          dispatch,
          actions.saveChipTeam,
          { elementsById: getElementsById(getState()) }
        );
      } catch (e) {
        // Handle error if needed
        return;
      }
    }
  };

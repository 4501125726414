import React from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { IPulseNewsArticle } from "../../utils/pulse";
import SectionHeading from "../SectionHeading";
import NewsItem from "./NewsItem";
import NewsThumb from "./NewsThumb";

export const StyledNewsList = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: 0;
    padding-left: 0;
  }
`;

interface IProps {
  news: IPulseNewsArticle[];
  title: string;
}

const NewsList: React.FC<IProps> = ({ news, title }) => {
  return (
    <>
      <Box mb={4}>
        <SectionHeading>{title}</SectionHeading>
      </Box>
      <StyledNewsList>
        {news.map((n: IPulseNewsArticle) => (
          <Box mb={2} key={n.id}>
            <NewsItem
              href={`https://www.premierleague.com/news/${n.id}`}
              img={
                <NewsThumb
                  news={n}
                  imgSizes={{
                    x1: { width: 104, height: 78 },
                    x2: { width: 208, height: 156 },
                  }}
                />
              }
              subtitle={n.subtitle}
              title={n.title}
            />
          </Box>
        ))}
      </StyledNewsList>
    </>
  );
};

export default NewsList;

import { rgba } from "polished";
import styled from "styled-components/macro";

const Title = styled.h2`
  /* Consider moving margins to Copy component */
  margin: ${({ theme }) => theme.space[3]} 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[4]};

  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: relative;
    padding-bottom: ${({ theme }) => theme.space[3]};
    margin-bottom: ${({ theme }) => theme.space[4]};

    ::before {
      content: "";
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-image: linear-gradient(
        to right,
        ${rgba("white", 0)} 0%,
        #efefef 50%,
        ${rgba("white", 0)} 100%
      );
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 2.5rem;
  }
`;

export default Title;

import { Link } from "@reach/router";
import React from "react";
import styled from "styled-components/macro";
import { INavLink } from "../../core/hooks/types";
import "./ListItem.css";
import { ReactComponent as BaseExternalIcon } from "../../img/icons/external.svg";
import { size } from "polished";

const LIStyled = styled.li`
  margin-right: ${({ theme }) => theme.space[1]};
  list-style-type: none;
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.bold};

  a {
    font-size: ${({ theme }) => theme.fontSizes[0]};

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      font-size: 1.3rem;
      line-height: 4.8rem;
    }
  }
`;

const ExternalIcon = styled(BaseExternalIcon)`
  ${size(8)}
  margin-left: 0.4rem;
  fill: white;
`;

const ListItem = React.forwardRef<HTMLLIElement, INavLink>(
  ({ useRouter, text, href, rel, indicateExternal, isDraft, target }, ref) => {
    return (
      <LIStyled ref={ref}>
        {useRouter ? (
          <Link
            to={href}
            getProps={({ isCurrent, isPartiallyCurrent }) => {
              return {
                className: `ism-nav__tab ${
                  href === "/"
                    ? isCurrent
                      ? "active"
                      : ""
                    : isPartiallyCurrent
                    ? "active"
                    : ""
                }`,
              };
            }}
          >
            {text}
          </Link>
        ) : (
          <a
            href={href}
            className={`ism-nav__tab ${isDraft && "isDraft"}`}
            rel={rel}
            target={target}
          >
            <span>{text}</span>
            {indicateExternal && <ExternalIcon />}
          </a>
        )}
      </LIStyled>
    );
  }
);

export default ListItem;

import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementStats } from "../../core/store/element-stats/reducers";
import { IElementStat } from "../../core/store/element-stats/types";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import {
  IElement,
  IElementEventStats,
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementRow } from "../ElementTable";
import TabHeading from "../TabHeading";
import Table from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import { StyledCaptain, StyledViceCaptain } from "./TableCaptains";

const ScrollTable = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StatsTable = styled(Table)`
  white-space: nowrap;
  margin-bottom: ${({ theme }) => theme.space[3]};
`;

interface IEntryEventRowProps {
  chipName: string | null;
  data: IElementEventStats;
  pick: IPickLight;
  element: IElement;
  elementTypesById: IElementTypesById;
  renderElementMenu: (index: number) => void;
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
}

const EntryEventRow: React.FC<IEntryEventRowProps> = ({
  chipName,
  data,
  pick,
  element,
  renderElementMenu,
  stats,
  teamsById,
}) => (
  <ElementRow>
    <td>
      <ElementDialogButton elementId={element.id} variant="list" />
    </td>
    <td>
      {pick.is_captain &&
        (chipName === "3xc" ? (
          <StyledCaptain variant="3xc" />
        ) : (
          <StyledCaptain />
        ))}
      {pick.is_vice_captain &&
        (chipName === "3xc" ? (
          <StyledViceCaptain variant="3xc" />
        ) : (
          <StyledViceCaptain />
        ))}
    </td>
    <td>
      <ElementInTable
        renderElementMenu={() => renderElementMenu(pick.element)}
        element={element}
        team={teamsById[element.team]}
      />
    </td>
    <td>{data.total_points * Math.max(pick.multiplier, 1)}</td>
    {stats.map((s) => (
      <td key={s.name}>{data[s.name]}</td>
    ))}
  </ElementRow>
);

interface IOwnProps {
  chipName: string | null;
  dataById: IElementsEventDataById | null;
  picks: IPickLight[];
  renderElementMenu: (index: number) => void;
  title: string;
}

interface IPropsFromState {
  elementsById: IElementsById;
  elementTypesById: IElementTypesById;
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
}

type Props = IOwnProps & IPropsFromState;

const EntryEventTable: React.FC<Props> = ({
  dataById,
  elementsById,
  picks,
  stats,
  title,
  ...rest
}) => {
  if (!dataById) {
    return null;
  }
  return (
    <ScrollTable>
      <Box mx={2}>
        <TabHeading title={title} hasBorder={true} />
      </Box>
      <StatsTable>
        <thead>
          <tr>
            <th scope="col">&nbsp;</th>
            <th scope="col">&nbsp;</th>
            <th scope="col">&nbsp;</th>
            <th scope="col">Pts</th>
            {stats.map((s) => (
              <th scope="col" key={s.name}>
                <Tooltip content={s.label}>
                  <TooltipLabel>
                    {s.label
                      .split(" ")
                      .map((w) => w[0])
                      .join("")
                      .toUpperCase()}
                  </TooltipLabel>
                </Tooltip>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {picks.map((p) => (
            <EntryEventRow
              key={p.element}
              pick={p}
              element={elementsById[p.element]}
              stats={stats}
              data={dataById[p.element].stats}
              {...rest}
            />
          ))}
        </tbody>
      </StatsTable>
    </ScrollTable>
  );
};

export { EntryEventTable as EntryEventTableTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    stats: getElementStats(state),
    teamsById: getTeamsById(state),
  };
};

export default connect(mapStateToProps)(EntryEventTable);

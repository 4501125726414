import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getBestClassicPrivateLeagues } from "../../core/store/stats/reducers";
import { fetchBestClassicPrivateLeagues } from "../../core/store/stats/thunks";
import { IBestLeague } from "../../core/store/stats/types";
// import { PrimaryCell, PrimaryCellLined } from "../ElementTable";
import { PrimaryCell, PrimaryCellLined } from "../ElementTable";
import Footnote from "../Footnote";
import Link, { Hyperlink } from "../Link";
import Panel from "../Panel";
import Table from "../Table";

const BestLeaguesTable = styled(Table)`
  white-space: nowrap;
  table-layout: fixed;

  th:not(:nth-child(2)),
  td:not(:nth-child(2)) {
    text-align: center;
  }
`;

const PosCell = styled.th`
  width: 15%;
`;

const TeamCell = styled.th`
  width: 50%;
`;

const AverageCell = styled.th`
  width: 25%;
`;

interface IPropsFromState {
  leagues: IBestLeague[];
}

interface IPropsFromDispatch {
  fetchBestClassicPrivateLeagues: () => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

class BestLeagues extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchBestClassicPrivateLeagues();
  }

  public render() {
    const { leagues } = this.props;
    return (
      <Panel>
        <Panel.Header title="Best Leagues"></Panel.Header>
        <Panel.Body>
          <BestLeaguesTable>
            <thead>
              <tr>
                <PosCell scope="col">Pos</PosCell>
                <TeamCell scope="col">League</TeamCell>
                <AverageCell scope="col">
                  Average
                  <Hyperlink href="#footnote">
                    <sup>*</sup>
                  </Hyperlink>
                </AverageCell>
              </tr>
            </thead>
            <tbody>
              {leagues.slice(0, 5).map((l, i) => (
                <tr key={l.league}>
                  <PrimaryCell>{i + 1}</PrimaryCell>
                  <td>
                    <Link to={`/leagues/${l.league}/standings/c`}>
                      {l.name}
                    </Link>
                  </td>
                  <PrimaryCellLined>{l.average_score}</PrimaryCellLined>
                </tr>
              ))}
            </tbody>
          </BestLeaguesTable>
          <Box px={2}>
            <Footnote id="footnote">
              *Average score of the top 5 teams in the league
            </Footnote>
          </Box>
          {!leagues.length && (
            <Box m={4}>
              <p>The Best Leagues will appear here when available.</p>
            </Box>
          )}
        </Panel.Body>
      </Panel>
    );
  }
}

export { BestLeagues as BestLeaguesTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    leagues: getBestClassicPrivateLeagues(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchBestClassicPrivateLeagues: () =>
    dispatch(fetchBestClassicPrivateLeagues()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BestLeagues);

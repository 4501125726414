import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store";
import { getElementStatsByIdentifier } from "../../../core/store/element-stats/reducers";
import { IElementStat } from "../../../core/store/element-stats/types";
import {
  getElement,
  getHistory,
  getHistoryTotals,
} from "../../../core/store/elements/reducers";
import { getTeamsById } from "../../../core/store/teams/reducers";
import { formatNumberToLocaleString } from "../../../core/utils/number";
import {
  getElementStat,
  getElementStatNames,
  getStatAbbr,
} from "../../../utils/element-stats";
import { BorderedCell } from "../../ElementTable";
import Tooltip, { TooltipLabel } from "../../Tooltip";
import { VisuallyHidden } from "../../Utils";
import HistoryRow from "../HistoryRow";
import {
  HistoryPer90Row,
  HistoryTable,
  HistoryTableWrap,
  HistoryTotalsHeading,
  HistoryTotalsRow,
} from "../styles";
import { IEventHistoryTable } from "./types";

const EventHistoryTable: React.FC<IEventHistoryTable> = ({ elementId }) => {
  const statsByName: Record<string, IElementStat> = useSelector(
    getElementStatsByIdentifier
  );
  const teamsById = useSelector(getTeamsById);
  const history = useSelector((state: RootState) =>
    getHistory(state, elementId)
  );
  const historyTotals = useSelector((state: RootState) =>
    getHistoryTotals(state, elementId)
  );
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );

  const statNames = getElementStatNames();

  if (!element) {
    return null;
  }

  return (
    <HistoryTableWrap>
      <HistoryTable>
        <thead>
          <tr>
            <th scope="col">GW</th>
            <th scope="col">OPP</th>
            <th scope="col">
              <VisuallyHidden>Result</VisuallyHidden>
            </th>
            <th scope="col">Pts</th>
            {statNames.map((n) => (
              <th key={n} scope="col">
                <Tooltip content={statsByName[n].label}>
                  <TooltipLabel>{getStatAbbr(n, statsByName)}</TooltipLabel>
                </Tooltip>
              </th>
            ))}
            <th scope="col">NT</th>
            <th scope="col">SB</th>
            <th scope="col">£</th>
          </tr>
        </thead>
        <tbody>
          {[...history].reverse().map((h) => (
            <HistoryRow
              key={h.fixture}
              history={h}
              teamsById={teamsById}
              statsByName={statsByName}
              statNames={statNames}
            />
          ))}
        </tbody>
        <tfoot>
          <HistoryTotalsRow>
            <td>&nbsp;</td>
            <HistoryTotalsHeading scope="row">Totals</HistoryTotalsHeading>
            <td>&nbsp;</td>
            <BorderedCell>{historyTotals.total_points}</BorderedCell>
            {statNames.map((n) => (
              <td key={n}>
                {formatNumberToLocaleString(historyTotals[statsByName[n].name])}
              </td>
            ))}
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </HistoryTotalsRow>
          <HistoryPer90Row>
            <td>&nbsp;</td>
            <th scope="row">Per 90</th>
            <td>&nbsp;</td>
            <BorderedCell>-</BorderedCell>
            {statNames.map((n) => {
              const elementStat = getElementStat(n);
              return (
                <td key={n}>
                  {elementStat?.per90Stat &&
                  elementStat.per90Stat !== "starts_per_90"
                    ? element[elementStat.per90Stat]
                    : "-"}
                </td>
              );
            })}
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </HistoryPer90Row>
        </tfoot>
      </HistoryTable>
    </HistoryTableWrap>
  );
};

export default EventHistoryTable;

import { darken, size } from "polished";
import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { getCurrentEvent, getEvents } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import leftArrow from "../../img/icons/chevron-left.svg";
import rightArrow from "../../img/icons/chevron-right.svg";
import { ReactComponent as DreamTeamIcon } from "../../img/icons/dreamteam.svg";
import Panel from "../Panel";
import EventTopElement from "./EventTopElement";

const StyledSlider = styled(Slider)`
  min-height: 0px;
  min-width: 0px;
  padding: 2rem 2.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-right: 3.2rem;
    padding-left: 3.2rem;
  }

  .slick-prev {
    left: 0;
    border-radius: ${({ theme }) => `0 ${theme.radii[1]} ${theme.radii[1]} 0`};

    ::before {
      background: url(${leftArrow});
    }
  }

  .slick-next {
    right: 0;
    border-radius: ${({ theme }) => `${theme.radii[1]} 0 0 ${theme.radii[1]}`};

    ::before {
      background: url(${rightArrow});
    }
  }

  .slick-prev,
  .slick-next {
    top: 40%;
    bottom: auto;
    width: auto;
    height: auto;
    transition: all 0.2s;
    padding: 0.8rem 1.2rem;
    background-color: #edf1f9;

    :hover,
    :focus {
      background-color: ${darken(0.05, "#edf1f9")};
    }

    ::before {
      ${size(15, 9)};
      content: "";
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 14px 24px;
    }

    .slick-disabled {
      cursor: default;

      :hover,
      :focus {
        border-color: #ebebeb;
        background-color: #f6f6f5;
      }
    }

    .slick-list,
    .slick-track {
      min-height: 0px;
      min-width: 0px;
    }
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  elementsById: IElementsById;
  events: IEvent[];
}

const TopElementCarousel: React.FC<IPropsFromState> = ({
  events,
  now,
  elementsById,
}) => {
  const getInitialSlide = (slidesToShow: number) =>
    now && now.id > slidesToShow ? now.id - slidesToShow : 0;

  const settings = {
    className: "ism-top-element-carousel",
    infinite: true,
    initialSlide: getInitialSlide(6),
    responsive: [
      {
        breakpoint: 400,
        settings: {
          initialSlide: getInitialSlide(3),
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          initialSlide: getInitialSlide(4),
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          initialSlide: getInitialSlide(5),
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          initialSlide: getInitialSlide(4),
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1220,
        settings: {
          initialSlide: getInitialSlide(5),
          slidesToShow: 7,
        },
      },
    ],
    slidesToShow: 9,
    slidesToScroll: 1,
  };

  // These 2 functions are here to handle an API change. Once released can
  // be deleted as format is null or object
  const topElementFromEvent = (event: IEvent) => {
    if (
      typeof event.top_element_info === "undefined" ||
      event.top_element_info === null
    ) {
      return null;
    }
    return elementsById[event.top_element_info.id];
  };

  const topElementPointsFromEvent = (event: IEvent) => {
    if (
      typeof event.top_element_info === "undefined" ||
      event.top_element_info === null
    ) {
      return null;
    }
    return event.top_element_info.points;
  };

  return (
    <Panel>
      <Panel.Header
        title="2024/25 Player of the Week"
        icon={<DreamTeamIcon />}
      />
      <StyledSlider {...settings}>
        {events.map((event) => (
          <div key={event.id}>
            <EventTopElement
              event={event}
              element={topElementFromEvent(event)}
              points={topElementPointsFromEvent(event)}
            />
          </div>
        ))}
      </StyledSlider>
    </Panel>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    elementsById: getElementsById(state),
    events: getEvents(state),
    now: getCurrentEvent(state),
  };
};

export default connect(mapStateToProps)(TopElementCarousel);

import styled, { css } from "styled-components/macro";
import { ReactComponent as BaseMovementIcon } from "../../img/icons/movement.svg";
import { ReactComponent as BaseNewIcon } from "../../img/icons/new.svg";

export const NewIcon = styled(BaseNewIcon)`
  fill: ${({ theme }) => theme.colors.darkGrey};
`;

export const MovementIcon = styled(BaseMovementIcon)<{ status: string }>`
  ${(props) =>
    props.status &&
    css`
      fill: ${props.theme.colors.movement[props.status].bg};
      color: ${props.theme.colors.movement[props.status].color};
    `}

  ${(props) =>
    props.status === "down" &&
    css`
      transform: rotate(180deg);
    `}
`;

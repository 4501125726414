import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { INavLink } from "../../core/hooks/types";
import usePriorityNav from "../../core/hooks/usePriorityNav";
import { RootState } from "../../core/store";
import { getPlayerData } from "../../core/store/player/reducers";
import Dropdown from "./Dropdown";
import ListItem from "./ListItem";
import {
  getCurrentEvent,
  getNextEvent,
} from "../../core/store/events/reducers";
import { getServerError } from "../../core/store/global/reducers";
import { getEntry } from "../../core/store/entries/reducers";

interface IPropsFromState {
  links: INavLink[];
}

const StyledNav = styled.nav`
  white-space: nowrap;
`;

const StyledUL = styled.ul`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  padding: 0;
  display: flex;
`;

const Navigation: React.FC<IPropsFromState> = ({ links }) => {
  const [measuredRef, visibleLinks, nonVisibleLinks] = usePriorityNav({
    links,
    offset: 40,
  });
  return (
    <StyledNav
      role="navigation"
      className="ism-nav"
      aria-labelledby="ism-game-title"
    >
      <StyledUL>
        {visibleLinks.map((link) => (
          <ListItem {...link} ref={measuredRef} key={link.text} />
        ))}
        {!!nonVisibleLinks.length && (
          <Dropdown text="More" links={nonVisibleLinks as INavLink[]} />
        )}
      </StyledUL>
    </StyledNav>
  );
};

export { Navigation as NavigationTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state);
  const currentEvent = getCurrentEvent(state);
  const nextEvent = getNextEvent(state);
  const serverError = getServerError(state);
  const entry = player && player.entry ? getEntry(state, player.entry) : null;

  const logoutNavLink = {
    useRouter: false,
    href: `${process.env.REACT_APP_PLUSERS_BASE}/accounts/logout/?app=plfpl-web&redirect_uri=${window.location.protocol}//${window.location.host}/`,
    text: "Sign Out",
  };

  const fplChallengeLink = {
    useRouter: false,
    href: "//fplchallenge.premierleague.com",
    text: "FPL Challenge",
    isDraft: true,
  };

  const podcastNavLink = {
    useRouter: false,
    href: "//premierleague.com/official-fpl-podcast/",
    text: "Podcast",
  };

  let links: INavLink[] = [];

  const useRouter = !serverError;

  if (player && player.entry) {
    // Logged in with entry
    links = [{ useRouter, href: "/", text: currentEvent ? "Status" : "Home" }];
    if (currentEvent && entry && entry.started_event <= currentEvent.id) {
      links.push({
        useRouter,
        href: `/entry/${player.entry}/event/${currentEvent.id}`,
        text: "Points",
      });
    }
    if (nextEvent) {
      links.push({ useRouter, href: "/my-team", text: "Pick Team" });
    }
    links.push(
      { useRouter, href: "/transfers", text: "Transfers" },
      { useRouter, href: "/leagues", text: "Leagues & Cups" },
      { useRouter, href: "/fixtures", text: "Fixtures" },
      { useRouter, href: "/the-scout", text: "The Scout" },
      podcastNavLink,
      { useRouter, href: "/statistics", text: "Stats" },
      { useRouter, href: "/prizes", text: "Prizes" },
      { useRouter, href: "/help", text: "Help" },
      fplChallengeLink,
      logoutNavLink
    );
  } else if (player) {
    // Logged in, no entry
    links = [
      { useRouter, href: "/", text: "Home" },
      { useRouter, href: "/squad-selection", text: "Squad Selection" },
      { useRouter, href: "/help", text: "Help" },
      logoutNavLink,
    ];
  } else {
    // Logged Out
    links = [
      { useRouter, href: "/", text: "Home" },
      { useRouter, href: "/prizes", text: "Prizes" },
      { useRouter, href: "/the-scout", text: "Scout" },
      podcastNavLink,
      { useRouter, href: "/help", text: "Help" },
      { useRouter, href: "/statistics", text: "Statistics" },
      fplChallengeLink,
      // rotoNavLink
    ];
  }
  return {
    links,
  };
};

export default connect(mapStateToProps)(Navigation);

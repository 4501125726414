import React from "react";
import styled from "styled-components/macro";

const StyledResult = styled.span<IOwnProps>`
  font-family: ${({ theme }) => theme.fonts.bold};
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.result[props.resultChar]};
  color: white;
  font-size: 1.1rem;
  line-height: 2rem;
  text-align: center;
  vertical-align: middle;
`;

interface IOwnProps {
  resultChar: string;
}

const Result: React.FC<IOwnProps> = ({ resultChar }) => (
  <StyledResult resultChar={resultChar.toLowerCase()}>
    {resultChar}
  </StyledResult>
);

export default Result;

import { hideVisually, hiDPI, rgba, size } from "polished";
import * as React from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components/macro";
import { ReactComponent as Close } from "../img/icons/close.svg";
import pattern2Crop184 from "../img/pattern/pattern-2-crop-184.png";
import pattern2Crop90 from "../img/pattern/pattern-2-crop-90.png";
import "./OverflowScrolling.css";

export const CloseButtonWrap = styled.div`
  position: absolute;
  right: ${(props) => props.theme.space[2]};
  top: ${(props) => props.theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    right: ${(props) => props.theme.space[4]};
    top: ${(props) => props.theme.space[3]};
  }
`;

export const CloseButton = styled.button`
  padding: ${(props) => `${props.theme.space[2]} ${props.theme.space[3]}`};
  border: 0;
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: ${rgba("white", 0.3)};
  line-height: 0;
  cursor: pointer;
`;

const CloseLabel = styled.span`
  ${hideVisually}
`;

export const CloseIcon = styled(Close)`
  ${size(12)}
  fill: ${({ theme }) => theme.colors.primary};
`;

// https://css-tricks.com/considerations-styling-modal/
// 1. commented out height to see if we can be more flexible

const StyledDialog = styled.dialog<{ isElementDialog: boolean }>`
  z-index: ${(props) => props.theme.zIndex.dialog};
  max-width: 90%;
  /* height: 400px; [1] */
  max-height: 90%;
  padding: 0;
  border: 0;
  border-radius: ${({ theme }) => theme.radii[1]};
  /* Ugly hack to deal with sub-pixel rounding in chrome bug causing blurry content with transform */
  width: 640px;
  color: currentColor;
  box-shadow: 0 12px 24px 0 ${rgba("black", 0.25)};

  background-repeat: no-repeat;

  background-position: right top, 0, 0;
  background-image: url(${pattern2Crop90}),
    linear-gradient(
      to bottom,
      ${rgba("white", 0)} 10px,
      rgba(255, 255, 255, 0.2) 30px,
      white 60px
    ),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.lightBlue},
      ${({ theme }) => theme.colors.darkBlue}
    );

  ${hiDPI(2)} {
    background-image: url(${pattern2Crop184}),
      linear-gradient(
        to bottom,
        ${rgba("white", 0)} 20px,
        rgba(255, 255, 255, 0.2) 45px,
        white 70px
      ),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-size: 90px 60px, auto, auto;
  }

  ${(props) =>
    props.isElementDialog &&
    css`
      width: 1008px;
      background-image: none;

      ${hiDPI(2)} {
        background-image: none;
      }
    `}
`;

const Overlay = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.overlay};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(
    ${rgba("white", 0.3)},
    ${rgba("white", 0.8)}
  );
  border-radius: 6px;
`;

const StyledHeader = styled.div<IStyledHeaderProps>`
  position: relative;

  ${(props) =>
    props.variant === "light" &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.primary};
    `};
`;

const DialogHeading = styled.h2`
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding-top: 2.4rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  text-align: center;
`;

export const DialogButtonItem = styled.li`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

interface IDialogHeaderProps {
  children: React.ReactNode;
  closeDialog: () => void;
}

interface IStyledHeaderProps {
  variant?: "light" | undefined;
}

type DialogHeaderProps = IDialogHeaderProps & IStyledHeaderProps;

const DialogHeader: React.FC<DialogHeaderProps> = ({
  children,
  variant,
  closeDialog,
}) => (
  <StyledHeader variant={variant}>
    <DialogHeading id="ism-dialog-title">{children}</DialogHeading>
    <CloseButtonWrap>
      <CloseButton onClick={closeDialog}>
        <CloseLabel>close</CloseLabel>
        <CloseIcon />
      </CloseButton>
    </CloseButtonWrap>
  </StyledHeader>
);

const isVisible = (elem: HTMLElement) =>
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

interface IDialogBodyProps {
  isPadded?: boolean;
}

const StyledDialogBody = styled.div<IDialogBodyProps>`
  max-height: calc(90vh - 61px);
  overflow-y: auto;
  ${(props) =>
    props.isPadded &&
    css`
      margin: ${props.theme.space[4]} ${props.theme.space[3]};
    `};
`;

const DialogBody: React.FC<IDialogBodyProps> = ({ children, isPadded }) => (
  <StyledDialogBody isPadded={isPadded} className="ism-overflow-scroll">
    {children}
  </StyledDialogBody>
);

DialogBody.defaultProps = {
  isPadded: true,
};

interface IDialogProps {
  children: any;
  closeDialog: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLDivElement>
      | KeyboardEvent
  ) => void;
  focusable: string;
  isElementDialog?: boolean;
}

class Dialog extends React.Component<IDialogProps> {
  public static Header = DialogHeader;
  public static Body = DialogBody;

  public static defaultProps = {
    focusable:
      "a[href], area[href], input:not([disabled]), " +
      "select:not([disabled]), textarea:not([disabled]), " +
      "button:not([disabled]), iframe, object, embed, " +
      "*[tabindex], *[contenteditable]",
  };

  private docref = React.createRef<HTMLDivElement>();
  private focusReturn = document.activeElement;

  public componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown);
    const docRef = this.docref.current;
    if (docRef) {
      const focusElement = docRef.querySelector(
        this.props.focusable
      ) as HTMLElement;
      if (focusElement) {
        focusElement.focus();
      }
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
    const focusReturn = this.focusReturn as HTMLElement;
    if (focusReturn) {
      if (focusReturn.focus) {
        focusReturn.focus();
      }
    }
  }

  public handleKeydown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      this.props.closeDialog(e);
    } else if (e.key === "Tab") {
      this.handleTab(e);
    }
  };

  public handleOutsideMouseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      this.props.closeDialog(e);
      e.stopPropagation();
    }
  };

  public handleTab(e: KeyboardEvent) {
    const docRef = this.docref.current;
    const focusedItem = document.activeElement;
    if (docRef) {
      const focusableItems = Array.prototype.slice
        .call(docRef.querySelectorAll(this.props.focusable))
        .filter(isVisible);
      const numFocusableItems = focusableItems.length;
      const focusedIndex = focusableItems.indexOf(focusedItem);
      if (!e.shiftKey && focusedIndex === numFocusableItems - 1) {
        // Moving past last focusable item so focus first
        focusableItems[0].focus();
        e.preventDefault();
      } else if (e.shiftKey && focusedIndex === 0) {
        // Moving before first focusable item so focus last
        focusableItems[numFocusableItems - 1].focus();
        e.preventDefault();
      }
    }
  }

  public render() {
    const { isElementDialog = false } = this.props;
    // https://github.com/facebook/react/issues/11387
    return ReactDOM.createPortal(
      <Overlay
        onClick={(e) => {
          e.stopPropagation();
          this.handleOutsideMouseClick(e);
        }}
        role="presentation"
      >
        <StyledDialog
          role="dialog"
          aria-labelledby="ism-dialog-title"
          open={true}
          isElementDialog={isElementDialog}
        >
          <div role="document" ref={this.docref}>
            {this.props.children}
          </div>
        </StyledDialog>
      </Overlay>,
      document.getElementById("root-dialog") as HTMLElement
    );
  }
}

export default Dialog;

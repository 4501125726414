import React from "react";
import { useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState } from "../../core/store";
import {
  getEntry,
  getPrivateClassicCupLeaguesForEntry,
  getPublicClassicCupLeaguesForEntry,
  getSystemClassicCupLeaguesForEntry,
} from "../../core/store/entries/reducers";
import { getPlayerData } from "../../core/store/player/reducers";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import HelmetHead from "../HelmetHead";
import { ControlArrowRight } from "../icons/Arrows";
import { Main, Secondary, Wrapper } from "../Layout";
import LeaderboardAd from "../LeaderboardAd";
import Title from "../Title";
import CupTypeTable from "./CupTypeTable";
import LeaguesNav from "./LeaguesNav";
import {
  getBroadcasterLeagues,
  getFilteredSystemClassicLeagues,
} from "./utils";

const MyCups: React.FC = () => {
  const player = useSelector(getPlayerData);
  const entry = useSelector((state: RootState) =>
    player?.entry ? getEntry(state, player.entry) : null
  );
  const privateClassicLeagues = useSelector((state: RootState) =>
    entry ? getPrivateClassicCupLeaguesForEntry(state, entry.id) : []
  );
  const publicClassicLeagues = useSelector((state: RootState) =>
    entry ? getPublicClassicCupLeaguesForEntry(state, entry.id) : []
  );
  const systemClassicLeagues = useSelector((state: RootState) =>
    entry ? getSystemClassicCupLeaguesForEntry(state, entry.id) : []
  );

  if (!entry) {
    return null;
  }

  const hasCupLeagues =
    privateClassicLeagues.length > 0 ||
    publicClassicLeagues.length > 0 ||
    systemClassicLeagues.length > 0;

  const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
  const filteredSystemClassicLeagues = getFilteredSystemClassicLeagues(
    systemClassicLeagues,
    broadcasterLeagues
  );

  return (
    <>
      <LeaderboardAd slot="Leaderboard_Leagues" id="ism-leagues-ad" />
      <Wrapper>
        <Box flex={1} pt={4} mx={2}>
          <Title>{`Leagues & Cups - ${entry.name}`}</Title>
          <LeaguesNav />
        </Box>
      </Wrapper>
      <Wrapper>
        <HelmetHead
          title="Play FPL League & General Cups | Fantasy Premier League"
          description="Challenge yourself against other FPL players in your leagues, country and the overall Fantasy Premier League. Find out more, visit the official website of the Premier League."
        />
        <Main>
          {hasCupLeagues ? (
            <>
              {privateClassicLeagues.length > 0 && (
                <Box pb={4}>
                  <CupTypeTable
                    leagues={privateClassicLeagues}
                    title="League cups"
                  />
                </Box>
              )}
              {publicClassicLeagues.length > 0 && (
                <Box pb={4}>
                  <CupTypeTable
                    leagues={publicClassicLeagues}
                    title="Public cups"
                  />
                </Box>
              )}
              {filteredSystemClassicLeagues.length > 0 && (
                <Box pb={4}>
                  <CupTypeTable
                    leagues={filteredSystemClassicLeagues}
                    title="General cups"
                  />
                </Box>
              )}
              {broadcasterLeagues.length > 0 && (
                <Box pb={4}>
                  <CupTypeTable
                    leagues={broadcasterLeagues}
                    title="Broadcaster cups"
                  />
                </Box>
              )}
            </>
          ) : (
            <Copy>
              <p>None of your leagues have a cup yet.</p>
              <ButtonLink to="/leagues/create-join">
                <span>Create And Join New Leagues &amp; Cups</span>
                <ControlArrowRight />
              </ButtonLink>
            </Copy>
          )}
        </Main>
        <Secondary />
      </Wrapper>
    </>
  );
};

export default MyCups;

import { RouteComponentProps } from "@reach/router";
import React from "react";
import { Box } from "rebass/styled-components";
import Fixtures from "./Fixtures";
import FixturesNav from "./FixturesNav";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import Title from "./Title";

const FixturesWrapper: React.FC<RouteComponentProps<{ eventId?: string }>> = ({
  eventId = 0,
}) => (
  <>
    <Wrapper>
      <Box flex={1} pt={4} mx={2}>
        <Title>Fixtures &amp; Results</Title>
        <FixturesNav />
      </Box>
    </Wrapper>
    <Wrapper>
      <HelmetHead
        title="View Latest Premier League Fixtures | Fantasy Premier League"
        description="To view the latest Gameweek fixtures and help choose your next Fantasy Premier League team, visit the official website of the Premier League."
      />
      <Main isWide={true}>
        <Fixtures eventId={Number(eventId)} useLinks />
      </Main>
    </Wrapper>
  </>
);

export default FixturesWrapper;

import styled from "styled-components/macro";
import { StatDivider } from "../styles";

export const StyledStatItem = styled.li`
  ${StatDivider}
  flex: 0 0 25%;
  min-height: 4.6rem;
  padding: 0 ${({ theme }) => theme.space[1]};
  list-style-type: none;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 12.5%;
  }
`;

export const StatHeading = styled.h3`
  margin-bottom: 0.2rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1rem;
`;

export const StatValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.8rem;
`;

export const StatRank = styled.div`
  font-size: 1rem;
`;

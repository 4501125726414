import React from "react";
import HelmetHead from "../HelmetHead";

const HelmetHeadLeagues: React.FC = () => (
  <HelmetHead
    title="Create & Join Fantasy Football Leagues | Fantasy Premier League"
    description="To view all types of leagues including head-to-head, classic & global, as well as creating & joining new leagues, visit the official website of the Premier League."
  />
);

export default HelmetHeadLeagues;

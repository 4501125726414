import styled from "styled-components/macro";

export const PillList = styled.ul`
  display: flex;
`;

export const PillItem = styled.li`
  :not(:first-child) {
    padding-left: ${(props) => props.theme.space[1]};
  }
`;

import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import { updateElementTypeControl } from "../../core/store/elements/thunks";
import {
  getElementEventOpponents,
  getFixturesForNextEventByTeam,
} from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import { getProposedElements } from "../../core/store/squad/reducers";
import { IProposedElements } from "../../core/store/squad/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToMoney } from "../../core/utils/money";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { BorderedCell, ElementRow, ElementTable } from "../ElementTable";
import TabHeading from "../TabHeading";
import Tooltip, { TooltipLabel } from "../Tooltip";

const FilterElTypeButton = styled.button`
  padding: 10px 0 9px;
  border: 0;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.3rem;
  white-space: normal;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SquadListStatus = styled.th`
  width: 10%;
`;

const SquadListElement = styled.th`
  width: 34%;
`;

const SquadListCost = styled.th`
  width: 13%;
`;

const SquadListSelected = styled.th`
  width: 14%;
`;

const SquadListPoints = styled.th`
  width: 10%;
`;

const SquadListFixture = styled.th`
  width: 19%;
`;

interface ISquadListRowProps {
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  proposedElements: IProposedElements;
  pos: number;
  renderElementMenu: (position: number) => void;
  showElementType: (elementTypeId: number) => void;
  teamsById: ITeamsById;
}

const SquadListRow: React.FC<ISquadListRowProps> = ({
  elementTypesByPosition,
  proposedElements,
  pos,
  renderElementMenu,
  showElementType,
  teamsById,
  fixturesForNextEventByTeam,
}) => {
  const element = proposedElements[pos];
  const fixtures = element ? fixturesForNextEventByTeam[element.team] : [];
  const elementEventOpponents = useSelector((state: RootState) =>
    fixtures.length && element
      ? getElementEventOpponents(state, element, fixtures)
      : []
  );

  if (!elementTypesByPosition) {
    return null;
  }

  return (
    <ElementRow>
      {element ? (
        <>
          <td>
            <ElementDialogButton elementId={element.id} variant="list" />
          </td>
          <BorderedCell>
            <ElementInTable
              renderElementMenu={() => renderElementMenu(pos)}
              element={element}
              team={teamsById[element.team]}
            />
          </BorderedCell>
          <td>{integerToMoney(element.now_cost, 10)}</td>
          <td>{element.selected_by_percent}</td>
          <td>{element.total_points}</td>
          <td>
            {elementEventOpponents.length ? (
              elementEventOpponents.map((opp) => <div>{opp}</div>)
            ) : (
              <>&nbsp;</>
            )}
          </td>
        </>
      ) : (
        <>
          <td>&nbsp;</td>
          <td colSpan={5}>
            <FilterElTypeButton
              onClick={() =>
                showElementType(elementTypesByPosition[pos].thisType.id)
              }
            >
              Select {elementTypesByPosition[pos].thisType.singular_name}
            </FilterElTypeButton>
          </td>
        </>
      )}
    </ElementRow>
  );
};

interface IPropsFromState {
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  proposedElements: IProposedElements;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  showElementType: (elementTypeId: number) => void;
}

interface ISquadListTableProps {
  positions: number[];
  renderElementMenu: (pos: number) => void;
}

type Props = IPropsFromState & IPropsFromDispatch & ISquadListTableProps;

const SquadListTable: React.FC<Props> = ({
  elementTypesByPosition,
  positions,
  ...rest
}) => {
  if (!elementTypesByPosition) {
    return null;
  }
  return (
    <>
      <Box mx={2}>
        <TabHeading
          title={elementTypesByPosition[positions[0]].thisType.plural_name}
          hasBorder={true}
        />
      </Box>
      <ElementTable>
        <thead>
          <tr>
            <SquadListStatus scope="col">&nbsp;</SquadListStatus>
            <SquadListElement scope="col">&nbsp;</SquadListElement>
            <SquadListCost scope="col">£</SquadListCost>
            <SquadListSelected scope="col">
              <Tooltip content="Teams selected by %">
                <TooltipLabel>SB</TooltipLabel>
              </Tooltip>
            </SquadListSelected>
            <SquadListPoints scope="col">
              <Tooltip content="Total points">
                <TooltipLabel>TP</TooltipLabel>
              </Tooltip>
            </SquadListPoints>
            <SquadListFixture scope="col">
              <Tooltip content="Fixture next Gameweek">
                <TooltipLabel>Fix</TooltipLabel>
              </Tooltip>
            </SquadListFixture>
          </tr>
        </thead>
        <tbody>
          {positions.map((p) => (
            <SquadListRow
              key={p}
              pos={p}
              elementTypesByPosition={elementTypesByPosition}
              {...rest}
            />
          ))}
        </tbody>
      </ElementTable>
    </>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementTypesByPosition: getElementTypesBySquadPosition(state),
  fixturesForNextEventByTeam: getFixturesForNextEventByTeam(state),
  proposedElements: getProposedElements(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementType: (elementTypeId) =>
    dispatch(updateElementTypeControl(elementTypeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SquadListTable);

import { navigate } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import {
  getJoinPrivateLeagueError,
  getJoinPrivateLeagueSuccess,
} from "../../core/store/leagues/reducers";
import { joinPrivateLeague } from "../../core/store/leagues/thunks";
import { IJoinPrivateLeagueData } from "../../core/store/leagues/types";
import { formatErrorMsg, getErrorType } from "./errors";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import { FieldWrap, InputField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import HelmetHeadLeagues from "./HelmetHeadLeagues";

interface IState {
  code: string;
}

interface IPropsFromState {
  error: IError | null;
  settings: ISettings;
  hasJoinedPrivate: boolean;
}

interface IPropsFromDispatch {
  joinPrivateLeague: (data: IJoinPrivateLeagueData) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

class JoinPrivate extends React.Component<Props, IState> {
  public state = { code: "" };

  public apiDataFromState = (): IJoinPrivateLeagueData => ({
    code: this.state.code,
  });

  public handleCodeChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ code: e.currentTarget.value });

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.joinPrivateLeague(this.apiDataFromState());
  };

  public render() {
    const disabled = !this.state.code;
    const error = getErrorType(this.props.error);
    const { settings, hasJoinedPrivate } = this.props;

    if (hasJoinedPrivate) {
      navigate("/leagues");
      return null;
    }

    return (
      <Wrapper>
        <HelmetHeadLeagues />
        <Main>
          <Copy>
            <Title>Join Invitational League &amp; Cup</Title>
            {error && (
              <Box mb={2}>
                <Alert type="error">
                  {settings && formatErrorMsg(error, settings)}
                </Alert>
              </Box>
            )}
            <form onSubmit={this.handleSubmit}>
              <FieldWrap>
                <InputField
                  id="ismLeagueCode"
                  label="League code"
                  onChange={this.handleCodeChange}
                  value={this.state.code}
                />
              </FieldWrap>
              <FieldWrap>
                <Button type="submit" disabled={disabled}>
                  Join League
                </Button>
              </FieldWrap>
            </form>
          </Copy>
        </Main>
      </Wrapper>
    );
  }
}

export { JoinPrivate as JoinPrivateTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  error: getJoinPrivateLeagueError(state),
  settings: getSettings(state) as ISettings,
  hasJoinedPrivate: getJoinPrivateLeagueSuccess(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  joinPrivateLeague: (data: IJoinPrivateLeagueData) =>
    dispatch(joinPrivateLeague(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinPrivate);

import { rgba } from "polished";
import styled, { css } from "styled-components/macro";
import { ReactComponent as AddPersonBase } from "../../img/icons/add-person.svg";
import { StyledElementFixtureBar } from "../ElementFixtureBar/styles";
import { InfoIcon } from "../ElementStatus";
import { IElementName, IElementStyles } from "./types";

export const PitchElementWrap = styled.div<IElementStyles>`
  position: relative;
  width: 6.8rem;
  margin: auto;

  ${(props) =>
    (props.variant === "instigator" || props.variant === "target") &&
    css`
      ${StyledPitchElement} {
        transition: all 0.15s;
      }

      ${StyledElementFixtureBar} {
        border-radius: ${({ theme }) =>
          `0 0 ${theme.radii[0]} ${theme.radii[0]}`};
        transition: all 0.15s;
      }

      ${ElementName} {
        margin-right: 0.1rem;
        margin-left: 0.1rem;
        transition: all 0.15s;
      }
    `}

  ${(props) =>
    props.variant === "instigator" &&
    css`
      ${StyledPitchElement} {
        box-shadow: inset 0 0 0 0.2rem ${(props) => props.theme.colors.darkPink};
      }

      ${StyledElementFixtureBar} {
        background-color: ${(props) => props.theme.colors.darkPink};
        color: ${(props) => props.theme.colors.white};
      }
    `}

  ${(props) =>
    props.variant === "target" &&
    css`
      ${StyledPitchElement} {
        box-shadow: inset 0 0 0 0.2rem ${(props) => props.theme.colors.green};
      }

      ${StyledElementFixtureBar} {
        background-color: ${(props) => props.theme.colors.green};
      }
    `}

  ${(props) =>
    props.variant === "invalid" &&
    css`
      opacity: 0.4;
      transition: all 0.15s;
      box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, 0.4);
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 10rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 11.4rem;
  }
`;

export const PitchCardButton = styled.button`
  display: block;
  width: 100%;
  padding: 4px;
  border: 0;
  border-radius: 1rem;
  background-color: transparent;
  /* TODO Make this global */
  letter-spacing: -0.4px;

  &:hover,
  &:focus {
    border-style: solid;
    border-color: ${(props) => props.theme.colors.white};
  }

  &:hover {
    border-width: 2px;
    padding: 2px;
  }

  &:focus {
    padding: 0;
    border-width: 4px;
    outline: 0;
  }
`;

export const PitchCard = styled.span`
  display: block;
  padding: 0.1rem;
  box-shadow: inset 0 0 0 0.1rem rgb(255, 255, 255, 0.2);
  min-height: 7.8rem;
  border-radius: ${(props) => props.theme.radii[1]};
  background-color: ${(props) => props.theme.colors.darkGreen};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 1rem;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    min-height: 9.6rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    min-height: 13.2rem;
  }
`;

export const AddPerson = styled(AddPersonBase)`
  display: block;
  fill: ${(props) => props.theme.colors.white};
`;

export const StyledPitchPlaceholder = styled(PitchCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  margin: auto;
  padding: ${(props) => props.theme.space[1]};
  border-color: ${(props) => rgba(props.theme.colors.white, 0.3)};
  color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[3]};
  }
`;

export const StyledPitchElement = styled(PitchCard)`
  position: relative;
  background-image: linear-gradient(to bottom right, #0d9356, #10c773);
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.bold};
`;

export const ElementCost = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 1rem;
  line-height: 1.4;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    font-size: ${(props) => props.theme.fontSizes[0]};
    line-height: 1;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    font-size: ${(props) => props.theme.fontSizes[3]};
    line-height: 1;
  }
`;

export const PitchElementData = styled.span`
  display: block;
  position: absolute;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem;
  border-radius: 0 0 0.3rem 0.3rem;
`;

export const RemovedElement = styled(PitchCard)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding-bottom: 3rem;
  justify-content: center;
  color: ${(props) => props.theme.colors.primary};

  ${PitchElementData} {
    opacity: 40%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-bottom: 4rem;
  }
`;

export const ElementName = styled.span<IElementName>`
  display: block;
  padding: 0.3rem;
  background-color: ${({ copnr, theme }) =>
    copnr !== null ? theme.colors.elementStatus[copnr].bg : theme.colors.white};
  color: ${({ copnr, theme }) =>
    copnr !== null ? theme.colors.elementStatus[copnr].color : "inherit"};
  font-family: ${(props) => props.theme.fonts.bold};
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[1]};
    font-size: ${(props) => props.theme.fontSizes[0]};
  }
`;

const sharedControlStyles = css`
  position: absolute;
  top: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    top: 10px;
  }
`;

export const PitchElementIconsWrap = styled.div`
  ${sharedControlStyles}
  left: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    left: 10px;
    gap: 4px;
  }
`;

export const InfoControl = styled.span`
  display: none;

  ${InfoIcon} {
    box-shadow: 1px 1px 10px -4px ${rgba("black", 0.5)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${sharedControlStyles}
    display: block;
    right: 10px;
  }
`;

export const ShirtWrap = styled.span`
  display: block;
  padding-top: 0.7rem;
  padding-left: 0.8rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 1.2rem;
    padding-left: 1.4rem;
  }
`;

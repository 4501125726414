import moment from "moment";
import * as React from "react";
import Table from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import styled from "styled-components/macro";
import { getCurrentEventStatus } from "../../core/store/events/reducers";
import { IEventStatusData } from "../../core/store/events/types";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "../../core/store";
import { fetchEventStatus } from "../../core/store/events/thunks";
import { textGradient } from "../Utils";

const EventStatusHead = styled.thead`
  th:nth-child(n + 2) {
    text-align: center;
  }
`;

const EventStatusBody = styled.tbody`
  th {
    background-color: transparent;
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  td {
    text-align: center;
  }
`;

const EventStatusInfo = styled.span`
  padding: 0.4rem 1.6rem;
  border-radius: ${({ theme }) => theme.radii[0]};
  background-color: ${({ theme }) => theme.colors.fantasy};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: uppercase;
`;

const EventStatusLeagues = styled.tr`
  background-color: ${(props) => props.theme.colors.fantasy};
`;

const EventStatusLeagueInfo = styled(EventStatusInfo)`
  background-color: ${({ theme }) => theme.colors.primary};
  > span {
    ${textGradient}
  }
`;

interface IPropsFromState {
  statusData: IEventStatusData | null;
}

interface IPropsFromDispatch {
  fetchEventStatus: () => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

class EventStatus extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchEventStatus();
  }

  pointsLabels = {
    l: "Live",
    p: "Provisional",
    r: "Confirmed",
  };

  public render() {
    const { statusData } = this.props;
    if (!statusData) {
      return null;
    }
    return (
      <Table>
        <EventStatusHead>
          <tr>
            <th scope="col">Day</th>
            <th scope="col">
              <Tooltip content="Player points are updated live as the matches take place. Assists are checked and confirmed within one hour of the last match of the day finishing.">
                <TooltipLabel>Match Points</TooltipLabel>
              </Tooltip>
            </th>
            <th scope="col">
              <Tooltip content="Bonus points will be awarded one hour after the last match of the day finishing.">
                <TooltipLabel>Bonus Points</TooltipLabel>
              </Tooltip>
            </th>
          </tr>
        </EventStatusHead>
        <EventStatusBody>
          {statusData.status.map((s) => (
            <tr key={s.date}>
              <th scope="row">{moment(s.date).format("dddd D MMM")}</th>
              <td>
                {s.points ? (
                  <EventStatusInfo>
                    {this.pointsLabels[s.points]}
                  </EventStatusInfo>
                ) : (
                  <span>&nbsp;</span>
                )}
              </td>
              <td>
                {s.bonus_added ? (
                  <EventStatusInfo>Added</EventStatusInfo>
                ) : (
                  <span>&nbsp;</span>
                )}
              </td>
            </tr>
          ))}
          <EventStatusLeagues>
            <th scope="row">League Tables</th>
            <td colSpan={2}>
              {statusData.leagues ? (
                <EventStatusLeagueInfo>
                  <span>{statusData.leagues}</span>
                </EventStatusLeagueInfo>
              ) : (
                <span>&nbsp;</span>
              )}
            </td>
          </EventStatusLeagues>
        </EventStatusBody>
      </Table>
    );
  }
}

export { EventStatus as EventStatusTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  statusData: getCurrentEventStatus(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEventStatus: () => dispatch(fetchEventStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventStatus);

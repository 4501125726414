import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import LatestAlert from "../LatestAlert";
import LeaderboardAd from "../LeaderboardAd";
import ScoutLeader from "../scout/ScoutLeader";
import EventStatus from "../status/EventStatus";
import SubHeading from "../SubHeading";
import Title from "../Title";
import BestLeagues from "./BestLeagues";
import DreamTeamPanel from "./DreamTeamPanel";
import EventInfo from "./EventInfo";
import PlayerAvailability from "./PlayerAvailability";
import TopElementCarousel from "./TopElementCarousel";
import TransfersPanel from "./TransfersPanel";
import ValuableTeams from "./ValuableTeams";

const StatusWrapper = styled.div`
  max-width: 1220px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: ${({ theme }) => theme.space[2]};
    padding-left: ${({ theme }) => theme.space[2]};
  }
`;

const StatusMain = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding-right: ${({ theme }) => theme.space[2]};
    padding-left: ${({ theme }) => theme.space[2]};
  }
`;

const StatusHead = styled.div`
  margin: 0 ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0;
  }
`;

const StatusBlock = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StatusUnit = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1;
    padding: 0 ${({ theme }) => theme.space[2]};
  }
`;

const StatusPrimary = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 calc(100% / 3 * 2);
    padding-right: ${({ theme }) => theme.space[2]};
  }
`;

const StatusSecondary = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 calc(100% / 3);
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
}

const Status: React.FC<IPropsFromState> = ({ now, player }) => {
  if (!now || !player) {
    return null;
  }
  return (
    <StatusWrapper>
      <LeaderboardAd slot="Leaderboard_Status" id="ism-status-ad" />
      <StatusMain>
        <StatusHead>
          <Box mt={4}>
            <p>
              You are logged in as{" "}
              <strong>
                {player.first_name} {player.last_name}
              </strong>
              .
            </p>
          </Box>
          <LatestAlert />
          <Title>{`${now.name} Status`}</Title>
        </StatusHead>
        <Box mb={4}>
          <EventStatus />
        </Box>
        <StatusBlock>
          <StatusPrimary>
            <Box mb={4}>
              <EventInfo />
            </Box>
            <Box mx={2}>
              <SubHeading>Latest from The Scout</SubHeading>
            </Box>
            <Box mb={4}>
              <ScoutLeader />
            </Box>
          </StatusPrimary>
          <StatusSecondary>
            <Box mb={4}>
              <DreamTeamPanel />
            </Box>
          </StatusSecondary>
        </StatusBlock>
        <StatusBlock>
          <StatusUnit>
            <Box mb={4}>
              <TransfersPanel />
            </Box>
          </StatusUnit>
          <StatusUnit>
            <Box mb={4}>
              <TransfersPanel isOut={true} />
            </Box>
          </StatusUnit>
        </StatusBlock>
        <Box mb={4}>
          <TopElementCarousel />
        </Box>
        <StatusBlock>
          <StatusUnit>
            <Box mb={4}>
              <ValuableTeams />
            </Box>
            <Box mb={4}>
              <BestLeagues />
            </Box>
          </StatusUnit>
          <StatusUnit>
            <Box mb={4}>
              <PlayerAvailability />
            </Box>
          </StatusUnit>
        </StatusBlock>
      </StatusMain>
    </StatusWrapper>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
});

export default connect(mapStateToProps)(Status);

import { size } from "polished";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getActiveChip,
  getActiveChipName,
} from "../../core/store/chips/reducers";
import { IPotentialChip } from "../../core/store/chips/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { ITransferState } from "../../core/store/my-team/types";
import {
  getFreeTransfers,
  getProposedTransfers,
  getSquadError,
  getTransferCosts,
  getTransferState,
} from "../../core/store/squad/reducers";
import { makeTransfers } from "../../core/store/squad/thunks";
import { ITransfersData } from "../../core/store/squad/types";
import { formatRawAsLocal } from "../../core/utils/datetime";
import { ReactComponent as Status } from "../../img/icons/status.svg";
import Alert, { AlertGroup, AlertItem } from "../Alert";
import Button from "../Button";
import Dialog, { DialogButtonItem } from "../Dialog";
import Table from "../Table";
import { getCodeFromError } from "./helpers";

const StatusIcon = styled(Status)`
  ${size(16)}
  fill: #fbe772;
  color: black;
  margin-right: 4px;
  vertical-align: text-bottom;
`;
interface IOwnProps {
  handleHide: () => void;
}

interface IPropsFromState {
  activeChip: IPotentialChip | null;
  activeChipName: string;
  elementsById: IElementsById;
  freeTransfers: number;
  nextEvent: IEvent | null;
  error: any;
  transferCosts: number;
  transfers: ITransfersData[];
  transferState: ITransferState | null;
}

interface IPropsFromDispatch {
  makeTransfers: () => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch;

const ConfirmTransfersDialog = ({
  activeChip,
  activeChipName,
  error,
  freeTransfers,
  handleHide,
  elementsById,
  makeTransfers,
  nextEvent,
  transferCosts,
  transfers,
  transferState,
}: Props) => {
  const errorCode = getCodeFromError(error);
  const transferChipName =
    activeChipName === "wildcard" ? "Wildcard" : "Free Hit";
  if (error) {
    const handledErrorCodes = [
      "transfer_cap_exceeded",
      "transfer_element_in_price_mismatch",
      "transfer_element_out_price_mismatch",
    ];
    if (handledErrorCodes.indexOf(errorCode) === -1) {
      window.location.reload();
      return null;
    }
  }
  if (!nextEvent || !transferState) {
    return (
      <Dialog closeDialog={handleHide}>
        <Dialog.Header closeDialog={handleHide}>Game over</Dialog.Header>
        <Dialog.Body isPadded={true}>
          <p>
            This game has now finished. Thanks for your interest, come back soon
            to select your squad for next season's game.
          </p>
        </Dialog.Body>
      </Dialog>
    );
  }

  let showTransferChipWarning = false;

  if (activeChipName === "freehit" && activeChip?.is_pending) {
    showTransferChipWarning = transfers.length + transferState.made > 0;
  } else if (activeChipName === "wildcard" && activeChip?.is_pending) {
    showTransferChipWarning = transferState.limit
      ? transfers.length + transferState.made > 1
      : false;
  }

  return (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>Confirm Transfers</Dialog.Header>
      <Dialog.Body isPadded={true}>
        <Box mb={4}>
          <Table>
            <thead>
              <tr>
                <th scope="col">Out</th>
                <th scope="col">In</th>
                <th scope="col">Cost</th>
              </tr>
            </thead>
            <tbody>
              {transfers.map((t, index) => (
                <tr key={t.element_out}>
                  <td>{elementsById[t.element_out].web_name}</td>
                  <td>{elementsById[t.element_in].web_name}</td>
                  <td>
                    {transferCosts && index >= freeTransfers
                      ? transferState.cost
                      : 0}{" "}
                    pts
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={2} scope="row">
                  Total Cost
                </th>
                <td>{transferCosts} pts</td>
              </tr>
            </tfoot>
          </Table>
        </Box>
        <span className="notranslate" role="status" aria-live="polite">
          {transferCosts > 0 ? (
            <Alert type="info">
              <AlertGroup>
                <AlertItem>
                  These transfers will be active for {nextEvent.name} (
                  {formatRawAsLocal(nextEvent.deadline_time)}) and{" "}
                  <strong>
                    will deduct {transferCosts}pts from your score!
                  </strong>
                </AlertItem>
              </AlertGroup>
            </Alert>
          ) : (
            <Alert type="info">
              <AlertGroup>
                {showTransferChipWarning && (
                  <>
                    <AlertItem>
                      <StatusIcon />
                      <strong>{`${transferChipName} Active`}</strong>
                    </AlertItem>
                    <AlertItem>
                      {`Confirming this transfer locks in your ${transferChipName}.`}
                    </AlertItem>
                  </>
                )}
                <AlertItem>
                  These transfers will be active for {nextEvent.name} (
                  {formatRawAsLocal(nextEvent.deadline_time)}).
                </AlertItem>
              </AlertGroup>
            </Alert>
          )}
          <Box mt={4}>
            {errorCode && (
              <Box mb={2}>
                <Alert type="error">
                  {errorCode === "transfer_cap_exceeded" &&
                    "Unable to confirm transfers as they will take you over the limit of 20 transfers in a single Gameweek."}
                  {(errorCode === "transfer_element_in_price_mismatch" ||
                    errorCode === "transfer_element_out_price_mismatch") &&
                    "Unable to confirm transfers as player prices have changed. Please refresh the page and try again."}
                </Alert>
              </Box>
            )}
            <ul>
              <DialogButtonItem>
                <Button onClick={makeTransfers} width={1}>
                  Confirm Transfers
                  {transferCosts > 0 ? (
                    <>
                      <br /> (-{transferCosts}pts)
                    </>
                  ) : activeChipName === "wildcard" &&
                    showTransferChipWarning ? (
                    <>&nbsp;(and play Wildcard)</>
                  ) : activeChipName === "freehit" &&
                    showTransferChipWarning ? (
                    <>&nbsp;(and play Free Hit)</>
                  ) : null}
                </Button>
              </DialogButtonItem>
              <DialogButtonItem>
                <Button onClick={handleHide} width={1} variant="secondary">
                  Change Transfers
                </Button>
              </DialogButtonItem>
            </ul>
          </Box>
        </span>
      </Dialog.Body>
    </Dialog>
  );
};

export { ConfirmTransfersDialog as ConfirmTransfersDialogTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    activeChip: getActiveChip(state),
    activeChipName: getActiveChipName(state),
    elementsById: getElementsById(state),
    error: getSquadError(state),
    freeTransfers: getFreeTransfers(state),
    nextEvent: getNextEvent(state),
    transferCosts: getTransferCosts(state),
    transferState: getTransferState(state),
    transfers: getProposedTransfers(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  makeTransfers: () => dispatch(makeTransfers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmTransfersDialog);

import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { getEvents } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getCreateClassicLeagueError } from "../../core/store/leagues/reducers";
import { createClassicLeague } from "../../core/store/leagues/thunks";
import { ICreateClassicLeagueData } from "../../core/store/leagues/types";
import Button from "../Button";
import Collapsible from "../Collapsible";
import Copy from "../Copy";
import { FieldWrap, InputField, SelectField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import HelmetHeadLeagues from "./HelmetHeadLeagues";

interface IPropsFromState {
  error: IError | null;
  events: IEvent[];
  settings: ISettings | null;
}

interface IPropsFromDispatch {
  createClassicLeague: (data: ICreateClassicLeagueData) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

interface IState {
  name: string;
  startEvent: number;
  hasCup: boolean;
  isSubmitting: boolean;
}

class CreateClassic extends React.Component<Props, IState> {
  public state: IState = {
    name: "",
    startEvent: 1,
    hasCup: true,
    isSubmitting: false,
  };

  public requiredFields: Array<keyof IState> = ["name", "startEvent"];

  public apiDataFromState = (): ICreateClassicLeagueData => ({
    name: this.state.name,
    has_cup: this.state.hasCup,
    start_event: this.state.startEvent,
  });

  public handleNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ name: e.currentTarget.value });

  public handleStartEventChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ startEvent: parseInt(e.currentTarget.value, 10) });

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    this.props.createClassicLeague(this.apiDataFromState());
  };

  public render() {
    const { error, events, settings } = this.props;
    const { isSubmitting, name, startEvent } = this.state;
    const disabled =
      this.requiredFields.some((e) => !this.state[e]) || isSubmitting;
    return (
      <Wrapper>
        <HelmetHeadLeagues />
        <Main>
          <Title>Create Invitational League and Cup</Title>
          <Copy>
            {error ? (
              error.badRequest &&
              error.badRequest.non_field_errors &&
              error.badRequest.non_field_errors[0].code ===
                "max_private_entries" ? (
                <p>
                  You may only enter{" "}
                  {settings && settings.league_join_private_max} invitational
                  leagues
                </p>
              ) : (
                <p>{JSON.stringify(error)}</p>
              )
            ) : (
              ""
            )}
            <form onSubmit={this.handleSubmit}>
              <FieldWrap>
                <InputField
                  id="ismLeagueName"
                  label="League name"
                  value={name}
                  onChange={this.handleNameChange}
                  hint="Maximum 30 characters"
                  maxLength={30}
                />
              </FieldWrap>
              <Box mb={4}>
                <p>
                  Please think carefully before entering your league name.
                  Leagues with names that are deemed inappropriate or offensive
                  may result in your account being deleted. Please refer to the
                  Terms & Conditions of entry for more information.
                </p>
              </Box>
              <FieldWrap>
                <SelectField
                  id="ismLeagueStartEvent"
                  value={startEvent}
                  onChange={this.handleStartEventChange}
                  label="Scoring starts"
                >
                  {events.map((e) => (
                    <option
                      value={e.id}
                      aria-selected={e.id === startEvent}
                      key={e.id}
                    >
                      {e.name}
                    </option>
                  ))}
                </SelectField>
              </FieldWrap>
              <p>
                In a league with classic scoring, teams are ranked based on
                their total points in the game. You can join or league a league
                with classic scoring at any point during the season.
              </p>

              <SubHeading>The Cup</SubHeading>
              <p>
                A League Cup will also be created for those who join your
                Invitational Classic Leagues. The League Cups will start in the
                second half of the season dependent on the size of your league
                and will conclude in Gameweek 38.
              </p>

              <Collapsible headingText="How the cup works">
                <p>
                  Each qualifying team will be randomly drawn against another in
                  the first round. The winner (the team with the highest
                  Gameweek score minus any transfer points), will progress to
                  the second round and another random draw, the losers are out!
                  This process continues until the final round when the two
                  remaining teams contest the cup final.
                </p>
                <p>
                  If a cup match is drawn, then the following tie-breaks will be
                  applied until a winner is found:
                </p>
                <ol>
                  <li>Most goals scored in the Gameweek</li>
                  <li>Fewest goals conceded in the Gameweek</li>
                  <li>Virtual coin toss</li>
                </ol>
              </Collapsible>
              <FieldWrap>
                <Button type="submit" disabled={disabled}>
                  {isSubmitting ? "Please wait..." : "Create"}
                </Button>
              </FieldWrap>
            </form>
          </Copy>
        </Main>
      </Wrapper>
    );
  }
}

export { CreateClassic as CreateClassicTest };

const mapStateToProps = (state: RootState) => ({
  error: getCreateClassicLeagueError(state),
  events: getEvents(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  createClassicLeague: (data: ICreateClassicLeagueData) =>
    dispatch(createClassicLeague(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClassic);

import React from "react";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { IEvent } from "../../core/store/events/types";
import BoldLinkButton from "../BoldLinkButton";
import Fixtures from "../Fixtures";
import LatestAlert from "../LatestAlert";
import { Main, Wrapper } from "../Layout";
import NewsTicker from "../NewsTicker";
import PageHeader from "../PageHeader";
import { ControlArrowRight } from "../icons/Arrows";
import CreateSquad, { IListTableProps } from "./CreateSquad";
import ElementList from "./ElementList";

const PusherWrap = styled.div`
  overflow-x: hidden;
`;

interface IPusher {
  isSidebarVisible: boolean;
}

const Pusher = styled.div<IPusher>`
  position: relative;
  transition: all 300ms;

  ${(props) =>
    props.isSidebarVisible &&
    css`
      transform: translate3d(-100%, 0, 0);

      @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
        transform: none;
      }
    `}
`;

const PusherSecondary = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  overflow-y: auto;
  padding: 2rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    position: static;
    transform: none;
    width: calc(100% / 3);
    padding-right: 1rem;
    padding-left: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 25%;
  }
`;

const ButtonWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: none;
  }
`;

interface IProps {
  headTags: React.ReactNode;
  listTable: (listTableProps: IListTableProps) => void;
  nextEvent: IEvent | null;
  scoreboard: string;
  submitDialog: (handleHide: () => void) => React.ReactNode;
  title: React.ReactNode;
}

const SquadBase: React.FC<IProps> = ({
  headTags,
  listTable,
  nextEvent,
  scoreboard,
  submitDialog,
  title,
}) => {
  const [isSidebarVisible, setIsSidebarVisible] =
    React.useState<boolean>(false);

  const showRef = React.createRef<HTMLButtonElement>();
  const hideRef = React.createRef<HTMLButtonElement>();

  const handleShowSidebar = () => setIsSidebarVisible(true);
  const handleHideSidebar = () => setIsSidebarVisible(false);

  const handleTransitionEnd = (e: React.TransitionEvent) => {
    // Just handle the Pusher transition
    if (e.target === e.currentTarget) {
      isSidebarVisible ? hideRef.current!.focus() : showRef.current!.focus();
    }
  };

  return (
    <>
      <PusherWrap>
        <Pusher
          isSidebarVisible={isSidebarVisible}
          onTransitionEnd={handleTransitionEnd}
        >
          {headTags}
          <Wrapper>
            <Main>
              <LatestAlert />
              <PageHeader title={title}>
                <ButtonWrap>
                  <BoldLinkButton onClick={handleShowSidebar} ref={showRef}>
                    Player List
                    <Box ml={1}>
                      <ControlArrowRight color="primary" />
                    </Box>
                  </BoldLinkButton>
                </ButtonWrap>
              </PageHeader>
              <CreateSquad
                listTable={listTable}
                scoreboard={scoreboard}
                showSidebar={handleShowSidebar}
                submitDialog={submitDialog}
              />
              <Box mt="3rem" mb={4}>
                <NewsTicker />
              </Box>
              {nextEvent && <Fixtures eventId={nextEvent.id} />}
            </Main>
            <PusherSecondary>
              <ElementList hideRef={hideRef} hideSidebar={handleHideSidebar} />
            </PusherSecondary>
          </Wrapper>
        </Pusher>
      </PusherWrap>
    </>
  );
};

export default SquadBase;

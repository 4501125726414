import { hideVisually, rgba } from "polished";
import styled, { css } from "styled-components/macro";
import { ReactComponent as Close } from "../../../img/icons/closeChipDialog.svg";
import { IDialogBodyProps, IDialogWarningStyles } from "./types";

export const CloseIcon = styled(Close)`
  fill: ${({ theme }) => theme.colors.white};
`;

export const CloseLabel = styled.span`
  ${hideVisually}
`;

export const CloseButton = styled.button`
  padding: ${({ theme }) => theme.space[3]};
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const CloseButtonWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const StyledHeader = styled.div`
  position: relative;
  padding: 26px 0;
  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: 16px 0;
  }
`;

export const DialogTitle = styled.h2<IDialogWarningStyles>`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 3rem;
  text-align: center;
  line-height: 45px;
  letter-spacing: -4%;

  ${(props) =>
    props.$showWarning &&
    css`
      margin-top: 4rem;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: 2rem;
  }
`;

export const DialogCopy = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  text-align: center;
  line-height: 24px;
  letter-spacing: -4%;
  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

export const Overlay = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.overlay};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.radii[1]};
  background-image: linear-gradient(
    ${({ theme }) => rgba(theme.colors.white, 0.3)},
    ${({ theme }) => rgba(theme.colors.white, 0.8)}
  );
`;

export const StyledDialog = styled.dialog`
  z-index: ${(props) => props.theme.zIndex.dialog};
  max-width: 90%;
  max-height: 90%;
  padding: 0;
  border: 0;
  border-radius: ${({ theme }) => theme.space[4]};
  /* Ugly hack to deal with sub-pixel rounding in chrome bug causing blurry content with transform */
  width: 700px;
  background-color: ${({ theme }) => theme.colors.purpleDark};
  color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) =>
    `0 12px 24px 0 ${rgba(theme.colors.white, 0.25)}`};
  letter-spacing: -0.04em;
`;

export const StyledDialogBody = styled.div<IDialogBodyProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[5]};
  max-height: calc(90vh - 200px);
  margin: ${({ theme }) => `0 ${theme.space[3]} ${theme.space[3]}`};
  padding: ${({ theme }) => `${theme.space[5]} ${theme.space[3]}`};
  border-radius: ${({ theme }) => theme.space[3]};
  background-color: ${({ theme }) => rgba(theme.colors.white, 0.08)};
  text-align: center;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: ${({ theme }) =>
      `${theme.space[1]} ${theme.space[1]} ${theme.space[3]}`};
    gap: ${({ theme }) => theme.space[1]};
  }
`;

export const ButtonWrap = styled.div`
  margin: auto;
`;

export const StyledDialogWarning = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.red};
  border-top-left-radius: ${({ theme }) => theme.space[3]};
  border-top-right-radius: ${({ theme }) => theme.space[3]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

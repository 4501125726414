import groupBy from "lodash/groupBy";
import range from "lodash/range";
import * as React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getElementTypes,
  getElementTypesById,
} from "../../core/store/element-types/reducers";
import {
  IElementType,
  IElementTypesById,
} from "../../core/store/element-types/types";
import { updateElementControls } from "../../core/store/elements/actions";
import {
  getElementControls,
  getElementsFromControls,
} from "../../core/store/elements/reducers";
import {
  showElementSummary,
  updateElementControlsAndMaxCost,
  updateElementTypeControl,
} from "../../core/store/elements/thunks";
import {
  IElement,
  IElementControls,
  IElementDataFromControls,
} from "../../core/store/elements/types";
import {
  getProposedElementsById,
  getTypesNeeded,
} from "../../core/store/squad/reducers";
import { proposeElement, removeElement } from "../../core/store/squad/thunks";
import {
  IProposedElementsById,
  ITypesNeeded,
} from "../../core/store/squad/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToMoney } from "../../core/utils/money";
import Alert from "../Alert";
import BoldLinkButton from "../BoldLinkButton";
import Button from "../Button";
import Dialog, { DialogButtonItem } from "../Dialog";
import ElementFilter from "../element-controls/ElementFilter";
import ElementSort from "../element-controls/ElementSort";
import Paginator from "../element-controls/Paginator";
import ElementListRow from "../ElementListRow";
import { ElementTable } from "../ElementTable";
import { FieldWrap, Option, SearchField, SelectField } from "../FieldRenderers";
import { PatternWrap } from "../GraphicPatterns";
import { ControlArrowLeft } from "../icons/Arrows";
import SubHeading from "../SubHeading";
import TabHeading from "../TabHeading";

const Form = styled.form`
  padding: 0 ${({ theme }) => theme.space[3]};
`;

const ElementsShown = styled.p`
  margin: 3rem ${({ theme }) => theme.space[1]} 2.4rem;
  padding: 0.6rem;
  border-radius: 12px;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: 1;
  text-align: center;

  strong {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ElementTypeButtonWrap = styled.div`
  margin: 0 ${({ theme }) => theme.space[2]};
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const ElementTypeButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;
  cursor: pointer;
`;

const ElementListStatus = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 14%;
  }
`;

const ElementListElement = styled.th`
  width: 46%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 50%;
  }
`;

const ElementListPrice = styled.th`
  width: 22%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 18%;
  }
`;

const ElementListStat = styled.th`
  width: 22%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 18%;
  }
`;

const ButtonWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: none;
  }
`;

interface IPropsFromState {
  controls: IElementControls;
  currencyDivisor: number;
  elementTypes: IElementType[];
  elementTypesById: IElementTypesById;
  elements: IElementDataFromControls;
  proposedElementsById: IProposedElementsById;
  teamsById: ITeamsById;
  typesNeeded: ITypesNeeded;
}

interface IPropsFromDispatch {
  proposeElement: (elementId: number) => void;
  removeElement: (position: number) => void;
  showElementDialog: (elementId: number) => void;
  showElementType: (elementTypeId: number) => void;
  updateControls: (controls: IElementControls) => void;
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

interface IOwnProps {
  hideRef: React.RefObject<HTMLButtonElement>;
  hideSidebar: () => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  page: number;
  elementForMenu: IElement | null;
}

class ElementList extends React.Component<Props> {
  public state: IState = { elementForMenu: null, page: 1 };

  public addElement = (elementId: number) => {
    this.props.proposeElement(elementId);
    this.handleHideMenuForElement();
  };

  public removeElement = (position: number) => {
    this.props.removeElement(position);
    this.handleHideMenuForElement();
  };

  public handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: e.target.value,
    });
  };

  public handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      sort: e.target.value,
    });
  };

  public handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      search: e.target.value,
    });
  };

  public handleMaxCostChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      maxCost: Number(e.target.value),
    });
  };

  public handleShowMenuForElement = (element: IElement) =>
    this.setState({ elementForMenu: element });

  public handleHideMenuForElement = () => {
    this.setState({ elementForMenu: null });
  };

  public showDialog = (element: IElement) => {
    this.props.showElementDialog(element.id);
    this.handleHideMenuForElement();
  };

  public setPage = (page: number) => this.setState({ page });

  public componentDidMount() {
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: "all",
      sort: "total_points",
      search: "",
      getUnavailable: false,
    });
  }

  public paginateAndGroup(elements: IElement[], pageSize: number) {
    const start = (this.state.page - 1) * pageSize;
    return {
      data: groupBy(elements.slice(start, start + pageSize), "element_type"),
      totalPages: Math.ceil(elements.length / pageSize),
    };
  }

  public renderMenu() {
    const element = this.state.elementForMenu;
    if (!element) {
      return null;
    }

    const proposedElement = this.props.proposedElementsById[element.id];
    const roomInSquad = this.props.typesNeeded[element.element_type];

    let alert = null;
    if (!roomInSquad) {
      alert = (
        <Alert type="error" isInline>
          You already have the maximum number of{" "}
          {this.props.elementTypesById[element.element_type].plural_name}
        </Alert>
      );
    } else if (proposedElement) {
      alert = (
        <Alert>
          <strong>{element.web_name}</strong> is already in your squad
        </Alert>
      );
    }

    return (
      <Dialog closeDialog={this.handleHideMenuForElement}>
        <Dialog.Header closeDialog={this.handleHideMenuForElement}>
          {`${element.first_name} ${element.second_name}`}
        </Dialog.Header>
        {alert && alert}
        <Dialog.Body>
          <ul>
            <DialogButtonItem>
              {proposedElement ? (
                <Button
                  onClick={() => this.removeElement(proposedElement.position)}
                  width={1}
                  variant="secondary"
                >
                  Remove Player
                </Button>
              ) : (
                <Button
                  onClick={() => this.addElement(element.id)}
                  disabled={Boolean(!roomInSquad)}
                  width={1}
                  variant="secondary"
                >
                  Add Player
                </Button>
              )}
            </DialogButtonItem>
            <DialogButtonItem>
              <Button
                onClick={() => this.showDialog(element)}
                width={1}
                variant="tertiary"
              >
                Player Information
              </Button>
            </DialogButtonItem>
          </ul>
        </Dialog.Body>
      </Dialog>
    );
  }

  public render() {
    const {
      controls,
      currencyDivisor,
      elements,
      elementTypes,
      hideRef,
      hideSidebar,
      proposedElementsById,
      showElementType,
      teamsById,
    } = this.props;
    const { data, totalPages } = this.paginateAndGroup(elements.data, 30);
    return (
      <PatternWrap>
        <Flex mx={3} mb={4} alignItems="center" justifyContent="space-between">
          <SubHeading>Player Selection</SubHeading>
          <ButtonWrap>
            <BoldLinkButton onClick={hideSidebar} ref={hideRef}>
              <Box mr={1}>
                <ControlArrowLeft color="primary" />
              </Box>
              Back
            </BoldLinkButton>
          </ButtonWrap>
        </Flex>
        <Form onSubmit={(e) => e.preventDefault()}>
          <ElementFilter handleFilterChange={this.handleFilterChange} />
          <ElementSort handleSortChange={this.handleSortChange} />
          <FieldWrap>
            <SelectField
              id="maxCost"
              name="maxCost"
              label="Max cost"
              value={controls.maxCost}
              onChange={this.handleMaxCostChange}
              hint={`Between ${integerToMoney(
                elements.minCost,
                currencyDivisor
              )} and ${integerToMoney(elements.maxCost, currencyDivisor)}`}
            >
              {range(elements.maxCost, elements.minCost - 1, -5).map((cost) => (
                <Option
                  key={cost}
                  value={cost}
                  aria-selected={controls.maxCost === Number(cost)}
                >
                  {integerToMoney(cost, 10)}
                </Option>
              ))}
            </SelectField>
          </FieldWrap>
          <Box my={4}>
            <SearchField
              id="search"
              name="search"
              label="Search"
              onChange={this.handleSearchChange}
              placeholder="Search for player&#8230;"
              value={controls.search}
            />
          </Box>
        </Form>
        <ElementsShown role="status" aria-live="polite">
          <strong>{elements.data.length}</strong> players shown
        </ElementsShown>
        {elementTypes.map(
          (et) =>
            data[et.id] && (
              <div key={et.id}>
                <ElementTypeButtonWrap>
                  <ElementTypeButton onClick={() => showElementType(et.id)}>
                    <TabHeading title={et.plural_name} />
                  </ElementTypeButton>
                </ElementTypeButtonWrap>
                <ElementTable key={et.id}>
                  <thead>
                    <tr>
                      <ElementListStatus scope="col">&nbsp;</ElementListStatus>
                      <ElementListElement scope="col">
                        &nbsp;
                      </ElementListElement>
                      <ElementListPrice scope="col">£</ElementListPrice>
                      <ElementListStat scope="col">**</ElementListStat>
                    </tr>
                  </thead>
                  <tbody>
                    {data[et.id].map((e) => (
                      <ElementListRow
                        key={e.id}
                        element={e}
                        isProposed={proposedElementsById[e.id] ? true : false}
                        renderElementMenu={() =>
                          this.handleShowMenuForElement(e)
                        }
                        renderElementDialog={() => this.showDialog(e)}
                        team={teamsById[e.team]}
                        currencyDivisor={currencyDivisor}
                        sort={controls.sort}
                      />
                    ))}
                  </tbody>
                </ElementTable>
              </div>
            )
        )}
        {this.renderMenu()}
        <Paginator
          totalPages={totalPages}
          page={this.state.page}
          setPage={this.setPage}
        />
      </PatternWrap>
    );
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  currencyDivisor: 10,
  elementTypes: getElementTypes(state),
  elementTypesById: getElementTypesById(state),
  elements: getElementsFromControls(state),
  proposedElementsById: getProposedElementsById(state),
  teamsById: getTeamsById(state),
  typesNeeded: getTypesNeeded(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: IOwnProps
): IPropsFromDispatch => ({
  proposeElement: (elementId) => {
    dispatch(proposeElement(elementId));
    ownProps.hideSidebar();
  },
  removeElement: (position) => dispatch(removeElement(position)),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  showElementType: (elementTypeId) =>
    dispatch(updateElementTypeControl(elementTypeId)),
  updateControls: (controls) => dispatch(updateElementControls(controls)),
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementList);

import styled from "styled-components/macro";

const SubHeading = styled.h3`
  margin-top: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[3]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.7rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2rem;
  }
`;

export default SubHeading;

import React from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { IH2HMatch } from "../../core/store/leagues/types";
import { getShortNameFromId } from "../../utils/events";
import Result from "../Result";
import CupSummaryScore from "./CupSummaryScore";

const EventCell = styled.td`
  text-align: center;
`;

const StatusCell = styled.td`
  text-align: center;
`;

const CupSummaryData: React.FC<{
  match: IH2HMatch;
  entryId: number;
}> = ({ match, entryId }) => {
  const resultChar = match.winner === entryId ? "W" : match.winner ? "L" : "";
  return (
    <>
      <EventCell>{getShortNameFromId(match.event)}</EventCell>
      <StatusCell>
        {match.winner ? (
          <Box mr={1}>
            <Result resultChar={resultChar} />
          </Box>
        ) : (
          <CupSummaryScore match={match} entryId={entryId} />
        )}
      </StatusCell>
    </>
  );
};

export default CupSummaryData;

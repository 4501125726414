import { useSelector } from "react-redux";
import { getTeamsById } from "../../../core/store/teams/reducers";
import { getSuffixFromId } from "../../../utils/fixtures";
import { IFixtureForElement } from "./types";

const FixtureForElement = ({ fixture, element }: IFixtureForElement) => {
  const teamsById = useSelector(getTeamsById);
  const isHome = element.team === fixture.team_h;
  const suffix = getSuffixFromId(fixture.id, isHome);
  const team = isHome ? teamsById[fixture.team_a] : teamsById[fixture.team_h];
  return (
    <span>
      {team.short_name} {suffix}
    </span>
  );
};

export default FixtureForElement;

import React from "react";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import TextDivider from "../TextDivider";
import Title from "../Title";
import HelmetHeadLeagues from "./HelmetHeadLeagues";

const CreateJoin: React.FC = () => (
  <Wrapper>
    <HelmetHeadLeagues />
    <Main>
      <Copy>
        <Title>Create or Join Leagues &amp; Cups</Title>
        <SubHeading>Join an Existing League &amp; cup</SubHeading>
        <p>
          Join invitational or public leagues &amp; cups to compete against
          friends and other game players
        </p>
        <ButtonLink to="../join">Join a League &amp; Cup</ButtonLink>
        <TextDivider>or</TextDivider>
        <SubHeading>Create a New League &amp; Cup</SubHeading>
        <p>Create an invitational league and cup to compete against friends.</p>
        <ButtonLink to="../create">Create a League &amp; Cup</ButtonLink>
      </Copy>
    </Main>
  </Wrapper>
);

export default CreateJoin;

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import {
  getElementControls,
  getElementsFromControls,
} from "../../core/store/elements/reducers";
import { updateElementControlsAndMaxCost } from "../../core/store/elements/thunks";
import {
  IElementControls,
  IElementDataFromControls,
} from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import BoldLink, { BoldLinkWrap } from "../BoldLink";
import ElementDialogButton from "../ElementDialogButton";
import ElementShirt from "../ElementShirt";
import {
  BorderedCell,
  ElementRow,
  ElementTable,
  PrimaryCell,
} from "../ElementTable";
import Panel from "../Panel";
import { Ellipsis } from "../Utils";

const AvailabilityTable = styled(ElementTable)`
  th:not(:nth-child(3)),
  td:not(:nth-child(3)) {
    text-align: center;
  }
`;

const StatusCol = styled.th`
  width: 12%;
`;

const ShirtCol = styled.th`
  width: 16%;
`;

const PlayerCol = styled.th`
  width: 36%;
`;

const PosCol = styled.th`
  width: 20%;
`;

const ClubCol = styled.th`
  width: 20%;
`;

const ShirtCell = styled.td`
  text-align: right;
`;

interface IPropsFromState {
  controls: IElementControls;
  elements: IElementDataFromControls;
  elementTypesById: IElementTypesById;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

class PlayerAvailability extends React.Component<Props> {
  public componentDidMount() {
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: "all",
      sort: "news_added",
      search: "",
    });
  }

  public render() {
    const { elements, elementTypesById, teamsById } = this.props;
    return (
      <Panel>
        <Panel.Header title="Player Availability" />
        <Panel.Body>
          <AvailabilityTable>
            <thead>
              <tr>
                <StatusCol scope="col">&nbsp;</StatusCol>
                <ShirtCol scope="col">&nbsp;</ShirtCol>
                <PlayerCol scope="col">Player</PlayerCol>
                <PosCol scope="col">Pos</PosCol>
                <ClubCol scope="col">Club</ClubCol>
              </tr>
            </thead>
            <tbody>
              {elements.data.slice(0, 16).map((e) => (
                <ElementRow key={e.id}>
                  <BorderedCell>
                    <ElementDialogButton elementId={e.id} variant="list" />
                  </BorderedCell>
                  <ShirtCell>
                    <ElementShirt elementId={e.id} sizes="16px" />
                  </ShirtCell>
                  <PrimaryCell>
                    <Ellipsis>{e.web_name}</Ellipsis>
                  </PrimaryCell>
                  <td>
                    {elementTypesById[e.element_type].singular_name_short}
                  </td>
                  <td>{teamsById[e.team].short_name}</td>
                </ElementRow>
              ))}
            </tbody>
          </AvailabilityTable>
        </Panel.Body>
        <BoldLinkWrap align="right">
          <BoldLink to="/the-scout/player-news">More</BoldLink>
        </BoldLinkWrap>
      </Panel>
    );
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  elements: getElementsFromControls(state),
  elementTypesById: getElementTypesById(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerAvailability);

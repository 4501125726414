import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "../../OverflowScrolling.css";
import ChipBadge from "../ChipBadge";
import {
  ButtonWrap,
  CloseButton,
  CloseButtonWrap,
  CloseIcon,
  CloseLabel,
  DialogCopy,
  DialogTitle,
  Overlay,
  StyledDialog,
  StyledDialogBody,
  StyledDialogWarning,
  StyledHeader,
} from "./styles";
import {
  IChipDialogHeaderProps,
  IChipDialogWarningProps,
  IDialogBodyProps,
  IProps,
} from "./types";

export const ChipDialogBody: React.FC<IDialogBodyProps> = ({
  children,
  title,
  chip,
  chipId,
  chipAction,
  showWarning,
  warningValue,
}) => {
  return (
    <StyledDialogBody className="ism-overflow-scroll" title={title}>
      {showWarning && <DialogWarning>{warningValue}</DialogWarning>}
      <DialogTitle id="ism-dialog-title" $showWarning={showWarning}>
        {title}
      </DialogTitle>
      <DialogCopy>{children}</DialogCopy>
      {chip && chipId && chipAction && (
        <ButtonWrap>{chipAction(chip, chipId)}</ButtonWrap>
      )}
    </StyledDialogBody>
  );
};

const DialogWarning: React.FC<IChipDialogWarningProps> = ({ children }) => {
  return <StyledDialogWarning>{children}</StyledDialogWarning>;
};

export const ChipDialogHeader: React.FC<IChipDialogHeaderProps> = ({
  closeDialog,
  chip,
}) => {
  return (
    <StyledHeader>
      <CloseButtonWrap>
        <CloseButton onClick={closeDialog}>
          <CloseLabel>close</CloseLabel>
          <CloseIcon />
        </CloseButton>
      </CloseButtonWrap>
      <ChipBadge
        path={`${process.env.PUBLIC_URL}/img/chips/${chip.name}`}
        sizes="110px"
      />
    </StyledHeader>
  );
};

const ChipDialog = ({
  children,
  closeDialog,
  focusable = "a[href], area[href], input:not([disabled]), " +
    "select:not([disabled]), textarea:not([disabled]), " +
    "button:not([disabled]), iframe, object, embed, " +
    "*[tabindex], *[contenteditable]",
}: IProps) => {
  const docref = useRef<HTMLDivElement>(null);
  const focusReturn = document.activeElement as HTMLElement;

  const isVisible = (elem: HTMLElement) =>
    !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

  const handleTab = (e: KeyboardEvent) => {
    const docRef = docref.current;
    const focusedItem = document.activeElement;
    if (docRef) {
      const focusableItems = Array.prototype.slice
        .call(docRef.querySelectorAll(focusable))
        .filter(isVisible);
      const numFocusableItems = focusableItems.length;
      const focusedIndex = focusableItems.indexOf(focusedItem);
      if (!e.shiftKey && focusedIndex === numFocusableItems - 1) {
        // Moving past last focusable item so focus first
        focusableItems[0].focus();
        e.preventDefault();
      } else if (e.shiftKey && focusedIndex === 0) {
        // Moving before first focusable item so focus last
        focusableItems[numFocusableItems - 1].focus();
        e.preventDefault();
      }
    }
  };

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeDialog(e);
    } else if (e.key === "Tab") {
      handleTab(e);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    const docRef = docref.current;
    if (docRef) {
      const focusElement = docRef.querySelector(focusable) as HTMLElement;
      if (focusElement) {
        focusElement.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener("keydown", handleKeydown);
      if (focusReturn) {
        if (focusReturn.focus) {
          focusReturn.focus();
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideMouseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDialog(e);
      e.stopPropagation();
    }
  };

  return ReactDOM.createPortal(
    <Overlay
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleOutsideMouseClick(e);
      }}
      role="presentation"
    >
      <StyledDialog
        role="dialog"
        aria-labelledby="ism-dialog-title"
        open={true}
      >
        <div role="document" ref={docref}>
          {children}
        </div>
      </StyledDialog>
    </Overlay>,
    document.getElementById("root-dialog") as HTMLElement
  );
};

export default ChipDialog;

import * as React from "react";
import styled, { css } from "styled-components/macro";
import Tab from "./Tab";
import { ITabPanel } from "./TabPanel";

export const TabListShared = css`
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 8px;
  list-style-type: none;
  background-color: #edf1f9;
`;

const TabList = styled.ul`
  ${TabListShared}
  margin: 0 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    margin: 0 15%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: 0 25%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: 0 30%;
  }
`;

interface IProps {
  children: Array<React.ReactElement<ITabPanel>>;
  locationHash: string;
  selected: number;
}

interface IState {
  selected: number;
}

class Tabs extends React.Component<IProps, IState> {
  public static defaultProps = {
    locationHash: "",
    selected: 0,
  };

  public state: IState = {
    selected: this.getSelectedFromLocationHash(),
  };

  public getSelectedFromLocationHash() {
    // Default to prop, can override with location hash
    let selected = this.props.selected;
    if (this.props.locationHash) {
      React.Children.forEach(this.props.children, (child, index) => {
        const panel = child as React.ReactElement<ITabPanel>;
        if (`#${panel.props.link}` === this.props.locationHash) {
          selected = index;
        }
      });
    }
    return selected;
  }

  public handleClick = (index: number) => {
    this.setState({ selected: index });
  };

  public render() {
    return (
      <div>
        <TabList>
          {React.Children.map(this.props.children, (child, index) => {
            const panel = child as React.ReactElement<ITabPanel>;
            return (
              <Tab
                key={index}
                index={index}
                label={panel.props.label}
                link={panel.props.link}
                onClick={this.handleClick}
                selected={this.state.selected === index}
              />
            );
          })}
        </TabList>
        {this.props.children[this.state.selected]}
      </div>
    );
  }
}

export default Tabs;

import moment from "moment";
import React from "react";
import styled from "styled-components/macro";
import { fetchNews, IPulseResponse } from "../../utils/pulse";
import NewsThumb from "../news/NewsThumb";
import ScoutErrorBoundary from "./ScoutErrorBoundary";
import { ScoutPattern } from "./styles";

const ScoutLink = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;

  :hover img {
    transform: scale(1.1);
  }
`;

const ScoutContent = styled.figure`
  ${ScoutPattern}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
  }
`;

const ScoutImgWrap = styled.div`
  margin: 1rem;
  overflow: hidden;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1;
  }
`;

const ScoutBody = styled.figcaption`
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1;
    padding: 3rem;
  }
`;

const ScoutTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.space[2]};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.6rem;

  ${ScoutLink}:hover & {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: 0.7rem;
    font-size: 3rem;
  }
`;

const ScoutSummary = styled.p`
  font-size: 1.3rem;
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const ScoutDate = styled.time`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.1rem;
`;

type NewsLeaderProps = {
  news: any;
};

export const NewsLeader: React.FunctionComponent<NewsLeaderProps> = ({
  news,
}) => {
  return (
    <div>
      <ScoutLink href={`https://www.premierleague.com/news/${news.id}`}>
        <ScoutContent>
          <ScoutImgWrap>
            <NewsThumb
              news={news}
              imgSizes={{
                x1: { width: 400, height: 266 },
                x2: { width: 800, height: 532 },
              }}
            />
          </ScoutImgWrap>
          <ScoutBody>
            <ScoutTitle>{news.title}</ScoutTitle>
            <ScoutSummary>{news.summary}</ScoutSummary>
            <ScoutDate dateTime={news.date}>
              {moment(news.date).format("DD/MM/YYYY")}
            </ScoutDate>
          </ScoutBody>
        </ScoutContent>
      </ScoutLink>
    </div>
  );
};

interface IState {
  news: IPulseResponse | null;
}

class ScoutNewsLeader extends React.Component {
  public state: IState = { news: null };

  public async componentDidMount() {
    // TODO - Should set an error state then can throw in render and use the
    // error boundary
    const newsData = await fetchNews(1, "Fantasy%20Football%20Scout").catch(
      (e) => {}
    );
    if (newsData) {
      this.setState({
        news: newsData,
      });
    }
  }

  public render() {
    const { news } = this.state;
    return news?.content.length ? <NewsLeader news={news.content[0]} /> : null;
  }
}

const ScoutLeader: React.FC = () => (
  <ScoutErrorBoundary>
    <ScoutNewsLeader />
  </ScoutErrorBoundary>
);

export default ScoutLeader;

import { navigate, RouteComponentProps } from "@reach/router";
import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getEntry } from "../core/store/entries/reducers";
import { IEntry } from "../core/store/entries/types";
import { getCurrentEvent, getNextEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
import homeStep1_1_342 from "../img/home-step-1-1-342.png";
import homeStep1_1_684 from "../img/home-step-1-1-684.png";
import homeStep1_2_342 from "../img/home-step-1-2-342.png";
import homeStep1_2_684 from "../img/home-step-1-2-684.png";
import homeStep1_3_342 from "../img/home-step-1-3-342.png";
import homeStep1_3_684 from "../img/home-step-1-3-684.png";
import homeStep1_4_342 from "../img/home-step-1-4-342.png";
import homeStep1_4_684 from "../img/home-step-1-4-684.png";
import homeStep1_5_342 from "../img/home-step-1-5-342.png";
import homeStep1_5_684 from "../img/home-step-1-5-684.png";
import homeStep1_6_342 from "../img/home-step-1-6-342.png";
import homeStep1_6_684 from "../img/home-step-1-6-684.png";
import homeStep1_7_342 from "../img/home-step-1-7-342.png";
import homeStep1_7_684 from "../img/home-step-1-7-684.png";
import homeStep2_342 from "../img/home-step-2-342.png";
import homeStep2_684 from "../img/home-step-2-684.png";
import pattern1_437 from "../img/pattern/pattern-1-437.png";
import LatestAlert from "./LatestAlert";
import { Main, Wrapper } from "./Layout";
import Login from "./Login";
import ScoutLeader from "./scout/ScoutLeader";
import Status from "./status/Status";
import SubHeading from "./SubHeading";

const HomeSteps = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

const HomeStep = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
  background-color: ${({ theme }) => theme.colors.grey};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(50% - 1rem);
    display: flex;
    align-items: center;
    text-align: left;
  }
`;

const HomeStepHeading = styled.h3`
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-size: 2.1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: 3rem;
  }
`;

const HomeStepImg = styled.img`
  display: block;
  margin: auto;
  max-width: 342px;
`;

const HomeStepBody = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  background-repeat: no-repeat;
  background-image: url(${pattern1_437}),
    linear-gradient(to right, ${({ theme }) => theme.colors.lightBlue}, #43a0ff);
  background-position: left -57px bottom -225px, 0 0;
  background-size: 313px 355px, auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    height: 100%;
    padding: 0 ${({ theme }) => theme.space[3]};
  }
`;

interface IPropsFromState {
  entry: IEntry | null;
  now: IEvent | null;
  nxt: IEvent | null;
  player: IPlayer | null;
}

type Props = RouteComponentProps & IPropsFromState;

class Home extends React.Component<Props> {
  public render() {
    const { entry, now, nxt, player } = this.props;

    // Delay rendering until entry is available
    if (player && player.entry && !entry) {
      return null;
    }

    // Look for a login error from the users server
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    let usersError = "";
    if (query.state) {
      switch (query.state) {
        case "fail":
          usersError = "Incorrect email or password";
          break;
        case "success":
          let url = "/";
          if (player && player.entry && entry) {
            // Season start
            if (!now) {
              url = "/my-team";
            }
            // Season over
            else if (!nxt) {
              url = `/entry/${player.entry}/event/${now.id}/`;
            } else {
              url =
                entry.started_event <= now.id
                  ? `/entry/${player.entry}/event/${now.id}/`
                  : "/my-team";
            }
          } else if (player && player.id) {
            url = "/squad-selection";
          }
          // Need this to make sure LocationProvider is ready
          setTimeout(() => {
            navigate(url, {
              replace: true,
            });
          }, 0);
          return null;
        default:
          break;
      }
    }

    // Show the status page if have a team and the game has started
    if (now && player && player.entry) {
      return <Status />;
    }

    const homeStep1Images = [
      {
        x1: homeStep1_1_342,
        x2: homeStep1_1_684,
      },
      {
        x1: homeStep1_2_342,
        x2: homeStep1_2_684,
      },
      {
        x1: homeStep1_3_342,
        x2: homeStep1_3_684,
      },
      {
        x1: homeStep1_4_342,
        x2: homeStep1_4_684,
      },
      {
        x1: homeStep1_5_342,
        x2: homeStep1_5_684,
      },
      {
        x1: homeStep1_6_342,
        x2: homeStep1_6_684,
      },
      {
        x1: homeStep1_7_342,
        x2: homeStep1_7_684,
      },
    ];
    const homeStep1Image =
      homeStep1Images[Math.floor(Math.random() * homeStep1Images.length)];

    return (
      <Wrapper>
        <Main isWide={true}>
          <LatestAlert />
          <Login usersError={usersError} />
          <HomeSteps>
            <HomeStep>
              <div>
                <HomeStepImg
                  srcSet={`${homeStep1Image.x1}, ${homeStep1Image.x2} 2x`}
                  src={homeStep1Image.x1}
                  alt=""
                />
              </div>
              <HomeStepBody>
                <HomeStepHeading>Pick Your Squad</HomeStepHeading>
                <p>
                  Use your budget of £100m to pick a squad of 15 players from
                  the Premier League.
                </p>
              </HomeStepBody>
            </HomeStep>
            <HomeStep>
              <div>
                <HomeStepImg
                  srcSet={`${homeStep2_342}, ${homeStep2_684} 2x`}
                  src={homeStep2_342}
                  alt=""
                />
              </div>
              <HomeStepBody>
                <HomeStepHeading>Create and Join Leagues</HomeStepHeading>
                <p>
                  Play against friends and family, colleagues or a web community
                  in invitational leagues and cups.
                </p>
              </HomeStepBody>
            </HomeStep>
          </HomeSteps>
          <Box mx={2}>
            <SubHeading>Latest from The Scout</SubHeading>
          </Box>
          <ScoutLeader />
        </Main>
      </Wrapper>
    );
  }
}

export { Home as HomeTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state);
  return {
    entry: player && player.entry ? getEntry(state, player.entry) : null,
    now: getCurrentEvent(state),
    nxt: getNextEvent(state),
    player,
  };
};

export default connect(mapStateToProps)(Home);

import { hiDPI, rgba, size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
import { ReactComponent as BaseLogoLight } from "../img/headline-logo-light.svg";
import { ReactComponent as AppleLogo } from "../img/social/apple.svg";
import { ReactComponent as FacebookLogo } from "../img/social/facebook.svg";
import { ReactComponent as GoogleLogo } from "../img/social/google.svg";
import { ReactComponent as TwitterLogo } from "../img/social/twitter.svg";
import pattern1_437 from "./../img/pattern/pattern-1-437.png";
import pattern1_874 from "./../img/pattern/pattern-1-874.png";
import Alert from "./Alert";
import { BoldHyperlink } from "./BoldLink";
import Button from "./Button";
import { ButtonHyperlink } from "./ButtonLink";
import Copy from "./Copy";
import { FieldWrap, InputField } from "./FieldRenderers";
import TextDivider from "./TextDivider";

const LoginForm = styled.form`
  margin: 0 ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    margin: 0;
    padding-top: 3rem;
  }
`;

const LoginTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 10%;
    margin-top: 2.3rem;
    margin-bottom: ${({ theme }) => theme.space[4]};
    padding-right: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 10%;
  }
`;

const Username = styled(FieldWrap)`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 30%;
    padding-right: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 28%;
  }
`;

const Password = styled(FieldWrap)`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 23%;
    padding-right: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 28%;
  }
`;

const LoginButtonWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 20%;
    margin-top: 2.3rem;
    margin-bottom: ${({ theme }) => theme.space[4]};
    padding-right: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 20%;
  }
`;

const LoginLinks = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 17%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 14%;
  }
`;

const LoginLink = styled.div`
  padding: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0;
    text-align: left;
  }
`;

const SocialLogin = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `0 ${theme.space[1]} ${theme.space[4]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 2%;
    margin-left: 2%;
  }
`;

const SocialButtonWrap = styled.div`
  flex: 0 0 50%;
  margin: ${({ theme }) => theme.space[1]} 0;
  padding: 0 ${({ theme }) => theme.space[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-basis: 25%;
  }
`;

const StyledSocialButton = styled.a<ISocialButtonStyleProps>`
  display: block;
  padding: 0 ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[0]};
  box-shadow: 0 1px 8px 0 ${rgba("black", 0.12)};
  background-color: ${(props) => props.theme.colors.social[props.network].bg};
  color: ${(props) => props.theme.colors.social[props.network].color};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: center;
  text-decoration: none;
  line-height: 4.4rem;

  > svg {
    vertical-align: middle;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SocialButtonText = styled.span`
  margin-left: ${({ theme }) => theme.space[2]};
  text-transform: capitalize;
`;

const Promo = styled.div`
  margin: ${({ theme }) => `0 ${theme.space[2]} ${theme.space[4]}`};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[0]};
  background-color: ${({ theme }) => theme.colors.primary};
  background-repeat: no-repeat;
  background-image: url(${pattern1_437}), url(${pattern1_437});
  background-position: left -78px bottom -260px, right -220px top -152px;
  background-size: 366px 415px;
  text-align: center;

  ${hiDPI(2)} {
    background-image: url(${pattern1_874}), url(${pattern1_874});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    margin: 0 0 4rem;
    padding: 2.2rem 8rem;
    text-align: left;
  }
`;

const PromoHead = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 19%;
  }
`;

const PromoBody = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 62%;
  }
`;

const PromoFoot = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 19%;
  }
`;

const LogoLight = styled(BaseLogoLight)`
  ${size(32, 139)}
  margin: ${({ theme }) => theme.space[4]} 0;
`;

const PromoHeading = styled.h3`
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-size: 2.1rem;
  color: ${({ theme }) => theme.colors.fantasy};
`;

const PromoText = styled.p`
  margin-bottom: ${({ theme }) => theme.space[4]};
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-bottom: 0;
    padding-right: 6rem;
  }
`;

interface ISocialButtonStyleProps {
  network: string;
}

interface ISocialButtonProps {
  link: string;
  logo: React.ReactNode;
}

type SocialButtonPros = ISocialButtonProps & ISocialButtonStyleProps;

const SocialButton: React.FC<SocialButtonPros> = ({ link, logo, network }) => (
  <StyledSocialButton href={link} network={network}>
    {logo}
    <SocialButtonText>login with {network}</SocialButtonText>
  </StyledSocialButton>
);

interface IOwnProps {
  usersError?: string;
}

interface IPropsFromState {
  player: IPlayer | null;
}

type Props = IOwnProps & IPropsFromState;

const Login: React.FC<Props> = ({ player, usersError = "" }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  if (player && player.id) {
    return (
      <Copy>
        <p>
          You are logged in as {player.first_name} {player.last_name}.
        </p>
      </Copy>
    );
  }
  // Constants to help build urls
  const app = "plfpl-web";
  const plusers = process.env.REACT_APP_PLUSERS_BASE || "";
  const redirect = `${window.location.protocol}//${window.location.host}/`;
  const register = `${plusers}/users/register/personal?app=${app}&redirect_uri=${redirect}`;
  const passLook = `${plusers}/accounts/password-reset/`;
  const makeSocialLogin = (net: string) =>
    `${plusers}/accounts/${net}/login/?app=${app}&redirect_uri=${redirect}`;

  return (
    <>
      {usersError && (
        <div>
          {usersError && (
            <Box mb={2} role="status" aria-live="polite">
              <Alert type="error">{usersError}</Alert>
            </Box>
          )}
        </div>
      )}
      <LoginForm
        method="POST"
        action={`${plusers}/accounts/login/`}
        onSubmit={() => setIsSubmitting(true)}
      >
        <LoginTitle>Sign In</LoginTitle>
        <Username>
          <InputField
            id="loginUsername"
            name="login"
            type="email"
            label="Email"
          />
        </Username>
        <Password>
          <InputField
            id="loginLoginWrap"
            name="password"
            type="password"
            label="Password"
          />
        </Password>
        <LoginButtonWrap>
          <Button type="submit" fullwidth="true" disabled={isSubmitting}>
            Sign In
          </Button>
        </LoginButtonWrap>
        <input type="hidden" name="app" value={app} />
        <input type="hidden" name="redirect_uri" value={redirect} />
        <LoginLinks>
          <LoginLink>
            <BoldHyperlink href={passLook}>Forgot your password?</BoldHyperlink>
          </LoginLink>
        </LoginLinks>
      </LoginForm>
      <TextDivider>or login with</TextDivider>
      <SocialLogin>
        <SocialButtonWrap>
          <SocialButton
            link={makeSocialLogin("facebook")}
            network="facebook"
            logo={<FacebookLogo />}
          />
        </SocialButtonWrap>
        <SocialButtonWrap>
          <SocialButton
            link={makeSocialLogin("twitter")}
            network="x"
            logo={<TwitterLogo />}
          />
        </SocialButtonWrap>
        <SocialButtonWrap>
          <SocialButton
            link={makeSocialLogin("google")}
            network="google"
            logo={<GoogleLogo />}
          />
        </SocialButtonWrap>
        <SocialButtonWrap>
          <SocialButton
            link={makeSocialLogin("apple")}
            network="apple"
            logo={<AppleLogo />}
          />
        </SocialButtonWrap>
      </SocialLogin>
      <Promo>
        <PromoHead>
          <LogoLight />
        </PromoHead>
        <PromoBody>
          <PromoHeading>Register to Play Fantasy Premier League</PromoHeading>
          <PromoText>
            With over 11 million players, Fantasy Premier League is the biggest
            Fantasy Football game in the world.{" "}
            <strong>It’s FREE to play and you can win great prizes!</strong>
          </PromoText>
        </PromoBody>
        <PromoFoot>
          <ButtonHyperlink href={register} fullwidth="true">
            Sign Up Now
          </ButtonHyperlink>
        </PromoFoot>
      </Promo>
    </>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  player: getPlayerData(state),
});

export default connect(mapStateToProps)(Login);

import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getMostValuableTeams } from "../../core/store/stats/reducers";
import { fetchMostValuableTeams } from "../../core/store/stats/thunks";
import { IMostValuableTeam } from "../../core/store/stats/types";
import { integerToMoney } from "../../core/utils/money";
import { BorderedCell } from "../ElementTable";
import Link from "../Link";
import Panel from "../Panel";
import Table from "../Table";

const MVTTable = styled(Table)`
  white-space: nowrap;
  table-layout: fixed;

  th:not(:nth-child(2)),
  td:not(:nth-child(2)) {
    text-align: center;
  }
`;

const PosCell = styled.th`
  width: 15%;
`;

const TeamCell = styled.th`
  width: 50%;
`;

const AverageCell = styled.th`
  width: 25%;
`;

interface IPropsFromState {
  teams: IMostValuableTeam[];
}

interface IPropsFromDispatch {
  fetchMostValuableTeams: () => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

class ValuableTeams extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchMostValuableTeams();
  }

  public render() {
    const { teams } = this.props;
    return (
      <Panel>
        <Panel.Header title="Most Valuable Teams" />
        <Panel.Body>
          <MVTTable>
            <thead>
              <tr>
                <PosCell scope="col">Pos</PosCell>
                <TeamCell scope="col">Team</TeamCell>
                <AverageCell scope="col">Value</AverageCell>
              </tr>
            </thead>
            <tbody>
              {teams.slice(0, 5).map((t, i) => (
                <tr key={t.entry}>
                  <td>{i + 1}</td>
                  <BorderedCell>
                    <Link to={`/entry/${t.entry}/history`}>{t.name}</Link>
                  </BorderedCell>
                  <td>£{integerToMoney(t.value_with_bank, 10)}m</td>
                </tr>
              ))}
            </tbody>
          </MVTTable>
          {!teams.length && (
            <Box m={4}>
              <p>The Most Valuable Teams will appear here when available.</p>
            </Box>
          )}
        </Panel.Body>
      </Panel>
    );
  }
}

export { ValuableTeams as ValuableTeamsTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    teams: getMostValuableTeams(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchMostValuableTeams: () => dispatch(fetchMostValuableTeams()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValuableTeams);

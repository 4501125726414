import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { updateElementControls } from "../../core/store/elements/actions";
import {
  getElementControls,
  getElementsFromControls,
} from "../../core/store/elements/reducers";
import { updateElementControlsAndMaxCost } from "../../core/store/elements/thunks";
import {
  IElementControls,
  IElementDataFromControls,
} from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import ElementFilter from "../element-controls/ElementFilter";
import ElementSort from "../element-controls/ElementSort";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementRow, ElementTable } from "../ElementTable";
import { FieldCol, FieldCols } from "../FieldRenderers";
import HelmetHead from "../HelmetHead";
import { Main, Wrapper } from "../Layout";
import ScoutNav from "../scout/ScoutNav";
import Title from "../Title";

const NewsStatus = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 6%;
  }
`;

const NewsElement = styled.th`
  width: 39%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 38%;
  }
`;

const NewsNews = styled.th`
  width: 51%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 56%;
  }
`;

const NewsCell = styled.td`
  white-space: normal;
`;

interface IState {
  page: number;
}

interface IPropsFromState {
  elements: IElementDataFromControls;
  controls: IElementControls;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  updateControls: (controls: IElementControls) => void;
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

type Props = RouteComponentProps & IPropsFromState & IPropsFromDispatch;

class News extends React.Component<Props, IState> {
  public handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.updateControls({
      ...this.props.controls,
      filter: e.target.value,
    });
  };

  public handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.updateControls({
      ...this.props.controls,
      sort: e.target.value,
    });
  };

  public componentDidMount() {
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: "all",
      sort: "news_added",
    });
  }

  public render() {
    const { elements, teamsById } = this.props;
    return (
      <>
        <Wrapper>
          <Box flex={1} pt={4} mx={2}>
            <Title>The Scout</Title>
            <ScoutNav />
          </Box>
        </Wrapper>
        <Wrapper>
          <HelmetHead
            title="Fantasy Premier League Player Availability, Injuries &amp; Eligibility | Fantasy Premier League"
            description="To view player availability including injuries and eligibility for all Fantasy Premier League players, or select by position or team, visit the official website of the Premier League."
          />
          <Main>
            <form>
              <FieldCols>
                <FieldCol>
                  <ElementFilter handleFilterChange={this.handleFilterChange} />
                </FieldCol>
                <FieldCol>
                  <ElementSort
                    handleSortChange={this.handleSortChange}
                    sortOptions={[
                      { label: "Most recently added", value: "news_added" },
                      {
                        label: "Chance of playing",
                        value: "chance_of_playing_next_round",
                      },
                    ]}
                  />
                </FieldCol>
              </FieldCols>
            </form>
            <ElementTable>
              <thead>
                <tr>
                  <NewsStatus scope="col">&nbsp;</NewsStatus>
                  <NewsElement scope="col">Player</NewsElement>
                  <NewsNews scope="col">News</NewsNews>
                </tr>
              </thead>
              <tbody>
                {elements.data
                  .filter((e) => e.news)
                  .map((e) => (
                    <ElementRow key={e.id}>
                      <td>
                        <ElementDialogButton elementId={e.id} variant="list" />
                      </td>
                      <td>
                        <ElementInTable
                          renderElementMenu={() => {
                            window.console.log("not needed");
                          }}
                          element={e}
                          team={teamsById[e.team]}
                        />
                      </td>
                      <NewsCell>{e.news}</NewsCell>
                    </ElementRow>
                  ))}
              </tbody>
            </ElementTable>
          </Main>
        </Wrapper>
      </>
    );
  }
}

export { News as NewsTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  elements: getElementsFromControls(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  updateControls: (controls) => dispatch(updateElementControls(controls)),
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);

import { size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as BaseExternalIcon } from "../../img/icons/external.svg";
import { ReactComponent as Info } from "../../img/icons/info.svg";

export const StyledSetPieceTakers = styled.section`
  width: 100%;
  margin-top: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding: ${({ theme }) => theme.space[2]};
`;

export const ExternalIcon = styled(BaseExternalIcon)`
  ${size(10)}
  margin-left: 0.4rem;
  fill: black;
`;

export const NotesWrapper = styled.ul`
  margin-top: ${({ theme }) => theme.space[4]};
`;

export const Note = styled.li`
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.space[2]};
`;

export const NoteLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.darkPink};
    text-decoration: underline;
  }
`;

export const SetPieceTakersContent = styled.div`
  ul {
    padding-left: 2.4rem;
  }
`;

export const TeamContentInner = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: 10px;
  }
`;

export const SetPieceTitle = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  margin-bottom: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[1]};
  background-color: ${({ theme }) => theme.colors.offWhite};
`;

export const Element = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.space[2]};
  button {
    display: inline-block;
    width: auto;
    margin-right: ${({ theme }) => theme.space[1]};
  }
`;

export const ElementName = styled.div``;

export const Group = styled.div`
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1;
    padding: 0 10px;
  }
`;

export const StyledInfo = styled(Info)`
  ${size(22)}
  display: inline-block;
  margin-right: ${({ theme }) => theme.space[2]};
  color: ${({ theme }) => theme.colors.black};
  fill: ${({ theme }) => theme.colors.lightGrey};
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(24)}
  }
`;

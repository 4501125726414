import { rgba } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";
import { ReactComponent as ChevronDown } from "../img/icons/chevron-down.svg";
import Copy from "./Copy";
import Badge from "./Badge";

import { ITeam } from "../core/store/teams/types";

const StyledCollapsible = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
  box-shadow: 0 1px 4px 0 ${rgba("black", 0.08)};

  > h5 {
    margin-bottom: 0;
  }
`;

const BadgeWrapper = styled.div`
  display: inline-block;
`;

const IconWrap = styled.div`
  align-self: stretch;
  padding: ${({ theme }) => theme.space[3]} 2.5rem;
  background-color: #f8f8f8;
`;

interface IButtonProps {
  team?: boolean;
}

const Button = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: white;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.4rem;
  text-align: left;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  &[aria-expanded="true"] {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.fantasy},
      ${({ theme }) => theme.colors.lightBlue}
    );
    ${IconWrap} {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    svg {
      transform: rotate(180deg);
      fill: white;
    }
  }

  ${({ team }) =>
    team === true &&
    css`
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    `}
`;

const HeadingText = styled.span`
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[3]}`};
`;

const CollapsibleContent = styled.div`
  padding: ${({ theme }) => theme.space[2]};

  &[aria-hidden] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
`;

// Used to generate unique ids for collapsible content / aria controls
let lastId = 0;
const newId = (prefix = "ism-id") => `${prefix}${++lastId}`;

interface IProps {
  headingText: string;
  initialOpen?: boolean;
  team?: ITeam;
}

interface IState {
  open: boolean;
}

class Collapsible extends React.Component<IProps, IState> {
  public state = { open: false };
  public id = "";
  public toggle = () => this.setState({ open: !this.state.open });

  componentDidMount() {
    this.id = newId("ism-collapsible-");
    if (this.props.initialOpen) {
      this.setState({ open: true });
    }
  }

  render() {
    const { children, headingText, team } = this.props;
    return (
      <StyledCollapsible>
        <h5>
          <Button
            type="button"
            data-testid="button"
            onClick={this.toggle}
            aria-expanded={this.state.open}
            aria-controls={this.id}
            team={typeof team !== "undefined"}
          >
            <IconWrap>
              <ChevronDown />
            </IconWrap>
            {team ? (
              <div>
                <BadgeWrapper data-testid="badge-wrapper">
                  <Badge
                    team={team}
                    isPresentation={true}
                    sizes="(min-width: 1024px) 32px, 24px"
                  />
                </BadgeWrapper>
                <HeadingText data-testid="title">{headingText}</HeadingText>
              </div>
            ) : (
              <HeadingText data-testid="title">{headingText}</HeadingText>
            )}
          </Button>
        </h5>
        <CollapsibleContent
          id={this.id}
          aria-hidden={!this.state.open}
          data-testid="content"
        >
          <Copy>{children}</Copy>
        </CollapsibleContent>
      </StyledCollapsible>
    );
  }
}

export default Collapsible;

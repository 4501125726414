import React from "react";
import { useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState } from "../../core/store";
import { getCupMatchesByLeagueId } from "../../core/store/entries/reducers";
import {
  CupMatchesByLeagueId,
  ILeagueEntry,
} from "../../core/store/entries/types";
import { VisuallyHidden } from "../Utils";
import CupTypeRow from "./CupTypeRow";
import {
  LeagueSummaryHeading,
  LeagueSummaryTable,
  SummaryCupCol,
  SummaryRoundCol,
  SummaryStatusCol,
} from "./Styles";

interface IProps {
  entryId: number;
  leagues: ILeagueEntry[];
  title: string;
}

const MyLeagueCupSummary: React.FC<IProps> = ({ entryId, leagues, title }) => {
  const cupMatches: CupMatchesByLeagueId | null = useSelector(
    (state: RootState) =>
      entryId ? getCupMatchesByLeagueId(state, entryId) : null
  );

  return (
    <Box pb={4}>
      <LeagueSummaryHeading>{title}</LeagueSummaryHeading>
      <LeagueSummaryTable>
        <thead>
          <tr>
            <SummaryCupCol scope="col">
              <VisuallyHidden>Cup</VisuallyHidden>
            </SummaryCupCol>
            <SummaryRoundCol scope="col">
              <VisuallyHidden>Round</VisuallyHidden>
            </SummaryRoundCol>
            <SummaryStatusCol scope="col">
              <VisuallyHidden>Status</VisuallyHidden>
            </SummaryStatusCol>
          </tr>
        </thead>
        <tbody>
          {leagues.map((l) => (
            <CupTypeRow
              key={l.id}
              cupMatch={
                cupMatches && l.cup_league ? cupMatches[l.cup_league] : null
              }
              entryId={entryId}
              league={l}
            />
          ))}
        </tbody>
      </LeagueSummaryTable>
    </Box>
  );
};

export default MyLeagueCupSummary;

import React from "react";
import SubNav from "../SubNav";

const links = [
  { href: "/leagues", text: "Leagues" },
  { href: "/leagues/cups", text: "Cups" },
];

const LeaguesNav: React.FC = () => <SubNav links={links} />;

export default LeaguesNav;

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getEventDreamTeam,
  getOverallDreamTeam,
} from "../../core/store/dream-teams/reducers";
import {
  fetchEventDreamTeam,
  fetchOverallDreamTeam,
} from "../../core/store/dream-teams/thunks";
import { IDreamTeamData } from "../../core/store/dream-teams/types";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import {
  IElementType,
  IElementTypesById,
} from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElement, IElementsById } from "../../core/store/elements/types";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeam, ITeamsById } from "../../core/store/teams/types";
import { ReactComponent as DreamTeamIcon } from "../../img/icons/dreamteam.svg";
import { isBlankWeek } from "../../utils/events";
import BoldLink, { BoldLinkWrap } from "../BoldLink";
import ElementDialogButton from "../ElementDialogButton";
import ElementShirt from "../ElementShirt";
import {
  BorderedCell,
  ElementRow,
  ElementTable,
  PrimaryCell,
} from "../ElementTable";
import Panel from "../Panel";
import { Ellipsis } from "../Utils";

const DTTable = styled(ElementTable)`
  th:not(:nth-child(4)),
  td:not(:nth-child(4)) {
    text-align: center;
  }
`;

const DTStatus = styled.th`
  width: 12%;
`;

const DTPos = styled.th`
  width: 14%;
  text-align: center;
`;

const DTShirt = styled.th`
  width: 10%;
`;

const DTName = styled.th`
  width: 32%;
`;

const DTClub = styled.th`
  width: 16%;
  text-align: center;
`;

const DTPts = styled.th`
  width: 16%;
  text-align: center;
`;

interface IDreamTeamRowProps {
  element: IElement;
  elementType: IElementType;
  team: ITeam;
  points: number;
}

const DreamTeamRow: React.FC<IDreamTeamRowProps> = ({
  element,
  elementType,
  team,
  points,
}) => (
  <ElementRow>
    <BorderedCell>
      <ElementDialogButton elementId={element.id} variant="list" />
    </BorderedCell>
    <td>{elementType.singular_name_short}</td>
    <td>
      <ElementShirt elementId={element.id} sizes="16px" />
    </td>
    <PrimaryCell>
      <Ellipsis>{element.web_name}</Ellipsis>
    </PrimaryCell>
    <BorderedCell>{team.short_name}</BorderedCell>
    <td>{points}</td>
  </ElementRow>
);

interface IPropsFromState {
  data: IDreamTeamData | null;
  elementsById: IElementsById;
  elementTypesById: IElementTypesById;
  now: IEvent | null;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  fetchEventDreamTeam: (eventId: number) => void;
  fetchOverallDreamTeam: () => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

class DreamTeamPanel extends React.Component<Props> {
  public componentDidMount() {
    if (this.props.now) {
      if (isBlankWeek(this.props.now.id)) {
        this.props.fetchOverallDreamTeam();
      } else {
        this.props.fetchEventDreamTeam(this.props.now.id);
      }
    }
  }

  public render() {
    const { data, elementsById, elementTypesById, now, teamsById } = this.props;
    if (!data || !now) {
      return null;
    }
    return (
      <Panel>
        <Panel.Header
          title={
            isBlankWeek(now.id) ? "Team of the Season" : "Team of the Week"
          }
          icon={<DreamTeamIcon />}
        ></Panel.Header>
        <Panel.Body>
          <DTTable>
            <thead>
              <tr>
                <DTStatus scope="col">&nbsp;</DTStatus>
                <DTPos scope="col">Pos</DTPos>
                <DTShirt scope="col">&nbsp;</DTShirt>
                <DTName scope="col">Player</DTName>
                <DTClub scope="col">Club</DTClub>
                <DTPts scope="col">Pts</DTPts>
              </tr>
            </thead>
            <tbody>
              {data.team.map((dt) => (
                <DreamTeamRow
                  key={dt.element}
                  element={elementsById[dt.element]}
                  team={teamsById[elementsById[dt.element].team]}
                  elementType={
                    elementTypesById[elementsById[dt.element].element_type]
                  }
                  points={dt.points}
                />
              ))}
            </tbody>
          </DTTable>
        </Panel.Body>
        <BoldLinkWrap align="right">
          <BoldLink
            to={`/team-of-the-week/${isBlankWeek(now.id) ? "" : now.id + "/"}`}
          >
            {data.team.reduce((total, e) => total + e.points, 0)} pts
          </BoldLink>
        </BoldLinkWrap>
      </Panel>
    );
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => {
  const now = getCurrentEvent(state);
  return {
    data: now
      ? isBlankWeek(now.id)
        ? getOverallDreamTeam(state)
        : getEventDreamTeam(state, now.id)
      : null,
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    now,
    teamsById: getTeamsById(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEventDreamTeam: (eventId) => dispatch(fetchEventDreamTeam(eventId)),
  fetchOverallDreamTeam: () => dispatch(fetchOverallDreamTeam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DreamTeamPanel);

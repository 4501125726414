import React from "react";
import SubNav from "./SubNav";

const links = [
  { href: "/fixtures", text: "Fixtures" },
  { href: "/fixtures/fdr", text: "FDR" },
];

const FixturesNav: React.FC = () => <SubNav links={links} />;

export default FixturesNav;

import { format } from "date-fns";
import { hideVisually, hiDPI, rgba, size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementStatsByIdentifier } from "../core/store/element-stats/reducers";
import { IElementStat } from "../core/store/element-stats/types";
import { getElementsById } from "../core/store/elements/reducers";
import { showElementSummary } from "../core/store/elements/thunks";
import { IElementsById } from "../core/store/elements/types";
import {
  getCurrentEvent,
  getEventsById,
  getNextEvent,
} from "../core/store/events/reducers";
import { IEvent, IEventsById } from "../core/store/events/types";
import { getGroupedFixturesByEvent } from "../core/store/fixtures/reducers";
import { fetchFixtures } from "../core/store/fixtures/thunks";
import {
  IFixtureStat,
  IGroupedFixturesByEvent,
  IGroupFixture,
} from "../core/store/fixtures/types";
import { formatRawAsISO, formatRawAsLocal } from "../core/utils/datetime";
import { ReactComponent as ChevronDown } from "../img/icons/chevron-down.svg";
import logo from "../img/pl-logo-lion.svg";
import pattern2Crop184 from "./../img/pattern/pattern-2-crop-184.png";
import pattern2Crop90 from "./../img/pattern/pattern-2-crop-90.png";
import { ButtonHyperlink } from "./ButtonLink";
import Fixture from "./Fixture";
import FixtureDetailedStats from "./FixtureDetailedStats";
import AddToCalendar from "./icons/AddToCalendar";
import {
  EventPager,
  NextButton,
  NextLink,
  PagerItem,
  PagerItemNext,
  PreviousButton,
  PreviousLink,
} from "./Pager";
import { GradBorderBox } from "./styles";
import TabHeading from "./TabHeading";
import TabPanel from "./tabs/TabPanel";
import Tabs from "./tabs/Tabs";
import { gradientBorder, NewTabCopy } from "./Utils";

const StyledFixtures = styled.section`
  margin: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[1]};
  background-repeat: no-repeat;
  background-position: right top, 0, 0;
  box-shadow: 0 8px 12px 0 ${rgba("#6B6B6B", 0.08)};
  background-image: url(${pattern2Crop90}),
    linear-gradient(to bottom, ${rgba("white", 0)} 80px, white 160px),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.lightBlue},
      ${({ theme }) => theme.colors.darkBlue}
    );

  ${hiDPI(2)} {
    background-image: url(${pattern2Crop184}),
      linear-gradient(to bottom, ${rgba("white", 0)} 80px, white 160px),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-size: 90px 60px, auto, auto;

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      margin-right: 0;
      margin-left: 0;
    }
  }
`;

const FixturesHeader = styled.div`
  ${gradientBorder}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.space[3]} ${theme.space[2]} ${theme.space[2]}`};
`;

const Heading = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2.4rem;
  }
`;

const FixtureLogo = styled.img`
  ${size(32)}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(52)}
  }
`;

const EventHeading = styled.h3`
  margin: 1.2rem ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: center;
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }
`;

const Deadline = styled.time`
  @media (max-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${hideVisually}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-left: 0.6rem;
    font-family: ${({ theme }) => theme.fonts.regular};
  }
`;

const Note = styled.p`
  padding: ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
  }
`;

const FixtureDay = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      ${rgba("white", 0)} 0%,
      ${({ theme }) => theme.colors.lightGrey} 15%,
      ${({ theme }) => theme.colors.lightGrey} 49%,
      ${({ theme }) => theme.colors.lightGrey} 85%,
      ${rgba("white", 0)} 100%
    );
  }
`;

const FixtureList = styled.ul`
  margin: 0 0 ${({ theme }) => theme.space[4]};
  padding: 0;
  font-size: 1rem;
  list-style: none;
`;

const FixtureItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.primary, 0.08)};
`;

const FixtureButton = styled.button<IStyledFixtureStatsProps>`
  display: flex;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: left;
  cursor: pointer;

  ${(props) =>
    props.isOpen &&
    css`
      background-image: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.fantasy},
        ${({ theme }) => theme.colors.lightBlue}
      );

      ${IconWrap} {
        background-color: ${({ theme }) => theme.colors.primary};
      }

      svg {
        transform: rotate(180deg);
        fill: white;
      }
    `}
`;

const IconWrap = styled.span`
  align-self: stretch;
  background-color: #f8f8f8;
  padding: 1.7rem ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 2.1rem ${({ theme }) => theme.space[3]};
  }
`;

const FixtureStatsWrap = styled.div<IStyledFixtureStatsProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding-top: ${(props) => props.theme.space[3]};
  border-width: 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.primary};
`;

const FixtureSummaryStats = styled.ul`
  padding: ${({ theme }) => `${theme.space[3]} 5%`};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding-right: 15%;
    padding-left: 15%;
  }
`;

const FixtureStat = styled.li`
  margin-bottom: ${({ theme }) => theme.space[4]};
  box-shadow: 0 8px 12px 0 rgba(135, 135, 135, 0.08);
  border-radius: ${({ theme }) => theme.radii[0]};
`;

const FixtureStatHeading = styled.h5`
  padding: 0.3rem ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => `${theme.radii[0]} ${theme.radii[0]} 0 0`};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;
`;

const FixtureStatBody = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[2]};
`;

const FixtureStatList = styled.ul`
  flex: 1;
  padding-right: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[2]};

  :first-child {
    border-right: 1px solid #e2e2e2;
    text-align: right;
  }
`;

const FixtureStatItem = styled.li`
  padding-top: ${({ theme }) => theme.space[1]};
  padding-bottom: ${({ theme }) => theme.space[1]};
  border-bottom: 1px solid #e2e2e2;
  font-size: ${({ theme }) => theme.fontSizes[2]};

  :last-child {
    border-bottom: 0;
  }
`;

const ElementButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[2]};

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface IFixtureStatsForTeamProps {
  elementsById: IElementsById;
  showElementDialog: (elementId: number) => void;
  stats: IFixtureStat[];
}

const FixtureStatsForTeam: React.FC<IFixtureStatsForTeamProps> = ({
  elementsById,
  showElementDialog,
  stats,
}) => (
  <FixtureStatList>
    {stats
      .filter((fs) => elementsById[fs.element])
      .map((fs) => (
        <FixtureStatItem key={fs.element}>
          <ElementButton onClick={(e: any) => showElementDialog(fs.element)}>
            {elementsById[fs.element].web_name}
          </ElementButton>{" "}
          ({fs.value})
        </FixtureStatItem>
      ))}
  </FixtureStatList>
);

interface IFixtureStatsProps {
  elementsById: IElementsById;
  fixture: IGroupFixture;
  id: string;
  showElementDialog: (elementId: number) => void;
  statsByIdentifier: Record<string, IElementStat>;
}

interface IStyledFixtureStatsProps {
  isOpen?: boolean;
}

type FixtureStatProps = IFixtureStatsProps & IStyledFixtureStatsProps;

const FixtureStats: React.FC<FixtureStatProps> = ({
  elementsById,
  fixture,
  id,
  isOpen = false,
  showElementDialog,
  statsByIdentifier,
}) => {
  // Our render stats should contain only stats with data and a maximum
  // of 5 per team of BPS
  const renderStats = fixture.stats
    .filter((stat) => stat.h.length || stat.a.length)
    .map((stat) =>
      stat.identifier === "bps"
        ? {
            identifier: stat.identifier,
            h: stat.h.slice(0, 5),
            a: stat.a.slice(0, 5),
          }
        : stat
    );
  return (
    <FixtureStatsWrap isOpen={isOpen} id={id}>
      <Tabs>
        <TabPanel label="Match Details" link="match">
          <GradBorderBox>
            <FixtureSummaryStats>
              {renderStats.map((stat) => (
                <FixtureStat key={stat.identifier}>
                  <FixtureStatHeading>
                    {statsByIdentifier[stat.identifier].label}
                  </FixtureStatHeading>
                  <FixtureStatBody>
                    <FixtureStatsForTeam
                      stats={stat.h}
                      elementsById={elementsById}
                      showElementDialog={showElementDialog}
                    />
                    <FixtureStatsForTeam
                      stats={stat.a}
                      elementsById={elementsById}
                      showElementDialog={showElementDialog}
                    />
                  </FixtureStatBody>
                </FixtureStat>
              ))}
            </FixtureSummaryStats>
          </GradBorderBox>
        </TabPanel>
        <TabPanel label="Player Stats" link="players">
          <GradBorderBox>
            <FixtureDetailedStats fixture={fixture} />
          </GradBorderBox>
        </TabPanel>
      </Tabs>
    </FixtureStatsWrap>
  );
};

// Used to generate unique ids for collapsible content / aria controls
let lastId = 0;
const newId = (prefix = "ism-id") => `${prefix}${++lastId}`;

interface IOwnProps {
  eventId?: number;
  useLinks?: boolean;
}

interface IPropsFromDispatch {
  fetchFixturesForEvent: (eventId: number) => void;
  showElementDialog: (elementId: number) => void;
}

interface IPropsFromState {
  currentEvent: IEvent | null;
  elementsById: IElementsById;
  eventsById: IEventsById;
  groupedFixturesByEvent: IGroupedFixturesByEvent;
  nextEvent: IEvent | null;
  statsByIdentifier: Record<string, IElementStat>;
}

type Props = IOwnProps & IPropsFromDispatch & IPropsFromState;

interface IState {
  eventId: number;
  isOpen: Record<string, boolean>;
}

class Fixtures extends React.Component<Props, IState> {
  public id = "";
  public constructor(props: Props) {
    super(props);
    let eventId = 0;
    if (props.eventId) {
      eventId = props.eventId;
    } else if (props.currentEvent && !props.currentEvent.finished) {
      // Current gw not finished
      eventId = props.currentEvent.id;
    } else if (props.nextEvent) {
      // Next gw
      eventId = props.nextEvent.id;
    } else if (props.currentEvent) {
      // Current gw. This will only happen one the last gw of the season
      // has finished
      eventId = props.currentEvent.id;
    }
    this.state = { eventId, isOpen: {} };
  }

  public componentDidMount() {
    this.id = newId("ism-collapsible-");
    this.props.fetchFixturesForEvent(this.state.eventId);
  }

  public changeEvent = (eventId: number) => {
    this.props.fetchFixturesForEvent(eventId);
    this.setState({ eventId });
  };

  public toggle = (fixtureId: number) => {
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [fixtureId]: !this.state.isOpen[fixtureId],
      },
    });
  };

  public render() {
    const {
      elementsById,
      eventsById,
      groupedFixturesByEvent,
      showElementDialog,
      statsByIdentifier,
      useLinks = false,
    } = this.props;
    const { eventId } = this.state;
    const event = eventsById[eventId];
    if (!event) {
      return null;
    }

    const eventFixtureGroups = groupedFixturesByEvent[eventId] || [];
    return (
      <StyledFixtures>
        <header>
          <FixturesHeader>
            <Heading>
              <FixtureLogo src={logo} alt="Premier League" />{" "}
              <span>Fixtures</span>
            </Heading>
            <ButtonHyperlink
              href="https://pl.ecal.com/"
              target="_blank"
              rel="noopener noreferrer"
              variant="light"
            >
              <Flex alignItems="center">
                <Box mr="0.8rem">
                  <AddToCalendar />
                </Box>
                Sync to Calendar
                <NewTabCopy />
              </Flex>
            </ButtonHyperlink>
          </FixturesHeader>
          <EventHeading role="status" aria-live="polite">
            <span>{event.name}:</span>
            <Deadline dateTime={formatRawAsISO(event.deadline_time)}>
              {formatRawAsLocal(event.deadline_time)}
            </Deadline>
          </EventHeading>
          <EventPager>
            {eventsById[eventId - 1] && (
              <PagerItem>
                {useLinks ? (
                  <PreviousLink
                    to={`/fixtures/${eventId - 1}`}
                    onClick={() => this.changeEvent(eventId - 1)}
                  />
                ) : (
                  <PreviousButton
                    onClick={() => this.changeEvent(eventId - 1)}
                  />
                )}
              </PagerItem>
            )}
            {eventsById[eventId + 1] && (
              <PagerItemNext>
                {useLinks ? (
                  <NextLink
                    to={`/fixtures/${eventId + 1}`}
                    onClick={() => this.changeEvent(eventId + 1)}
                  />
                ) : (
                  <NextButton onClick={() => this.changeEvent(eventId + 1)} />
                )}
              </PagerItemNext>
            )}
          </EventPager>
        </header>
        <Note>
          All times are shown in your <strong>local time</strong>
        </Note>
        {eventFixtureGroups.map((group) => (
          <FixtureDay key={group.date.toISOString()}>
            <Box mb={2}>
              <TabHeading
                title={
                  <time dateTime={group.date.toISOString()}>
                    {format(group.date, "EEEE d MMMM yyyy")}
                  </time>
                }
                isCentered={true}
              />
            </Box>
            <FixtureList>
              {group.fixtures.map((f) => (
                <React.Fragment key={f.id}>
                  {f.started && f.team_h_score !== null ? (
                    <FixtureItem>
                      <FixtureButton
                        onClick={() => this.toggle(f.id)}
                        isOpen={this.state.isOpen[f.id]}
                        aria-expanded={this.state.isOpen[f.id] ? true : false}
                        aria-controls={this.id}
                      >
                        <IconWrap>
                          <ChevronDown />
                        </IconWrap>
                        <Fixture fixture={f} />
                      </FixtureButton>
                      <FixtureStats
                        elementsById={elementsById}
                        fixture={f}
                        showElementDialog={showElementDialog}
                        statsByIdentifier={statsByIdentifier}
                        isOpen={this.state.isOpen[f.id]}
                        id={this.id}
                        aria-hidden={!this.state.isOpen}
                      />
                    </FixtureItem>
                  ) : (
                    <FixtureItem>
                      <Fixture fixture={f} />
                    </FixtureItem>
                  )}
                </React.Fragment>
              ))}
            </FixtureList>
          </FixtureDay>
        ))}
      </StyledFixtures>
    );
  }
}

export { Fixtures as FixturesTest };

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => ({
  currentEvent: getCurrentEvent(state),
  elementsById: getElementsById(state),
  eventsById: getEventsById(state),
  groupedFixturesByEvent: getGroupedFixturesByEvent(state),
  nextEvent: getNextEvent(state),
  statsByIdentifier: getElementStatsByIdentifier(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchFixturesForEvent: (eventId) => dispatch(fetchFixtures(eventId)),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fixtures);

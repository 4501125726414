import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "../../../core/store";
import { unbanLeagueEntry } from "../../../core/store/leagues/thunks";
import {
  IBanEntryData,
  IPrivateLeagueAdmin,
} from "../../../core/store/leagues/types";
import Button from "../../Button";
import SubHeading from "../../SubHeading";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

interface IPropsFromDispatch {
  unban: (leagueId: number, data: IBanEntryData) => void;
}

type Props = IOwnProps & IPropsFromDispatch;

class RemoveBan extends React.Component<Props> {
  public render() {
    if (!this.props.league.bans.length) {
      return null;
    }
    const { league, unban } = this.props;
    return (
      <>
        <SubHeading>Remove League Suspensions</SubHeading>
        <p>
          If a player has a suspension removed, they will need to join the
          league again.
        </p>
        <ul>
          {league.bans.map((b) => (
            <li key={b.entry}>
              {b.player_name}
              <Button onClick={() => unban(league.id, { entry: b.entry })}>
                Remove suspension
              </Button>
            </li>
          ))}
        </ul>
      </>
    );
  }
}
export { RemoveBan as RemoveBanTest };

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  unban: (leagueId: number, data: IBanEntryData) =>
    dispatch(unbanLeagueEntry(leagueId, data)),
});

export default connect(null, mapDispatchToProps)(RemoveBan);

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import SaveContext from "../../contexts/SaveContext";
import { getNextEvent } from "../../core/store/events/reducers";
import { getSquadError } from "../../core/store/squad/reducers";
import { createEntry } from "../../core/store/squad/thunks";
import { ICreateEntryData } from "../../core/store/squad/types";
import { getTeams } from "../../core/store/teams/reducers";
import Alert from "../Alert";
import Button from "../Button";
import Dialog from "../Dialog";
import {
  CheckboxField,
  FieldWrap,
  InputField,
  SelectField,
} from "../FieldRenderers";
import { NewTabCopy } from "../Utils";
import { getCodeFromError } from "./helpers";

interface IProps {
  handleHide: () => void;
}
interface IState extends ICreateEntryData {
  favourite_team: number;
}

const CreateEntryDialog: React.FC<IProps> = ({ handleHide }) => {
  const { setSaveState } = React.useContext(SaveContext);
  const [state, setState] = useState<IState>({
    email: false,
    favourite_team: 0,
    name: "",
    terms_agreed: false,
  });

  const error = useSelector(getSquadError);
  const nextEvent = useSelector(getNextEvent);
  const teams = useSelector(getTeams);

  const requiredFields: Array<keyof IState> = [
    "favourite_team",
    "name",
    "terms_agreed",
  ];

  const dispatch = useDispatch();

  const apiDataFromState = () => ({
    email: state.email,
    favourite_team: state.favourite_team > 0 ? state.favourite_team : null,
    name: state.name,
    terms_agreed: state.terms_agreed,
  });

  const handleFaveChange = (e: React.FormEvent<HTMLSelectElement>) =>
    setState({ ...state, favourite_team: parseInt(e.currentTarget.value, 10) });

  const handleNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    setState({ ...state, name: e.currentTarget.value });

  const handleTermsChange = (e: React.FormEvent<HTMLInputElement>) =>
    setState({
      ...state,
      terms_agreed: e.currentTarget.checked ? true : false,
    });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValid()) {
      dispatch(createEntry(apiDataFromState()));
      setSaveState("initial");
    }
  };

  const isValid = () => requiredFields.every((e) => Boolean(state[e]));

  const errorCode = getCodeFromError(error);
  if (error) {
    const handledErrorCodes = ["price_changed"];
    if (handledErrorCodes.indexOf(errorCode) === -1) {
      window.location.reload();
      return null;
    }
  }
  if (!nextEvent) {
    return (
      <Dialog closeDialog={handleHide}>
        <Dialog.Header closeDialog={handleHide}>Game over</Dialog.Header>
        <Dialog.Body isPadded={true}>
          <p>
            This game has now finished. Thanks for your interest, come back soon
            to select your squad for next season's game.
          </p>
        </Dialog.Body>
      </Dialog>
    );
  }

  return (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>Enter squad</Dialog.Header>
      <Dialog.Body isPadded={true}>
        <form onSubmit={handleSubmit}>
          <FieldWrap>
            <InputField
              id="entryName"
              name="entryName"
              label="Team name"
              hint="Maximum 20 characters"
              onChange={handleNameChange}
              value={state.name}
              maxLength={20}
            />
          </FieldWrap>
          <FieldWrap>
            <SelectField
              id="entryFave"
              name="entryFave"
              label="Favourite club"
              onChange={handleFaveChange}
              value={state.favourite_team}
            >
              <option value={0} aria-selected={state.favourite_team === 0}>
                Choose a club...
              </option>
              {teams.map((tm) => (
                <option
                  value={tm.id}
                  key={tm.id}
                  aria-selected={state.favourite_team === tm.id}
                >
                  {tm.name}
                </option>
              ))}
              <option value={-1} aria-selected={state.favourite_team === -1}>
                None
              </option>
            </SelectField>
          </FieldWrap>
          <FieldWrap>
            <CheckboxField
              id="entryTerms"
              name="entryTerms"
              label={
                <>
                  I agree to the{" "}
                  <a
                    href="/help/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                    <NewTabCopy />
                  </a>
                  .
                </>
              }
              onChange={handleTermsChange}
              checked={state.terms_agreed}
            />
          </FieldWrap>
          {errorCode && (
            <Box mb={2}>
              <Alert type="error">
                {errorCode === "price_changed" &&
                  "Unable to enter squad as player prices have changed. Please refresh the page and try again."}
              </Alert>
            </Box>
          )}
          <Button type="submit" disabled={!isValid()}>
            Enter squad
          </Button>
        </form>
      </Dialog.Body>
    </Dialog>
  );
};

export { CreateEntryDialog as CreateEntryDialogTest };
export default CreateEntryDialog;

import React from "react";

type Props = {
  shortColor: string;
};

const shorts: React.FunctionComponent<Props> = ({ shortColor }) => (
  <>
    <path
      id="shorts_colour"
      data-name="shorts colour"
      d="M434.1 458s-2.1-101.2 6.4-155.7 25-111.1 25-111.1l3-13.4s35.4-12.4 104.8-12.4a768.5 768.5 0 0 1 103.7 7.5l7.3 20.4s22.2 69.5 26.5 141.7 0 150 0 150-24.1 22.6-56.6 21.2-59.4-14.9-59.4-14.9-1.4-27.6-7.1-48.1-11.8-39.6-11.8-39.6-10.1 17.7-12.1 31.5-3.6 33.6-3.6 33.6-36.1 7.8-65.8 7.8-60.3-18.5-60.3-18.5z"
      fill={shortColor}
    />
    <path
      id="shorts_grey_tint_fill"
      data-name="shorts grey tint fill"
      opacity="0.2"
      d="M468.4 179.2s10.6-13.8 102.4-13.8 106.2 7.5 106.2 7.5-65.8 22-103.7 22-104.9-15.7-104.9-15.7z"
    />
    <path
      style={{
        fill: "none",
        stroke: "#94948d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "4px",
      }}
      d="M710.8 334.9c-4.2-72.2-27.2-143.7-27.2-143.7l-6.6-18.3s-14.3-7.4-106.2-7.4-102.4 13.8-102.4 13.8-20.9 77.7-28 123.1c-8.4 54.4-6.3 155.6-6.3 155.6s30.4 18.4 60.2 18.4 65.8-7.8 65.8-7.8 1.4-18 3.2-30.6c2.2-15 12.5-34.5 12.5-34.5s6.2 19.1 11.8 39.6 7.1 48.1 7.1 48.1 26.9 13.4 59.4 14.9 56.6-21.2 56.6-21.2 4.3-77.8.1-150z"
    />
    <path
      style={{
        fill: "none",
        stroke: "#94948d",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "4px",
      }}
      d="M469.9 179.2c-.5 2.1-5.7 14.4-5.7 16.5s76.8 18.9 111 17.9 108.3-22.3 108.3-22.3l-6.5-18.4s-66.9 22-102.2 22-104.9-15.7-104.9-15.7z"
      id="Shorts_waistband"
      data-name="Shorts waistband"
    />
  </>
);

export default shorts;

import React from "react";
import { Flex } from "rebass/styled-components";
import { IElement } from "../../core/store/elements/types";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
} from "../icons/Chevrons";
import PaginatorButton from "../paginator/PaginatorButton";

export const paginate = (
  elements: IElement[],
  page: number,
  pageSize: number
) => {
  const start = (page - 1) * pageSize;
  return {
    data: elements.slice(start, start + pageSize),
    totalPages: Math.ceil(elements.length / pageSize),
  };
};

interface IProps {
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
}

const Paginator: React.FunctionComponent<IProps> = ({
  page,
  setPage,
  totalPages,
}) => (
  <Flex justifyContent="space-between" alignItems="center" p={2}>
    <PaginatorButton
      actionMe={() => setPage(1)}
      label="First"
      disabled={page === 1}
      isSmall={true}
    >
      <ChevronDoubleLeft />
    </PaginatorButton>
    <PaginatorButton
      actionMe={() => setPage(page - 1)}
      label="Previous"
      disabled={page === 1}
    >
      <ChevronLeft />
    </PaginatorButton>
    <div role="status" aria-live="polite">
      <strong>{page}</strong> of {totalPages}
    </div>
    <PaginatorButton
      actionMe={() => setPage(page + 1)}
      label="Next"
      disabled={page === totalPages}
    >
      <ChevronRight />
    </PaginatorButton>
    <PaginatorButton
      actionMe={() => setPage(totalPages)}
      label="Last"
      disabled={page === totalPages}
      isSmall={true}
    >
      <ChevronDoubleRight />
    </PaginatorButton>
  </Flex>
);

export default Paginator;

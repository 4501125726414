import { navigate } from "@reach/router";
import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import useFormHook from "../core/hooks/useFormHook";
import { RootState, ThunkDispatch } from "../core/store";
import { getEntry } from "../core/store/entries/reducers";
import { fetchEntrySummary, updateEntry } from "../core/store/entries/thunks";
import { IEntry, IUpdateEntryData } from "../core/store/entries/types";
import { getEvents } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getPlayerData } from "../core/store/player/reducers";
import { ILoggedInPlayer } from "../core/store/player/types";
import { getTeams } from "../core/store/teams/reducers";
import { ITeam } from "../core/store/teams/types";
import { formatRawAsLocal } from "../core/utils/datetime";
import Button from "./Button";
import { FieldWrap, InputField, SelectField } from "./FieldRenderers";
import HelmetHead from "./HelmetHead";
import ColorPicker from "./kit/ColorPicker";
import SponsorText from "./kit/SponsorText";
import { Main, Wrapper } from "./Layout";
import SubHeading from "./SubHeading";
import Title from "./Title";
const Kit = React.lazy(() => import("./kit/Kit"));

const Fieldset = styled.fieldset`
  padding: 2rem 0;
  margin: 0;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  :first-of-type {
    padding-top: 0;
  }
  :last-of-type {
    border-bottom: 0;
  }
`;

const KitController = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-direction: row-reverse;
  }
`;

const KitControllerChildren = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 1 50%;
  }
`;

const KitWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: 10rem;
  }
`;

const SectionHeader = styled.h3`
  margin-bottom: 2rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.5rem;
`;

const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

export type ShirtValues = "plain" | "stripes" | "hoops";

const shirtOptions: { label: string; value: ShirtValues }[] = [
  { label: "Plain", value: "plain" },
  { label: "Stripes", value: "stripes" },
  { label: "Hoops", value: "hoops" },
];

export type SockValues = "plain" | "hoops";

const sockOptions: { label: string; value: SockValues }[] = [
  { label: "Plain", value: "plain" },
  { label: "Hoops", value: "hoops" },
];

export type SponsorValues =
  | "none"
  | "pl-inspires"
  | "pl-kicks"
  | "pl-primary-stars"
  | "rainbow-laces"
  | "no-racism"
  | "ukraine";

const sponsorOptions: { label: string; value: SponsorValues }[] = [
  { label: "None", value: "none" },
  { label: "Premier League Inspires", value: "pl-inspires" },
  { label: "Premier League Kicks", value: "pl-kicks" },
  { label: "Premier League Primary Stars", value: "pl-primary-stars" },
  { label: "Rainbow Laces", value: "rainbow-laces" },
  { label: "No Room For Racism", value: "no-racism" },
  { label: "Football Stands Together", value: "ukraine" },
];

type KitData = {
  kit_shirt_type: ShirtValues;
  kit_shirt_base: string;
  kit_shirt_sleeves: string;
  kit_shirt_secondary: string;
  kit_shirt_logo: SponsorValues;
  kit_shorts: string;
  kit_socks_type: SockValues;
  kit_socks_base: string;
  kit_socks_secondary: string;
};

type KitState = {
  "ism-sponsor-logo": SponsorValues;
  "ism-shirt-style": ShirtValues;
  "ism-sock-style": SockValues;
  "ism-shirt-color": string;
  "ism-shirt-style-color": string;
  "ism-sleeve-color": string;
  "ism-short-color": string;
  "ism-sock-color": string;
  "ism-sock-style-color": string;
};

export type FormState = KitState & {
  "ism-team-name": string;
  "ism-fav-club": number;
  "ism-email": boolean;
};

export const kitFromEntry = (entry: IEntry): KitState => {
  const defaultHexColor = "#E1E1E1";
  let kit: KitData;
  const defaultKit: KitState = {
    "ism-shirt-style": "plain",
    "ism-sponsor-logo": "none",
    "ism-sock-style": "plain",
    "ism-shirt-color": defaultHexColor,
    "ism-sleeve-color": defaultHexColor,
    "ism-short-color": defaultHexColor,
    "ism-sock-color": defaultHexColor,
    "ism-shirt-style-color": defaultHexColor,
    "ism-sock-style-color": defaultHexColor,
  };
  if (!entry.kit) {
    return defaultKit;
  }
  try {
    kit = JSON.parse(entry.kit);
  } catch (error) {
    return defaultKit;
  }
  return {
    "ism-shirt-style": kit.kit_shirt_type,
    "ism-sponsor-logo": kit.kit_shirt_logo,
    "ism-sock-style": kit.kit_socks_type,
    "ism-shirt-color": kit.kit_shirt_base,
    "ism-sleeve-color": kit.kit_shirt_sleeves,
    "ism-short-color": kit.kit_shorts,
    "ism-sock-color": kit.kit_socks_base,
    "ism-shirt-style-color": kit.kit_shirt_secondary,
    "ism-sock-style-color": kit.kit_socks_secondary,
  };
};

export const initState = (entry: IEntry): FormState => ({
  ...kitFromEntry(entry),
  "ism-team-name": entry.name,
  "ism-fav-club": entry.favourite_team || -1,
  "ism-email": false,
});

type Props = {
  entry: IEntry;
  events: IEvent[];
  teams: ITeam[];
  updateEntry: (data: IUpdateEntryData) => void;
};

const EntryUpdate: React.FunctionComponent<Props> = ({
  entry,
  events,
  teams,
  updateEntry,
}) => {
  const [
    formState,
    updateState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateCheckboxState,
    updateColorState,
    updateSelectState,
  ] = useFormHook<FormState>(initState(entry));
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fave = Number(formState["ism-fav-club"]);
    updateEntry({
      name: formState["ism-team-name"],
      kit: JSON.stringify({
        kit_shirt_type: formState["ism-shirt-style"],
        kit_shirt_base: formState["ism-shirt-color"],
        kit_shirt_sleeves: formState["ism-sleeve-color"],
        kit_shirt_secondary: formState["ism-shirt-style-color"],
        kit_shirt_logo: formState["ism-sponsor-logo"],
        kit_shorts: formState["ism-short-color"],
        kit_socks_type: formState["ism-sock-style"],
        kit_socks_base: formState["ism-sock-color"],
        kit_socks_secondary: formState["ism-sock-style-color"],
      }),
      favourite_team: fave === -1 ? null : fave,
      email: formState["ism-email"],
    });
  };
  return (
    <Wrapper>
      <HelmetHead
        title="Fantasy Football Team Details | Fantasy Premier League"
        description="To view your team details including team name and shirt colour, visit the official website of the Premier League."
      />
      <Main>
        <Box mx={2}>
          <form onSubmit={handleSubmit}>
            <Title>My Details</Title>
            <SubHeading>Team Details</SubHeading>
            <FieldWrap>
              <InputField
                id="ism-team-name"
                label="Team Name"
                onChange={updateState}
                hint="Maximum 20 characters"
                value={formState["ism-team-name"]}
                maxLength={20}
                disabled={entry.name_change_blocked}
              />
            </FieldWrap>
            <Box mb={4}>
              <p>
                {entry.name_change_blocked
                  ? "Your requested team name is currently pending moderation and can't be edited at this time."
                  : "Please think carefully before entering your team name. Teams with names that are deemed inappropriate or offensive may result in your account being deleted. Please refer to the Terms & Conditions of entry for more information."}
              </p>
            </Box>
            <SubHeading>Team Kit</SubHeading>
            <KitController>
              <KitControllerChildren>
                <Suspense fallback={<p>Kit to go here</p>}>
                  <KitWrapper>
                    <Kit
                      shirtColor={formState["ism-shirt-color"]}
                      shirtStyle={formState["ism-shirt-style"]}
                      shirtStyleColor={formState["ism-shirt-style-color"]}
                      shortColor={formState["ism-short-color"]}
                      sleeveColor={formState["ism-sleeve-color"]}
                      sockColor={formState["ism-sock-color"]}
                      sockStyle={formState["ism-sock-style"]}
                      sockStyleColor={formState["ism-sock-style-color"]}
                      sponsorLogo={formState["ism-sponsor-logo"]}
                    />
                  </KitWrapper>
                </Suspense>
              </KitControllerChildren>
              <KitControllerChildren>
                <Fieldset>
                  <SectionHeader>Shirt</SectionHeader>
                  <TwoColumns>
                    <ColorPicker
                      name="ism-shirt-color"
                      label="Shirt Color"
                      onChange={updateColorState}
                      color={formState["ism-shirt-color"]}
                    />
                    <ColorPicker
                      name="ism-sleeve-color"
                      label="Sleeve Color"
                      onChange={updateColorState}
                      color={formState["ism-sleeve-color"]}
                    />
                  </TwoColumns>
                  <FieldWrap>
                    <SelectField
                      id="ism-shirt-style"
                      label="Shirt Style"
                      onChange={updateSelectState}
                      value={formState["ism-shirt-style"]}
                    >
                      {shirtOptions.map((o) => (
                        <option value={o.value} key={o.label}>
                          {o.label}
                        </option>
                      ))}
                    </SelectField>
                    {formState["ism-shirt-style"] !== "plain" && (
                      <Box my={2}>
                        <ColorPicker
                          name="ism-shirt-style-color"
                          label="Shirt Style Color"
                          onChange={updateColorState}
                          color={formState["ism-shirt-style-color"]}
                        />
                      </Box>
                    )}
                  </FieldWrap>
                  <SelectField
                    id="ism-sponsor-logo"
                    label="Sponsor Logo"
                    onChange={updateSelectState}
                    value={formState["ism-sponsor-logo"]}
                  >
                    {sponsorOptions.map((o) => (
                      <option value={o.value} key={o.label}>
                        {o.label}
                      </option>
                    ))}
                  </SelectField>
                  <SponsorText
                    selectedSponsor={formState["ism-sponsor-logo"]}
                  />
                </Fieldset>
                <Fieldset>
                  <SectionHeader>Shorts/Socks</SectionHeader>
                  <TwoColumns>
                    <ColorPicker
                      name="ism-short-color"
                      label="Shorts"
                      onChange={updateColorState}
                      color={formState["ism-short-color"]}
                    />

                    <ColorPicker
                      name="ism-sock-color"
                      label="Socks"
                      onChange={updateColorState}
                      color={formState["ism-sock-color"]}
                    />
                  </TwoColumns>
                  <SelectField
                    id="ism-sock-style"
                    label="Sock Style"
                    onChange={updateSelectState}
                    value={formState["ism-sock-style"]}
                  >
                    {sockOptions.map((o) => (
                      <option value={o.value} key={o.label}>
                        {o.label}
                      </option>
                    ))}
                  </SelectField>
                  {formState["ism-sock-style"] !== "plain" && (
                    <Box my={2}>
                      <ColorPicker
                        name="ism-sock-style-color"
                        label="Socks"
                        onChange={updateColorState}
                        color={formState["ism-sock-style-color"]}
                      />
                    </Box>
                  )}
                </Fieldset>
              </KitControllerChildren>
            </KitController>
            <SubHeading>Favourite Club</SubHeading>
            <Box mb={4}>
              <p>
                Choose your favourite Premier League club if you would like to
                be entered into a supporters league. If you do not select an
                option before {formatRawAsLocal(events[0].deadline_time)}, you
                will not be entered into a supporters league.
              </p>
            </Box>
            <FieldWrap>
              <SelectField
                id="ism-fav-club"
                label="Favourite club"
                onChange={updateSelectState}
                value={formState["ism-fav-club"]}
              >
                {teams.map((tm) => (
                  <option
                    value={tm.id}
                    key={tm.id}
                    aria-selected={formState["ism-fav-club"] === tm.id}
                  >
                    {tm.name}
                  </option>
                ))}
                <option
                  value={-1}
                  aria-selected={formState["ism-fav-club"] === -1}
                >
                  None
                </option>
              </SelectField>
            </FieldWrap>
            {/* <h3>Subscribe To Email Newsletters</h3>
												      <FieldWrap>
															<CheckboxField
												          id="entryEmail"
												          label="I would like to receive updates about my fantasy team via email."
												          onChange={updateCheckboxState}
												          checked={formState["ism-email"]}
																	/>
																</FieldWrap> */}
            <Button type="submit">Update Details</Button>
          </form>
        </Box>
      </Main>
    </Wrapper>
  );
};

export { EntryUpdate as EntryUpdateTest };

interface IPropsFromState {
  entry: IEntry | null;
  events: IEvent[];
  player: ILoggedInPlayer;
  teams: ITeam[];
}

interface IPropsFromDispatch {
  fetchEntry: (entryId: number) => void;
  updateEntry: (data: IUpdateEntryData) => void;
}

type FetcherProps = IPropsFromState & IPropsFromDispatch;

class EntryUpdateFetcher extends React.Component<FetcherProps> {
  public componentDidMount() {
    this.props.fetchEntry(Number(this.props.player.entry));
  }
  public render() {
    return this.props.entry ? (
      <EntryUpdate
        entry={this.props.entry}
        events={this.props.events}
        teams={this.props.teams}
        updateEntry={this.props.updateEntry}
      />
    ) : null;
  }
}

const mapStateToProps = (state: RootState) => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  return {
    player,
    entry: getEntry(state, player.entry),
    events: getEvents(state),
    teams: getTeams(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntry: (entryId: number) => dispatch(fetchEntrySummary(entryId)),
  updateEntry: async (data: IUpdateEntryData) => {
    await dispatch(updateEntry(data))
      .then(() => navigate("/my-team"))
      .catch(() => {});
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EntryUpdateFetcher);

import React from "react";
import { SockValues } from "../EntryUpdate";

type Props = {
  sockColor: string;
  sockStyle: SockValues;
  sockStyleColor: string;
};

const Socks: React.FunctionComponent<Props> = ({
  sockColor,
  sockStyle,
  sockStyleColor,
}) => {
  const hoopsDisplay = sockStyle === "hoops" ? "inline" : "none";
  return (
    <>
      <path
        id="sock_left_colour"
        data-name="sock left colour"
        d="M791.3 453.1c-18 0-23.4-14.3-15.9-20.2s46.2-37.7 48.8-55.7-3.7-112.5-3.7-148.1 4.4-35.3 4.4-35.3a43.5 43.5 0 0 0 0-13.6c-1.2-7.4.5-18 .5-18s9.4-5.8 28-5.8 29.2 5.8 29.2 5.8 0 5.8 1.1 15.9-2.1 15.7-2.1 15.7 5.2 8.7 2.3 36.3-26.2 121-26.2 134.3 7.5 40.6 3.2 53.9c-2 6.4-27.1 10.9-30.3 17.8s-.6 17-39.3 17z"
        fill={sockColor}
      />
      <path
        id="sock_right_colour"
        data-name="sock right colour"
        d="M934.9 467.4c-12.7 0-24.9-3.2-18-20.2s19.1-29.7 20.7-37.7 10.1-63.7 7.4-85.5-15.3-77-14.8-87 1.1-37.7 1.1-37.7a91.8 91.8 0 0 1-2.7-16.2c-.5-8.2 3.2-21 3.2-21s8.9-6.3 28.7-6.3c22.6 0 26.5 6.3 26.5 6.3s5.8 28.1 4.8 32.9 2.7 17 .5 38.7-14.9 113.1-14.3 126.9 8 51.5 7.4 54.7-1.6 31.8-8.5 39.8-20.8 12.3-42 12.3z"
        fill={sockColor}
      />
      <path
        id="sock_stripe_left_top"
        data-name="sock stripe left top"
        d="M825.3 194.2c0 3.7 12.6 6.6 28.2 6.6s28.2-3 28.2-6.6l2 18.5c0 4-13.9 7.3-31.1 7.3s-31.1-3.3-31.1-7.3z"
        fill={sockStyleColor}
        display={hoopsDisplay}
      />
      <path
        id="sock_stripe_left_bottom"
        data-name="sock stripe left bottom"
        d="M882.5 232.6c0 4-13.6 7.2-30.5 7.2s-30.5-3.2-30.5-7.2v22c0 3.8 13 6.8 29.1 6.8s29.1-3.1 29.1-6.8z"
        fill={sockStyleColor}
        display={hoopsDisplay}
      />
      <path
        id="sock_stripe_right_top"
        d="M989.5 194.2c0 3.7-12.6 6.6-28.2 6.6s-28.2-3-28.2-6.6l-2 18.5c0 4 13.9 7.3 31.1 7.3s31.1-3.3 31.1-7.3z"
        fill={sockStyleColor}
        display={hoopsDisplay}
      />
      <path
        id="sock_stripe_right_bottom"
        d="M930.2 232.6c0 4 13.6 7.2 30.5 7.2s30.5-3.2 30.5-7.2v22c0 3.8-13 6.8-29.1 6.8s-29.1-3.1-29.1-6.8z"
        fill={sockStyleColor}
        display={hoopsDisplay}
      />
      <path
        id="sock_left"
        data-name="sock left"
        style={{
          fill: "none",
          stroke: "#94948d",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "4",
        }}
        d="M791.3 453.1c-18 0-23.4-14.3-15.9-20.2s46.2-37.7 48.8-55.7-3.7-112.5-3.7-148.1 4.4-35.3 4.4-35.3a43.5 43.5 0 0 0 0-13.6c-1.2-7.4.5-18 .5-18s9.4 5.2 28 5.2 29.2-5.2 29.2-5.2 0 5.8 1.1 15.9-2.1 15.7-2.1 15.7 5.2 8.7 2.3 36.3-26.2 121-26.2 134.3 7.5 40.6 3.2 53.9c-2 6.4-27.1 10.9-30.3 17.8s-.6 17-39.3 17z"
      />
      <path
        id="sock_right"
        data-name="sock right"
        style={{
          fill: "none",
          stroke: "#94948d",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "4",
        }}
        d="M934.9 467.4c-12.7 0-24.9-3.2-18-20.2s19.1-29.7 20.7-37.7 10.1-63.7 7.4-85.5-15.3-77-14.8-87 2.1-37.7 2.1-37.7a91.8 91.8 0 0 1-2.7-16.2c-.5-8.2 3.2-21 3.2-21s8.9 3.7 27.6 3.7c17.2 0 26.5-3.7 26.5-3.7s4.8 28.1 3.7 32.9 3.7 17 1.6 38.7-14.9 113.1-14.3 126.9 8 51.5 7.4 54.7-1.6 31.8-8.5 39.8-20.7 12.3-41.9 12.3z"
      />
      <ellipse
        id="sock_left_grey_tint_fill"
        data-name="sock left grey tint fill"
        color="#242424"
        opacity="0.2"
        cx="853.9"
        cy="162.1"
        rx="28.6"
        ry="5.8"
      />
      <ellipse
        id="sock_right_grey_tint_fill"
        color="#242424"
        opacity="0.2"
        cx="960.1"
        cy="161"
        rx="28.6"
        ry="5.8"
      />
      <g id="sock_tops" data-name="sock tops">
        <ellipse
          cx="853.9"
          cy="162.2"
          rx="28.6"
          ry="5.8"
          style={{
            fill: "none",
            stroke: "#94948d",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "4px",
          }}
        />
        <ellipse
          cx="959.9"
          cy="161"
          rx="26.8"
          ry="5.5"
          style={{
            fill: "none",
            stroke: "#94948d",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "4px",
          }}
        />
      </g>
    </>
  );
};

export default Socks;

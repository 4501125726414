import { createContext, useState } from "react";

export type ISaveState = "initial" | null;
interface ISaveContext {
  saveState: ISaveState;
  setSaveState: (value: ISaveState) => void;
}

const SaveContext = createContext<ISaveContext>({
  saveState: null,
  setSaveState: (value: ISaveState) => {},
});

export default SaveContext;

interface ISaveProviderProps {
  children: React.ReactNode;
}

export const SaveProvider: React.FC<ISaveProviderProps> = ({ children }) => {
  const [saveState, setSaveState] = useState<ISaveState>(null);

  return (
    <SaveContext.Provider
      value={{
        saveState,
        setSaveState,
      }}
    >
      {children}
    </SaveContext.Provider>
  );
};

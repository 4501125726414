import { size } from "polished";
import * as React from "react";
import styled from "styled-components/macro";
import { IElement } from "../core/store/elements/types";
import { ReactComponent as Info } from "../img/icons/info.svg";
import { ReactComponent as Status } from "../img/icons/status.svg";
import { VisuallyHidden } from "./Utils";

export const InfoIcon = styled.div`
  display: inline-block;
  padding: 2px;
  border-radius: 50%;
  background-color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 2px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 2px;
  }

  svg {
    ${size(10)}
    display: block;
    fill: ${(props) => props.theme.colors.primary};

    @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
      ${size(12)}
    }
  }
`;

const StyledStatus = styled(Status)<{ copnr: number }>`
  ${size(14)}
  display: block;
  margin: auto;
  fill: ${(props) => props.theme.colors.elementStatus[props.copnr].bg};
  color: ${(props) => props.theme.colors.elementStatus[props.copnr].color};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

interface IStatusLabel {
  copnr: number;
}

const StatusInfo: React.FC<IStatusLabel> = ({ copnr }) => {
  const copnrMessage = copnr
    ? `${copnr}% chance of playing`
    : "Unlikely to play";
  return (
    <>
      <StyledStatus title={copnrMessage} copnr={copnr} />
      <VisuallyHidden>{copnrMessage}</VisuallyHidden>
    </>
  );
};

interface IProps {
  element: IElement;
}

const ElementStatus: React.FC<IProps> = ({ element }) => {
  const copnr = element.chance_of_playing_next_round as number;
  return element.news ? (
    <StatusInfo copnr={copnr} />
  ) : (
    <InfoIcon>
      <Info />
    </InfoIcon>
  );
};

export default ElementStatus;

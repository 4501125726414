import { rgba } from "polished";
import styled from "styled-components/macro";
import { HistoryTotalsHeading } from "./ElementDialog/styles";
import Table from "./Table";

export const FixtureStatsTableWrap = styled.div`
  overflow-x: scroll;
`;

export const StyledFixtureStatsTable = styled(Table)`
  white-space: nowrap;
  background-color: #fff;

  td {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;

    :nth-of-type(2) {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }

  tbody,
  tfoot {
    th {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #fff;
    }

    ${HistoryTotalsHeading} {
      font-size: ${(props) => props.theme.fontSizes[2]};
    }
  }

  tfoot {
    th {
      font-weight: normal;
      text-align: right;
    }
  }
`;

export const GradBorderBox = styled.div`
  margin-top: ${(props) => props.theme.space[2]};
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      ${rgba("white", 0)} 0%,
      ${({ theme }) => theme.colors.lightGrey} 15%,
      ${({ theme }) => theme.colors.lightGrey} 49%,
      ${({ theme }) => theme.colors.lightGrey} 85%,
      ${rgba("white", 0)} 100%
    );
  }
`;

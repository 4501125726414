import { rgba } from "polished";
import styled, { css } from "styled-components/macro";

interface IOwnProps {
  variant?: string | null;
}

const StyledBench = styled.div`
  max-width: 410px;
  margin: auto;
  margin-top: ${({ theme }) => theme.space[2]};
  background-image: linear-gradient(to bottom, transparent 60%, white);

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: 620px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    max-width: none;
    padding: 0 ${({ theme }) => theme.space[2]};
  }
`;

const BenchInner = styled.div<IOwnProps>`
  padding: ${({ theme }) => theme.space[1]} 0;
  border-radius: ${({ theme }) => `${theme.radii[0]} ${theme.radii[0]}`} 0 0;
  background: ${rgba("white", 0.25)};
  background-color: rgba(114, 207, 159, 0.9);
  box-shadow: 0 8px 12px 0 ${rgba("black", 0.08)};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: ${({ theme }) => theme.space[3]} 0;
  }

  ${(props) =>
    props.variant === "bboost" &&
    css`
      box-shadow: 0 0 0 2px white,
        0 0 0 6px ${({ theme }) => theme.colors.lightBlue};
    `}
`;

const Bench: React.FC<IOwnProps> = ({ children, variant = null }) => (
  <StyledBench>
    <BenchInner variant={variant}>{children}</BenchInner>
  </StyledBench>
);

export default Bench;

import { RouteComponentProps } from "@reach/router";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementsById } from "../core/store/elements/reducers";
import { showElementSummary } from "../core/store/elements/thunks";
import { IElementsById } from "../core/store/elements/types";
import { getEntry, getTransfersForEntry } from "../core/store/entries/reducers";
import {
  fetchEntrySummary,
  fetchEntryTransfers,
} from "../core/store/entries/thunks";
import { IEntry, ITransfer } from "../core/store/entries/types";
import { getPlayerData } from "../core/store/player/reducers";
import { getLatestTransfers } from "../core/store/squad/reducers";
import { fetchLatestTransfers } from "../core/store/squad/thunks";
import Entry from "./Entry";
import HelmetHead from "./HelmetHead";
import { Main, Secondary, Wrapper } from "./Layout";
import Table from "./Table";
import Title from "./Title";
import { getShortNameFromId } from "../utils/events";

const DialogButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: inherit;
  text-decoration: none;
  text-align: left;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

type OwnProps = RouteComponentProps<{ entryId: string }>;

interface IPropsFromState {
  elementsById: IElementsById;
  entry: IEntry | null;
  latestTransfers: ITransfer[];
  mine: boolean;
  transfersHistory: ITransfer[];
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  fetchEntryTransfers: (entryId: number) => void;
  fetchLatestTransfers: () => void;
  showElementDialog: (elementId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

class TransfersHistory extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchEntrySummary(Number(this.props.entryId));
    this.props.fetchEntryTransfers(Number(this.props.entryId));
    if (this.props.mine) {
      this.props.fetchLatestTransfers();
    }
  }

  public render() {
    const {
      elementsById,
      entry,
      entryId,
      latestTransfers,
      showElementDialog,
      transfersHistory,
    } = this.props;

    return (
      <Wrapper>
        {entry && (
          <HelmetHead
            title="Fantasy Football Transfer History | Fantasy Premier League"
            description={`To view the transfer history of ${entry.player_first_name} ${entry.player_last_name}, along with the status of your Wildcard, visit the official website of the Premier League.`}
          />
        )}
        <Main>
          <Box mx={2}>
            <Title>Transfers</Title>
          </Box>
          {transfersHistory.length || latestTransfers.length ? (
            <>
              <Box mb={2}>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Time</th>
                      <th scope="col">In</th>
                      <th scope="col">Out</th>
                      <th scope="col">Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {latestTransfers.concat(transfersHistory).map((t) => (
                      <tr key={`${t.time}${t.element_in}`}>
                        <td>{moment(t.time).format("D MMM HH:mm")}</td>
                        <td>
                          <DialogButton
                            onClick={() =>
                              showElementDialog(elementsById[t.element_in].id)
                            }
                          >
                            {elementsById[t.element_in].web_name}
                          </DialogButton>
                        </td>
                        <td>
                          <DialogButton
                            onClick={() =>
                              showElementDialog(elementsById[t.element_out].id)
                            }
                          >
                            {elementsById[t.element_out].web_name}
                          </DialogButton>
                        </td>
                        <td>{getShortNameFromId(t.event)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
              <Box mx={2}>
                <p>
                  If you are not logged in or are viewing another player's team
                  then you will only be able to view transfers up to the last
                  deadline.
                </p>
              </Box>
            </>
          ) : (
            <Box mx={2}>
              <p>No transfers have been made yet for this team.</p>
            </Box>
          )}
        </Main>
        <Secondary>
          <Entry entryId={Number(entryId)} />
        </Secondary>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const entryId = Number(ownProps.entryId);
  const player = getPlayerData(state);
  let mine = player && player.entry && player.entry === entryId ? true : false;
  let latestTransfers = mine ? getLatestTransfers(state) : [];
  return {
    elementsById: getElementsById(state),
    entry: getEntry(state, entryId),
    latestTransfers,
    mine,
    transfersHistory: getTransfersForEntry(state, entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchEntryTransfers: (entryId: number) =>
    dispatch(fetchEntryTransfers(entryId)),
  fetchLatestTransfers: () => dispatch(fetchLatestTransfers()),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransfersHistory);

import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { updateElementControls } from "../../core/store/elements/actions";
import {
  getElementControls,
  getElementsFromControls,
} from "../../core/store/elements/reducers";
import {
  showElementSummary,
  updateElementControlsAndMaxCost,
} from "../../core/store/elements/thunks";
import {
  IElement,
  IElementControls,
  IElementDataFromControls,
} from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToMoney, isMoneyStat } from "../../core/utils/money";
import { getStatDetails } from "../../utils/stats";
import Alert from "../Alert";
import ElementFilter from "../element-controls/ElementFilter";
import ElementSort from "../element-controls/ElementSort";
import Paginator, { paginate } from "../element-controls/Paginator";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementRow, ElementTable } from "../ElementTable";
import { FieldCol, FieldCols } from "../FieldRenderers";
import HelmetHead from "../HelmetHead";
import { Main, Secondary, Wrapper } from "../Layout";
import NewsContentList from "../scout/NewsContentList";
import Title from "../Title";
import Tooltip, { TooltipLabel } from "../Tooltip";

const StatsStatus = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 7%;
  }
`;

const StatsElement = styled.th`
  width: 40%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 38%;
  }
`;

const StatsCost = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsSel = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsForm = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsPts = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

const StatsExtra = styled.th`
  width: 10%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 11%;
  }
`;

interface IState {
  page: number;
}

type OwnProps = RouteComponentProps<{ statName?: string }>;

interface IPropsFromState {
  currencyDivisor: number;
  elements: IElementDataFromControls;
  controls: IElementControls;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
  updateControls: (controls: IElementControls) => void;
  updateControlsAndMaxCost: (controls: IElementControls) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

class Statistics extends React.Component<Props, IState> {
  public state: IState = { page: 1 };

  public handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      filter: e.target.value,
    });
  };

  public handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setPage(1);
    this.props.updateControls({
      ...this.props.controls,
      sort: e.target.value,
    });
  };

  public setPage = (page: number) => this.setState({ page });

  public defaultStats: Array<keyof IElement> = [
    "now_cost",
    "selected_by_percent",
    "form",
    "total_points",
  ];

  public componentDidMount() {
    this.props.updateControlsAndMaxCost({
      ...this.props.controls,
      filter: "all",
      sort: this.props.statName || "total_points",
      search: "",
    });
  }

  public render() {
    const {
      controls,
      currencyDivisor,
      elements,
      teamsById,
      showElementDialog,
    } = this.props;
    const { page } = this.state;
    const { data, totalPages } = paginate(elements.data, page, 30);
    const extraStat =
      this.defaultStats.indexOf(controls.sort) === -1 ? controls.sort : "";
    const statDetail = getStatDetails(String(controls.sort));

    return (
      <Wrapper>
        <HelmetHead
          title="Fantasy Football Statistics | Fantasy Premier League"
          description="To view statistics for all Fantasy Premier League players, or select by position or team, visit the official website of the Premier League."
        />
        <Main>
          <Box mx={2}>
            <Title>Statistics</Title>
          </Box>
          <form>
            <FieldCols>
              <FieldCol>
                <ElementFilter handleFilterChange={this.handleFilterChange} />
              </FieldCol>
              <FieldCol>
                <ElementSort handleSortChange={this.handleSortChange} />
              </FieldCol>
            </FieldCols>
          </form>
          <Box mb="4" role="status" aria-live="polite">
            <Alert textAlign="left">
              {statDetail ? statDetail.description : ""}
            </Alert>
          </Box>
          <ElementTable>
            <thead>
              <tr>
                <StatsStatus scope="col">&nbsp;</StatsStatus>
                <StatsElement scope="col">Player</StatsElement>
                <StatsCost scope="col">Cost</StatsCost>
                <StatsSel scope="col">
                  <Tooltip content="Selected by %">
                    <TooltipLabel>Sel.</TooltipLabel>
                  </Tooltip>
                </StatsSel>
                <StatsForm scope="col">Form</StatsForm>
                <StatsPts scope="col">
                  <Tooltip content="Total points">
                    <TooltipLabel>Pts.</TooltipLabel>
                  </Tooltip>
                </StatsPts>
                {extraStat ? <StatsExtra scope="col">**</StatsExtra> : null}
              </tr>
            </thead>
            <tbody>
              {data.map((e) => (
                <ElementRow key={e.id}>
                  <td>
                    <ElementDialogButton elementId={e.id} variant="list" />
                  </td>
                  <td>
                    <ElementInTable
                      renderElementMenu={() => showElementDialog(e.id)}
                      element={e}
                      team={teamsById[e.team]}
                    />
                  </td>
                  <td>{integerToMoney(e.now_cost, currencyDivisor)}</td>
                  <td>{e.selected_by_percent}%</td>
                  <td>{e.form}</td>
                  <td>{e.total_points}</td>
                  {extraStat ? (
                    <td>
                      {isMoneyStat(extraStat)
                        ? integerToMoney(
                            e[extraStat] as number,
                            currencyDivisor
                          )
                        : e[extraStat]}
                    </td>
                  ) : null}
                </ElementRow>
              ))}
            </tbody>
          </ElementTable>
          <Paginator
            totalPages={totalPages}
            page={page}
            setPage={this.setPage}
          />
        </Main>
        <Secondary>
          <NewsContentList
            number={3}
            tag="FPL%20site%20HP"
            title="FPL Stats Explained"
          />
          <NewsContentList
            number={4}
            tag="Fantasy%20Football%20Scout"
            title="Latest FPL News"
          />
        </Secondary>
      </Wrapper>
    );
  }
}

export { Statistics as StatisticsTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  controls: getElementControls(state),
  currencyDivisor: 10,
  elements: getElementsFromControls(state),
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  updateControls: (controls) => dispatch(updateElementControls(controls)),
  updateControlsAndMaxCost: (controls) =>
    dispatch(updateElementControlsAndMaxCost(controls)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);

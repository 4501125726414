import debounce from "lodash/debounce";
import { hideVisually } from "polished";
import React from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import Button from "../Button";
import { NextIcon, PrevIcon } from "../icons/Chevrons";

const TableHeader = styled.header`
  max-width: 1180px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const TableTitleRow = styled.div`
  margin: 30px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const TableNavRow = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1180px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-left: 240px;
  }
`;

const TableNavButtonWrap = styled.div`
  width: 48%;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 25%;
  }
`;

const KeyWrapper = styled.div`
  display: flex;
`;

const KeyHeading = styled.h3`
  margin-top: 1rem;
  margin-right: 0.4rem;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 1.2rem;
`;

interface IKeyItem {
  difficulty: number;
}

const KeyItem = styled.span<IKeyItem>`
  display: inline-block;
  padding: 10px;
  background-color: ${({ theme, difficulty }) =>
    theme.colors.difficulties[difficulty].bg};
  color: ${({ theme, difficulty }) =>
    theme.colors.difficulties[difficulty].color};
`;

const KeyLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
`;

const KeyLabel = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 1.2rem;
`;

const KeyHide = styled.span`
  ${hideVisually}
`;

type Props = {
  title: string;
  showPrevBtn: boolean;
  showNextBtn: boolean;
  onHandlePrevClick: () => void;
  onHandleNextClick: () => void;
};

const FDRHeader: React.FC<Props> = ({
  title,
  showPrevBtn,
  showNextBtn,
  onHandlePrevClick,
  onHandleNextClick,
}) => {
  return (
    <TableHeader>
      <TableTitleRow>
        <Box mb={4}>
          <h2 data-testid="title">{title}</h2>
        </Box>
        <KeyWrapper data-testid="key">
          <div>
            <KeyHeading>FDR Key: </KeyHeading>
          </div>
          <div>
            <div>
              <KeyItem difficulty={1}>1</KeyItem>
              <KeyItem difficulty={2}>2</KeyItem>
              <KeyItem difficulty={3}>3</KeyItem>
              <KeyItem difficulty={4}>4</KeyItem>
              <KeyItem difficulty={5}>5</KeyItem>
            </div>
            <KeyLabels>
              <KeyHide>1 = </KeyHide>
              <KeyLabel>Easy</KeyLabel>
              <KeyHide>5 = </KeyHide>
              <KeyLabel>Hard</KeyLabel>
            </KeyLabels>
          </div>
        </KeyWrapper>
      </TableTitleRow>
      <TableNavRow>
        <TableNavButtonWrap>
          {showPrevBtn && (
            <Button
              data-testid="prev-btn"
              onClick={debounce(onHandlePrevClick, 250)}
              variant="light"
              fullwidth="true"
            >
              <PrevIcon />
              Previous GW
            </Button>
          )}
        </TableNavButtonWrap>
        <TableNavButtonWrap>
          {showNextBtn && (
            <Button
              data-testid="next-btn"
              onClick={debounce(onHandleNextClick, 250)}
              variant="light"
              fullwidth="true"
            >
              Next GW
              <NextIcon />
            </Button>
          )}
        </TableNavButtonWrap>
      </TableNavRow>
    </TableHeader>
  );
};

export default FDRHeader;

import styled from "styled-components/macro";
import { StyledButtonLink } from "./ButtonLink";
import { LinkStyles } from "./Link";

const Copy = styled.div`
  margin: 0 ${({ theme }) => theme.space[2]};
  h3,
  h4,
  h5,
  p,
  ul,
  ol,
  table {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  h6 {
    margin-bottom: ${({ theme }) => theme.space[1]};
  }

  ul,
  ol {
    padding-left: 4rem;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  li {
    line-height: 1.6;
  }

  a {
    ${LinkStyles}
  }

  /* Turn off underline on ButtonLinks */
  ${StyledButtonLink}:hover::after {
    height: 0;
  }
`;

export default Copy;

import { ellipsis, hideVisually, rgba } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";

export const Ellipsis = styled.div`
  ${ellipsis()}
`;

export const UnstyledButton = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;
  cursor: pointer;
`;

export const VisuallyHidden = styled.span`
  ${hideVisually}
`;

export const textGradient = css`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.blue},
    ${({ theme }) => theme.colors.fantasy}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

export const gradientBorder = css`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
      to right,
      ${rgba("white", 0)} 0%,
      ${rgba("white", 0.5)} 50%,
      ${rgba("white", 0)} 100%
    );
  }
`;

export const MatchValueBase = css`
  padding: ${(props) => props.theme.space[1]};
  border-radius: 2px;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
  text-align: center;
  line-height: 1;
`;

export const NewTabCopy: React.FC = () => (
  <VisuallyHidden> Opens in new tab</VisuallyHidden>
);

import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../../core/store";
import { getCode } from "../../../core/store/leagues/reducers";
import {
  fetchLeagueCode,
  regenerateLeagueCode,
} from "../../../core/store/leagues/thunks";
import { IPrivateLeagueAdmin } from "../../../core/store/leagues/types";
import Button from "../../Button";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

interface IPropsFromState {
  code: string | null;
}

interface IPropsFromDispatch {
  fetchCode: (leagueId: number) => void;
  regenerateCode: (leagueId: number) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch;

class Code extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.league.closed) {
      this.props.fetchCode(this.props.league.id);
    }
  }

  public render() {
    const { code, league, regenerateCode } = this.props;
    if (league.closed) {
      return null;
    }
    return (
      <Box mb={4}>
        <p>League code: {code}</p>
        <Button onClick={() => regenerateCode(league.id)}>Regenerate</Button>
      </Box>
    );
  }
}

export { Code as CodeTest };

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => ({
  code: getCode(state, ownProps.league.id),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchCode: (leagueId: number) => dispatch(fetchLeagueCode(leagueId)),
  regenerateCode: (leagueId: number) =>
    dispatch(regenerateLeagueCode(leagueId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Code);

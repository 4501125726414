import { getSuffixFromId, isElementFixtureHome } from "../../../utils/fixtures";
import { TeamName } from "./styles";
import { IFixtureOpponent } from "./types";

const FixtureOpponent: React.FC<IFixtureOpponent> = ({
  fixture,
  element,
  teamsById,
}) => {
  const isHome = isElementFixtureHome(element.team, fixture.team_h);
  const opponent = isHome
    ? teamsById[fixture.team_a]
    : teamsById[fixture.team_h];
  const suffix = getSuffixFromId(fixture.id, isHome);
  return (
    <span>
      <TeamName title={opponent.name}>
        <span>{opponent.short_name}</span>
      </TeamName>{" "}
      {suffix}
    </span>
  );
};

export default FixtureOpponent;

import React from "react";
import { useSelector } from "react-redux";
import { getTeamsById } from "../../core/store/teams/reducers";
import { PillRadioLabel, PillRadioInput } from "../pillRadioButton";
import { ITeamPillRadio } from "./types";

const TeamPillRadio: React.FC<ITeamPillRadio> = ({
  handleChange,
  teamId = 0,
}) => {
  const teamsById = useSelector(getTeamsById);
  const team = teamId ? teamsById[teamId] : null;
  return (
    <div>
      <PillRadioLabel>
        <PillRadioInput
          type="radio"
          name="club"
          value={team ? team.id : 0}
          defaultChecked={!team}
          onChange={handleChange}
        />
        <span>
          <span>{team ? team.name : "All"}</span>
        </span>
      </PillRadioLabel>
    </div>
  );
};

export default TeamPillRadio;

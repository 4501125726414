import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getActiveOrProposedTeamChipName } from "../../core/store/chips/reducers";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElement, IElementsById } from "../../core/store/elements/types";
import {
  getElementEventOpponents,
  getFixturesForNextEventByTeam,
} from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import { IPickProposed } from "../../core/store/my-team/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { BorderedCell, ElementRow, ElementTable } from "../ElementTable";
import TabHeading from "../TabHeading";
import { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import { StyledCaptain, StyledViceCaptain } from "./TableCaptains";

const MyTeamStatus = styled.th`
  width: 6%;
`;

const MyTeamCpt = styled.th`
  width: 4%;
`;

const MyTeamElement = styled.th`
  width: 32%;
`;

const MyTeamPos = styled.th`
  width: 12%;
`;

const MyTeamForm = styled.th`
  width: 10%;
`;

const MyTeamGW = styled.th`
  width: 10%;
`;

const MyTeamPts = styled.th`
  width: 12%;
`;

const MyTeamFix = styled.th`
  width: 14%;
`;

interface IMyTeamRowProps {
  chipInPlayName: string | null;
  pick: IPickProposed;
  element: IElement;
  elementTypesById: IElementTypesById;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  renderElementMenu: (index: number) => void;
  teamsById: ITeamsById;
}

const MyTeamRow: React.FC<IMyTeamRowProps> = ({
  chipInPlayName,
  pick,
  element,
  elementTypesById,
  fixturesForNextEventByTeam,
  renderElementMenu,
  teamsById,
}) => {
  const fixtures = fixturesForNextEventByTeam[element.team];
  const elementEventOpponents = useSelector((state: RootState) =>
    fixtures?.length && element
      ? getElementEventOpponents(state, element, fixtures)
      : []
  );

  return (
    <ElementRow variant={pick.subStatus}>
      <td>
        <ElementDialogButton elementId={element.id} variant="list" />
      </td>
      <td>
        {pick.is_captain &&
          (chipInPlayName === "3xc" ? (
            <StyledCaptain variant="3xc" />
          ) : (
            <StyledCaptain />
          ))}
        {pick.is_vice_captain &&
          (chipInPlayName === "3xc" ? (
            <StyledViceCaptain variant="3xc" />
          ) : (
            <StyledViceCaptain />
          ))}
      </td>
      <BorderedCell>
        <ElementInTable
          renderElementMenu={() => renderElementMenu(pick.element)}
          element={element}
          team={teamsById[element.team]}
        />
      </BorderedCell>
      <td>{elementTypesById[element.element_type].singular_name_short}</td>
      <td>{element.form}</td>
      <td>{element.event_points}</td>
      <td>{element.total_points}</td>
      <td>
        {elementEventOpponents.length ? (
          elementEventOpponents.map((opp) => <div>{opp}</div>)
        ) : (
          <>&nbsp;</>
        )}
      </td>
    </ElementRow>
  );
};

interface IOwnProps {
  picks: IPickProposed[];
  renderElementMenu: (index: number) => void;
  title: string;
}

interface IPropsFromState {
  chipInPlayName: string | null;
  elementsById: IElementsById;
  elementTypesById: IElementTypesById;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  teamsById: ITeamsById;
}

type Props = IOwnProps & IPropsFromState;

const MyTeamTable: React.FC<Props> = ({
  elementsById,
  picks,
  title,
  ...rest
}) => {
  return (
    <ScrollTable>
      <Box mx={2}>
        <TabHeading title={title} hasBorder={true} />
      </Box>
      <ElementTable>
        <thead>
          <tr>
            <MyTeamStatus scope="col">&nbsp;</MyTeamStatus>
            <MyTeamCpt scope="col">&nbsp;</MyTeamCpt>
            <MyTeamElement scope="col">&nbsp;</MyTeamElement>
            <MyTeamPos scope="col">
              <Tooltip content="Position">
                <TooltipLabel>Pos</TooltipLabel>
              </Tooltip>
            </MyTeamPos>
            <MyTeamForm scope="col">Form</MyTeamForm>
            <MyTeamGW scope="col">
              <Tooltip content="Gameweek points">
                <TooltipLabel>GW</TooltipLabel>
              </Tooltip>
            </MyTeamGW>
            <MyTeamPts scope="col">
              <Tooltip content="Total points">
                <TooltipLabel>Pts</TooltipLabel>
              </Tooltip>
            </MyTeamPts>
            <MyTeamFix scope="col">
              <Tooltip content="Fixture next Gameweek">
                <TooltipLabel>Fix</TooltipLabel>
              </Tooltip>
            </MyTeamFix>
          </tr>
        </thead>
        <tbody>
          {picks.map((p) => (
            <MyTeamRow
              key={p.element}
              pick={p}
              element={elementsById[p.element]}
              {...rest}
            />
          ))}
        </tbody>
      </ElementTable>
    </ScrollTable>
  );
};

export { MyTeamTable as MyTeamTableTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    chipInPlayName: getActiveOrProposedTeamChipName(state),
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    fixturesForNextEventByTeam: getFixturesForNextEventByTeam(state),
    teamsById: getTeamsById(state),
  };
};

export default connect(mapStateToProps)(MyTeamTable);

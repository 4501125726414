import { navigate } from "@reach/router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex } from "rebass";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getSettings } from "../../core/store/game/reducers";
import {
  getActiveEntryInLeague,
  getAutoJoinCode,
  getJoinPrivateLeagueError,
  getJoinPrivateLeagueSuccess,
  getLeagueFromCode,
} from "../../core/store/leagues/reducers";
import {
  checkLeagueCode,
  clearCode,
  joinPrivateLeague,
} from "../../core/store/leagues/thunks";
import { getPlayerData } from "../../core/store/player/reducers";
import Alert from "../Alert";
import Button from "../Button";
import Dialog from "../Dialog";
import { formatErrorMsg, getErrorType } from "./errors";

const ConfirmBtnWrapper = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.space[3]};
`;

const AutoJoinDialog: React.FC = () => {
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const code = useSelector((state: RootState) => getAutoJoinCode(state));
  const player = useSelector(getPlayerData);
  const error = useSelector((state: RootState) =>
    getErrorType(getJoinPrivateLeagueError(state))
  );
  const settings = useSelector((state: RootState) => getSettings(state));
  const joinedPrivateLeagueSuccess = useSelector((state: RootState) =>
    getJoinPrivateLeagueSuccess(state)
  );

  // Most important part, if it returns null, nothing renders
  const league = useSelector((state: RootState) =>
    code ? getLeagueFromCode(state, code) : null
  );

  // Check if entry is already apart of the league
  const isPlayerInLeague = useSelector((state: RootState) =>
    league
      ? getActiveEntryInLeague(
          state,
          league.id,
          league.scoring === "c" ? "classic" : "h2h"
        )
      : null
  );

  // If we have a code, try and fetch the associated league. If there is an
  // issue fetching the league clear the code from local storage so we don't
  // try again
  useEffect(() => {
    // If you're logged in check the code
    // You might be logged out and we don't want a 403 forbidden
    if (player && player.entry && code) {
      reduxDispatch(checkLeagueCode(code));
    }
  }, [player, code, reduxDispatch]);

  const handleHide = () => {
    reduxDispatch(clearCode());
  };

  const confirmJoinLeague = (code: string) => {
    reduxDispatch(joinPrivateLeague({ code }));
  };

  if (joinedPrivateLeagueSuccess && code && league) {
    handleHide();
    navigate(`/leagues/${league.id}/standings/${league.scoring}`);
    return null;
  }

  return league ? (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>
        Join League{league && `: ${league.name}`}
      </Dialog.Header>
      <Dialog.Body isPadded={true}>
        {isPlayerInLeague && (
          <>
            <Box mb={2}>
              <Alert type="error">You are already in this league</Alert>
            </Box>
          </>
        )}
        {!isPlayerInLeague && (
          <>
            {error ? (
              <>
                <Box mb={2}>
                  <Alert type="error">
                    {settings && formatErrorMsg(error, settings)}
                  </Alert>
                </Box>
              </>
            ) : (
              <>
                <Box mb={2}>
                  <p>Please press confirm to join league: {league.name}.</p>
                </Box>
                <Flex>
                  {code && (
                    <ConfirmBtnWrapper>
                      <Button onClick={() => confirmJoinLeague(code)}>
                        Confirm
                      </Button>
                    </ConfirmBtnWrapper>
                  )}
                  <Button onClick={handleHide}>Cancel</Button>
                </Flex>
              </>
            )}
          </>
        )}
      </Dialog.Body>
    </Dialog>
  ) : null;
};

export default AutoJoinDialog;

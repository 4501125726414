import React from "react";
import styled from "styled-components/macro";

import Logos from "./Logos";
import Shirt from "./Shirt";
import Socks from "./Socks";
import Shorts from "./Shorts";
import { ShirtValues, SponsorValues, SockValues } from "../EntryUpdate";

type Props = {
  shirtColor: string;
  shirtStyle: ShirtValues;
  shirtStyleColor: string;
  shortColor: string;
  sleeveColor: string;
  sockColor: string;
  sockStyle: SockValues;
  sockStyleColor: string;
  sponsorLogo: SponsorValues;
};

const SVGWrapper = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
`;

const Kit: React.FunctionComponent<Props> = (props) => (
  <SVGWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="ism-team-kit"
      data-name="ism-team-kit"
      viewBox="0 0 994.8 530.9"
      role="img"
      aria-labelledby="ism-kit-title ism-kit-desc"
    >
      <title id="ism-kit-title">Team Shirt, shorts and socks</title>
      <desc id="ism-kit-desc">My team kit design</desc>
      <Shirt {...props} />
      <Shorts {...props} />
      <Socks {...props} />
      <Logos {...props} />
    </svg>
  </SVGWrapper>
);

export default Kit;

import { useSelector } from "react-redux";
import { getNextEvent } from "../../core/store/events/reducers";
import HelmetHead from "../HelmetHead";
import CreateEntryDialog from "./CreateEntryDialog";
import SquadBase from "./SquadBase";
import SquadListTable from "./SquadListTable";

const SquadSelection = () => (
  <SquadBase
    listTable={(listTableProps) => <SquadListTable {...listTableProps} />}
    scoreboard="basic"
    submitDialog={(handleHide) => <CreateEntryDialog handleHide={handleHide} />}
    title="Squad Selection"
    headTags={
      <HelmetHead
        title="Select Fantasy Football Players | Fantasy Premier League"
        description="To select your first Fantasy Premier League squad after completing the registration process, visit the official website of the Premier League."
      />
    }
    nextEvent={useSelector(getNextEvent)}
  />
);

export default SquadSelection;

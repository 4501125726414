import * as React from "react";
import { NewIcon, MovementIcon } from "../icons/Movement";

interface IProps {
  lastRank: number | null;
  rank: number | null;
}

const Movement: React.FC<IProps> = ({ lastRank, rank }) => {
  if (lastRank === 0 || rank === 0 || lastRank === null || rank === null) {
    return <NewIcon />;
  }
  let status = "";
  if (rank === lastRank) {
    status = "same";
  }
  if (rank > lastRank) {
    status = "down";
  }
  if (rank < lastRank) {
    status = "up";
  }

  return <MovementIcon status={status} title={status} />;
};

export default Movement;

import React from "react";
import styled from "styled-components/macro";
import { SponsorValues } from "../EntryUpdate";

const Paragraph = styled.p`
  margin: ${({ theme }) => theme.space[2]} 0;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-family: ${({ theme }) => theme.fonts.regular};
  line-height: 1.6;
`;

type Props = {
  selectedSponsor: SponsorValues;
};

const TwitterLink = styled.a`
  text-decoration: none;
  color: #e90052;
  font-family: ${({ theme }) => theme.fonts.regular};
  :hover {
    text-decoration: underline;
  }
`;

const SponsorComponents: Record<SponsorValues, React.ReactNode> = {
  none: <div></div>,
  "pl-inspires": (
    <Paragraph>
      Inspiring children and young people to develop their personal skills and
      positive attitudes to succeed in life.
    </Paragraph>
  ),
  "pl-kicks": (
    <Paragraph>
      Inspiring children and young people to achieve their potential and improve
      their wellbeing; working together to build stronger, safer and more
      inclusive communities.
    </Paragraph>
  ),
  "pl-primary-stars": (
    <Paragraph>
      Inspiring primary school children in everything from Maths and English to
      teamwork and sport.
    </Paragraph>
  ),
  "rainbow-laces": (
    <Paragraph>
      The Premier League and clubs are committed to supporting LGBTQ+ inclusion
      and making it clear that football is for everyone.{" "}
      <TwitterLink href="https://twitter.com/hashtag/RainbowLaces">
        #RainbowLaces
      </TwitterLink>
    </Paragraph>
  ),
  "no-racism": (
    <Paragraph>
      There’s no room for racism. Anywhere. And we won’t stop until it is kicked
      out.{" "}
      <TwitterLink href="https://twitter.com/hashtag/NoRoomForRacism">
        #NoRoomForRacism
      </TwitterLink>
    </Paragraph>
  ),
  ukraine: (
    <Paragraph>
      Join the Premier League and clubs in showing support for the people of
      Ukraine suffering from Russia's actions.
    </Paragraph>
  ),
};

const SponsorText: React.FunctionComponent<Props> = ({ selectedSponsor }) => {
  return (
    <div className="notranslate">{SponsorComponents[selectedSponsor]}</div>
  );
};

export default SponsorText;

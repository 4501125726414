import * as React from "react";
import styled, { css } from "styled-components/macro";
import { ITeam } from "../core/store/teams/types";
import shadow from "../img/shirt-shadow.png";

const StyledShirt = styled.img<IStyledProps>`
  display: inline-block;
  margin: auto;
  vertical-align: middle;

  ${(props) =>
    props.hasShadow &&
    css`
      padding-bottom: 20%;
      background: url(${shadow}) no-repeat center bottom;
      background-size: contain;
    `}
`;

interface IProps {
  path: string;
  sizes: string;
  team?: ITeam;
}

interface IStyledProps {
  hasShadow?: boolean;
}

type Props = IProps & IStyledProps;

const Shirt: React.FC<Props> = ({ hasShadow, path, sizes, team }) => (
  <picture>
    <source
      type="image/webp"
      srcSet={`
        ${path}-66.webp 66w,
        ${path}-110.webp 110w,
        ${path}-220.webp 220w
        `}
      sizes={sizes}
    />
    <StyledShirt
      src={`${path}-66.png`}
      srcSet={`
        ${path}-66.png 66w,
        ${path}-110.png 110w,
        ${path}-220.png 220w
        `}
      sizes={sizes}
      alt={team ? team.name : ""}
      hasShadow={hasShadow}
    />
  </picture>
);

export default Shirt;

import * as React from "react";
import styled from "styled-components/macro";
import Tooltip, { TooltipLabel } from "../Tooltip";

interface StyledProps {
  difficulty: number;
}

const StyledFDRCell = styled.div<StyledProps>`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.4rem;
  background-color: ${({ theme, difficulty }) =>
    theme.colors.difficulties[difficulty].bg};
  color: ${({ theme, difficulty }) =>
    theme.colors.difficulties[difficulty].color};
  margin-top: 0.2rem;
  padding-left: 5px;
  padding-right: 5px;

  :first-child {
    margin-top: 0;
  }
`;

const Teamname = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
  margin-right: 3px;
`;

interface IProps {
  difficulty: number;
  teamName: string;
  isHome?: boolean | undefined;
}

const FDRCell: React.FC<IProps> = ({ difficulty = 1, teamName, isHome }) => {
  if (!teamName) {
    return (
      <StyledFDRCell data-testid="fdr-cell-empty" difficulty={3}>
        -
      </StyledFDRCell>
    );
  }

  return (
    <StyledFDRCell difficulty={difficulty} data-testid="fdr-cell-full">
      <Tooltip content={`FDR: ${difficulty}`}>
        <TooltipLabel>
          <Teamname>{teamName}</Teamname>
        </TooltipLabel>
      </Tooltip>
      {isHome !== undefined && <span>({isHome ? "H" : "A"})</span>}
    </StyledFDRCell>
  );
};

export default FDRCell;

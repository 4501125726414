import orderBy from "lodash/orderBy";
import { size } from "polished";
import React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { getElements } from "../../core/store/elements/reducers";
import { IElement } from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { ReactComponent as ArrowIcon } from "../../img/icons/transfer-arrow-right.svg";
import BoldLink, { BoldLinkWrap } from "../BoldLink";
import ElementDialogButton from "../ElementDialogButton";
import {
  BorderedCell,
  ElementRow,
  ElementTable,
  PrimaryCell,
} from "../ElementTable";
import Panel from "../Panel";
import { Ellipsis } from "../Utils";

const ArrowWrap = styled.div<ITransferArrowProps>`
  ${size(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #05fa87;
  border-radius: 50%;

  > svg {
    ${size(15, 12)};
    fill: ${({ theme }) => theme.colors.primary};
  }

  ${(props) =>
    props.isOut &&
    css`
      background-color: #fc2c80;

      > svg {
        fill: white;
        transform: rotate(180deg);
      }
    `}

  ${(props) =>
    props.isSmall &&
    css`
      transform: scale(0.75);
    `}
`;

const TransfersTable = styled(ElementTable)`
  th:not(:nth-child(4)),
  td:not(:nth-child(4)) {
    text-align: center;
  }
`;

const StatusCol = styled.th`
  width: 12%;
`;

const ArrowCol = styled.th`
  width: 10%;
`;

const PosCol = styled.th`
  width: 14%;
`;

const ClubCol = styled.th`
  width: 16%;
`;

const PlayerCol = styled.th`
  width: 32%;
`;

const TransfersCol = styled.th`
  width: 16%;
`;

interface IPropsFromState {
  elements: IElement[];
  elementTypesById: IElementTypesById;
  teamsById: ITeamsById;
}

interface IOwnProps {
  isOut?: boolean;
}

type Props = IPropsFromState & IOwnProps;

interface ITransferArrowProps {
  isOut?: boolean;
  isSmall?: boolean;
}

const TransferArrow: React.FC<ITransferArrowProps> = ({
  isOut = false,
  isSmall = false,
}) => (
  <ArrowWrap isOut={isOut} isSmall={isSmall}>
    <ArrowIcon />
  </ArrowWrap>
);

const TransfersPanel: React.FC<Props> = ({
  elements,
  elementTypesById,
  isOut,
  teamsById,
}) => {
  const stat = isOut ? "transfers_out_event" : "transfers_in_event";
  return (
    <>
      <Panel>
        <Panel.Header
          title={`Top Transfers ${isOut ? "out" : "in"} this Gameweek`}
          icon={<TransferArrow isOut={isOut} />}
        ></Panel.Header>
        <Panel.Body>
          <TransfersTable>
            <thead>
              <tr>
                <StatusCol scope="col">&nbsp;</StatusCol>
                <ArrowCol scope="col">&nbsp;</ArrowCol>
                <PosCol scope="col">Pos</PosCol>
                <PlayerCol scope="col">Player</PlayerCol>
                <ClubCol scope="col">Club</ClubCol>
                <TransfersCol scope="col">&nbsp;</TransfersCol>
              </tr>
            </thead>
            <tbody>
              {orderBy(elements, [stat], ["desc"])
                .slice(0, 5)
                .map((e) => (
                  <ElementRow key={e.id}>
                    <BorderedCell>
                      <ElementDialogButton elementId={e.id} variant="list" />
                    </BorderedCell>
                    <td>
                      <TransferArrow isOut={isOut} isSmall={true} />
                    </td>
                    <td>
                      {elementTypesById[e.element_type].singular_name_short}
                    </td>
                    <PrimaryCell>
                      <Ellipsis>{e.web_name}</Ellipsis>
                    </PrimaryCell>
                    <BorderedCell>{teamsById[e.team].short_name}</BorderedCell>
                    <td>{e[stat]}</td>
                  </ElementRow>
                ))}
            </tbody>
          </TransfersTable>
        </Panel.Body>
        <BoldLinkWrap align="right">
          <BoldLink to={`/statistics/${stat}`}>More</BoldLink>
        </BoldLinkWrap>
      </Panel>
    </>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elements: getElements(state),
  elementTypesById: getElementTypesById(state),
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(TransfersPanel);

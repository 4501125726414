import styled from "styled-components/macro";
import { IStyledElementFixtureBar } from "./types";

export const StyledElementFixtureBar = styled.div<IStyledElementFixtureBar>`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-radius: 0 0 0.3rem 0.3rem;
  background: ${({ $isLive, $isFinished, theme }) =>
    $isLive
      ? theme.colors.pinkToOrange
      : $isFinished
      ? theme.colors.primary
      : theme.colors.purple10};
  color: ${({ $isLive, $isFinished }) =>
    $isLive || $isFinished ? "white" : "inherit"};
  font-size: 1rem;
  text-align: center;
  line-height: 1;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`;

export const PointsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: 0;
  }
`;

import React from "react";
import SubNav from "../SubNav";

const links = [
  { href: "/prizes", text: "Prizes" },
  { href: "/prizes/winners", text: "Winners" },
];

const PrizesNav: React.FC = () => <SubNav links={links} />;

export default PrizesNav;

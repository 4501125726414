import styled, { css } from "styled-components/macro";
import { IChipButtonProps } from "./types";

export const StyledChipButton = styled.button<IChipButtonProps>`
  padding: ${({ theme }) => `1.3rem ${theme.space[5]}`};
  border: 0;
  border-radius: ${({ theme }) => theme.radii[2]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  cursor: pointer;

  ${(props) =>
    props.variant === "play" &&
    css`
      background: linear-gradient(to right, #0ae5ff, #42a2ff);
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${(props) =>
    props.variant === "cancel" &&
    css`
      background: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.white};
    `}
`;

import { useSelector } from "react-redux";
import { RootState } from "../../core/store";
import { getElement } from "../../core/store/elements/reducers";
import ElementPoints from "../ElementPoints";
import FixturesForElement from "../FixturesForElement";
import { StyledElementFixtureBar } from "./styles";
import { IElementFixtureBar } from "./types";

const ElementFixtureBar = ({
  elementFixtures,
  eventId,
  isStarted,
  pick,
}: IElementFixtureBar) => {
  const element = useSelector((state: RootState) =>
    getElement(state, pick.element)
  );

  if (!element) {
    return null;
  }

  if (!elementFixtures.length || !eventId) {
    return <StyledElementFixtureBar>-</StyledElementFixtureBar>;
  }

  const isLive = elementFixtures.some(
    (fixture) => !fixture.finished_provisional && fixture.started
  );

  const isFinished = elementFixtures.every(
    (fixture) => fixture.finished_provisional
  );

  if (isStarted) {
    return (
      <StyledElementFixtureBar $isLive={isLive} $isFinished={isFinished}>
        <ElementPoints eventId={eventId} pick={pick} />
      </StyledElementFixtureBar>
    );
  }

  return (
    <StyledElementFixtureBar>
      <FixturesForElement eventId={eventId} elementId={element.id} />
    </StyledElementFixtureBar>
  );
};

export default ElementFixtureBar;

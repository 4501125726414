import styled from "styled-components/macro";
import { ReactComponent as ChevronDoubleLeftIcon } from "../../img/icons/chevron-double-left.svg";
import { ReactComponent as ChevronDoubleRightIcon } from "../../img/icons/chevron-double-right.svg";
import { ReactComponent as ChevronLeftIcon } from "../../img/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../img/icons/chevron-right.svg";
import { ReactComponent as BaseNextIcon } from "../../img/icons/next.svg";
import { ReactComponent as BasePrevIcon } from "../../img/icons/previous.svg";

export const ChevronLeft = styled(ChevronLeftIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

export const ChevronRight = styled(ChevronRightIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

export const ChevronDoubleLeft = styled(ChevronDoubleLeftIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

export const ChevronDoubleRight = styled(ChevronDoubleRightIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

export const PrevIcon = styled(BasePrevIcon)`
  margin-right: 0.6rem;
  fill: currentColor;
  vertical-align: middle;
`;

export const NextIcon = styled(BaseNextIcon)`
  margin-left: 0.6rem;
  fill: currentColor;
  vertical-align: middle;
`;

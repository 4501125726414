import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../core/store";
import { getElementStats } from "../../core/store/element-stats/reducers";
import { getElementControls } from "../../core/store/elements/reducers";
import { IElement } from "../../core/store/elements/types";
import { FieldWrap, Option, SelectField } from "../FieldRenderers";
import { getStatNames, getStatDetails } from "../../utils/stats";

const mapState = (state: RootState) => ({
  controls: getElementControls(state),
  elementStats: getElementStats(state),
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ISortOption {
  label: string;
  value: keyof IElement;
}

type Props = PropsFromRedux & {
  handleSortChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  sortOptions?: ISortOption[];
};

class ElementSort extends React.Component<Props> {
  public getSortOptions = (): ISortOption[] =>
    getStatNames().map((s) => ({
      label: getStatDetails(s)!.label,
      value: s,
    }));

  public render() {
    const {
      controls,
      handleSortChange,
      sortOptions = this.getSortOptions(),
    } = this.props;
    return (
      <FieldWrap>
        <SelectField
          id="sort"
          name="sort"
          label="Sorted by"
          value={controls.sort}
          onChange={handleSortChange}
        >
          {sortOptions.map((o) => (
            <Option
              key={o.value}
              value={o.value}
              aria-selected={controls.filter === o.value}
            >
              {o.label}
            </Option>
          ))}
        </SelectField>
      </FieldWrap>
    );
  }
}

export { ElementSort as ElementSortTest };

export default connector(ElementSort);

import { StyledChipButton } from "./styles";
import { IChipButtonProps } from "./types";

const ChipButton: React.FC<IChipButtonProps> = ({
  children,
  variant = "play",
  onClick,
  handleHide,
  disabled,
}) => (
  <StyledChipButton
    type="button"
    variant={variant}
    onClick={onClick}
    handleHide={handleHide}
    disabled={disabled}
  >
    {children}
  </StyledChipButton>
);

export default ChipButton;

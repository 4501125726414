import * as React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementsById } from "../core/store/elements/reducers";
import { showElementSummary } from "../core/store/elements/thunks";
import { IElementsById } from "../core/store/elements/types";
import ElementStatus from "./ElementStatus";
import { VisuallyHidden } from "./Utils";

const StyledElementDialogButton = styled.button<IStyledProps>`
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;
  cursor: pointer;

  ${(props) =>
    props.variant === "list" &&
    css`
      padding: 0.7rem ${props.theme.space[1]};
    `}
`;

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

interface IPropsFromState {
  elementsById: IElementsById;
}

interface IOwnProps {
  elementId: number;
}

interface IStyledProps {
  variant?: "list" | "pitch";
}

type Props = IPropsFromDispatch & IPropsFromState & IOwnProps & IStyledProps;

const ElementDialogButton: React.FC<Props> = ({
  elementId,
  elementsById,
  showElementDialog,
  variant = "pitch",
}) => (
  <StyledElementDialogButton
    onClick={() => showElementDialog(elementId)}
    variant={variant}
  >
    <ElementStatus element={elementsById[elementId]} />
    <VisuallyHidden>View player information</VisuallyHidden>
  </StyledElementDialogButton>
);

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ElementDialogButton);

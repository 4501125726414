import React from "react";
import { ILeagueEntry } from "../../core/store/entries/types";
import { IH2HMatch } from "../../core/store/leagues/types";
import Link from "../Link";
import CupSummaryData from "./CupSummaryData";
import { getCupUrl } from "./utils";

interface ICupTypeRowProps {
  cupMatch: IH2HMatch | null;
  entryId: number;
  league: ILeagueEntry;
}
const CupTypeRow: React.FC<ICupTypeRowProps> = ({
  cupMatch,
  entryId,
  league,
}) => {
  return (
    <tr key={league.id}>
      <td>
        <Link to={getCupUrl(league.id, league.cup_league, entryId)}>
          {league.name} cup
        </Link>
      </td>
      {cupMatch && league.cup_qualified ? (
        <CupSummaryData entryId={entryId} match={cupMatch} />
      ) : (
        <>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </>
      )}
    </tr>
  );
};

export default CupTypeRow;

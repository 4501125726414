import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getFinishedEvents } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getFinishedPhases } from "../core/store/phases/reducers";
import { IPhase } from "../core/store/phases/types";
import {
  getWinnersByEvent,
  getWinnersByPhase,
} from "../core/store/winners/reducers";
import {
  fetchEventWinners,
  fetchPhaseWinners,
} from "../core/store/winners/thunks";
import { IWinner } from "../core/store/winners/types";
import { isBlankWeek } from "../utils/events";
import { SelectField } from "./FieldRenderers";
import Footnote from "./Footnote";
import { Main, Wrapper } from "./Layout";
import Link from "./Link";
import PrizesNav from "./prizes/PrizesNav";
import Table from "./Table";
import Title from "./Title";

const WinnersWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: flex;
  }
`;
interface IPropsFromState {
  eventWinners: Record<string, IWinner[]>;
  finishedEvents: IEvent[];
  finishedPhases: IPhase[];
  phaseWinners: Record<string, IWinner[]>;
}

interface IPropsFromDispatch {
  fetchEventWinners: (eventId: number) => void;
  fetchPhaseWinners: (phaseId: number) => void;
}

type Props = RouteComponentProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  prizeEvent: number;
  prizePhase: number;
}

class Winners extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      prizeEvent: props.finishedEvents.length
        ? props.finishedEvents[props.finishedEvents.length - 1].id
        : 0,
      prizePhase: props.finishedPhases.length
        ? props.finishedPhases[props.finishedPhases.length - 1].id
        : 0,
    };
  }

  public refreshEventPrizes() {
    this.props.fetchEventWinners(this.state.prizeEvent);
  }

  public refreshPhasePrizes() {
    this.props.fetchPhaseWinners(this.state.prizePhase);
  }

  public componentDidMount() {
    if (this.state.prizeEvent) {
      this.refreshEventPrizes();
    }
    if (this.state.prizePhase) {
      this.refreshPhasePrizes();
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: IState) {
    if (
      this.state.prizeEvent &&
      this.state.prizeEvent !== prevState.prizeEvent
    ) {
      this.refreshEventPrizes();
    }
    if (
      this.state.prizePhase &&
      this.state.prizePhase !== prevState.prizePhase
    ) {
      this.refreshPhasePrizes();
    }
  }

  public handlePrizeEventChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ prizeEvent: Number(e.currentTarget.value) });

  public handlePrizePhaseChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ prizePhase: Number(e.currentTarget.value) });

  public render() {
    const { finishedEvents, finishedPhases } = this.props;
    const eventWinners = this.props.eventWinners[this.state.prizeEvent] || [];
    const phaseWinners = this.props.phaseWinners[this.state.prizePhase] || [];
    return (
      <>
        <Wrapper>
          <Box flex={1} pt={4}>
            <PrizesNav />
          </Box>
        </Wrapper>
        <Wrapper>
          <Main isWide={true}>
            <Box mx={2}>
              <Title>Prize Winners</Title>
            </Box>
            <WinnersWrap>
              <Box mb={4} px={2} width={[1, 1, 1, 1, 1 / 2]}>
                <Box mb={4}>
                  <SelectField
                    id="ismPrizeEvent"
                    onChange={this.handlePrizeEventChange}
                    label="Gameweek Winners*"
                    value={this.state.prizeEvent}
                  >
                    {finishedEvents.length ? (
                      finishedEvents.map((e) => (
                        <option
                          value={e.id}
                          aria-selected={e.id === this.state.prizeEvent}
                          key={e.id}
                        >
                          {e.name}
                        </option>
                      ))
                    ) : (
                      <option>No Completed Gameweeks</option>
                    )}
                  </SelectField>
                </Box>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">Team & Manager</th>
                      <th scope="col">GW Pts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isBlankWeek(this.state.prizeEvent) ? (
                      eventWinners.map((w) => (
                        <tr key={w.entry_id}>
                          <td>{w.rank_sort}</td>
                          <td>
                            <Link
                              to={`/entry/${w.entry_id}/event/${this.state.prizeEvent}`}
                            >
                              <strong>{w.team_name}</strong>
                              <br />
                              {w.first_name} {w.last_name}
                            </Link>
                          </td>
                          <td>{w.points}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>No winners</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Box mt={1} px={2}>
                  <Footnote>
                    * To be eligible to win a weekly prize, you must have joined
                    the game before the start of the season or a minimum of two
                    clear Gameweeks before the one in which you are top of the
                    weekly leaderboard. Players who have played a chip
                    (including Bench Boost, Free Hit Triple Captain or Wildcard)
                    will not be eligible to win a weekly prize for the Gameweek
                    that such a chip has been used.
                  </Footnote>
                </Box>
              </Box>
              <Box px={2} width={[1, 1, 1, 1, 1 / 2]}>
                <Box mb={4}>
                  <SelectField
                    id="ismPrizePhase"
                    onChange={this.handlePrizePhaseChange}
                    label="Monthly Winners"
                    value={this.state.prizePhase}
                  >
                    {finishedPhases.length ? (
                      finishedPhases.map((e) => (
                        <option
                          value={e.id}
                          aria-selected={e.id === this.state.prizePhase}
                          key={e.id}
                        >
                          {e.name}
                        </option>
                      ))
                    ) : (
                      <option>No Completed Phases</option>
                    )}
                  </SelectField>
                </Box>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">Team & Manager</th>
                      <th scope="col">Phase Pts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {phaseWinners.map((w) => (
                      <tr key={w.entry_id}>
                        <td>{w.rank_sort}</td>
                        <td>
                          <Link to={`/entry/${w.entry_id}/history`}>
                            <strong>{w.team_name}</strong>
                            <br />
                            {w.first_name} {w.last_name}
                          </Link>
                        </td>
                        <td>{w.points}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </WinnersWrap>
          </Main>
        </Wrapper>
      </>
    );
  }
}

export { Winners as WinnersTest };

const mapStateToProps = (state: RootState) => ({
  eventWinners: getWinnersByEvent(state),
  finishedEvents: getFinishedEvents(state),
  // Don't show overall phase
  finishedPhases: getFinishedPhases(state).filter((p) => p.id !== 1),
  phaseWinners: getWinnersByPhase(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEventWinners: (eventId: number) => dispatch(fetchEventWinners(eventId)),
  fetchPhaseWinners: (phaseId: number) => dispatch(fetchPhaseWinners(phaseId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Winners);

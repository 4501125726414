import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState } from "../../core/store";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import HelmetHeadLeagues from "./HelmetHeadLeagues";

interface IPropsFromState {
  nxt: IEvent | null;
  settings: ISettings | null;
}

const Join: React.FC<IPropsFromState> = ({ nxt, settings }) => {
  if (!settings) {
    return null;
  }
  return (
    <Wrapper>
      <HelmetHeadLeagues />
      <Main>
        <Copy>
          <Title>Choose a League Type to Join</Title>
          <p>
            You can join up to {settings.league_join_private_max} invitational
            leagues and {settings.league_join_public_max} public leagues.
          </p>
          <Box mb={4}>
            <SubHeading>Invitational Leagues &amp; Cups</SubHeading>
            <p>
              Join an invitational league and cup if somebody has given you a
              league code to enter.
            </p>
            <ButtonLink to="private">
              Join Invitational League &amp; Cup
            </ButtonLink>
          </Box>
          <SubHeading>Public Leagues</SubHeading>
          <p>
            Join a public league to play against a small, randomly selected
            group of other game players.
          </p>
          {nxt ? (
            <ButtonLink to="public">Join Public League</ButtonLink>
          ) : (
            <p>
              It is no longer possible to join public leagues as the last
              deadline has passed.
            </p>
          )}
        </Copy>
      </Main>
    </Wrapper>
  );
};

export { Join as JoinTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  nxt: getNextEvent(state),
  settings: getSettings(state),
});

export default connect(mapStateToProps)(Join);

import * as Sentry from "@sentry/browser";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import configureStore from "./core/configureStore";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://81d88aa7bc0b46739acf531684529abf@sentry.io/1462585",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    whitelistUrls: [/premierleague\.com/, /ismgames\.com/],
    ignoreErrors: [
      /Unexpected token/,
      /from accessing a cross-origin frame/,
      /NetworkError when attempting to fetch/,
      /Failed to fetch/,
      /Unexpected end of input/,
      /Service Unavailable/,
      /contextMenuMessageHandler/,
      /find variable: webkit/,
      /NPObject deleted/,
      /touchDownX/,
      /SecurityError/,
      /ntp is not defined/,
      /Permission denied to access property/,
      /NPMethod called on non-NPObject/,
      /\[Immer\] minified error nr: 18 'ES5'/,
      /NoModificationAllowedError/,
      /Trust Tokens/,
      /send-redemption-record/,
      /GVLError/,
      /token-redemption/,
    ],
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { rgba } from "polished";
import styled, { css } from "styled-components/macro";
import { ChevronRight } from "../../icons/Chevrons";

export interface IChipCardStyles {
  chipStatus:
    | "available"
    | "proposed"
    | "active"
    | "cancelled"
    | "unavailable"
    | "played"
    | "info";
  $fullWidth?: boolean;
}

export interface IChipCardBadgeProps {
  blue1x?: string;
  purple1x?: string;
}

export const ChipCardTitle = styled.span`
  margin: auto;
  padding: 0 ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  letter-spacing: -0.4px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    padding: 0;
  }
`;

export const CardButton = styled.button<IChipCardStyles>`
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 0.4rem;
  padding: 2px;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => rgba(theme.colors.white, 0.5)};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  ${({ chipStatus, theme }) =>
    (chipStatus === "active" || chipStatus === "proposed") &&
    css`
      background: ${theme.colors.primary};
      color: ${theme.colors.white};
    `}

  ${(props) =>
    props.chipStatus === "unavailable" &&
    css`
      opacity: 0.5;
    `};
`;

export const ChipLabel = styled.span<IChipCardStyles>`
  display: block;
  padding: 3px 2px;
  border-radius: ${({ theme }) => theme.radii[0]};
  background: linear-gradient(to right, #0ae5ff, #42a2ff);
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: -0.4px;

  ${({ chipStatus, theme }) =>
    (chipStatus === "active" || chipStatus === "proposed") &&
    css`
      background: ${theme.colors.purple70};
      color: ${theme.colors.white};
    `}

  ${({ chipStatus, theme }) =>
    chipStatus === "played" &&
    css`
      background: ${rgba(theme.colors.white, 0)};
      color: ${theme.colors.primary};
      font-family: ${theme.fonts.regular};
    `};

  ${({ chipStatus, theme }) =>
    chipStatus === "info" &&
    css`
      background: ${rgba(theme.colors.white, 1)};
      color: ${theme.colors.primary};
      font-family: ${theme.fonts.bold};
    `};

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};
`;

export const Card = styled.li`
  display: inherit;
  flex: 0 1 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex: 1 0 100px;
  }
`;

export const ChevronRightIcon = styled(ChevronRight)`
  width: 10px;
  height: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 8px;
    height: 8px;
  }
`;

export const ChipLabelWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: ${({ theme }) => theme.space[0]};
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    gap: ${({ theme }) => theme.space[1]};
  }
`;

import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../core/store";
import {
  getElementControls,
  getTeamFilterOptions,
  getElementTypeFilterOptions,
} from "../../core/store/elements/reducers";
import { getPlayerData } from "../../core/store/player/reducers";
import { FieldWrap, Option, SelectField } from "../FieldRenderers";

const mapState = (state: RootState) => ({
  controls: getElementControls(state),
  elementTypeFilterOptions: getElementTypeFilterOptions(state),
  teamFilterOptions: getTeamFilterOptions(state),
  player: getPlayerData(state),
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  handleFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

class ElementFilter extends React.Component<Props> {
  public render() {
    const {
      controls,
      elementTypeFilterOptions,
      handleFilterChange,
      player,
      teamFilterOptions,
    } = this.props;
    return (
      <FieldWrap>
        <SelectField
          id="filter"
          name="filter"
          label="View"
          value={controls.filter}
          onChange={handleFilterChange}
        >
          <optgroup label="Global">
            <Option value="all" aria-selected={controls.filter === "all"}>
              All players
            </Option>
            {player && player.entry && (
              <Option value="wl" aria-selected={controls.filter === "wl"}>
                Watchlist
              </Option>
            )}
          </optgroup>
          <optgroup label="By Position">
            {elementTypeFilterOptions.map((o) => (
              <Option
                key={o.value}
                value={o.value}
                aria-selected={controls.filter === o.value}
              >
                {o.label}
              </Option>
            ))}
          </optgroup>
          <optgroup label="By Team">
            {teamFilterOptions.map((o) => (
              <Option
                key={o.value}
                value={o.value}
                aria-selected={controls.filter === o.value}
              >
                {o.label}
              </Option>
            ))}
          </optgroup>
        </SelectField>
      </FieldWrap>
    );
  }
}

export { ElementFilter as ElementFilterTest };

export default connector(ElementFilter);

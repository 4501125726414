import React from "react";
import SubNav from "../SubNav";

const links = [
  { href: "/the-scout", text: "News" },
  { href: "/the-scout/set-piece-takers", text: "Set-Pieces" },
  { href: "/the-scout/player-news", text: "Availability" },
];

const ScoutNav: React.FC = () => <SubNav links={links} />;

export default ScoutNav;

import { rgba } from "polished";
import styled from "styled-components/macro";

const SaveBar = styled.div`
  display: flex;
  gap: 4px;
  padding: ${({ theme }) => `0 ${theme.space[2]} ${theme.space[4]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    gap: 12px;
    position: relative;
    padding-right: 20%;
    padding-left: 20%;

    ::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background-image: linear-gradient(
        to right,
        ${({ theme }) => rgba(theme.colors.lightGrey, 0)},
        ${({ theme }) => theme.colors.lightGrey},
        ${({ theme }) => rgba(theme.colors.lightGrey, 0)}
      );
    }
  }
`;

export default SaveBar;

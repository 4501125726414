import { navigate, RouteComponentProps } from "@reach/router";
import qs from "qs";
import React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getCurrentEvent,
  getCurrentEventStatus,
} from "../../core/store/events/reducers";
import { fetchEventStatus } from "../../core/store/events/thunks";
import { IEvent, IEventStatusData } from "../../core/store/events/types";
import {
  getClassicLeague,
  getClassicNewEntries,
  getClassicStandings,
} from "../../core/store/leagues/reducers";
import { fetchClassicLeagueStandings } from "../../core/store/leagues/thunks";
import {
  IClassicStandingsWithDates,
  ILeague,
  INewEntriesData,
} from "../../core/store/leagues/types";
import { getStartedPhases } from "../../core/store/phases/reducers";
import { IPhase } from "../../core/store/phases/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { formatRawAsLocal } from "../../core/utils/datetime";
import { getShortNameFromId } from "../../utils/events";
import Alert from "../Alert";
import BoldLink from "../BoldLink";
import ButtonLink from "../ButtonLink";
import { InlineSelectField } from "../FieldRenderers";
import { ControlArrowLeft, ControlArrowRight } from "../icons/Arrows";
import { NextIcon, PrevIcon } from "../icons/Chevrons";
import { Main, Secondary, Wrapper } from "../Layout";
import LeaderboardAd from "../LeaderboardAd";
import Link from "../Link";
import { Pager, PagerItem, PagerItemNext } from "../Pager";
import ReportNameButton from "../ReportNameButton";
import SubHeading from "../SubHeading";
import Table from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import HelmetHeadLeagues from "./HelmetHeadLeagues";
import Movement from "./Movement";
import StandingsHeading from "./StandingsHeading";
import StandingsRow from "./StandingsRow";
import { getCupUrl } from "./utils";

type OwnProps = RouteComponentProps<{ leagueId: string }>;

interface IPropsFromState {
  league: ILeague | null;
  leagueNumber: number;
  newEntries: INewEntriesData | null;
  now: IEvent | null;
  pageNewEntries: number;
  pageStandings: number;
  phase: number;
  phases: IPhase[];
  player: IPlayer | null;
  standings: IClassicStandingsWithDates | null;
  statusData: IEventStatusData | null;
}

interface IPropsFromDispatch {
  fetchEventStatus: () => void;
  fetchStandings: (
    leagueId: number,
    phaseId: number,
    pageNewEntries: number,
    pageStandings: number
  ) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

class StandingsClassic extends React.Component<Props> {
  public fetchLeagueStandings() {
    if (this.props.leagueNumber) {
      this.props.fetchStandings(
        this.props.leagueNumber,
        this.props.phase,
        this.props.pageNewEntries,
        this.props.pageStandings
      );
    }
  }

  public componentDidMount() {
    this.fetchLeagueStandings();
    this.props.fetchEventStatus();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.pageNewEntries !== this.props.pageNewEntries ||
      prevProps.pageStandings !== this.props.pageStandings ||
      prevProps.phase !== this.props.phase
    ) {
      this.fetchLeagueStandings();
    }
  }

  public buildLink(
    phase: number,
    pageNewEntries: number,
    pageStandings: number
  ) {
    return `/leagues/${
      this.props.league!.id
    }/standings/c?phase=${phase}&page_new_entries=${pageNewEntries}&page_standings=${pageStandings}`;
  }

  public handleChange = (e: React.FormEvent<HTMLSelectElement>) =>
    navigate(this.buildLink(parseInt(e.currentTarget.value, 10), 1, 1));

  public render() {
    const {
      league,
      newEntries,
      now,
      pageNewEntries,
      pageStandings,
      phase,
      phases,
      player,
      standings,
      statusData,
      leagueNumber,
    } = this.props;

    if (!league) {
      return null;
    }

    const started = now && league.start_event <= now.id;
    return (
      <>
        <HelmetHeadLeagues />
        <LeaderboardAd
          id="div-gpt-ad-1501757861635-0"
          slot="DesktopFPLLeague"
          targetValue={`fplClassicLeague${league.id}`}
        />
        <Wrapper>
          <Main>
            <StandingsHeading
              type="classic"
              league={league}
              leagueNumber={leagueNumber}
            />
            {league.has_cup && (
              <Box px={2} mb={4}>
                <BoldLink to={getCupUrl(league.id, league.cup_league)}>
                  View cup {league.cup_league ? "matches" : "info"}
                </BoldLink>
              </Box>
            )}
            {league.start_event > 1 && (
              <Box m={2}>
                <Alert>
                  {started
                    ? `League scoring started in Gameweek ${getShortNameFromId(
                        league.start_event,
                        true
                      )}`
                    : `League scoring will start in Gameweek ${getShortNameFromId(
                        league.start_event,
                        true
                      )}`}
                </Alert>
              </Box>
            )}
            <Box m={2}>
              <form>
                <InlineSelectField
                  id="selectPhase"
                  name="selectPhase"
                  label="Select phase:  "
                  onChange={this.handleChange}
                  value={phase}
                >
                  {phases.map((p) => (
                    <option
                      value={p.id}
                      key={p.id}
                      aria-selected={phase === p.id}
                    >
                      {p.name}
                    </option>
                  ))}
                </InlineSelectField>
              </form>
            </Box>
            {started && statusData && statusData.leagues === "Updating" && (
              <Box m={2}>
                <Alert>
                  League tables are currently being re-calculated. The table
                  below may still contain old data.
                </Alert>
              </Box>
            )}
            {standings && standings.lastUpdatedData && (
              <Box m={2}>
                Last updated: {formatRawAsLocal(standings.lastUpdatedData)}{" "}
                (local time)
              </Box>
            )}
            <Box mb={2}>
              <Table>
                <thead>
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Team & Manager</th>
                    <th scope="col">
                      <Tooltip content="Gameweek points">
                        <TooltipLabel>GW</TooltipLabel>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip content="Total points">
                        <TooltipLabel>TOT</TooltipLabel>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {standings &&
                    standings.results.map((ls) => (
                      <StandingsRow
                        mine={player !== null && player.entry === ls.entry}
                        key={ls.entry}
                      >
                        <td>
                          <Flex alignItems="center">
                            <Box mr={1}>{ls.rank}</Box>
                            <Movement lastRank={ls.last_rank} rank={ls.rank} />
                          </Flex>
                        </td>
                        <td>
                          {now ? (
                            <Link to={`/entry/${ls.entry}/event/${now.id}`}>
                              <strong>{ls.entry_name}</strong>
                            </Link>
                          ) : (
                            <strong>{ls.entry_name}</strong>
                          )}
                          <br />
                          {ls.player_name}
                        </td>
                        <td>{ls.event_total}</td>
                        <td>{ls.total}</td>
                      </StandingsRow>
                    ))}
                </tbody>
              </Table>
            </Box>
            {standings && (
              <Pager>
                <PagerItem>
                  {pageStandings > 1 && (
                    <ButtonLink
                      to={this.buildLink(
                        phase,
                        pageNewEntries,
                        pageStandings - 1
                      )}
                      variant="tertiary"
                      fullwidth="true"
                    >
                      <PrevIcon />
                      <span>Previous</span>
                    </ButtonLink>
                  )}
                </PagerItem>
                <PagerItemNext>
                  {standings.has_next && (
                    <ButtonLink
                      to={this.buildLink(
                        phase,
                        pageNewEntries,
                        pageStandings + 1
                      )}
                      variant="tertiary"
                      fullwidth="true"
                    >
                      <span>Next</span>
                      <NextIcon />
                    </ButtonLink>
                  )}
                </PagerItemNext>
              </Pager>
            )}
            {newEntries && newEntries.results.length > 0 && (
              <>
                <SubHeading>New entries</SubHeading>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Team</th>
                      <th scope="col">Manager</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newEntries.results.map((ne) => (
                      <tr key={ne.entry}>
                        <td>{ne.entry_name}</td>
                        <td>
                          <Link to={`/entry/${ne.entry}/history`}>
                            {ne.player_first_name} {ne.player_last_name}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pager>
                  <PagerItem>
                    {pageNewEntries > 1 && (
                      <ButtonLink
                        to={this.buildLink(
                          phase,
                          pageNewEntries - 1,
                          pageStandings
                        )}
                        variant="secondary"
                        fullwidth="true"
                      >
                        <ControlArrowLeft />
                        <span>Previous</span>
                      </ButtonLink>
                    )}
                  </PagerItem>
                  <PagerItemNext>
                    {newEntries.has_next && (
                      <ButtonLink
                        to={this.buildLink(
                          phase,
                          pageNewEntries + 1,
                          pageStandings
                        )}
                        variant="secondary"
                        fullwidth="true"
                      >
                        <Flex
                          justify-content="space-between"
                          alignItems="center"
                        >
                          <span>Next</span>
                          <ControlArrowRight />
                        </Flex>
                      </ButtonLink>
                    )}
                  </PagerItemNext>
                </Pager>
              </>
            )}
          </Main>
          <Secondary>
            {league && (
              <Box mt="0.7rem" mx={2}>
                <ReportNameButton league={league} />
              </Box>
            )}
          </Secondary>
        </Wrapper>
      </>
    );
  }
}

export { StandingsClassic as StandingClassicTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  // Get league id from path
  const leagueNumber = parseInt(ownProps.leagueId!, 10) || 0;

  // Get phase and page numbers from query string
  const query = qs.parse(ownProps.location!.search, {
    ignoreQueryPrefix: true,
  });
  const phase = parseInt(query.phase as string, 10) || 1;
  const pageNewEntries = parseInt(query.page_new_entries as string, 10) || 1;
  const pageStandings = parseInt(query.page_standings as string, 10) || 1;

  return {
    now: getCurrentEvent(state),
    league: getClassicLeague(state, leagueNumber),
    leagueNumber,
    newEntries: getClassicNewEntries(state, leagueNumber, pageNewEntries),
    pageNewEntries,
    pageStandings,
    phase,
    phases: getStartedPhases(state),
    player: getPlayerData(state),
    standings: getClassicStandings(state, leagueNumber, phase, pageStandings),
    statusData: getCurrentEventStatus(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchStandings: (
    leagueId: number,
    phaseId: number,
    pageNewEntries: number,
    pageStandings: number
  ) =>
    dispatch(
      fetchClassicLeagueStandings(
        leagueId,
        pageNewEntries,
        pageStandings,
        phaseId
      )
    ),
  fetchEventStatus: () => dispatch(fetchEventStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandingsClassic);

import React, { useEffect, useState } from "react";
import { fetchNews, IPulseResponse } from "../../utils/pulse";
import NewsList from "../news/NewsList";

interface INewsContentList {
  number: number;
  tag: string;
  title: string;
}

const NewsContentList: React.FC<INewsContentList> = ({
  number,
  tag,
  title,
}) => {
  const [news, setNews] = useState<IPulseResponse | null>(null);
  useEffect(() => {
    async function fetchData() {
      const newsData = await fetchNews(number, tag).catch((e) => {
        return null;
      });
      setNews(newsData);
    }
    fetchData();
  }, [number, tag]);

  if (!news) {
    return null;
  }
  return <NewsList news={news.content} title={title} />;
};

export default NewsContentList;

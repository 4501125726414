import { rgba } from "polished";
import * as React from "react";
import { Button as Base } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";

export const StyledButton = styled(Base)<IButtonStyledProps>`
  display: block;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: 0;
  border-radius: ${({ theme }) => theme.radii[0]};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  box-shadow: 1px 1px 10px -4px ${rgba("black", 0.5)};
  cursor: pointer;

  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.variant === "secondary" &&
    css`
      background: ${({ theme }) => theme.colors.lightPurple};
      color: white;
      box-shadow: 0 1px 8px 0 ${rgba("black", 0.06)};
    `}

  ${(props) =>
    props.variant === "tertiary" &&
    css`
      background: ${({ theme }) => theme.colors.lightGrey};
      color: black;
      font-family: ${({ theme }) => theme.fonts.regular};
      box-shadow: 0 1px 8px 0 ${rgba("black", 0.06)};
    `}

  ${(props) =>
    props.variant === "light" &&
    css`
      background: ${({ theme }) => theme.colors.lightGrey};
      color: black;
      font-family: ${({ theme }) => theme.fonts.regular};
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: #edf1f9;
      background-image: none;
      color: ${theme.colors.darkGrey};
      cursor: auto;
    `}
`;

export interface IButtonStyledProps {
  variant?: "primary" | "secondary" | "tertiary" | "light";
  disabled?: boolean;
  fullwidth?: string;
}

interface IProps {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  width?: number;
}

type Props = IButtonStyledProps & IProps;

const Button: React.FC<Props> = ({
  children,
  variant = "primary",
  type = "button",
  fullwidth,
  ...rest
}) => (
  <StyledButton type={type} variant={variant} fullwidth={fullwidth} {...rest}>
    {children}
  </StyledButton>
);

export default Button;

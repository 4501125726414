import * as Sentry from "@sentry/browser";
import * as React from "react";
import { Wrapper, Main } from "../Layout";
import Copy from "../Copy";

interface IState {
  eventId: string | null;
  error: Error | null;
}

class ScoutErrorBoundary extends React.Component {
  public state: IState = { error: null, eventId: null };

  public componentDidCatch(error: Error | null, errorInfo: {}) {
    this.setState({ error });
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  public render() {
    return this.state.error ? (
      <Wrapper>
        <Main>
          <Copy>Problem showing scout content.</Copy>
        </Main>
      </Wrapper>
    ) : (
      this.props.children
    );
  }
}

export default ScoutErrorBoundary;

import { em } from "polished";
import styled from "styled-components/macro";

export const ScrollTable = styled.div`
  @media (max-width: ${em("480px")}) {
    overflow-x: auto;

    table {
      width: 48rem;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    font-size: 1.1rem;

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      font-size: 1.3rem;
    }
  }

  thead th {
    padding: 0.6rem 1rem;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1rem;
    text-align: left;
  }

  tbody th {
    text-align: left;
  }

  tfoot td {
    font-weight: ${({ theme }) => theme.fonts.bold};
  }
`;

export default Table;

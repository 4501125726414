import * as React from "react";
import styled, { css } from "styled-components/macro";
import { MatchValueBase } from "./Utils";

interface IStyledProps extends IProps {
  rating: number;
}

const StyledFixtureDifficulty = styled.div<IStyledProps>`
  ${MatchValueBase}
  background-color: ${(props) =>
    props.theme.colors.difficulties[props.rating].bg};
  color: ${(props) => props.theme.colors.difficulties[props.rating].color};

  ${(props) =>
    props.isLarge &&
    css`
      @media (min-width: ${props.theme.breakpoints[2]}) {
        padding: ${(props) => `0.8rem ${props.theme.space[1]}`};
        font-size: ${(props) => props.theme.fontSizes[2]};
      }
    `}
`;

interface IProps {
  rating: number | undefined;
  isLarge?: boolean;
}

const FixtureDifficulty: React.FC<IProps> = ({ isLarge = false, rating }) => {
  if (!rating) {
    return null;
  }
  return (
    <StyledFixtureDifficulty isLarge={isLarge} rating={rating}>
      {rating}
    </StyledFixtureDifficulty>
  );
};

export default FixtureDifficulty;

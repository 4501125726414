import { hideVisually, size } from "polished";
import React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getEntry,
  getPrivateClassicLeaguesForEntry,
  getPrivateH2HLeaguesForEntry,
  getPublicClassicLeaguesForEntry,
  getPublicH2HLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IEntry, ILeagueEntry } from "../../core/store/entries/types";
import { leaveLeague } from "../../core/store/leagues/thunks";
import { getPlayerData } from "../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../core/store/player/types";
import { ReactComponent as BaseCog } from "../../img/icons/cog.svg";
import { ReactComponent as BasePlus } from "../../img/icons/plus.svg";
import { ReactComponent as BaseRenew } from "../../img/icons/renew.svg";
import Button from "../Button";
import ButtonLink from "../ButtonLink";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogManager from "../DialogManager";
import { PatternWrapMain } from "../GraphicPatterns";
import { Main, Secondary, Wrapper } from "../Layout";
import LeaderboardAd from "../LeaderboardAd";
import Link, { LinkStyles } from "../Link";
import LinkButton from "../LinkButton";
import TabHeading from "../TabHeading";
import Table from "../Table";
import Title from "../Title";
import { Ellipsis, VisuallyHidden } from "../Utils";
import HelmetHeadLeagues from "./HelmetHeadLeagues";
import LeaguesNav from "./LeaguesNav";
import Movement from "./Movement";
import {
  getBroadcasterLeagues,
  getFilteredSystemClassicLeagues,
  getLeagueUrl,
} from "./utils";

const ActionBar = styled.ul`
  margin-bottom: ${({ theme }) => theme.space[4]}; ;
`;

const ActionBarItem = styled.li`
  display: inline-block;
  margin: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin: ${({ theme }) => theme.space[2]};
  }
`;

const ActionList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
`;

const Icons = css`
  margin-right: 0.4rem;
  fill: ${({ theme }) => theme.colors.primary};
`;

const Plus = styled(BasePlus)`
  ${Icons}
`;

const Renew = styled(BaseRenew)`
  ${Icons}
`;

const MyLeaguesTable = styled(Table)`
  table-layout: fixed;

  th,
  td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`;

const LeagueCol = styled.th`
  width: 40%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 36%;
  }
`;

const MovementCol = styled.th`
  width: 8%;
`;

const RankCol = styled.th`
  width: 24%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 18%;
  }
`;

const OptionsCol = styled.th`
  width: 10%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 20%;
  }
`;

const Cog = styled(BaseCog)`
  ${size(14)};
  margin-right: ${({ theme }) => theme.space[1]};
`;

const Label = styled.span`
  ${LinkStyles}
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${hideVisually}
  }
`;

interface IPropsFromState {
  entry: IEntry | null;
  player: ILoggedInPlayer;
  privateClassicLeagues: ILeagueEntry[];
  privateH2HLeagues: ILeagueEntry[];
  publicClassicLeagues: ILeagueEntry[];
  publicH2HLeagues: ILeagueEntry[];
  systemClassicLeagues: ILeagueEntry[];
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  leave: (leagueId: number) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

class MyLeagues extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchEntrySummary(this.props.player.entry);
  }

  public render() {
    const {
      entry,
      leave,
      privateClassicLeagues,
      privateH2HLeagues,
      publicClassicLeagues,
      publicH2HLeagues,
      systemClassicLeagues,
    } = this.props;
    if (!entry) {
      return null;
    }

    const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
    const filteredSystemClassicLeagues = getFilteredSystemClassicLeagues(
      systemClassicLeagues,
      broadcasterLeagues
    );

    return (
      <>
        <LeaderboardAd slot="Leaderboard_Leagues" id="ism-leagues-ad" />
        <Wrapper>
          <Box flex={1} pt={4} mx={2}>
            <Title>{`Leagues & Cups - ${entry.name}`}</Title>
            <LeaguesNav />
          </Box>
        </Wrapper>
        <Wrapper>
          <HelmetHeadLeagues />
          <Main>
            <PatternWrapMain>
              <ActionBar>
                <ActionBarItem>
                  <ButtonLink to="create-join" variant="light">
                    <Flex alignItems="center">
                      <Plus />
                      <span>Create &amp; Join New Leagues &amp; Cups</span>
                    </Flex>
                  </ButtonLink>
                </ActionBarItem>
                <ActionBarItem>
                  <ButtonLink to="renew" variant="light">
                    <Flex alignItems="center">
                      <Renew />
                      <span>Renew Your Leagues</span>
                    </Flex>
                  </ButtonLink>
                </ActionBarItem>
              </ActionBar>
              <Box mx={2}>
                <Box pb={4}>
                  <LeagueTypeTable
                    title="Invitational Classic Leagues"
                    leagues={privateClassicLeagues}
                    leave={leave}
                  />
                </Box>
                <Box pb={4}>
                  <LeagueTypeTable
                    title="Invitational Head-to-Head Leagues"
                    leagues={privateH2HLeagues}
                    leave={leave}
                  />
                </Box>
                <Box pb={4}>
                  <LeagueTypeTable
                    title="Public Classic Leagues"
                    leagues={publicClassicLeagues}
                    leave={leave}
                  />
                </Box>
                <Box pb={4}>
                  <LeagueTypeTable
                    title="Public Head-to-Head Leagues"
                    leagues={publicH2HLeagues}
                    leave={leave}
                  />
                </Box>
                <Box pb={4}>
                  <LeagueTypeTable
                    title="General Leagues"
                    leagues={filteredSystemClassicLeagues}
                    leave={leave}
                  />
                </Box>
                {broadcasterLeagues.length > 0 && (
                  <Box pb={4}>
                    <LeagueTypeTable
                      title="Broadcaster Leagues"
                      leagues={broadcasterLeagues}
                      leave={leave}
                    />
                  </Box>
                )}
              </Box>
            </PatternWrapMain>
          </Main>
          <Secondary />
        </Wrapper>
      </>
    );
  }
}

export { MyLeagues as MyLeaguesTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  const entry = player.entry;
  return {
    entry: getEntry(state, entry),
    player,
    privateClassicLeagues: getPrivateClassicLeaguesForEntry(state, entry),
    privateH2HLeagues: getPrivateH2HLeaguesForEntry(state, entry),
    publicClassicLeagues: getPublicClassicLeaguesForEntry(state, entry),
    publicH2HLeagues: getPublicH2HLeaguesForEntry(state, entry),
    systemClassicLeagues: getSystemClassicLeaguesForEntry(state, entry),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  leave: (leagueId: number) => {
    if (window.confirm("Are you sure you want to leave this league?")) {
      dispatch(leaveLeague(leagueId));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLeagues);

interface ILeagueTypeTableProps {
  leagues: ILeagueEntry[];
  leave: (leagueId: number) => void;
  title: string;
}

const LeagueTypeTable: React.FC<ILeagueTypeTableProps> = ({
  leagues,
  leave,
  title,
}) => (
  <div>
    <TabHeading title={title} hasBorder={true} />
    {leagues.length === 0 ? (
      <Box m={2}>
        <p>
          No {title.toLowerCase()} joined yet.{" "}
          <Link to="create-join">Create and join new leagues</Link>.
        </p>
      </Box>
    ) : (
      <MyLeaguesTable>
        <thead>
          <tr>
            <LeagueCol scope="col">League</LeagueCol>
            <MovementCol scope="col">
              <VisuallyHidden>Movement</VisuallyHidden>
            </MovementCol>
            <RankCol scope="col">Current Rank</RankCol>
            <RankCol scope="col">Last Rank</RankCol>
            <OptionsCol scope="col">&nbsp;</OptionsCol>
          </tr>
        </thead>
        <tbody>
          {leagues.map((l) => (
            <tr key={l.id}>
              <td>
                <Link to={getLeagueUrl(l.id, l.scoring)}>
                  <Ellipsis>{l.name}</Ellipsis>
                </Link>
              </td>
              <td>
                <Movement lastRank={l.entry_last_rank} rank={l.entry_rank} />
              </td>
              <td>{l.entry_rank ? l.entry_rank.toLocaleString() : "-"}</td>
              <td>
                {l.entry_last_rank ? l.entry_last_rank.toLocaleString() : "-"}
              </td>
              <td>
                <DialogManager
                  render={(showDialog, handleShow, handleHide) => (
                    <>
                      <LinkButton onClick={handleShow}>
                        <Cog />
                        <Label>Options</Label>
                      </LinkButton>
                      {showDialog && (
                        <LeagueMenuDialog
                          leagueEntry={l}
                          leave={leave}
                          handleHide={handleHide}
                        />
                      )}
                    </>
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </MyLeaguesTable>
    )}
  </div>
);

interface ILeagueMenuDialogProps {
  handleHide: () => void;
  leagueEntry: ILeagueEntry;
  leave: (leagueId: number) => void;
}

const LeagueMenuDialog: React.FC<ILeagueMenuDialogProps> = ({
  handleHide,
  leagueEntry,
  leave,
}) => (
  <Dialog closeDialog={handleHide}>
    <Dialog.Header closeDialog={handleHide}>{leagueEntry.name}</Dialog.Header>
    <Dialog.Body isPadded={true}>
      <ActionList>
        <DialogButtonItem>
          <ButtonLink
            to={`/leagues/${leagueEntry.id}/standings/${leagueEntry.scoring}`}
            fullwidth="true"
            variant="secondary"
          >
            Standings
          </ButtonLink>
        </DialogButtonItem>
        {leagueEntry.entry_can_leave && (
          <DialogButtonItem>
            <Button
              onClick={() => {
                leave(leagueEntry.id);
                handleHide();
              }}
              fullwidth="true"
              variant="tertiary"
            >
              Leave league
            </Button>
          </DialogButtonItem>
        )}
        {leagueEntry.entry_can_admin && (
          <DialogButtonItem>
            <ButtonLink
              to={`/leagues/${leagueEntry.id}/admin/${leagueEntry.scoring}`}
              fullwidth="true"
            >
              Administer
            </ButtonLink>
          </DialogButtonItem>
        )}
        {leagueEntry.entry_can_invite && (
          <DialogButtonItem>
            <ButtonLink
              to={`/leagues/${leagueEntry.id}/invite`}
              fullwidth="true"
            >
              Invite Friends
            </ButtonLink>
          </DialogButtonItem>
        )}
      </ActionList>
    </Dialog.Body>
  </Dialog>
);

import { IChipBadgeProps } from "./types";
import { StyledChipBadge } from "./styles";

const ChipBadge: React.FC<IChipBadgeProps> = ({ path, sizes }) => (
  <StyledChipBadge
    src={`${path}-120.png`}
    srcSet={`
        ${path}-120.png 120w,
        ${path}-240.png 240w,
        ${path}-480.png 480w,
        `}
    sizes={sizes}
    alt=""
  />
);

export default ChipBadge;

import React from "react";
import { ShirtValues } from "../EntryUpdate";

const style8 = {
  fill: "#94948d",
};

type Props = {
  shirtStyle: ShirtValues;
  shirtColor: string;
  shirtStyleColor: string;
  sleeveColor: string;
};

const Shirt: React.FunctionComponent<Props> = ({
  shirtStyle,
  shirtColor,
  sleeveColor,
  shirtStyleColor,
}) => {
  const stripesDisplay = shirtStyle === "stripes" ? "inline" : "none";
  const hoopsDisplay = shirtStyle === "hoops" ? "inline" : "none";
  return (
    <>
      <path
        id="shirt_colour"
        d="M228.2 2c-16.8 7.2-42.7 14.1-66.3 11.8-6.9-.7-13.2-3.8-18.9-3.6-9.6.5-15.7 19.7-24.9 21.3-10.3 6.6-22.9 11-33.1 17.7h-1.2c-4.9 1.8-10.1 3.4-13 7.1h-1.2c-11.1 5.1-17.5 7.3-26 15-.6-3.8-12.1 48.4-8.4 91.6 4.3 49.9 23 95.8 23.3 95.8.6 6.4-.6 20 .5 29.5v124.4c-1.5 36.5-.7 57.8-5.9 82.8 31.3 16 142.8 29.6 143.2 29.6 37.4 3 72.6 7.3 99.1-3.4 10-3.3 18.4-24.3 13.4-34.5-5.5-51-31.2-86.4-24.9-145.5 1.1-10.1.4-11.4 1.2-16.6-.3-24.3 13.9-79.1 14.9-84.9 1.6 3.8 46.1-159.2 42.9-165-19.7-24-57.7-30.2-85-51.8-3.7-1.1-6.5-2.9-9.5-4.7A144.4 144.4 0 0 1 229.3 2h-1.2z"
        fill={shirtColor}
      />
      <g id="stripes_group" data-name="stripes group">
        <path
          id="stripe_1"
          data-name="stripe 1"
          d="M68 58c-6 33.3-3.5 115.6-1.9 203.6 2.3 95.9 3.7 196.2 2.5 239.3 5.8 1.4 12.1 2.2 17.5 4 7.5.5 12.7 3.3 19.7 4.4.3-65.4-2.1-168.6-3.8-264.1-1.3-88.2-1.9-168.8 5.6-207.9C99.9 41.1 68.5 57.7 68 58z"
          fill={shirtStyleColor}
          display={stripesDisplay}
        />
        <path
          id="stripe_2"
          data-name="stripe 2"
          d="M184.6 62.6c-14.4 4.9-29 6.4-40.3 2.3-6.9 89.5-.8 344.7.2 452.9q10.7 1.6 22.1 2.5c6.6 1.2 14 1.7 21 2.5-6.3-151.9-11.1-397.4-3-460.2z"
          fill={shirtStyleColor}
          display={stripesDisplay}
        />
        <path
          id="stripe_3"
          data-name="stripe 3"
          d="M276.6 526.2c-13-92.1-15.8-186.3-15.6-279.2.1-43.7 1-87.7 5.2-131.3 2.3-24 2.8-57.3 16.9-78.1-12-6-25.6-13.4-36.3-21.4C205.2 70.4 225 431.4 233.6 528c13.9 2.3 42.1-1.8 43-1.8z"
          fill={shirtStyleColor}
          display={stripesDisplay}
        />
      </g>
      <g id="hoops_group" data-name="hoops group">
        <path
          id="hoop_1"
          data-name="hoop 1"
          d="M54.2 64.3a79.8 79.8 0 0 0-10.4 6.9c-1 9.8-1.5 20.3-1.6 30.9 81.1 13.1 180.9 12.5 256.4 9.2a385.4 385.4 0 0 1 15.8-43.1c-68.3 2.3-153.3 8.2-260.2-3.9z"
          fill={shirtStyleColor}
          display={hoopsDisplay}
        />
        <path
          id="hoop_2"
          data-name="hoop 2"
          d="M287.4 181.6a174.6 174.6 0 0 1 1.2-23.5c-60.9 4.1-145.8 4.4-244.9-10.1 1.2 17.2 2.8 32.7 4.2 43.5 74.1 15.3 171.1 16.5 244 13.6a78.2 78.2 0 0 1-4.5-23.5z"
          fill={shirtStyleColor}
          display={hoopsDisplay}
        />
        <path
          id="hoop_3"
          data-name="hoop 3"
          d="M55.4 240.5l3 18.3c-1.1-.1-.1 15.2.1 24.1 71.1 9.8 159.7 18 229.5 12 2.2-10.7 10.1-42 10.4-44.7-61.5 5.8-161-1.2-243-9.7z"
          fill={shirtStyleColor}
          display={hoopsDisplay}
        />
        <path
          id="hoop_4"
          data-name="hoop 4"
          d="M284.9 386.8a171.9 171.9 0 0 1-1.2-44.9c-59.8 5.9-136.9-1.7-224.8-13.5v44.4c71.2 12.1 151.3 20.5 226 14z"
          fill={shirtStyleColor}
          display={hoopsDisplay}
        />
        <path
          id="hoop_5"
          data-name="hoop 5"
          d="M275.4 479.2c13.8-1.6 22.8-3.4 31.2-5.9-2.7-14.7-6.7-28.3-10.8-41.9-5.5 1.2-13.2 3.4-23.9 4.2-56.7 4.3-131.6-1.3-213.1-20.4-.6 15.9-.8 28.9-1.3 40.6 57 16 145.8 31.9 217.9 23.4z"
          fill={shirtStyleColor}
          display={hoopsDisplay}
        />
      </g>
      <path
        id="shirt_sleeve_left_colour"
        data-name="shirt sleeve left colour"
        d="M50 208s-12.2-79.6-6.2-136.8c-.6.5-19.5 21.1-22.7 33.7-1 7.2-4.2 12.3-4.7 20.1v1.2c-3.9 8.8-4 21.2-7.1 30.8v5.9c-2.7 9.1 1 18.6 1.2 28.4C5 204 8.1 225.4.9 236.3v1.2c3.1 4.8 8.3 7.4 13 10.6 6.3 2.3 12.5 4.9 20.1 5.9 5.6 3 16.8 3.8 24.4 4.8z"
        fill={sleeveColor}
      />
      <path
        id="shirt_sleeve_right_colour"
        data-name="shirt sleeve right colour"
        d="M319.6 56.7c-17.1 36-33.1 84.4-32.2 124.9.4 18.8 9 34.2 15.4 51.3 3.5 9-.7 21.2 5.8 28.1 8.8 9.2 78.3-18.6 92.8-26.6-5.4-7.3-21.9-80.6-39.5-125.2-15.7-39.5-39.5-50.9-42.3-52.5z"
        fill={sleeveColor}
      />
      <path
        id="shirt_grey_tint_fill"
        data-name="shirt grey tint fill"
        opacity="0.2"
        d="M137.5 12.6s-13.7 43.2 25 43.2C230.7 40.2 230.7 2 230.7 2h-2.6c-16.8 7.2-42.7 14.1-66.3 11.8-6.9-.7-13.2-3.8-18.9-3.6a10.3 10.3 0 0 0-6.1 2.8z"
      />
      <path
        id="Main_shirt_outline"
        data-name="Main shirt outline"
        style={{
          fill: "none",
          stroke: "#94948d",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "4px",
        }}
        d="M54 494.8s8.9-123.3 6.7-162.6-2.5-73.8-2.5-73.8-31.5-3.7-40.5-7.4S2 238.9 2 238.9s4.6-23.1 7.1-49.8c.6-6.2-.5-15.7 0-22.2 2.5-29.6 8.9-54.1 12-62.1C28.8 84.8 43.3 71 43.3 71s78-39.8 81.7-44.6 11-17.6 15.9-17 22.8 5.8 38.7 5.8S220 2 229 2c5.4 0 11 10.6 25.8 20.2 10.8 7 64.7 34.5 64.7 34.5s14.1 7 28 26.7c9 12.8 18 34.5 22.7 50.7 6.8 23.3 14 49.7 20.5 69.6 4.6 14.1 9.6 24.5 10.2 30.2S341 258.4 331.5 261s-22.8 3.7-23.4.5-6.4-29.2-6.4-29.2-21.7 72.9-19 128.2c2.2 45.1 27.7 121.3 27.4 134.9s-5.8 33.4-58.3 33.4-198.4-23.4-197.8-34z"
      />
      <path
        style={style8}
        d="M232.6 8.5l3.6 2.4c-1.1 10.8-10.6 23.4-25.9 34.1s-35.7 18.2-51.2 18.2c-10.1 0-17.9-2.8-23.2-8.3s-8.4-14.7-8.3-26.4l6-8.4c-1.6 13.2.3 23.1 5.6 29.8s11.7 8.8 20.5 8.8c13.4 0 30.7-7 46.3-18.6 13.3-9.9 22.8-21.3 26.5-31.7M230.2 2c-3.1 22.8-43.8 52.8-70.3 52.8-16.8 0-27.9-11.9-20.2-45.1a21.7 21.7 0 0 0-8.4 7.1l-7.5 10.6c-.9 29 15.1 39.9 35.4 39.9 34.3 0 81.2-31 81.2-58.3l-10.2-7z"
        id="Collar_Outline"
        data-name="Collar Outline"
      />
    </>
  );
};

export default Shirt;

import * as React from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";

const StyledLatestAlert = styled.div`
  padding: ${(props) => props.theme.space[1]} ${(props) => props.theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[0]};
  background: ${({ theme }) => theme.colors.lightPurple};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.bold};
`;

// const StyledAnchor = styled.a`
//   color: ${({ theme }) => theme.colors.white};
//   text-decoration: underline;
//   font-family: ${({ theme }) => theme.fonts.bold};
// `;

const LatestAlert: React.FC<any> = () => {
  const showAlert = false;
  if (!showAlert) {
    return null;
  }
  return (
    <Box my={2}>
      <StyledLatestAlert>
        <p>
          Gameweek 17 will remain open after the conclusion of Sunday’s matches.
          More information will follow in due course.
        </p>
      </StyledLatestAlert>
    </Box>
  );
};

export default LatestAlert;

import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState } from "../../core/store";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import HelmetHeadLeagues from "./HelmetHeadLeagues";

interface IPropsFromState {
  nxt: IEvent | null;
}

const Create: React.FC<IPropsFromState> = ({ nxt }) => (
  <Wrapper>
    <HelmetHeadLeagues />
    <Main>
      <Copy>
        <Title>Choose a League Type to Create</Title>
        <Box mb={4}>
          <SubHeading>Classic Scoring</SubHeading>
          <p>
            In a league with classic scoring, teams are ranked based on their
            total points in the game. You can join or leave a league with
            classic scoring at any point during the season.
          </p>
          <ButtonLink to="classic">Create a League &amp; Cup</ButtonLink>
        </Box>
        <Box mb={4}>
          <Box mb={2}>
            <SubHeading>Head-to-Head Scoring</SubHeading>
          </Box>
          <p>
            In a league with head-to-head scoring, every team plays a match
            against another team in the league each Round. The match result is
            based on the Round score of each team minus any transfer points
            spent preparing for the Round.
          </p>
          <p>
            The Head-to-Head schedule is generated at the start of the league's
            first Round. Once the schedule has been generated the league is
            locked and teams will not be able to join or leave.
          </p>
        </Box>
        {nxt ? (
          <ButtonLink to="h2h">Create Head-to-Head League</ButtonLink>
        ) : (
          <p>
            It is no longer possible to create head-to-head leagues as the last
            deadline has passed.
          </p>
        )}
      </Copy>
    </Main>
  </Wrapper>
);

export { Create as CreateTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  nxt: getNextEvent(state),
});

export default connect(mapStateToProps)(Create);

import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import { IMostValuableTeam, IBestLeague } from "./types";
import * as actions from "./actions";

export const fetchBestClassicPrivateLeagues =
  (): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
      await apiGet<IBestLeague[]>(
        `stats/best-classic-private-leagues/`,
        dispatch,
        actions.fetchBestClassicPrivateLeagues
      );
    } catch (e) {
      return;
    }
  };

export const fetchMostValuableTeams =
  (): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
      await apiGet<IMostValuableTeam[]>(
        `stats/most-valuable-teams/`,
        dispatch,
        actions.fetchMostValuableTeams
      );
    } catch (e) {
      return;
    }
  };

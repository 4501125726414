import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ThunkDispatch } from "../../core/store";
import { getAllStatsForFixture } from "../../core/store/fixtures/reducers";
import { fetchFixtureAllStats } from "../../core/store/fixtures/thunks";
import FixtureStatsTable from "./FixtureStatsTable";
import { IFixtureDetailedStats } from "./types";

const FixtureDetailedStats: React.FC<IFixtureDetailedStats> = ({ fixture }) => {
  const allStatsForFixture = useSelector((state: RootState) =>
    getAllStatsForFixture(state, fixture.id)
  );

  const reduxDispatch = useDispatch<ThunkDispatch>();

  useEffect(() => {
    !allStatsForFixture && reduxDispatch(fetchFixtureAllStats(fixture.id));
  }, [fixture, reduxDispatch, allStatsForFixture]);

  if (!allStatsForFixture) {
    return null;
  }

  return (
    <FixtureStatsTable
      fixture={fixture}
      fixtureStats={allStatsForFixture.h
        .concat(allStatsForFixture.a)
        .sort((a, b) => b.total_points - a.total_points)}
    />
  );
};

export default FixtureDetailedStats;

import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import useClipboard from "react-use-clipboard";
import { RootState, ThunkDispatch } from "../../core/store";
import { getLeagueForEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { ILeagueEntry } from "../../core/store/entries/types";
import { getCode } from "../../core/store/leagues/reducers";
import { fetchLeagueCode } from "../../core/store/leagues/thunks";
import { getPlayerData } from "../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../core/store/player/types";
import Button from "../Button";
import Copy from "../Copy";
import { ControlArrowRight } from "../icons/Arrows";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import HelmetHeadLeagues from "./HelmetHeadLeagues";

const LeagueInvite = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.fantasy};
  font-size: ${({ theme }) => theme.space[3]};
  text-align: center;
`;

const LeagueInviteCode = styled.strong`
  margin-left: ${({ theme }) => theme.space[1]};
  color: white;
`;

const Hr = styled.hr`
  border: 0;
  height: 1px;
  margin-top: 23px;
  margin-bottom: 23px;
  background-color: ${({ theme }) => theme.colors.darkGrey};
`;

const inviteMessage = (code: string, leagueName: string) => [
  "Hello,",
  `For the 2024/25 Fantasy Premier League why don't you join my mini-league "${leagueName}"?`,
  `Joining the league couldn't be easier. Simply use the link below and you'll be added automatically after you've entered the game.`,
  `${window.location.origin}/leagues/auto-join/${code}`,
  `League Code: ${code}`,
  "Looking forward to playing against you this season!",
];

type OwnProps = RouteComponentProps<{ leagueId: string }>;

interface IPropsFromState {
  code: string | null;
  entryId: number;
  leagueNumber: number;
  league: ILeagueEntry | null;
}

interface IPropsFromDispatch {
  fetchCode: (leagueId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

const Invite: React.FC<Props> = ({
  code,
  league,
  leagueNumber,
  entryId,
  fetchCode,
  fetchEntrySummary,
}) => {
  const codeToCopy = code ? code : "";
  const leagueName = league ? league.name : "";
  const message = inviteMessage(codeToCopy, leagueName);
  const emailText = message ? message.join("\n\n") : "";
  const emailBody = encodeURIComponent(emailText);
  const joinUrl = `${window.location.origin}/leagues/auto-join/${code}`;
  const [isCopied, setCopied] = useClipboard(codeToCopy, {
    successDuration: 500,
  });
  const [isCopiedUrl, setCopiedUrl] = useClipboard(joinUrl, {
    successDuration: 500,
  });
  const [isCopiedEmail, setCopiedEmail] = useClipboard(emailText, {
    successDuration: 500,
  });

  useEffect(() => {
    if (leagueNumber) {
      fetchCode(leagueNumber);
    }

    fetchEntrySummary(entryId);
  }, [leagueNumber, entryId, fetchCode, fetchEntrySummary]);

  if (!codeToCopy || !leagueNumber || !league) {
    return null;
  }

  return (
    <Wrapper>
      <HelmetHeadLeagues />
      <Main>
        <Copy>
          <Title>Invite players to join {leagueName}</Title>
          <LeagueInvite onDoubleClick={setCopied}>
            Code to join this league:{" "}
            <LeagueInviteCode>
              {isCopied ? "Copied!" : codeToCopy}
            </LeagueInviteCode>
          </LeagueInvite>

          <Button onClick={setCopiedUrl}>
            {isCopiedUrl ? "Copied!" : "Copy auto-join link"}
          </Button>

          <Hr />

          <p>
            When you click the link below, the link will attempt to open your
            default email program and start a new email. If you do not have a
            default email program, like Outlook or Mail, enabled on your
            computer, clicking the link may not result in any action or you may
            be prompted to set up an email program.
          </p>

          <Button
            onClick={() =>
              (window.location.href = `mailto:?to=&subject=Play%20Fantasy%20Premier%20League&body=${emailBody}`)
            }
          >
            Share league code by email <ControlArrowRight />
          </Button>

          <Hr />

          <p>
            If you use a browser based webmail application, like Gmail or
            Yahoo!, you can copy and paste the text below:
          </p>

          <Box bg="lightGrey" p={4} mb={3} onDoubleClick={setCopiedEmail}>
            {message.map((line) => (
              <p key={line}>{line}</p>
            ))}
          </Box>

          <Button onClick={setCopiedEmail}>
            {isCopiedEmail ? "Copied!" : "Copy email text"}
          </Button>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export { Invite as InviteTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  const entry = player.entry;
  const leagueNumber =
    ownProps.leagueId && parseInt(ownProps.leagueId, 10)
      ? parseInt(ownProps.leagueId, 10)
      : 0;
  return {
    code: leagueNumber ? getCode(state, leagueNumber) : null,
    league: leagueNumber ? getLeagueForEntry(state, entry, leagueNumber) : null,
    leagueNumber,
    entryId: entry,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchCode: (leagueId: number) => dispatch(fetchLeagueCode(leagueId)),
  fetchEntrySummary: (entryId: number) => dispatch(fetchEntrySummary(entryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invite);

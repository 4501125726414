import * as Sentry from "@sentry/browser";
import { hiDPI, size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { ThemeProvider } from "styled-components/macro";
import GlobalStyle from "../GlobalStyle";
import { SaveProvider } from "../contexts/SaveContext";
import { RootState, ThunkDispatch } from "../core/store";
import { getBootstrapped } from "../core/store/bootstrap/reducers";
import { bootstrap } from "../core/store/bootstrap/thunks";
import { getServerError } from "../core/store/global/reducers";
import { IServerError } from "../core/store/global/types";
import "../fonts.css";
import { ReactComponent as BaseHeadlineLogo } from "../img/headline-logo.svg";
import Theme from "../theme";
import { IPlayerCompProps, getPlayerComp } from "../utils/player-comps";
import pattern2Desktop from "./../img/pattern/pattern-2-d.png";
import pattern2Desktop2x from "./../img/pattern/pattern-2-d@2x.png";
import pattern2Mobile from "./../img/pattern/pattern-2-m.png";
import pattern2Mobile2x from "./../img/pattern/pattern-2-m@2x.png";
import ElementDialog from "./ElementDialog";
import FixtureBroadcasters from "./FixtureBroadcasters";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import Routes from "./Routes";
import ServerError from "./ServerError";
import AutoJoinDialog from "./leagues/AutoJoinDialog";
import Navigation from "./nav/Navigation";

const GameHeader = styled.div`
  background-image: url(${pattern2Mobile}), url(${pattern2Mobile}),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.lightBlue},
      ${({ theme }) => theme.colors.darkBlue}
    );
  background-repeat: no-repeat;
  background-position: right 140px bottom -15px, right -18px bottom 27px, 0;
  background-size: 220px 312px, 220px 312px, auto;

  ${hiDPI(2)} {
    background-image: url(${pattern2Mobile2x}), url(${pattern2Mobile2x}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-image: url(${pattern2Desktop}), url(${pattern2Desktop}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-position: 50% -460px, right -73px bottom -24px, 0;
    background-size: 618px 873px, 618px 873px, auto;

    ${hiDPI(2)} {
      background-image: url(${pattern2Desktop2x}), url(${pattern2Desktop2x}),
        linear-gradient(
          to right,
          ${({ theme }) => theme.colors.lightBlue},
          ${({ theme }) => theme.colors.darkBlue}
        );
    }
  }
`;

const StyledPlayerComp = styled.div<IPlayerCompProps>`
  /* Until we come up with a wrapper without Flex */
  flex: 1;
  background-image: url(${(props) => props.images.x1});
  background-repeat: no-repeat;
  background-position: right 2rem bottom;
  background-size: 175px 117px;

  ${hiDPI(2)} {
    background-image: url(${(props) => props.images.x2});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-size: 360px 240px;
  }
`;

const HeadlineLogo = styled(BaseHeadlineLogo)`
  ${size(32, 139)}
  margin: 2.8rem 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(104, 453)}
    margin: 5.6rem 0 3.5rem;
  }
`;

interface IProps {
  bootstrapped: boolean;
  serverError: IServerError | null;
}

export const Loading: React.FC = () => (
  <Wrapper>
    <Main>
      <Box mx={2}>Loading ...</Box>
    </Main>
  </Wrapper>
);

const App: React.FC<IProps> = ({ bootstrapped, serverError }) => {
  return (
    <React.StrictMode>
      <HelmetHead
        title="Fantasy Premier League, Official Fantasy Football Game of the Premier League"
        description="Official Fantasy Premier League 2024/25. Free to play fantasy football game, set up your fantasy football team at the Official Premier League site."
      />
      <GlobalStyle />
      <ThemeProvider theme={Theme}>
        <>
          <GameHeader>
            <Wrapper>
              <StyledPlayerComp images={getPlayerComp()}>
                <Box px={2}>
                  <HeadlineLogo id="ism-game-title" title="Fantasy" />
                  <Navigation />
                </Box>
              </StyledPlayerComp>
            </Wrapper>
          </GameHeader>
          {serverError ? (
            <ServerError error={serverError} />
          ) : bootstrapped ? (
            <FixtureBroadcasters>
              <Routes />
            </FixtureBroadcasters>
          ) : (
            <Loading />
          )}
          <AutoJoinDialog />
          <ElementDialog />
        </>
      </ThemeProvider>
    </React.StrictMode>
  );
};

interface IContainerPropsFromState {
  bootstrapped: boolean;
  serverError: IServerError | null;
}

interface IState {
  eventId: string | null;
  error: Error | null;
}

interface IContainerPropsFromDispatch {
  bootstrapApp: () => Promise<void>;
}
type ContainerProps = IContainerPropsFromState & IContainerPropsFromDispatch;
class AppContainer extends React.Component<ContainerProps, IState> {
  public state: IState = { error: null, eventId: null };

  public componentDidMount() {
    this.props.bootstrapApp();
  }

  public componentDidCatch(error: Error | null, errorInfo: {}) {
    this.setState({ error });
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  public render() {
    // JS Error
    if (this.state.error) {
      // Can't reliably use any of our components, eg Button
      return (
        <>
          <h2>Error</h2>
          <p>We're sorry - something's gone wrong.</p>
          {process.env.REACT_APP_SENTRY_ENVIRONMENT && (
            <>
              <p>
                Our team has been notified, but click here to fill out a report.
              </p>
              <button
                onClick={() =>
                  Sentry.showReportDialog({
                    eventId: this.state.eventId as string,
                  })
                }
              >
                Report feedback
              </button>
            </>
          )}
        </>
      );
    }
    // All OK - Render App
    return (
      <SaveProvider>
        <App
          bootstrapped={this.props.bootstrapped}
          serverError={this.props.serverError}
        />
      </SaveProvider>
    );
  }
}

const mapStateToProps = (state: RootState): IContainerPropsFromState => ({
  bootstrapped: getBootstrapped(state),
  serverError: getServerError(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch
): IContainerPropsFromDispatch => ({
  bootstrapApp: () => dispatch(bootstrap()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);

import { ellipsis } from "polished";
import * as React from "react";
import styled from "styled-components/macro";
import { IElement } from "../core/store/elements/types";
import { ITeam } from "../core/store/teams/types";
import Media from "./Media";
import ElementShirt from "./ElementShirt";
import { connect } from "react-redux";
import { getElementTypesById } from "../core/store/element-types/reducers";
import { RootState } from "../core/store";
import { IElementTypesById } from "../core/store/element-types/types";

const MenuButton = styled.button`
  ${ellipsis()};
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  font-family: ${(props) => props.theme.fonts.regular};
  text-align: left;
  cursor: pointer;
`;

const Name = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  ${ellipsis()};
`;

const Info = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

const Team = styled.span`
  margin-right: ${({ theme }) => theme.space[2]};
`;

interface IPropsFromState {
  elementTypesById: IElementTypesById;
}

interface IOwnProps {
  element: IElement;
  renderElementMenu: () => void;
  team: ITeam;
}

type Props = IPropsFromState & IOwnProps;

const ElementInTable: React.FC<Props> = ({
  element,
  elementTypesById,
  renderElementMenu,
  team,
}) => {
  const Shirt = <ElementShirt elementId={element.id} sizes="24px" />;
  return (
    <MenuButton onClick={renderElementMenu}>
      <Media img={Shirt} centred={true}>
        <Name>{element.web_name}</Name>
        <Info>
          <Team>{team.short_name}</Team>
          <span>
            {elementTypesById[element.element_type].singular_name_short}
          </span>
        </Info>
      </Media>
    </MenuButton>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementTypesById: getElementTypesById(state),
});

export default connect(mapStateToProps)(ElementInTable);

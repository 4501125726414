import { IElementsById } from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import ElementFixtureBar from "../ElementFixtureBar";

const valueForPlayerElement =
  (initProps: {
    elementsById: IElementsById;
    eventId: number;
    fixturesForEventByTeam: IFixturesForEventByTeam;
  }) =>
  (pick: IPickLight) => {
    const element = initProps.elementsById[pick.element];
    const elementFixtures = initProps.fixturesForEventByTeam[element.team];
    const isStarted = elementFixtures.some((fixture) => fixture.started);

    return (
      <ElementFixtureBar
        elementFixtures={elementFixtures}
        eventId={initProps.eventId}
        isStarted={isStarted}
        pick={pick}
      />
    );
  };

export default valueForPlayerElement;

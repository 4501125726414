import React from "react";
import { Box } from "rebass/styled-components";
import { IPrivateLeagueAdmin } from "../../../core/store/leagues/types";
import Button from "../../Button";
import { FieldWrap } from "../../FieldRenderers";
import SubHeading from "../../SubHeading";
import EntrySelector from "./EntrySelector";

interface IProps {
  updateAdmin: (entryId: number) => void;
  league: IPrivateLeagueAdmin;
}

interface IState {
  entry: number;
}

class ChangeAdmin extends React.Component<IProps, IState> {
  public state: IState = {
    entry: 0,
  };

  public handleEntryChange = (entryId: number) => {
    this.setState({ entry: entryId });
  };

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.updateAdmin(this.state.entry);
  };

  public render() {
    const disabled = !this.state.entry;
    const { league } = this.props;
    return (
      <>
        <Box mb={2}>
          <SubHeading>Change League Administrator</SubHeading>
        </Box>
        <form onSubmit={this.handleSubmit}>
          <FieldWrap>
            <EntrySelector
              handleChange={this.handleEntryChange}
              leagueId={league.id}
              excluded={[league.admin_entry]}
            />
          </FieldWrap>
          <FieldWrap>
            <Button type="submit" disabled={disabled}>
              Change administrator
            </Button>
          </FieldWrap>
        </form>
      </>
    );
  }
}

export default ChangeAdmin;

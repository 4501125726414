import { navigate } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getJoinPublicLeagueError } from "../../core/store/leagues/reducers";
import { joinPublicLeague } from "../../core/store/leagues/thunks";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import HelmetHeadLeagues from "./HelmetHeadLeagues";

interface IPropsFromState {
  error: IError | null;
  nxt: IEvent | null;
  settings: ISettings;
}

interface IPropsFromDispatch {
  joinPublicLeagueClassic: () => void;
  joinPublicLeagueH2H: () => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

const JoinPublic: React.FC<Props> = ({
  error,
  joinPublicLeagueClassic,
  joinPublicLeagueH2H,
  nxt,
  settings,
}) => {
  if (!nxt) {
    setTimeout(() => navigate("/", { replace: true }), 0);
    return null;
  }
  return (
    <Wrapper>
      <HelmetHeadLeagues />
      <Main>
        <Copy>
          <Title>Join Public League</Title>
          {error ? (
            error.badRequest &&
            error.badRequest.non_field_errors &&
            error.badRequest.non_field_errors[0].code ===
              "public_league_max_exceeded" ? (
              <Box mb={2}>
                <Alert type="error">
                  You may only enter {settings.league_join_public_max} public
                  leagues
                </Alert>
              </Box>
            ) : (
              <p>{JSON.stringify(error)}</p>
            )
          ) : (
            ""
          )}
          <Box mb={4}>
            <p>
              Public leagues allow you to compete against{" "}
              {settings.league_max_size_public_classic} randomly assigned game
              players in a classic league and up to{" "}
              {settings.league_max_size_public_h2h} in head-to-head leagues. You
              can join up to {settings.league_join_public_max} public leagues.
            </p>
            <p>
              <strong>
                Note, you can't remove your team from a public league after the
                league has started, once the challenge is on there's no
                quitting.
              </strong>
            </p>
          </Box>

          <Box mb={4}>
            <SubHeading>Classic Scoring</SubHeading>
            <p>
              In a league with classic scoring, teams are ranked based on their
              total points in the game. You can join or leave a league with
              classic scoring at any point during the season
            </p>
            <Button onClick={joinPublicLeagueClassic}>
              Join Classic League
            </Button>
          </Box>
          <SubHeading>Head-to-Head Scoring</SubHeading>
          <p>
            In a league with head-to-head scoring, every team plays a match
            against another team in the league each Round. The match result is
            based on the Round score of each team minus any transfer points
            spent preparing for the Round.
          </p>
          <Button onClick={joinPublicLeagueH2H}>
            Join Head-to-Head League
          </Button>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export { JoinPublic as JoinPublicTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  error: getJoinPublicLeagueError(state),
  nxt: getNextEvent(state),
  settings: getSettings(state) as ISettings,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  joinPublicLeagueClassic: () => dispatch(joinPublicLeague({ scoring: "c" })),
  joinPublicLeagueH2H: () => dispatch(joinPublicLeague({ scoring: "h" })),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinPublic);

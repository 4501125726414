import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
// Components
import { Box } from "rebass";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  getCurrentEvent,
  getEventsById,
  getNextEvent,
} from "../../core/store/events/reducers";
// Types
import { IEvent } from "../../core/store/events/types";
import { getFixturesByEvent } from "../../core/store/fixtures/reducers";
// Actions
import { getTeamsById } from "../../core/store/teams/reducers";
import { formatRawAsISO, formatRawAsLocal } from "../../core/utils/datetime";
import theme from "../../theme";
import { fetchNewsArticles } from "../../utils/pulse";
import Badge from "../Badge";
import DeadlineBar from "../DeadlineBar";
import { NewTabCopy } from "../Utils";
import { transformNewsArticles } from "./helpers";
import {
  ExternalIcon,
  Hashtag,
  LogoWrapper,
  SliderArticle,
  SliderArticles,
  SliderHeader,
  StyledArticleTitle,
  StyledArticleTitleAnchor,
  StyledNewsTickerHeader,
  StyledNewsTickerSlider,
  StyledSlide,
  StyledSlideInner,
  StyledSlider,
} from "./styles";
import { INewsByFixtureId } from "./types";

const SLICK_SETTINGS = {
  className: "ism-top-element-carousel",
  infinite: true,
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: theme.breakpointsArray[4],
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: theme.breakpointsArray[1],
      settings: {
        variableWidth: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

type Props = {
  eventId?: number;
};

const NewsTicker: React.FunctionComponent<Props> = ({ eventId }) => {
  const teamsById = useSelector(getTeamsById);
  const eventsById = useSelector(getEventsById);
  const currentEvent = useSelector(getCurrentEvent);
  const nextEvent = useSelector(getNextEvent);
  const fixturesByEvent = useSelector(getFixturesByEvent);

  let localEventId = 0;
  if (eventId) {
    localEventId = eventId;
  } else if (nextEvent) {
    localEventId = nextEvent.id;
  } else if (currentEvent) {
    localEventId = currentEvent.id;
  }

  const fixtures = useMemo(
    () => fixturesByEvent[localEventId] || [],
    [fixturesByEvent, localEventId]
  );

  const event = eventsById[localEventId];

  const [news, setNewsData] = useState<INewsByFixtureId>({});
  const enoughFixtures = Object.keys(fixtures).length >= 4;
  const fixturesWithNews = fixtures.reduce(
    (acc, f) =>
      f.pulse_id &&
      news[f.pulse_id] &&
      (news[f.pulse_id][f.team_h] || news[f.pulse_id][f.team_a])
        ? acc + 1
        : acc,
    0
  );
  const enoughNews = fixturesWithNews >= 4;

  useEffect(() => {
    async function fetchNewsData(event: IEvent) {
      try {
        // Create the referenceExpression from pulse fixture ids
        const referenceExpression = fixtures.map(
          (fixture) => `"FOOTBALL_FIXTURE:${fixture.pulse_id}"`
        );

        // Fetch news articles for all fixtures
        const rawNewsArticles = await fetchNewsArticles(
          referenceExpression.join("or")
        );

        // Transform them into an object we can easily use:
        // { [fixture.id] : { [team.id]...newsArticle } }
        const transformedNewsArticles = transformNewsArticles(
          rawNewsArticles,
          teamsById
        );

        setNewsData(transformedNewsArticles);
      } catch (error) {
        console.error(error);
      }
    }
    if (event && enoughFixtures) {
      fetchNewsData(event);
    }
  }, [event, teamsById, enoughFixtures, fixtures]);

  if (!event || !enoughFixtures || !enoughNews) {
    return null;
  }

  return (
    <div>
      <StyledNewsTickerHeader>
        <Box mb={2}>
          {event && (
            <DeadlineBar
              altDeadline="Team News"
              deadlineISO={formatRawAsISO(event.deadline_time)}
              deadlineLocal={formatRawAsLocal(event.deadline_time)}
              heading={`${event.name}`}
              label={`${event.name}`}
            />
          )}
        </Box>
      </StyledNewsTickerHeader>
      <StyledNewsTickerSlider>
        <StyledSlider {...SLICK_SETTINGS}>
          {fixtures.map((fixture) => {
            const homeTeamObj = teamsById[fixture.team_h];
            const awayTeamObj = teamsById[fixture.team_a];

            // If there is no team found for that fixtureId
            if (!homeTeamObj || !awayTeamObj) {
              return false;
            }

            // If there is no news articles for a fixture
            if (
              !fixture.pulse_id ||
              !news[fixture.pulse_id] ||
              !(
                news[fixture.pulse_id][homeTeamObj.id] ||
                news[fixture.pulse_id][awayTeamObj.id]
              )
            ) {
              return false;
            }

            const homeTeamArticle = news[fixture.pulse_id][homeTeamObj.id];
            const awayTeamArticle = news[fixture.pulse_id][awayTeamObj.id];
            return (
              <StyledSlide className="slide-test" key={fixture.id}>
                <StyledSlideInner>
                  <SliderHeader>
                    <LogoWrapper>
                      <Badge
                        team={homeTeamObj}
                        isPresentation={true}
                        sizes="24px"
                      />
                    </LogoWrapper>
                    <Hashtag>{`#${homeTeamObj.short_name}${awayTeamObj.short_name}`}</Hashtag>
                    <LogoWrapper>
                      <Badge
                        team={awayTeamObj}
                        isPresentation={true}
                        sizes="24px"
                      />
                    </LogoWrapper>
                  </SliderHeader>
                  <SliderArticles className="articles-list">
                    <>
                      {homeTeamArticle && (
                        <SliderArticle className="article article--home">
                          <div>
                            <Badge
                              team={homeTeamObj}
                              isPresentation={true}
                              sizes="16px"
                            />
                          </div>
                          {homeTeamArticle.hotlinkUrl ? (
                            <>
                              <StyledArticleTitleAnchor
                                href={homeTeamArticle.hotlinkUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {homeTeamArticle.title}
                                <NewTabCopy />
                                <ExternalIcon />
                              </StyledArticleTitleAnchor>
                            </>
                          ) : (
                            <StyledArticleTitle>
                              {homeTeamArticle.title}
                            </StyledArticleTitle>
                          )}
                        </SliderArticle>
                      )}
                      {awayTeamArticle && (
                        <SliderArticle className="article article--away">
                          <div>
                            <Badge
                              team={awayTeamObj}
                              isPresentation={true}
                              sizes="16px"
                            />
                          </div>
                          {awayTeamArticle.hotlinkUrl ? (
                            <>
                              <StyledArticleTitleAnchor
                                href={awayTeamArticle.hotlinkUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {awayTeamArticle.title}
                                <NewTabCopy />
                                <ExternalIcon />
                              </StyledArticleTitleAnchor>
                            </>
                          ) : (
                            <StyledArticleTitle>
                              {awayTeamArticle.title}
                            </StyledArticleTitle>
                          )}
                        </SliderArticle>
                      )}
                    </>
                  </SliderArticles>
                </StyledSlideInner>
              </StyledSlide>
            );
          })}
        </StyledSlider>
      </StyledNewsTickerSlider>
    </div>
  );
};

export default NewsTicker;

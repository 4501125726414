import { rgba } from "polished";
import * as React from "react";
import { Box, Flex } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { PatternWrap } from "./GraphicPatterns";

interface IStyledProps {
  hasBorder?: boolean;
  isPadded?: boolean;
}

const StyledPanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const PanelHeading = styled.h3`
  flex: 1;
  margin: ${({ theme }) => theme.space[3]} 0;
  padding: 0 ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.7rem;
`;

const PanelBody = styled.div<IStyledProps>`
  padding-top: ${({ theme }) => theme.space[2]};

  ${(props) =>
    props.isPadded &&
    css`
      padding-right: ${({ theme }) => theme.space[2]};
      padding-left: ${({ theme }) => theme.space[2]};
    `}

  ${(props) =>
    props.hasBorder &&
    css`
      position: relative;
      width: 100%;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background-image: linear-gradient(
          to right,
          ${({ theme }) => rgba(theme.colors.lightGrey, 0)},
          ${({ theme }) => theme.colors.lightGrey},
          ${({ theme }) => rgba(theme.colors.lightGrey, 0)}
        );
      }
    `}
`;

const PanelFooter = styled.div`
  margin: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[2]} 0;
  padding-bottom: ${({ theme }) => theme.space[3]};
`;

interface IPanelHeaderProps {
  icon?: React.ReactNode;
  title: React.ReactNode;
}

const PanelHeader: React.FC<IPanelHeaderProps> = ({
  children,
  icon = null,
  title,
}) => (
  <StyledPanelHeader>
    <PanelHeading>
      {icon ? (
        <Flex alignItems="center">
          <Box mr={2}>{icon}</Box>
          <span>{title}</span>
        </Flex>
      ) : (
        <>{title}</>
      )}
    </PanelHeading>
    {children}
  </StyledPanelHeader>
);

class Panel extends React.Component {
  public static Header = PanelHeader;
  public static Body = PanelBody;
  public static Footer = PanelFooter;

  render() {
    return <PatternWrap>{this.props.children}</PatternWrap>;
  }
}

export default Panel;

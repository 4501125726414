import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "rebass";
import { getElementStatsByIdentifier } from "../../../core/store/element-stats/reducers";
import { IElementStat } from "../../../core/store/element-stats/types";
import { getElementsById } from "../../../core/store/elements/reducers";
import { getTeamsById } from "../../../core/store/teams/reducers";
import { getElementStatNames, getStatAbbr } from "../../../utils/element-stats";
import { PillItem, PillList } from "../../pillList";
import { FixtureStatsTableWrap, StyledFixtureStatsTable } from "../../styles";
import TeamPillRadio from "../../TeamPillRadio";
import Tooltip, { TooltipLabel } from "../../Tooltip";
import { VisuallyHidden } from "../../Utils";
import FixtureStatsRow from "../FixtureStatsRow";
import { IFixtureStatsTable } from "./types";

const FixtureStatsTable: React.FC<IFixtureStatsTable> = ({
  fixture,
  fixtureStats,
}) => {
  const teamsById = useSelector(getTeamsById);
  const elementsById = useSelector(getElementsById);
  const statsByName: Record<string, IElementStat> = useSelector(
    getElementStatsByIdentifier
  );
  const [filteredStats, setFilteredStats] = useState(fixtureStats);

  const teamH = teamsById[fixture.team_h];
  const teamA = teamsById[fixture.team_a];

  const statNames = getElementStatNames();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const teamId = Number(e.target.value);

    return teamId
      ? setFilteredStats(
          fixtureStats.filter((s) => elementsById[s.element].team === teamId)
        )
      : setFilteredStats(fixtureStats);
  };
  return (
    <>
      <Box py={3} px={4}>
        <PillList>
          {[0, teamH.id, teamA.id].map((teamId) => (
            <PillItem key={teamId}>
              <TeamPillRadio teamId={teamId} handleChange={handleChange} />
            </PillItem>
          ))}
        </PillList>
      </Box>
      <FixtureStatsTableWrap>
        <StyledFixtureStatsTable>
          <thead>
            <tr>
              <th>
                <VisuallyHidden>Info</VisuallyHidden>
              </th>
              <th>Player</th>
              <th>Pts</th>
              {statNames.map((n) => (
                <th key={n}>
                  <Tooltip content={statsByName[n].label}>
                    <TooltipLabel>{getStatAbbr(n, statsByName)}</TooltipLabel>
                  </Tooltip>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredStats.map((s) => (
              <FixtureStatsRow stat={s} key={s.element} />
            ))}
          </tbody>
        </StyledFixtureStatsTable>
      </FixtureStatsTableWrap>
    </>
  );
};

export default FixtureStatsTable;
